import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import useCellModes from '@/hooks/useCellModes';

export const VehicleScheduleTable = ({ rows, updateRows, disabled }) => {
	const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellModes();

	const columns = [
		{
			field: 'heavyVehicles',
			headerName: '',
			flex: 0.3,
			minWidth: 100,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => (params.row.id === 0 ? 'Light vehicles' : 'Heavy vehicles'),
			// renderEditCell: (params) => <DataGridNumericFormat {...params} isNumber={true} />,
		},
		{
			field: 'numberOfVehicles',
			headerName: 'Number of vehicles',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			// renderCell: (params) => {
			// 	let value = parseInt(params.row.sumInsured ?? 0);
			// 	value = isNaN(value) ? 0 : value;
			// 	if (value > 0) {
			// 		return formatCurrency(value, false);
			// 	} else {
			// 		return (
			// 			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter number of vehicles</Typography>
			// 		);
			// 	}
			// },
			renderEditCell: (params) => <DataGridNumericFormat {...params} isNumber={true} />,
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			flex: 0.3,
			minWidth: 150,
			// editable: false,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, false);
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} isCurrency={true} />,
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => !disabled}
			// getRowId={(row) => `${row.year}-${row.make}-${row.model}`}
			// checkboxSelection
			// onRowSelectionModelChange={setRowSelectionModel}
			// rowSelectionModel={rowSelectionModel}
			cellModesModel={cellModesModel}
			onCellModesModelChange={handleCellModesModelChange}
			onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
