export const DEAL_NAME_HELP_TEXT = 'Enter a clear and friendly name for this business deal to help you easily identify it later.';
export const FOLDER_NAME_HELP_TEXT = 'Enter a short, clear and friendly name for the folder to help you easily identify it later.';
export const DEAL_OVERVIEW_HELP_TEXT = 'Add a short description to help capture the purpose or context of the deal. This helps keep track of what the deal involves, especially when managing multiple deals for the same client. You can always update this later from the Deal Overview tab.';
export const YEAR_FOLDER_HELP_TEXT = 'Specify the year this business deal applies to, keeping your records organised.';
export const PREMIUM_HELP_TEXT = 'Enter the current premium amount for this business deal.';
export const PRIORITY_HELP_TEXT = 'Indicate the priority level of this deal to help focus on the most urgent tasks.';
export const RENEWAL_DATE_HELP_TEXT = 'Set the renewal date for this business deal to ensure timely follow-ups.';
export const ASSIGNED_TO_HELP_TEXT = 'Select the broker responsible for managing this business deal.';
export const SEARCH_CLIENT_HELP_TEXT = 'Search for and select the client associated with this business deal.';
export const UPLOAD_FILES_HELP_TEXT = 'Upload any relevant files for this deal—these will be securely attached for easy access later.';
export const NOTES_HELP_TEXT = 'Include any additional details or context that might be helpful for future reference.';
