import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { formatCurrency } from '@/utils/constants';
import { NumericFormat } from 'react-number-format';

const EXCESS_OPTIONS = [250, 500, 750];

const DataGridExcessSelect = (props) =>  {
	const { id, field, value, colDef, hasFocus } = props;
	const apiRef = useGridApiContext();
	const [optionValue, setOptionValue] = useState(value);
	const [open, setOpen] = useState(true);
	const [inputRef, setInputRef] = useState(null);

	useLayoutEffect(() => {
		if (hasFocus && inputRef) {
			inputRef.focus();
		}
	}, [hasFocus, inputRef]);

	const handleChange = useCallback(
		(event, newValue) => {
			// let floatValue = parseFloat(newValue);
			// console.log('NEW VALUE', newValue, floatValue);
			// floatValue = /[^0-9.]/.test(newValue) ? newValue : formatCurrency(floatValue.toString(), false);
			// setOptionValue(floatValue);
			// apiRef.current.setEditCellValue({ id, field, value: floatValue });
			setOptionValue(newValue);
			apiRef.current.setEditCellValue({ id, field, value: newValue });
			if (event?.type === 'click') {
				setOpen(false);
				apiRef.current.stopCellEditMode({ id, field });
			}
		},
		[apiRef, field, id]
	);

	return (
		<Autocomplete
			value={optionValue}
			open={open}
			onOpen={() => setOpen(true)}
			onChange={handleChange}
			onInputChange={handleChange}
			fullWidth
			options={EXCESS_OPTIONS}
			freeSolo
			getOptionLabel={(option) => option.toString()}
			renderOption={(props, option, { selected }) => {
				const { key, ...optionProps } = props;
				return (
					<li key={key} {...optionProps}>
						{formatCurrency(option, false)}
					</li>
				);
			}}
			renderInput={(inputParams) => (
				<TextField 
					{...inputParams}
					variant='standard'
					sx={{ '& .MuiInputBase-root': {
						backgroundColor: 'transparent',
						pl: '0.5em'
					}}}
					InputProps={{
						...inputParams.InputProps,
						disableUnderline: true,
						height: '100%'
					}}
					inputRef={(ref) => setInputRef(ref)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							setOpen(false);
						}
						return e.key === ' ' && e.stopPropagation();
					}}
				/>
				// <NumericFormat
				// 	{...inputParams}
				// 	customInput={TextField}
				// 	thousandSeparator
				// 	decimalScale={0}
				// 	fixedDecimalScale
				// 	allowNegative={false}
				// 	prefix='$'
				// 	variant='standard'
				// 	sx={{ '& .MuiInputBase-root': {
				// 		backgroundColor: 'transparent',
				// 		pl: '0.5em'
				// 	}}}
				// 	InputProps={{
				// 		...inputParams.InputProps,
				// 		disableUnderline: true,
				// 		height: '100%'
				// 	}}
				// 	inputRef={(ref) => setInputRef(ref)}
				// 	onKeyDown={(e) => {
				// 		if (e.key === 'Enter') {
				// 			setOpen(false);
				// 		}
				// 		return e.key === ' ' && e.stopPropagation();
				// 	}}
				// />
			)}
		/>
	);
};

export default DataGridExcessSelect;