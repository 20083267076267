import { FOLDER_NAME, FOLDER_NAME_MAX } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import { useGetClientRowQuery } from '@/features/clients/clientsApi';
import { useGetFoldersAndFilesQuery } from '@/features/msGraph/msGraphApi';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { getFolderFromPipeline, getPipelineFromSlug, tryDecodeFilePath } from '@/utils/constants';
import { FOLDER_NAME_HELP_TEXT } from '@/utils/helperText';
import { FormHelperText, FormLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CLIENT_ID } from './validationSchema';

const FolderNameField = ({ formik, disabled, dealName='Deal name' }) => {
	const location = useLocation();

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const { data: clientRow, ...clientRowQuery } = useGetClientRowQuery(formik.values[CLIENT_ID], {
		skip: !formik.values[CLIENT_ID],
	});

	const filesQuery = useGetFoldersAndFilesQuery(
		{
			itemId: clientRow?.driveId,
			driveId: clientRow?.clientFolderDriveId,
			recursive: false,
		},
		{ skip: !clientRow?.driveId || !clientRow?.clientFolderDriveId }
	);

	const yearPathDriveId = useMemo(() => {
		const { folders } = filesQuery.data ?? { folders: [] };
		const folder = folders.find((f) => tryDecodeFilePath(f.name, formik.values.yearFolder));
		return folder?.id;
	}, [filesQuery.data]);

	const yearPathFilesQuery = useGetFoldersAndFilesQuery(
		{
			itemId: yearPathDriveId,
			driveId: clientRow?.clientFolderDriveId,
			recursive: false,
		},
		{ skip: !yearPathDriveId || !clientRow?.clientFolderDriveId }
	);

	const dealFolderDriveId = useMemo(() => {
		const { folders } = yearPathFilesQuery.data ?? { folders: [] };
		const pipelineId = getPipelineFromSlug(location.pathname);
		const folderName = getFolderFromPipeline(pipelineId);
		const folder = folders.find((f) => tryDecodeFilePath(f.name, folderName));
		return folder?.id;
	}, [yearPathFilesQuery.data]);

	const dealFolderFilesQuery = useGetFoldersAndFilesQuery(
		{
			itemId: dealFolderDriveId,
			driveId: clientRow?.clientFolderDriveId,
			recursive: false,
		},
		{ skip: !dealFolderDriveId || !clientRow?.clientFolderDriveId }
	);

	const existingFolders = useMemo(() => {
		const { folders } = dealFolderFilesQuery.data ?? { folders: [] };
		console.log('Existing Folders', folders);
		return folders.map((f) => f.name);
	}, [dealFolderFilesQuery.data]);

	useEffect(() => {
		if (existingFolders.length != (formik.values.existingFolders ?? []).length) {
			formik.setFieldValue('existingFolders', existingFolders);
		}
	}, [existingFolders]);

	return (
		<>
			<FormLabel required>{dealName}</FormLabel>
			<TextField
				fullWidth
				name={FOLDER_NAME}
				{...formik.getFieldProps(FOLDER_NAME)}
				error={getError(FOLDER_NAME)}
				
				disabled={disabled || filesQuery.isLoading || yearPathFilesQuery.isLoading}
				required
			/>

			<Stack direction='row' justifyContent='space-between'>
				<Stack direction='column'>
					{getError(FOLDER_NAME) && (
						<FormHelperText error>{getErrorMessage(FOLDER_NAME)}</FormHelperText>
					)}
					<FormHelperText>{FOLDER_NAME_HELP_TEXT}</FormHelperText>
				</Stack>

				<FormHelperText
					error={
						getError(FOLDER_NAME) || formik.values[FOLDER_NAME].length > FOLDER_NAME_MAX
					}
					sx={{
						...(formik.values[FOLDER_NAME].length >= FOLDER_NAME_MAX && {
							fontWeight: 'bold',
						}),
						mr: '14px',
					}}
				>
					<span>{formik.values[FOLDER_NAME].length}</span>
					{`/${FOLDER_NAME_MAX}`}
				</FormHelperText>
			</Stack>
		</>
	);
};

export default FolderNameField;
