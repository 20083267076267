/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Stack,
	IconButton,
	Menu,
	MenuItem,
	Link,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import { ChevronRightOutlined, Delete } from '@mui/icons-material';
import {
	folioBlue,
	formatDateString,
	getPipelineIcon,
	getUrlFromPipeline,
	DEAL_STATUS_TYPES,
} from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { ClaimsIcon } from '@/components/icons/ClaimsIcon';
import { paths } from '@/app/routes';
import ClientContext from '@/context/clientContext';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { RefreshIcon } from '@/components/icons/RefreshIcon';
import { formatCurrency } from '@/utils/constants';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { useDeleteDealMutation, useGetDealRowQuery } from '@/features/deals/dealsApi';
import { showToast } from '@/features/toast/toastSlice';
import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';
import { EndorsementIcon } from '@/components/icons/Endorsement';
import { RenewalIcon } from '@/components/icons/Renewal';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { IS_DEV } from '@/utils/environment';

export const OldPolicyTransactionRow = ({ hit: deal }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
	const { client } = useContext(ClientContext);
	const [subMenuType, setSubMenuType] = useState(null);

	const dealId = deal?.id;

	const objectType = IS_DEV
		? deal?.pipeline === process.env.REACT_APP_PIPELINE_CLAIMS ||
		  deal?.pipeline === process.env.REACT_APP_PIPELINE_ENDORSEMENTS
			? 'ticket'
			: 'deal'
		: deal?.pipeline === process.env.REACT_APP_PIPELINE_CLAIMS
			? 'ticket'
			: 'deal';

	const open = Boolean(anchorEl);
	const subOpen = Boolean(subMenuAnchorEl);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const pipeline = deal?.pipeline ?? deal?.properties?.hs_pipeline;

	const [showDialog, setShowDialog] = useState(false);

	const [deleteDeal, { isLoadingDeal }] = useDeleteDealMutation({
		fixedCacheKey: `delete-deal-${dealId}-${objectType}`,
	});

	const isLoading = deal?.policiesLoading || isLoadingDeal;

	const handleClose = (menuType) => {
		if (menuType === 'main') {
			setAnchorEl(null);
		} else {
			setSubMenuAnchorEl(null);
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOpenSubMenu = (event, type) => {
		event.stopPropagation();
		setSubMenuAnchorEl(event.currentTarget);
		setSubMenuType(type);
	};

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const { data: dealRow } = useGetDealRowQuery(dealId, {
		skip: !dealId,
	});

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const stages = useMemo(
		() =>
			pipelineQuery?.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? []).flatMap((r) =>
				r?.stages.map((s) => ({ ...s, pipeline: r?.label }))
			) ??
			[],
		[pipelineQuery.data?.pipelines]
	);

	const { active, inactive, closed } = useMemo(() => {
		return {
			active: stages.filter((stage) => stage.metadata?.isClosed != 'true').map((s) => s.id),
			inactive: stages
				.filter(
					(stage) =>
						stage.metadata?.probability?.toString() == '1.0' ||
						(IS_DEV &&
							stage.metadata?.isClosed == 'true' &&
							stage.metadata?.probability != '0.0')
				)
				.map((s) => s.id),
			closed: stages
				.filter((stage) => stage.metadata?.probability?.toString() == '0.0')
				.map((s) => s.id),
		};
	}, [stages]);

	const isRenewable = useMemo(
		() =>
			deal?.policies.length > 0 &&
			showNewRenewalsFeature &&
			(dealRow?.isRenewable == null || dealRow?.isRenewable === true) &&
			inactive.includes(dealRow?.dealStage),
		[deal?.policies, showNewRenewalsFeature, dealRow, inactive]
	);

	const handleRenewDeal = () => {
		console.log('RENEW DEAL', deal, client?.hs_object_id, deal?.policies);

		if (deal && client?.hs_object_id) {
			const { hs_object_id: clientId } = client;
			const dealIds = [dealId];
			const policies = deal?.policies || [];
			navigate(paths.createRenewal, {
				state: {
					dealIds,
					clientId,
					policies,
				},
			});
		} else {
			console.error('Missing deal or client information');
		}
	};

	const handlePolicySelect = (policy, type) => {
		handleClose();
		const policyInsight = policy?.policy ?? {};
		const transaction = policy?.transaction ?? {};
		if (type === 'claim') {
			navigate(paths.createClaim, {
				state: {
					client: client,
					policy: policyInsight,
					transaction: transaction,
				},
			});
		} else if (type === 'endorse') {
			navigate(paths.createEndorsement, {
				state: {
					client: client,
					policy: policyInsight,
					transaction: transaction,
				},
			});
		}
	};

	const renderPolicyMenuItems = () => {
		return deal?.policies?.map((policy, index) => (
			<MenuItem key={index} onClick={() => handlePolicySelect(policy, subMenuType)}>
				<Stack>
					<Typography>
						{policy?.transaction?.PolicyNumber ?? policy?.policy?.PolicyNumber ?? '-'}
					</Typography>
					<Typography>
						{`${
							policy?.transaction?.InvoiceNumber
								? `${policy?.transaction?.InvoiceNumber} - `
								: ''
						}`}
						{policy?.transaction?.BriefDescription ??
							policy?.policy?.Description ??
							'-'}
					</Typography>
				</Stack>
			</MenuItem>
		));
	};

	const dealLink = useMemo(() => {
		const path = getUrlFromPipeline(pipeline);
		return `${path}/${dealId}`;
	}, [deal, pipeline]);

	const subMenus = [
		<MenuItem onClick={(e) => handleOpenSubMenu(e, 'claim')} key={'menu-claims'}>
			<ListItemIcon>
				<ClaimsIcon bgColor={folioBlue} width='22px' height='22px' sx={{ ml: '4px' }} />
			</ListItemIcon>
			<ListItemText sx={{ color: folioBlue }}>Claim</ListItemText>
			<ChevronRightOutlined sx={{ color: folioBlue }} />
		</MenuItem>,

		<MenuItem onClick={(e) => handleOpenSubMenu(e, 'endorse')} key={'menu-endorse'}>
			<ListItemIcon>
				<EndorsementIcon
					bgColor={folioBlue}
					width='22px'
					height='22px'
					sx={{ ml: '4px' }}
				/>
			</ListItemIcon>
			<ListItemText sx={{ color: folioBlue }}>Endorse</ListItemText>
			<ChevronRightOutlined sx={{ color: folioBlue }} />
		</MenuItem>,

		<Menu
			anchorEl={subMenuAnchorEl}
			open={subOpen}
			onClose={() => handleClose('submenu')}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			key={'menu-policy-submenu'}
		>
			{renderPolicyMenuItems()}
		</Menu>,
	];

	const getMenuLink = () => {
		return (
			<>
				<IconButton aria-label='edit' onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu anchorEl={anchorEl} open={open} onClose={() => handleClose('main')}>
					<MenuItem
						onClick={(e) => {
							handleClose('main');
							navigate(dealLink);
						}}
					>
						<ListItemIcon>
							<ArticleIcon sx={{ color: folioBlue }} />
						</ListItemIcon>
						<ListItemText sx={{ color: folioBlue }}>View deal</ListItemText>
					</MenuItem>
					{deal?.policies?.length > 0 && !isLoading && subMenus.map((s) => s)}
					{isRenewable && !isLoading && (
						<MenuItem onClick={handleRenewDeal}>
							<ListItemIcon>
								<RenewalIcon
									bgColor={folioBlue}
									width='22px'
									height='22px'
									sx={{ ml: '4px' }}
								/>
							</ListItemIcon>
							<ListItemText sx={{ color: folioBlue }}>Renew</ListItemText>
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const handleDeleteConfirmation = async () => {
		setShowDialog(false);
		const path = getUrlFromPipeline(deal?.pipeline ?? deal?.hs_pipeline);

		await deleteDeal({ dealId, objectType })
			.unwrap()
			.then((res) => {
				dispatch(
					showToast({
						message: `Deal ${dealId} successfully deleted.`,
						success: true,
					})
				);
				navigate(path);
			})
			.catch((err) => {
				console.log(`Error deleting deal ${dealId}`, err);
				dispatch(
					showToast({
						message: 'Error deleting deal; please try again.',
						error: true,
					})
				);
			});
	};
	// const isInactive = useMemo(() => {
	// 	const today = new Date();
	// 	const isLapsed = transaction?.ToDate
	// 	  ? isAfter(today, new Date(transaction?.ToDate))
	// 	  : false;
	// 	return isLapsed || transaction?.IsClosed || !transaction?.IsLatestInPeriod;
	//   }, [transaction?.IsLatestInPeriod, transaction?.IsClosed, transaction?.ToDate]);

	// const isInactive = useMemo(() => {
	// 	const today = new Date();

	// 	// Check if any policy is inactive
	// 	const isAnyPolicyInactive = deal?.policies?.some((policy) => {
	// 		const transaction = policy?.transaction;
	// 		if (!transaction) return false;

	// 		const isLapsed = transaction?.ToDate
	// 			? isAfter(today, new Date(transaction?.ToDate))
	// 			: false;
	// 		return (
	// 			isLapsed || transaction?.IsClosed || !transaction?.IsLatestInPeriod
	// 		);
	// 	});

	// 	return isAnyPolicyInactive;
	// }, [deal?.policies]);

	const checkPolicyInactive = (transaction) => {
		if (!transaction) return false;
		// console.log('transaction: ', transaction);
		const today = new Date();
		const isLapsed = transaction?.ToDate
			? isAfter(today, new Date(transaction?.ToDate))
			: false;
		//transaction?.IsClosed !transaction?.IsCurrent
		const isClosed =
			transaction?.IsClosed?.length > 0 ? transaction?.IsClosed : transaction?.IsCurrent;
		return isLapsed || !transaction?.IsLatestInPeriod || isClosed;
	};

	return (
		<>
			{deal?.id || deal?.hs_object_id ? (
				<TableRow
					key={`table-row-${deal?.id ?? deal?.hs_object_id}`}
					selected={showDialog}
					sx={{
						'&.Mui-selected': {
							color: 'rgba(0, 0, 0, 0.9) !important',
							'& .MuiTypography-root': {
								color: 'rgba(0, 0, 0, 0.9) !important',
							},
						},
					}}
				>
					<TableCell>
						<Typography variant='subtitle2'>{deal?.dealName}</Typography>
					</TableCell>
					<TableCell width={600}>
						{isLoading ? (
							<Skeleton variant='text' />
						) : deal?.policies?.length > 0 ? (
							<Stack direction='column' spacing={3} width='100%'>
								{deal?.policies?.map(
									(policy, policyIndex) =>
										Object.keys(policy?.policy || {}).length > 0 && (
											<Stack key={policyIndex} direction='column' spacing={1}>
												<Stack
													key={policyIndex}
													direction='row'
													spacing={2}
												>
													<Typography variant="subtitle2" fontWeight={'bold'} noWrap>
														{`INV: ${policy?.transaction?.InvoiceNumber ?? policy?.policy?.InvoiceNumber ?? '-'}`}
													</Typography>
													<Typography variant="subtitle2" fontWeight={'bold'} noWrap>
														{`POLICY: ${policy?.transaction?.PolicyNumber ?? policy?.policy?.PolicyNumber ?? '-'}`}
													</Typography>
												</Stack>
												<Stack
													key={policyIndex}
													direction='row'
													spacing={2}
													alignItems='center'
												>
													<Typography
														variant='subtitle2'
														sx={{
															whiteSpace: 'normal',
															wordBreak: 'break-word',
															overflowWrap: 'break-word',
														}}
													>
														{policy?.transaction?.BriefDescription ??
															policy?.policy?.Description ??
															'-'}
													</Typography>
													{(policy?.transaction?.FromDate ||
														policy?.policy?.FromDate) &&
														(policy?.transaction?.ToDate ||
															policy?.policy?.ToDate) && (
														<Typography variant='subtitle2'>
															{`${formatDateString(
																policy?.transaction?.FromDate ||
																		policy?.policy?.FromDate
															)} - ${formatDateString(
																policy?.transaction?.ToDate ||
																		policy?.policy?.ToDate
															)}`}
														</Typography>
													)}
												</Stack>
												{policy?.transaction?.TransactionTypeName ===
													'Endorsement' && (
													<Typography>
														{`Effective Date: ${formatDateString(
															policy?.transaction?.EffectiveDate
														)}`}
													</Typography>
												)}
											</Stack>
										)
								)}
							</Stack>
						) : (
							<Typography variant='body2'>No policies linked</Typography>
						)}
					</TableCell>
					<TableCell width={150}>
						{isLoading ? (
							<Skeleton variant='text' />
						) : (
							<Stack direction='column' spacing={4} width='100%'>
								{/*Check if no policie linked or deal isn't closed */}
								{deal?.status == DEAL_STATUS_TYPES.archived ? (
									<Stack
										direction='row'
										width='100%'
										alignItems='center'
										spacing={'0.25em'}
									>
										<BlinkingCircle fontSize='6px' color={'rgba(0,0,0,0.5)'} />
										{'Archived'}
									</Stack>
								) : deal?.policies?.length === 0 ||
								  !inactive.includes(deal?.dealStage) ? (
										<Stack
											direction='row'
											width='100%'
											alignItems='center'
											spacing={'0.25em'}
										>
											<BlinkingCircle
												fontSize='6px'
												color={
													closed.includes(deal?.dealStage)
														? '#FF0000'
														: folioBlue
												}
											/>
											{closed.includes(deal?.dealStage)
												? 'Closed'
												: 'In progress'}
										</Stack>
									) : (
										deal?.policies?.map(
											(policy, policyIndex) =>
												Object.keys(policy?.policy || {}).length > 0 && (
													<Stack
														key={policyIndex}
														direction='row'
														width='100%'
														alignItems='center'
														spacing={'0.25em'}
													>
														<BlinkingCircle
															fontSize='6px'
															color={
																closed.includes(deal?.dealStage)
																	? '#FF0000'
																	: checkPolicyInactive(
																		policy?.transaction ||
																			policy?.policy
																  )
																		? '#FF0000'
																		: '#00C650'
															}
														/>
														{closed.includes(deal?.dealStage)
															? 'Closed'
															: checkPolicyInactive(
																policy?.transaction ||
																	policy?.policy
														  )
																? 'Inactive'
																: 'Active'}
													</Stack>
												)
										)
									)}
							</Stack>
						)}
					</TableCell>
					<TableCell>
						<Stack direction='row' alignItems={'center'} spacing={1} width='100%'>
							{getPipelineIcon(pipeline, '1.5em')}
							<Typography variant='subtitle2'>
								{pipelineQuery.data?.pipelines?.label}
							</Typography>
						</Stack>
					</TableCell>
					<TableCell>{getMenuLink()}</TableCell>
				</TableRow>
			) : (
				<></>
			)}
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={handleDeleteConfirmation}
					actionName={'delete'}
				/>
			)}
		</>
	);
};
