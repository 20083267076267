import { useAttachmentsContext } from '@/context/attachmentsContext';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { determineIfCanCreateFolder, determineIfFolderDeletable } from '@/utils/folders';
import {
	FormLabel,
	Skeleton,
	Typography,
	Stack,
	Box,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import React, { useEffect, useMemo, useState } from 'react';
import { FolderOutlined } from '@mui/icons-material';
import { useFolderSelectContext } from '@/context/folderSelectContext';
import { useGetDriveItemQuery, useGetFoldersAndFilesQuery } from '@/features/msGraph/msGraphApi';
import { useSelector } from 'react-redux';

const SharepointTreeItem = ({ item, disabled }) => {
	// const { clientFolderDriveId } = useAttachmentsContext();

	const { apiRef, expandedItems, selectedFolder, setSelectedFolder, cannotSelect: determineIfFolderSelectable } =
		useFolderSelectContext();

	const expanded = useMemo(() => expandedItems.includes(item.id), [expandedItems]);

	const folderQuery = useGetFoldersAndFilesQuery(
		{
			itemId: item?.id,
			driveId: item?.parentReference?.driveId,
		},
		{ skip: !item.id || !item?.parentReference?.driveId || !expanded }
	);

	const folders = useMemo(() => {
		const { folders: newFolders } = folderQuery.data ?? { folders: [] };
		console.log('FOLDERS', folderQuery.data);
		return newFolders.map((f) => {
			const cannotSelect = determineIfFolderSelectable(f);
			return {
				...f,
				isFolder: !Object.keys(f).includes('file'),
				cannotSelect,
			};
		});
	}, [folderQuery.data]);

	const generateLoadingSkeletons = () =>
		Array.from({ length: item?.folder?.childCount ?? 1 }, (x, i) => {
			return <Skeleton key={`loading-tree-item-${item.id}-${i}`} />;
		});

	const isDisabled = disabled || item.cannotSelect;// || selectedRows.some((r) => r.id === item.id);
	const checked = useMemo(() => selectedFolder == item.id, [selectedFolder]);

	const handleChange = (e) => {
		apiRef?.current?.focusItem(e, checked ? null : item.id);
		setSelectedFolder(checked ? null : item.id);
	};

	return (
		<TreeItem
			itemId={item.id}
			label={
				<Stack
					direction='row'
					alignItems={'center'}
					onClick={(e) => {
						if (!isDisabled) {
							handleChange(e);
						}
					}}
				>
					<Checkbox
						size={'small'}
						checked={checked}
						onChange={handleChange}
						disabled={isDisabled}
						icon={<FolderOutlined />}
						checkedIcon={<FolderIcon />}
						sx={{ p: '4px' }}
					/>
					<Typography {...(checked && { sx: { fontWeight: 'bold' } })}>
						{item.name}
					</Typography>
				</Stack>
			}
		>
			{folderQuery.isLoading ? (
				generateLoadingSkeletons()
			) : folders.length > 0 ? (
				folders.map((f, i) => (
					<SharepointTreeItem
						key={`sharepoint-tree-item-child-${item.id}-${i}`}
						item={f}
					/>
				))
			) : (
				<Box pl={'3em'}>
					<Typography variant={'task_updated'}>No folders found</Typography>
				</Box>
			)}
		</TreeItem>
	);
};

const SharepointFolderTree = () => {
	// const { deal, dealRow, client } = useAttachmentsContext();

	const { expandedItems, setExpandedItems, itemId, driveId, disableParent } =
		useFolderSelectContext();

	const { data: driveItem, ...driveItemQuery } = useGetDriveItemQuery(
		{
			driveId,
			itemId,
		},
		{ skip: !itemId || !driveId }
	);

	useEffect(
		() => console.log('DRIVE ITEM', { driveItem, itemId, driveId }),
		[driveItem, itemId, driveId]
	);
	// const rowId = useMemo(
	// 	() => (deal ? dealRow?.driveId : client?.clientRow?.driveId),
	// 	[deal, dealRow?.driveId, client?.clientRow?.driveId]
	// );

	// const item = useMemo(
	// 	() => ({
	// 		id: rowId,
	// 		name: dealRow?.dealName ?? client?.clientRow?.name,
	// 		cannotCreate: !deal,
	// 	}),
	// 	[rowId, dealRow?.dealName, client?.clientRow?.name]
	// );

	const handleExpandedItemsChange = (event, itemIds) => {
		setExpandedItems(itemIds);
	};

	return (
		<Stack>
			<FormLabel>Select folder</FormLabel>
			<Box
				sx={{
					maxHeight: '20em',
					overflowY: 'auto',
					pt: '1em',
				}}
			>
				<SimpleTreeView
					disableSelection
					expandedItems={expandedItems}
					onExpandedItemsChange={handleExpandedItemsChange}
				>
					{driveItem ? <SharepointTreeItem item={driveItem} disabled={disableParent} /> : <Skeleton />}
				</SimpleTreeView>
			</Box>
		</Stack>
	);
};

export default SharepointFolderTree;
