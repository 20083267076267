import { LOGIN_TYPE } from '@/app/authConfig';
import { clearAccessToken, clearAccount, setAccessToken } from '@/features/msalAccount/msalAccountSlice';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';

export const useMSALHelper = () => {
	// Using MSAL hook to get auth instance and accounts
	const { instance } = useMsal();
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);

	const extractToken = (response) => {
		const token = response.accessToken;
		const expiration = response.expiresOn ? new Date(response.expiresOn).getTime() : null;
		console.log('RESPONSE MS GRAPH ACCESS TOKEN', expiration);
		dispatch(setAccessToken({ accessToken: token, expiration }));
		return token;
	};
    
	const getAccessToken = async (scopes) => {
		const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
		const request = {
			scopes,
			account: currentAccount,
		};
		return await instance
			.acquireTokenSilent(request)
			.then(extractToken)
			.catch(async (error) => {
				console.error(
					'🙅 ~ [getMSAccessToken] Error retrieving access token silently',
					error
				);
				// Redirect user to login screen if there is an issue getting access token
				if (LOGIN_TYPE === 'popup') {
					return await instance
						.acquireTokenPopup(request)
						.then(extractToken)
						.catch((e) => {
							console.log('🙅 ~ [getMSAccessToken] Token popup error', e);
							dispatch(clearAccessToken());
							return Promise.reject(e);
						});
				} else {
					return await instance
						.acquireTokenRedirect(request)
						.then(extractToken)
						.catch((e) => {
							console.log('🙅 ~ [getMSAccessToken] Token redirect error', e);
							dispatch(clearAccessToken());
							return Promise.reject(e);
						});
				}
			});
	};

	const handleLogout = () => {
		const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
		if (LOGIN_TYPE === 'popup') {
			instance
				.logoutPopup({
					postLogoutRedirectUri: '/',
					mainWindowRedirectUri: '/',
					account: currentAccount,
				})
				.then(() => {
					localStorage.clear();
					dispatch(clearAccount());
					sessionStorage.clear();
				});
		} else {
			instance
				.logoutRedirect({
					postLogoutRedirectUri: '/',
					account: currentAccount,
				})
				.then(() => {
					localStorage.clear();
					dispatch(clearAccount());
					sessionStorage.clear();
				});
		}
	};

	return {
		getAccessToken,
		handleLogout,
	};
};
