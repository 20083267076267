/* eslint-disable no-mixed-spaces-and-tabs */
import * as Yup from 'yup';
import { CLIENT_NAME, CONTACT_ERROR, EMAIL, FIRST_NAME, LAST_NAME, OTHER_INFO, PHONE } from '@/components/forms/CreateNewBusinessForm/validationSchema';

const validationSchema = Yup.object().shape({
	[FIRST_NAME]: Yup.string().when(['isExisting', 'contacts'], {
		  is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		  then: () => Yup.string().required('First name is required.'),
		  otherwise: () => Yup.string(),
	}),
	[LAST_NAME]: Yup.string().when(['isExisting', 'contacts'], {
		  is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		  then: () => Yup.string().required('Last name is required.'),
		  otherwise: () => Yup.string(),
	}),
	[PHONE]: Yup.string().when(['isExisting', 'contacts'], {
		  is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		  then: () =>
			Yup.string()
			  .required('Phone number is required.')
			  .min(7, 'Phone number should be more than 7 characters')
			  .max(15, 'Phone number should be no more than 15 characters'),
		  otherwise: () => Yup.string(),
	}),
	[CONTACT_ERROR]: Yup.boolean()
		.test('contact-error', 'Contact error must be false', (value) => value === false),
	[EMAIL]: Yup.string().when(['isExisting', 'contacts'], {
		  is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		  then: () =>
			Yup.string()
			  .email('Email address must be a valid email')
			  .required('Email address is required.')
			  .test(
					'domain-length',
					'Email address must be a valid email',
					(value) => {
				  if (!value) return false;
				  const parts = value.split('@');
				  if (parts.length !== 2 || parts[1].length <= 2) {
							return false;
				  }
				  const domainParts = parts[1].split('.');
				  if (domainParts.length < 2 || domainParts.length > 3) {
							return false;
				  }
				  return domainParts.every((part) => part.length >= 2);
					}
			  ),
		  otherwise: () => Yup.string(),
	}),

});

export default validationSchema;