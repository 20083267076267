import React from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ContactFields } from './ContactFields';
import Grid from '@mui/material/Grid2';
import RemoveIcon from '@mui/icons-material/Remove';

export const MultiContactFields = ({ formik, loading }) => {
	const { values, setFieldValue } = formik;
	const { contacts } = values;

	const addContact = () => {
		setFieldValue('contacts', [
			...contacts,
			{ firstName: '', lastName: '', emailAddress: '', phoneNumber: '', isMainContact: false, existingContact: false },
		]);
	};

	const removeContact = (index) => {
		if (contacts.length > 1) {
			const newContacts = contacts.filter((_, i) => i !== index);
			setFieldValue('contacts', newContacts);
		}
	};

	return (
		<>
			{contacts.map((contact, index) => (
				<Grid container spacing={2} key={index}>
					<ContactFields
						formik={formik}
						loading={loading}
						index={index}
						hideHeader={true}
					/>
					{(contacts.length > 1 && index !== 0) && (
						<Grid item xs={12}>
							<Button disabled={formik.isSubmitting}  onClick={() => removeContact(index)}startIcon={<RemoveIcon />}>
          Remove Contact
							</Button>
						</Grid>
					)}
				</Grid>
			))}
			<Grid size={{xs: 12}}>
				<Button disabled={formik.isSubmitting} onClick={addContact} startIcon={<AddIcon />}>
          Add additional Contact
				</Button>
			</Grid>
		</>
	);
};