/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useContext, useEffect, useState } from 'react';
import SharepointFolderTree from '@/components/SharepointFolderTree';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks/useTreeViewApiRef';
import { determineIfCanCreateFolder } from '@/utils/folders';

const initialState = {
	apiRef: null,
	selectedFolder: '',
	setSelectedFolder: (folder) => {},
	expandedItems: [],
	setExpandedItems: (event, itemIds) => {},
	itemId: null,
	driveId: null,
	disableParent: false,
	cannotSelect: (folder) => false,
	selectedRows: [],
};

const FolderSelectContext = createContext(initialState);

export const useFolderSelectContext = () => {
	const context = useContext(FolderSelectContext);
	if (!context) {
		throw new Error('useFolderSelectContext must be used within a FolderSelectContextProvider');
	}
	return context;
};

export const FolderSelectContextProvider = ({ selectedFolder, setSelectedFolder, itemId, driveId, disableParent, cannotSelect }) => {
	const apiRef = useTreeViewApiRef();

	const [expandedItems, setExpandedItems] = useState([]);

	const contextValue = {
		apiRef,
		selectedFolder,
		setSelectedFolder,
		expandedItems,
		setExpandedItems,
		itemId,
		driveId,
		disableParent,
		cannotSelect: (folder) => cannotSelect ? cannotSelect(folder) : (folder) => true,
	};

	useEffect(() => {
		console.log('📋 ~ FOLDER SELECTION CONTEXT VALUE', contextValue);
	}, [contextValue]);

	return (
		<FolderSelectContext.Provider value={contextValue}>
			<SharepointFolderTree />
		</FolderSelectContext.Provider>
	);
};

export default FolderSelectContext;
