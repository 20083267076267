import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import { determineContext } from '@/hooks/determineContext';
import { closedPipelineStages } from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const EditPolicyTransactionButton = () => {
	const dispatch = useDispatch();
	const { dealstageId, dealsPipelineData } = determineContext();
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'addPolicyTransactions'});
	const showButton = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);
    
	const isRenewal = dealsPipelineData?.pipelines.id === process.env.REACT_APP_PIPELINE_RENEWALS;
	const isEndorsement = dealsPipelineData?.pipelines.id === process.env.REACT_APP_PIPELINE_RENEWALS;

	const handleOpenDialog = () => {
		dispatch(openDialog('editPolicyTransaction'));
	};
  
	const shouldShowButton = useMemo(() => {
		if (isRenewal || isEndorsement) return true;
		return closedPipelineStages.includes(dealstageId) && showButton;
	}, [isRenewal, dealstageId, showButton]);
    
	return shouldShowButton ? (
		<Button 
			variant="tab_header" 
			startIcon={<Add />}
			onClick={handleOpenDialog}
		>
			{'Add policy transaction'}
		</Button>
	) : null;
};