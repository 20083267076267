/* eslint-disable no-mixed-spaces-and-tabs */
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { folderStructure, tryDecodeFilePath } from '@/utils/constants';
import { MAX_SIMPLE_UPLOAD_SIZE, msGraphAxios } from '@/utils/msGraphUtils';
import { MSG_TYPE } from '@/utils/folders';
import { clearAccessToken } from '@/features/msalAccount/msalAccountSlice';
import { filesApi } from '../files/filesApi';
import { graphClient } from '@/utils/msGraphClient';

const getError = (error, dispatch) => {
	console.log('ERROR', error);
	const code = error?.response?.data?.error?.code ?? error?.code;
	console.log('ERROR CODE', code);
	if ((code ?? '').toString() === 'InvalidAuthenticationToken') {
		dispatch(clearAccessToken());
	}
	return {
		error: {
			code: code ?? error?.code,
			message: error.response?.data?.error?.message ?? error.message ?? error?.body,
		},
	};
};

const invalidateFilesTags = async (dispatch, { driveId, itemId }) => {
	try {
		await dispatch(
			filesApi.util.invalidateTags([{ type: 'FILES_LIST', id: `${driveId}-${itemId}` }])
		);
	} catch (e) {
		console.log('Error invalidating Files API tags', e);
	}
};

const encodeSharingUrl = (url) => {
	const base64Url = btoa(url).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
	return `u!${base64Url}`;
};

export const msGraphApi = createApi({
	reducerPath: 'msGraphApi',
	tagTypes: ['ITEMS', 'ITEM', 'MSG_FILE', 'LIST_ITEM', 'LIST', 'LISTS', 'DRIVES', 'BREADCRUMBS'],
	baseQuery: fakeBaseQuery(),
	endpoints: (builder) => ({
		getItems: builder.infiniteQuery({
			// providesTags: ['ITEMS'],
			providesTags: (result, error, { direction, driveId, itemId, orderBy, searchText }) => {
				return [
					{
						type: 'ITEMS',
						id: `${driveId}-${itemId}-${direction}-${orderBy}${
							searchText ? `-${searchText}` : ''
						}`,
					},
					// ...(result?.folders ?? []).concat(result?.files ?? []).map((f) => ({
					// 	type: 'ITEM',
					// 	id: f.id,
					// })),
					...(result?.files ?? [])
						.filter((f) => f?.file?.mimeType == MSG_TYPE)
						.map((f) => ({
							type: 'MSG_FILE',
							id: f.id,
						})),
				];
			},
			infiniteQueryOptions: {
				initialPageParam: null,
				// limit: 1,
				getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
					// console.log('GET NEXT PAGE', {
					// 	lastPage,
					// 	allPages,
					// 	lastPageParam,
					// 	allPageParams,
					// 	nextUrl: lastPage?.nextUrl,
					// });

					// const nextPage = lastPageParam.page + 1;
					// const remainingPages = lastPage?.totalPages - nextPage;

					// if (remainingPages <= 0) {
					// 	return undefined;
					// }

					// return {
					// 	...lastPageParam,
					// 	page: nextPage,
					// };
					// const nextPage = lastPageParam.page + 1;
					if (!lastPage?.nextUrl) {
						return undefined;
					}

					return lastPage?.nextUrl;
				},
				getPreviousPageParam: (firstPage, allPages, firstPageParam, allPageParams) => {
					// console.log('GET PREVIOUS PAGE', {
					// 	firstPage,
					// 	allPages,
					// 	firstPageParam,
					// 	allPageParams,
					// });

					// const prevPage = (allPages ?? [])[(allPages ?? []).length - 1];

					// const prevPage = firstPageParam.page - 1;
					// if (prevPage < 0) return undefined;

					// return {
					//   ...firstPageParam,
					//   page: prevPage,
					// }
					// return {
					// 	...firstPageParam,
					// 	page: prevPage,
					// 	currentUrl: firstPage?.prevUrl,
					// };
					return firstPageParam;
				},
			},
			queryFn: async (
				{ pageParam, queryArg },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				console.log('infiniteQuery QUERY ARGS', queryArg, pageParam);
				const { driveId, itemId, orderBy, direction, searchText, exact } = queryArg;
				const limit = 10;
				const url = pageParam;

				const order = `$orderby=${orderBy}${direction === 'asc' ? '' : '%20desc'}&`;

				const urlStart = `/drives/${driveId}/${
					itemId === 'root' ? itemId : `items/${itemId}`
				}/`;
				const urlEnd = '&$expand=listItem';

				let nextUrl;
				const initialUrl = urlStart + 'children';
				const searchUrl = `${urlStart}${
					exact
						? `children?$filter=name eq '${searchText}'&`
						: `search(q='${searchText}')?`
				}${order}$top=${limit}`;

				try {
					const folders = [];
					const files = [];

					const getFolders = async () => {
						// const response = await baseQuery({
						// 	url: (url ?? '').length === 0 ? initialUrl : url,
						// 	method: 'GET',
						// });
						let query = graphClient
							.api(initialUrl)
							.top(limit)
							.orderby(`${orderBy}${direction === 'asc' ? '' : ' DESC'}`)
							.expand('listItem');
						if ((searchText ?? '').length > 0) {
							query = graphClient.api(searchUrl);
							// query = exact
							// 	? query.filter(`name eq '${searchText}'`)
							// 	: query.search(searchText);
						}
						console.log('QUERY', query, 'url', url);
						const response =
							(url ?? '').length === 0
								? await query
								// .expand('listItem')
									.get() //.then(e => console.log('RES graph client', e))
								: await graphClient.api(url).get();

						nextUrl = response?.['@odata.nextLink'];
						const clientDrives = response?.value ?? [];

						console.log('infiniteQuery RESPONSE', response);
						// Categorize into folders or files
						for (let drive of clientDrives) {
							// console.log('Drive before getting list item', drive);
							const listItem = drive?.listItem
								? drive.listItem
								: await dispatch(
									msGraphApi.endpoints.getListItem.initiate({
										driveId,
										itemId: drive.id,
									})
								  )
									.unwrap()
									.catch((e) => console.log('Error getting list item', e));
							// console.log('List item after', { listItem, drive });
							if (listItem) {
								const url = listItem?.webUrl
									? listItem.webUrl.split('/sites/')
									: [''];
								const serverRelativeUrl = `/sites/${url[url.length - 1]}`;
								drive = {
									...drive,
									...(!drive?.listItem && { listItem }),
									description: listItem?.fields?._ExtendedDescription ?? '',
									metadata: listItem?.fields,
									serverRelativeUrl,
								};
							}

							Object.keys(drive).includes('file')
								? files.push(drive)
								: folders.push(drive);
						}
					};

					await getFolders();

					// console.log(
					// 	`Data being returned ${initialUrl}`,
					// 	{ driveId, itemId, orderBy, direction, searchText },
					// 	{ data: { folders, files, nextUrl, prevUrl: pageParam } }
					// );
					return {
						data: { folders, files, nextUrl, prevUrl: url },
					};
				} catch (error) {
					console.error('❌ Error retrieving client folders', error);

					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getFoldersAndFiles: builder.query({
			providesTags: (result, error, { driveId, itemId, recursive }) => {
				return [
					{
						type: 'ITEMS',
						id: `${driveId}-${itemId}${recursive ? '-recursive' : ''}`,
					},
					// ...(result?.folders ?? []).concat(result?.files ?? []).map((f) => ({
					// 	type: 'ITEM',
					// 	id: f.id,
					// })),
					...(result?.files ?? [])
						.filter((f) => f?.file?.mimeType == MSG_TYPE)
						.map((f) => ({
							type: 'MSG_FILE',
							id: f.id,
						})),
				];
			},
			queryFn: async (
				{ driveId, itemId, recursive = false },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const folders = [];
					const files = [];

					const getFolders = async (driveId, itemId) => {
						// const driveItem = await dispatch(
						// 	msGraphApi.endpoints.getDriveItem.initiate({
						// 		driveId,
						// 		itemId,
						// 	})
						// ).unwrap();
						// // console.log('[getFoldersAndFiles] query getDriveItem', driveItem, {
						// // 	driveId,
						// // 	itemId,
						// // });
						// if (!driveItem?.folder) {
						// 	throw new Error(`Item ${itemId} is not a valid folder`);
						// }

						const response = await graphClient
							.api(`/drives/${driveId}/items/${itemId}/children`)
							.expand('listItem')
							.get();
						// const response = await baseQuery({
						// 	url: `/drives/${driveId}/items/${itemId}/children?$expand=listItem`,
						// 	method: 'GET',
						// });

						const clientDrives = response?.value ?? [];

						const notes = [
							decodeURI(folderStructure.notes.normalize()),
							decodeURI(folderStructure.clientNotes.normalize()),
						];

						// Filter out unwanted folders (notes)
						const filteredDrives = !recursive
							? clientDrives
							: clientDrives.filter((d) => {
								const name = (d?.name ?? '').normalize();
								try {
									return (
										Object.keys(d).includes('file') ||
											!notes.includes(decodeURI(name))
									);
								} catch (err) {
									console.log(`Error decoding ${d.name}`, err);
									return (
										Object.keys(d).includes('file') || !notes.includes(name)
									);
								}
							  });

						// Categorize into folders or files
						for (let drive of filteredDrives) {
							if (!recursive) {
								// const listItem = await dispatch(
								// 	msGraphApi.endpoints.getListItem.initiate({
								// 		driveId,
								// 		itemId: drive.id,
								// 	})
								// ).unwrap();
								const listItem = drive?.listItem;
								const url = listItem?.webUrl
									? listItem.webUrl.split('/sites/')
									: [''];
								const serverRelativeUrl = `/sites/${url[url.length - 1]}`;
								drive = {
									...drive,
									// listItem,
									description: listItem?.fields?._ExtendedDescription ?? '',
									metadata: listItem?.fields,
									serverRelativeUrl,
								};
							}

							Object.keys(drive).includes('file')
								? files.push(drive)
								: folders.push(drive);
							// Recursively fetch subfolders if needed
							if ((drive.folder?.childCount ?? 0) > 0 && recursive) {
								await getFolders(driveId, drive.id);
							}
						}
					};

					await getFolders(driveId, itemId);

					return { data: { folders, files } };
				} catch (error) {
					console.error('❌ Error retrieving client folders', error);

					if ((error?.message ?? '').includes('not a valid folder')) {
						console.log('Not a valid folder');
						return {
							error: {
								code: 500,
								message: 'Not a valid folder',
							},
						};
					}
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		searchFoldersAndFiles: builder.query({
			providesTags: (result, error, args) => {
				return [
					// { type: 'ITEMS', id: `${args.driveId}-${args.itemId}-${args.searchText}` },
					...(result?.folders ?? []).concat(result?.files ?? []).map((f) => ({
						type: 'ITEM',
						id: f.id,
					})),
				];
			},
			queryFn: async (
				{ driveId, itemId, searchText = '', exact = false },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const folders = [];
					const files = [];
					// $filter=eq(name, '${searchText}')
					// $filter=name eq '${encodedFolderName}'
					const url = exact
						? `/drives/${driveId}/${
							itemId === 'root' ? itemId : `items/${itemId}`
						  }/children?$filter=name eq '${searchText}'`
						: `/drives/${driveId}/${
							itemId === 'root' ? itemId : `items/${itemId}`
						  }/search(q='${searchText}')`;

					const response = await graphClient.api(url).get();
					// const response = await baseQuery({
					// 	url,
					// 	method: 'GET',
					// });

					console.log(
						'✅ ~ [searchFoldersAndFiles] query',
						response, //.data,
						{
							driveId,
							itemId,
							searchText,
						}
					);
					const clientDrives = response?.value;
					console.log('SEARCHED CLIENT DRIVES', clientDrives);

					for (let drive of clientDrives ?? []) {
						const listItem =
							drive?.listItem ??
							(await dispatch(
								msGraphApi.endpoints.getListItem.initiate({
									driveId,
									itemId: drive.id,
								})
							).unwrap());
						// const listItem = drive?.listItem;
						const url = listItem?.webUrl ? listItem.webUrl.split('/sites/') : [''];
						const serverRelativeUrl = `/sites/${url[url.length - 1]}`;
						drive = {
							...drive,
							// listItem,
							description: listItem?.fields?._ExtendedDescription ?? '',
							metadata: listItem?.fields,
							serverRelativeUrl,
						};
						Object.keys(drive).includes('file')
							? files.push(drive)
							: folders.push(drive);
					}

					return { data: { folders, files } };
				} catch (error) {
					console.error('❌ Error retrieving client folders', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getBreadcrumbs: builder.query({
			providesTags: (result, error, { driveId, startItemId, endItemId }) => {
				return [
					{
						type: 'BREADCRUMBS',
						id: `${driveId}-${startItemId}-${endItemId}`,
					},
				];
			},
			queryFn: async (
				{ driveId, startItemId, endItemId },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const breadcrumbs = [];
					const getFolders = async (itemId) => {
						const driveItem = await dispatch(
							msGraphApi.endpoints.getDriveItem.initiate({
								driveId,
								itemId,
							})
						).unwrap();

						if (driveItem) {
							breadcrumbs.push(driveItem);
						}
						console.log(
							'itemId != startItemId',
							itemId != startItemId,
							'driveItem?.parentReference?.id',
							driveItem?.parentReference?.id
						);
						if (itemId != startItemId && driveItem?.parentReference?.id) {
							await getFolders(driveItem.parentReference.id);
						}
					};
					await getFolders(endItemId);
					console.log('breadcrumbs before reversal', breadcrumbs);

					breadcrumbs.reverse();
					console.log('breadcrumbs after reversal', breadcrumbs);
					return { data: breadcrumbs };
				} catch (error) {
					console.error('❌ Error retrieving breadcrumbs', error);

					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		searchSharepointSites: builder.query({
			queryFn: async ({ searchText }, { getState, dispatch }, _extraOptions, baseQuery) => {
				try {
					// const response = await baseQuery({
					// 	url: `/sites?search=${searchText}`,
					// 	method: 'GET',
					// });

					const response = await graphClient
						.api('/sites')
						.search(`"${searchText}"`)
						.get();
					console.log('✅ ~ [searchSharepointSites] retrieved successfully', response);

					return { data: response?.value?.[0]?.id };
				} catch (error) {
					console.error('❌ Error searching sharepoint sites:', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		searchFilesByMetadata: builder.query({
			providesTags: (result, error, { driveId, itemId, metadata }) => {
				return [
					{
						type: 'ITEMS',
						id: `${driveId}-${itemId}-${metadata?.column}-${metadata?.value}`,
					},
					...(result ?? []).map((f) => ({
						type: 'ITEM',
						id: f.id,
					})),
				];
			},
			queryFn: async (
				{ driveId, itemId, metadata = { column: '', value: '' } },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				const { column, value } = metadata;
				try {
					const listId = await dispatch(
						msGraphApi.endpoints.getListIdFromDrive.initiate({
							driveId,
						})
					).unwrap();
					const item = await dispatch(
						msGraphApi.endpoints.getListItem.initiate({
							driveId,
							itemId,
						})
					).unwrap();

					// const response = await baseQuery({
					// 	url: `/sites/${item.parentReference.siteId}/lists/${listId}/items?$filter=fields/${column} eq '${value}'`,
					// 	method: 'GET',
					// });
					const response = await graphClient
						.api(`/sites/${item.parentReference.siteId}/lists/${listId}/items`)
						.filter(`fields/${column} eq '${value}'`)
						.get();

					console.log(`✅ Files found with metadata ${column} ${value}:`, response); //.data);

					return { data: response?.value }; //data?.value };
				} catch (error) {
					console.error('❌ Error searching files by metadata:', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getDriveItem: builder.query({
			providesTags: (result, error, { driveId, itemId }) => {
				return [{ type: 'ITEM', id: itemId }];
			},
			queryFn: async (
				{ driveId, itemId },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					// console.log('getDriveItem', {driveId, itemId});
					const response = await graphClient
						.api(`/drives/${driveId}/items/${itemId}`)
						.expand('listItem')
						.get();
					// const response = await baseQuery({
					// 	url: `/drives/${driveId}/items/${itemId}`,
					// 	method: 'GET',
					// });

					// console.log('✅ ~ Drive item:', response.data);

					return { data: response }; //.data };
				} catch (error) {
					console.error('❌ Error retrieving drive item', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getDriveItemFromUrl: builder.query({
			queryFn: async (url, { getState, dispatch }, _extraOptions, baseQuery) => {
				try {
					const encodedUrl = encodeSharingUrl(url);

					const response = await graphClient.api(`/shares/${encodedUrl}/driveItem`).get();

					console.log('✅ ~ Drive Info:', response);

					// console.log('✅ ~ Drive item:', response.data);

					return { data: response }; //.data };
				} catch (error) {
					console.error(`❌ Error retrieving drive item from url ${url}`, error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getListItem: builder.query({
			providesTags: (result, error, { driveId, itemId }) => {
				return [{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` }];
			},
			queryFn: async (
				{ driveId, itemId },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const response = await graphClient
						.api(`/drives/${driveId}/items/${itemId}/listItem`)
						.get();
					// const response = await baseQuery({
					// 	url: `/drives/${driveId}/items/${itemId}/listItem`,
					// 	method: 'GET',
					// });

					// console.log('✅ ~ List item:', response.data);

					return { data: response }; //.data };
				} catch (error) {
					console.error('❌ Error retrieving list item', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getSharepointLists: builder.query({
			providesTags: (result, error, { siteId }) => {
				return [{ type: 'LISTS', id: siteId }];
			},
			queryFn: async ({ siteId }, { getState, dispatch }, _extraOptions, baseQuery) => {
				try {
					const response = await graphClient.api(`/sites/${siteId}/lists`).get();
					// const response = await baseQuery({
					// 	url: `/sites/${siteId}/lists`,
					// 	method: 'GET',
					// });
					console.log('✅ ~ SharePoint lists:', response?.value);

					return { data: response?.value };
				} catch (error) {
					console.error('❌ Error retrieving SharePoint list items', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getSharepointDrives: builder.query({
			providesTags: (result, error, { siteId }) => {
				return [{ type: 'DRIVES', id: siteId }];
			},
			queryFn: async ({ siteId }, { getState, dispatch }, _extraOptions, baseQuery) => {
				try {
					const response = await graphClient.api(`/sites/${siteId}/drives`).get();
					// const response = await baseQuery({
					// 	url: `/sites/${siteId}/drives`,
					// 	method: 'GET',
					// });
					console.log('✅ ~ SharePoint drives:', response?.value);

					return { data: response?.value };
				} catch (error) {
					console.error('❌ Error retrieving SharePoint drives', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		getListIdFromDrive: builder.query({
			providesTags: (result, error, { driveId }) => {
				return [{ type: 'LIST', id: driveId }];
			},
			queryFn: async ({ driveId }, { getState, dispatch }, _extraOptions, baseQuery) => {
				try {
					const response = await graphClient.api(`/drives/${driveId}/list`).get();
					// const response = await baseQuery({
					// 	url: `/drives/${driveId}/list`,
					// 	method: 'GET',
					// });

					console.log('✅ ~ List information', response); //.data);

					return { data: response?.id }; //.data.id };
				} catch (error) {
					console.error('❌ Error retrieving list information', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		addFileToSharepointBySession: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}}`,
				},
			],
			queryFn: async (
				{ driveId, itemId, file, metadata = {} },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const fileContent =
						file?.content instanceof Blob ? file.content : await file.arrayBuffer();

					const fileSize = fileContent.byteLength ?? fileContent.size ?? file.size;
					const chunkSize = 8 * 1024 * 1024;

					const sessionResponse = await graphClient
						.api(
							`/drives/${driveId}/items/${itemId}:/${file.name}:/createUploadSession`
						)
						.post({
							item: {
								'@microsoft.graph.conflictBehavior': 'rename', // Ensure no file overwrites
								name: file.name,
							},
						});

					const uploadUrl = sessionResponse.uploadUrl; //.data.uploadUrl;
					console.log('✅ ~ Upload session created:', uploadUrl);

					//Uploads the file in chunks
					let start = 0;

					let fileRes;
					console.log('Upload file session data', { fileSize, chunkSize, start });
					while (start < fileSize) {
						const end = Math.min(start + chunkSize, fileSize);
						const chunk = fileContent.slice(start, end);

						fileRes = await graphClient
							.api(uploadUrl)
							.header('Content-Range', `bytes ${start}-${end - 1}/${fileSize}`)
							.putStream(chunk);
						console.log('Upload file session data', { end, chunk, fileRes });
						start = end;
					}

					console.log('✅ ~ File uploaded successfully!', fileRes);
					if (Object.keys(metadata).length > 0 && fileRes?.id) {
						await dispatch(
							msGraphApi.endpoints.addMetadataToFile.initiate({
								driveId,
								itemId: fileRes?.id,
								metadata,
							})
						)
							.unwrap()
							.catch((e) => console.log('Error updating metadata', e));
					}
					return { data: fileRes };
				} catch (error) {
					console.error('❌ Error uploading file via session:', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		addFileToSharepoint: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}}`,
				},
			],
			queryFn: async (
				{ driveId, itemId, file, metadata = {} },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const fileContent =
						file?.content instanceof Blob ? file.content : await file.arrayBuffer();

					let data;
					if (file.size <= MAX_SIMPLE_UPLOAD_SIZE) {
						// Use simple upload for files <= 4MB
						const response = await graphClient
							.api(`drives/${driveId}/items/${itemId}:/${file.name}:/content`)
							.putStream(fileContent);

						console.log('✅ ~ File added successfully:', response);

						// Add metadata after file upload
						if (Object.keys(metadata).length > 0) {
							await dispatch(
								msGraphApi.endpoints.addMetadataToFile.initiate({
									driveId,
									itemId: response?.id,
									metadata,
								})
							)
								.unwrap()
								.catch((e) => console.log('Error updating metadata', e));
						}
						data = response;
					} else {
						data = await dispatch(
							msGraphApi.endpoints.addFileToSharepointBySession.initiate({
								driveId,
								itemId,
								file,
								metadata,
							})
						).unwrap();
					}

					await invalidateFilesTags(dispatch, { driveId, itemId });
					return { data };
				} catch (error) {
					console.error('❌ Error uploading file:', error);
					const errorMessage =
						error?.response?.data?.error?.message ??
						error?.message ??
						'Error uploading file; please try again.';
					const pathIsTooLong =
						error?.response?.data?.error?.innerError?.code == 'pathIsTooLong';
					const additionalMessage = pathIsTooLong
						? ' Please try shortening folder or file names, or reduce number of sub-folders.'
						: '';
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message: `${errorMessage}${additionalMessage}` },
					};
				}
			},
		}),
		addFolderToSharepoint: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				// {
				// 	type: 'ITEMS',
				// 	id: `${driveId}-${itemId}}`,
				// },
			],
			queryFn: async (
				{ driveId, itemId, folderName },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					let formattedFolderName;
					try {
						formattedFolderName = decodeURI(folderName.normalize());
					} catch (e) {
						console.log('Error decoding uri', e);
						formattedFolderName = folderName.normalize();
					}

					const body = {
						name: formattedFolderName,
						folder: {},
					};

					const item = await dispatch(
						msGraphApi.endpoints.getDriveItem.initiate({
							driveId,
							itemId,
						})
					)
						.unwrap()
						.catch((e) =>
							console.log('Error getting parent folder', e, { driveId, itemId })
						);
					console.log('[addFolderToSharepoint] mutation getDriveItem', item, {
						driveId,
						itemId,
					});
					console.log('Here is the parent folder', item);

					if (!item?.folder) {
						throw new Error('Parent folder does not exist');
					}

					const folderExists = async () => {
						const encodedFolderName = encodeURIComponent(formattedFolderName);

						const res = await graphClient
							.api(`/drives/${driveId}/items/${itemId}/children`)
							.filter(`name eq '${encodedFolderName}'`)
							.get();
						return res?.value?.find((item) =>
							tryDecodeFilePath(item?.name, formattedFolderName)
						);
					};

					const existingFolder = await folderExists().catch((e) =>
						console.log('Error checking existing folder', e)
					);
					console.log('EXISTING FOLDER', existingFolder);

					if (existingFolder) {
						return { data: existingFolder };
					}

					const response = await graphClient
						.api(`/drives/${driveId}/items/${itemId}/children`)
						.post(body);
					console.log('Created folder', response);

					try {
						const patchResult = dispatch(
							msGraphApi.util.updateQueryData(
								'getFoldersAndFiles',
								{
									itemId,
									driveId,
									recursive: false,
								},
								(draft) => {
									if (draft?.folders && item.folder) {
										const folders = [...draft.folders, response];
										Object.assign(draft, {
											...draft,
											folders,
										});
									}
								}
							)
						);
					} catch (e) {
						console.log('Error patching files', e);
					}
					return { data: response }; //.data };
				} catch (error) {
					console.error('❌ Error creating folder:', error);

					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		renameSharepointItem: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}}`,
				},
			],
			queryFn: async (
				{ driveId, itemId, name },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const body = { name };

					const response = await graphClient
						.api(`/drives/${driveId}/items/${itemId}`)
						.patch(body);

					// const response = await baseQuery({
					// 	url: `/drives/${driveId}/items/${itemId}`,
					// 	method: 'PATCH',
					// 	data: body,
					// });

					console.log(
						`✅ ~ Item ${itemId} renamed successfully to ${name}:`,
						response //.data
					);

					try {
						const patchResultItem = dispatch(
							msGraphApi.util.updateQueryData(
								'getDriveItem',
								{
									itemId,
									driveId,
								},
								(draft) => {
									Object.assign(draft, {
										...draft,
										...response,
									});
								}
							)
						);
						const patchResult = dispatch(
							msGraphApi.util.updateQueryData(
								'getFoldersAndFiles',
								{
									itemId: response.parentReference.id,
									driveId,
									recursive: false,
								},
								(draft) => {
									if (draft?.folders && response.folder) {
										const folders = [
											...draft.folders.map((f) =>
												f.id === response.id
													? { ...f, ...response }
													: { ...f }
											),
										];
										Object.assign(draft, {
											...draft,
											folders,
										});
									} else if (draft?.files && !response.folder) {
										const files = [
											...draft.files.map((f) =>
												f.id === response.id
													? { ...f, ...response }
													: { ...f }
											),
										];
										Object.assign(draft, {
											...draft,
											files,
										});
									}
								}
							)
						);
					} catch (e) {
						console.log('Error patching files', e);
					}
					return { data: response }; //.data };
				} catch (error) {
					console.error('❌ Error renaming item', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		addMetadataToFile: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}}`,
				},
			],
			queryFn: async (
				{ driveId, itemId, metadata },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const listId = await dispatch(
						msGraphApi.endpoints.getListIdFromDrive.initiate({
							driveId,
						})
					).unwrap();
					const item = await dispatch(
						msGraphApi.endpoints.getListItem.initiate({
							driveId,
							itemId,
						})
					).unwrap();

					const response = await graphClient
						.api(
							`/sites/${item.parentReference.siteId}/lists/${listId}/items/${item.id}/fields`
						)
						.patch(metadata);
					// const response = await baseQuery({
					// 	url: `/sites/${item.parentReference.siteId}/lists/${listId}/items/${item.id}/fields`,
					// 	method: 'PATCH',
					// 	data: metadata,
					// });

					const data = response; //.data;
					console.log('✅ ~ Metadata added successfully:', data);

					try {
						const patchResult = dispatch(
							msGraphApi.util.updateQueryData(
								'getListItem',
								{
									itemId,
									driveId,
								},
								(draft) => {
									Object.assign(draft, {
										...draft,
										...response,
									});
								}
							)
						);
					} catch (e) {
						console.log('Error patching files', e);
					}

					return { data };
				} catch (error) {
					console.error('❌ Error adding metadata to item', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
		moveSharepointItem: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId, newParentId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}}`,
				},
				{ type: 'LIST_ITEM', id: `${driveId}-${newParentId}` },
				{ type: 'ITEM', id: newParentId },
				{
					type: 'ITEMS',
					id: `${driveId}-${newParentId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${newParentId}}`,
				},
				'BREADCRUMBS',
			],
			queryFn: async (
				{ driveId, itemId, newParentId },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const body = {
						parentReference: { id: newParentId },
						'@microsoft.graph.conflictBehavior': 'rename',
					};

					const item = await dispatch(
						msGraphApi.endpoints.getDriveItem.initiate({
							driveId,
							itemId,
						})
					)
						.unwrap()
						.catch((e) =>
							console.log('Error getting existing item', e, { driveId, itemId })
						);

					const response = await graphClient
						.api(`/drives/${driveId}/items/${itemId}`)
						.patch(body);
					// const response = await baseQuery({
					// 	url: `/drives/${driveId}/items/${itemId}`,
					// 	method: 'PATCH',
					// 	data: body,
					// });

					console.log(`✅ ~ Item ${itemId} moved successfully:`, response); //.data);

					try {
						const patchResultOldParent = dispatch(
							msGraphApi.util.updateQueryData(
								'getFoldersAndFiles',
								{
									itemId: item.parentReference.id,
									driveId,
									recursive: false,
								},
								(draft) => {
									if (draft?.folders && response.folder) {
										const folders = [
											...draft.folders.filter((i) => i.id !== item.id),
										];
										Object.assign(draft, {
											...draft,
											folders,
										});
									} else if (draft?.files && !response.folder) {
										const files = [
											...draft.files.filter((i) => i.id !== item.id),
										];
										Object.assign(draft, {
											...draft,
											files,
										});
									}
								}
							)
						);
						const patchResultItem = dispatch(
							msGraphApi.util.updateQueryData(
								'getDriveItem',
								{
									itemId,
									driveId,
								},
								(draft) => {
									Object.assign(draft, {
										...draft,
										...response,
									});
								}
							)
						);
						const patchResultNewParent = dispatch(
							msGraphApi.util.updateQueryData(
								'getFoldersAndFiles',
								{
									itemId: newParentId,
									driveId,
									recursive: false,
								},
								(draft) => {
									if (draft?.folders && response.folder) {
										const folders = [...draft.folders, response];
										Object.assign(draft, {
											...draft,
											folders,
										});
									} else if (draft?.files && !response.folder) {
										const files = [...draft.files, response];
										Object.assign(draft, {
											...draft,
											files,
										});
									}
								}
							)
						);
					} catch (e) {
						console.log('Error patching files', e);
					}
					await invalidateFilesTags(dispatch, { driveId, itemId });
					await invalidateFilesTags(dispatch, { driveId, itemId: newParentId });
					return { data: response.data };
				} catch (error) {
					console.error('❌ Error moving item', error);
					const errorMessage =
						error?.response?.data?.error?.message ??
						error?.message ??
						'Error moving items; please try again.';
					const pathIsTooLong =
						error?.response?.data?.error?.innerError?.code == 'pathIsTooLong';
					const additionalMessage = pathIsTooLong
						? ' Please try shortening folder or file names, or reduce number of sub-folders.'
						: '';

					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message: `${errorMessage}${additionalMessage}` },
					};
				}
			},
		}),
		deleteSharepointItem: builder.mutation({
			invalidatesTags: (result, error, { driveId, itemId }) => [
				{ type: 'LIST_ITEM', id: `${driveId}-${itemId}` },
				{ type: 'ITEM', id: itemId },
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}-recursive}`,
				},
				{
					type: 'ITEMS',
					id: `${driveId}-${itemId}}`,
				},
			],
			queryFn: async (
				{ driveId, itemId },
				{ getState, dispatch },
				_extraOptions,
				baseQuery
			) => {
				try {
					const item = await dispatch(
						msGraphApi.endpoints.getDriveItem.initiate({
							driveId,
							itemId,
						})
					)
						.unwrap()
						.catch((e) =>
							console.log('Error getting existing item', e, { driveId, itemId })
						);

					const response = await graphClient
						.api(`/drives/${driveId}/items/${itemId}`)
						.delete();
					// const response = await baseQuery({
					// 	url: `/drives/${driveId}/items/${itemId}`,
					// 	method: 'DELETE',
					// });

					console.log(`✅ ~ Item ${itemId} deleted successfully:`, response); //.data);

					const data = response; //.data;

					try {
						const patchResult = dispatch(
							msGraphApi.util.updateQueryData(
								'getFoldersAndFiles',
								{
									itemId: item.parentReference.id,
									driveId,
									recursive: false,
								},
								(draft) => {
									if (draft?.folders && item.folder) {
										const folders = [
											...draft.folders.filter((i) => i.id !== item.id),
										];
										Object.assign(draft, {
											...draft,
											folders,
										});
									} else if (draft?.files && !response.folder) {
										const files = [
											...draft.files.filter((i) => i.id !== item.id),
										];
										Object.assign(draft, {
											...draft,
											files,
										});
									}
								}
							)
						);
					} catch (e) {
						console.log('Error patching files', e);
					}
					await invalidateFilesTags(dispatch, { driveId, itemId });
					return { data };
				} catch (error) {
					console.error('❌ Error deleting item', error);
					const { code, message } = getError(error, dispatch);
					return {
						error: { code, message },
					};
				}
			},
		}),
	}),
});

export const {
	useGetItemsInfiniteQuery,
	useGetFoldersAndFilesQuery,
	useSearchFoldersAndFilesQuery,
	useLazyGetFoldersAndFilesQuery,
	useLazySearchFoldersAndFilesQuery,
	useSearchFilesByMetadataQuery,
	useLazySearchFilesByMetadataQuery,
	useGetSharepointDrivesQuery,
	useLazyGetSharepointDrivesQuery,
	useGetBreadcrumbsQuery,
	useSearchSharepointSitesQuery,
	useLazySearchSharepointSitesQuery,
	useGetListItemQuery,
	useLazyGetListItemQuery,
	useGetDriveItemQuery,
	useGetDriveItemFromUrlQuery,
	useLazyGetDriveItemFromUrlQuery,
	useLazyGetDriveItemQuery,
	useGetSharepointListsQuery,
	useLazyGetSharepointListsQuery,
	useGetListIdFromDriveQuery,
	useLazyGetListIdFromDriveQuery,
	useAddFileToSharepointMutation,
	useAddFileToSharepointBySessionMutation,
	useAddFolderToSharepointMutation,
	useRenameSharepointItemMutation,
	useMoveSharepointItemMutation,
	useDeleteSharepointItemMutation,
	useAddMetadataToFileMutation,
} = msGraphApi;
