/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	TextField,
	Skeleton,
	Typography,
	Stack,
	IconButton,
	Box,
	Button,
	Chip,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import {
	dealsApi,
	useUpdateDealPropertiesMutation,
	useUpdateClientDealRowsMutation,
	useUpdateDealRowMutation,
} from '@/features/deals/dealsApi';
import { useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	clientsApi,
	useCheckClientExistsMutation,
	useUpdateClientMutation,
} from '@/features/clients/clientsApi';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { folioBlue, replaceFolderName, specialChars } from '@/utils/constants';
import DoneIcon from '@mui/icons-material/Done';
import DealMenu from '@/components/DealMenu';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import {
	useGetDriveItemQuery,
	useGetFoldersAndFilesQuery,
	useRenameSharepointItemMutation,
} from '@/features/msGraph/msGraphApi';
import validationSchema, {
	FOLDER_NAME,
} from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import { useParams } from 'react-router-dom';

export const NameChangeField = ({ title }) => {
	const { objectType, loading, dealRow, client } = determineContext();

	const isDeal = objectType === 'deal';
	const isClient = objectType === 'client';

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const isProspect = isClient && client?.clientRow?.isProspect;

	const loadingDeal = loading?.deal;
	const dispatch = useDispatch();

	const [newName, setNewName] = useState(dealRow?.dealName ?? client?.clientRow?.name);
	const [error, setError] = useState('');

	const [isEditing, setIsEditing] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });
	const ownerId = useMemo(() => userQuery?.data.hubspotId, [userQuery?.data.hubspotId]);

	const [updateDealProperties, { isLoading: isUpdatingDeal }] = useUpdateDealPropertiesMutation();

	const [updateClientDealRows, { isLoading: isUpdatingDealRows }] =
		useUpdateClientDealRowsMutation();
	const [updateDealRow, { isLoading: isUpdatingDealRow }] = useUpdateDealRowMutation();

	const [updateTicketProperties, { isLoading: isUpdatingTicket }] =
		useUpdateTicketPropertiesMutation();

	const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClientMutation();
	const [checkClientExists] = useCheckClientExistsMutation();

	const itemId = isClient ? client?.clientRow?.driveId : dealRow?.driveId;
	const clientFolderDriveId = isClient
		? client?.clientRow?.clientFolderDriveId
		: dealRow?.clientFolderDriveId;

	const { data: driveItem, ...driveItemQuery } = useGetDriveItemQuery(
		{
			driveId: clientFolderDriveId,
			itemId,
		},
		{ skip: !itemId || !clientFolderDriveId }
	);

	const { data: parentFolders, ...parentFolderFilesQuery } = useGetFoldersAndFilesQuery(
		{
			itemId: driveItem?.parentReference?.id,
			driveId: clientFolderDriveId,
			recursive: false,
		},
		{ skip: !driveItem?.parentReference?.id || !clientFolderDriveId }
	);

	const validateClient = async (name) => {
		const res = await checkClientExists({
			ownerId,
			newName: (name ?? newName).trim(),
		}).unwrap();
		setError(res ? 'Client already exists under your brokerage.' : '');
		return !res;
	};

	const validateFolderName = async (name) => {
		// const existingName = driveItem?.name ?? '';
		const folders = (parentFolders.folders ?? [])
			.filter((f) => f.id !== driveItem?.id)
			.map((f) => {
				try {
					return decodeURI(f.name.normalize());
				} catch (e) {
					console.log('Error decode URI', e);
					return f.name.normalize();
				}
			});

		console.log('validate folder name', folders);
		try {
			await validationSchema(folders, 'Deal name').validate(
				{ [FOLDER_NAME]: (name ?? newName).trim() },
				{ strict: true }
			);
			setError('');
			if (isClient) {
				return await validateClient(name);
			}
			return true;
		} catch (err) {
			console.log('Folder validation error', err?.message);
			setError(err?.message);
			return false;
		}
	};

	const [renameSharepointItem] = useRenameSharepointItemMutation();

	// Used to invalidate the sharepoint folders
	const invalidateTags = () => {
		dispatch(triggerRefresh());
		setTimeout(async () => {
			try {
				if (objectType === 'deal' || objectType === 'ticket') {
					dispatch(
						dealsApi.util.invalidateTags([{ type: 'DEAL_ROW', id: dealRow?.dealId }])
					);
				}
				if (client) {
					dispatch(
						clientsApi.util.invalidateTags([
							{ type: 'CLIENT_ROW', id: client.hs_object_id ?? client.id },
						])
					);
				}
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 5000);
	};

	const updateDealName = async (trimmedName) => {
		try {
			const existingName = driveItem?.name ?? '';
			const newFolderName = replaceFolderName(trimmedName);
			const res = await renameSharepointItem({
				driveId: dealRow?.clientFolderDriveId,
				itemId: dealRow?.driveId,
				name: newFolderName,
			})
				.unwrap()
				.catch((e) => {
					console.log('Error: name already exists', e?.message);
					setError(e?.message ?? 'Error updating folder name');
					throw Error;
				});

			const webUrl = decodeURI((res?.webUrl ?? '').normalize());
			const urls = webUrl.split('/');
			const dealFolderPath = urls.length > 4 ? urls.slice(urls.length - 4).join('/') : null;
			const properties = {
				dealName: trimmedName,
				...(res?.webUrl && { dealFolderUrl: webUrl }),
				...(dealFolderPath && { dealFolderPath }),
			};

			console.log('UPDATE DEAL ROW', properties);
			await updateDealRow({ dealId: dealRow?.dealId, properties }).unwrap();
			const params = {
				[isDeal ? 'dealname' : 'subject']: trimmedName,
				// [isDeal ? 'description' : 'content']: trimmedName,
			};
			if (isDeal) {
				await updateDealProperties({
					dealId: dealRow?.dealId,
					body: params,
				}).unwrap();
			} else {
				await updateTicketProperties({
					ticketId: dealRow?.dealId,
					body: params,
				}).unwrap();
			}
			invalidateTags();
		} catch (error) {
			console.error('Failed to update:', error);
			setNewName(dealRow.dealName);
		}
	};

	const updateClientName = async (trimmedName) => {
		try {
			const newFolderName = replaceFolderName(trimmedName);

			const clientId = client.hs_object_id;
			await updateClientDealRows({ clientId, trimmedName });
			const properties = { name: trimmedName };
			await updateClient({ clientId: client.hs_object_id, properties });
			await renameSharepointItem({
				driveId: client?.clientRow?.clientFolderDriveId,
				itemId: client?.clientRow?.driveId,
				name: newFolderName,
			}).unwrap();

			invalidateTags();
			setError(false);
		} catch (error) {
			console.error('Failed to update client:', error);
			setNewName(client?.clientRow?.name);
		}
	};

	const handleUpdate = async () => {
		const trimmedName = newName.trim();

		if (title !== trimmedName) {
			if (objectType === 'deal' || objectType === 'ticket') {
				await updateDealName(trimmedName);
			} else if (objectType === 'client') {
				await updateClientName(trimmedName);
			}
		}
	};

	const isLoading =
		isUpdatingDeal ||
		isUpdatingDealRows ||
		isUpdatingTicket ||
		isUpdatingClient ||
		loadingDeal ||
		driveItemQuery.isLoading ||
		parentFolderFilesQuery.isLoading;

	const handleSaveClick = () => {
		handleUpdate();
		setIsEditing(false);
	};

	const handleCancelClick = () => {
		setNewName(dealRow?.dealName ?? client?.clientRow?.name);
		setIsEditing(false);
	};

	return (
		<Stack direction='row' alignItems='center' spacing={1} width='100%'>
			{isLoading ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : isEditing ? (
				<TextField
					variant='standard'
					className='minimal-input'
					value={newName}
					disabled={loadingDeal}
					onChange={(e) => setNewName(e.target.value)}
					onBlur={async (e) => {
						await validateFolderName(e.target.value);
					}}
					fullWidth
					multiline
					error={Boolean(error)}
					helperText={Boolean(error) && error}
					sx={{
						'& .Mui-error': {
							marginLeft: 0,
						},
					}}
				/>
			) : (
				<Stack direction='column' spacing={0.5} width='100%'>
					{/* {error && (
						<Typography variant='caption' color='error'>
							{error}
						</Typography>
					)} */}

					{isProspect && showProspects ? (
						<Stack
							direction='row'
							spacing={1}
							sx={{
								alignItems: 'center',
							}}
						>
							<Typography variant='deal_header'>{title}</Typography>
							<Chip
								size='small'
								label='PROSPECT'
								variant='outlined'
								sx={{ color: 'darkgray' }}
							/>
						</Stack>
					) : (
						<Typography variant='deal_header'>{title}</Typography>
					)}
				</Stack>
			)}
			{!isClient && (
				<>
					{isEditing ? (
						<IconButton
							sx={{ color: isEditing ? null : folioBlue, marginLeft: 5 }}
							aria-label='edit'
							disabled={isLoading}
							edge='start'
							onClick={() => setIsEditing(!isEditing)}
						>
							{isEditing ? <CloseIcon /> : <EditIcon />}
						</IconButton>
					) : (
						<DealMenu setIsEditing={setIsEditing} />
					)}
					{/* <IconButton
						sx={{ color: isEditing ? null : folioBlue, marginLeft: 5 }}
						aria-label="edit"
						disabled={isLoading}
						edge="start"
						onClick={() => setIsEditing(!isEditing)}
					>
						{isEditing ? <CloseIcon /> : <EditIcon />}
					</IconButton> */}
					{isEditing && (
						<IconButton
							sx={{ color: folioBlue }}
							onClick={async (e) => {
								const valid = await validateFolderName();
								if (valid) {
									handleSaveClick(e);
								}
							}}
							disabled={loadingDeal || Boolean(error)}
						>
							<DoneIcon />
						</IconButton>
					)}
				</>
			)}
		</Stack>
	);
};
