import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const getLocalState = () => {
	const exp = localStorage.getItem('expiration')
		? parseInt(localStorage.getItem('expiration'))
		: null;
	const token = Cookies.get('token');
	return {
		expiration: exp && !isNaN(exp) ? exp : null,
		account: null,
		accessToken: token != null,
	};
};

export const msalAccountSlice = createSlice({
	name: 'msalAccount',
	initialState: getLocalState(),
	reducers: {
		setAccount: (state, action) => {
			console.log('👾 ~ action.payload', action.payload);
			state.account = {
				...action.payload,
				...(action.payload.username && {
					username: action.payload.username.trim().toString(),
				}),
			};
		},
		clearAccount: (state) => {
			console.log('👾 ~ clear account', state);
			localStorage.removeItem('expiration');
			Cookies.remove('token');
			state.account = null;
			state.accessToken = false;
			state.expiration = null;
		},
		setAccessToken: (state, action) => {
			const { accessToken, expiration } = action.payload;
			state.accessToken = true;
			Cookies.set('token', accessToken, { secure: true });
			state.expiration = expiration;
			localStorage.setItem('expiration', parseInt(expiration));
		},
		clearAccessToken: (state) => {
			state.accessToken = false;
			Cookies.remove('token');
			state.expiration = null;
			localStorage.removeItem('expiration');
		},
	},
});

export const { setAccount, clearAccount, setAccessToken, clearAccessToken } =
	msalAccountSlice.actions;
export default msalAccountSlice.reducer;
