import { createSlice } from '@reduxjs/toolkit';

export const WIDTH = 400;

export const drawerSlice = createSlice({
	name: 'menuDrawerSlice',
	initialState: {
		open: false,
		width: WIDTH,
		isCollapsed: false,
		data: {}
	},
	reducers: {
		setOpen: (state) => {
			state.open = true;
		},
		setClose: (state) => {
			state.open = false;
			state.data = {};
		},
		toggleOpen: (state) => {
			state.open = !state.open;
		},
		setData: (state, action) => {
			state.data = action.payload; 
		},
	},
});

export const { 
	setOpen,
	setClose,
	toggleOpen,
	toggleWidth,
	setData,
} = drawerSlice.actions;
export default drawerSlice.reducer;