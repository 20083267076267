import React, { useMemo, useState } from 'react'; // Added useState
import {
	Typography,
	FormGroup,
	FormControlLabel,
	FormLabel,
	TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
	ADDITIONAL_GOALS_OF_COVER,
	GOALS_OF_COVER,
	GOALS_OF_COVER_LABELS,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';

export const GoalsOfCover = () => {
	const { dealRow } = determineContext();
	const goalsOfCover = useMemo(() => dealRow[GOALS_OF_COVER] ?? [], [dealRow]);
	const [additionalGoalsOfCover, setAdditionalGoalsOfCover] = useState(dealRow[ADDITIONAL_GOALS_OF_COVER] ?? '');

	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();

	const onChange = async (newValues) => {
		if (newValues !== goalsOfCover) {
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				properties: {
					[GOALS_OF_COVER]: newValues,
				},
			}).unwrap();
		}
	};

	const handleTextBlur = async (event) => {
		const newValue = event.target.value.trim();
		if (newValue !== dealRow[ADDITIONAL_GOALS_OF_COVER]) {
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				properties: {
					[ADDITIONAL_GOALS_OF_COVER]: newValue,
				},
			}).unwrap();
		}
	};

	const handleTextChange = (event) => {
		setAdditionalGoalsOfCover(event.target.value); 
	};

	return (
		<FormGroup row sx={{ width: '100%' }}>
			<Typography variant="form_label">Goals of cover</Typography>
			<Grid container width={'100%'} spacing={2}>
				{Object.entries(GOALS_OF_COVER_LABELS).map(([value, label]) => (
					<Grid  size={{xs:12, sm: 6}} key={`client-goals-${value}`}>
						<FormControlLabel
							control={
								<ClientCheckbox
									value={value}
									valuesArray={goalsOfCover}
									setValues={onChange}
									disabled={isLoading}
								/>
							}
							label={label}
							sx={{
								'& .MuiTypography-root': {
									fontSize: '12px',
								},
								'& .MuiButtonBase-root': {
									fontSize: '18px',
								},
							}}
						/>
					</Grid>
				))}
				<Grid size={12} sx={{ marginTop: 5 }}>
					<FormLabel>{'Add additional goals of cover here'}</FormLabel>
					<TextField
						fullWidth
						value={additionalGoalsOfCover}
						onChange={handleTextChange}
						onBlur={handleTextBlur}
						multiline
						rows={4}
						disabled={isLoading}
					/>
				</Grid>
			</Grid>
		</FormGroup>
	);
};