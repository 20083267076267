/* eslint-disable no-mixed-spaces-and-tabs */
import { FOLDER_NAME, default as folderValidationSchema } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import { currentYear, nextYear } from '@/utils/date';
import * as Yup from 'yup';

export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const PHONE = 'phoneNumber';
export const EMAIL = 'emailAddress';
export const EXISTINGCONTACT = 'existingContact';
export const WEBSITE = 'websiteAddress';
export const FILES = 'files';
export const ADDRESS = 'address';
export const CITY = 'city';
export const SUBURB = 'suburb';
export const POSTCODE = 'postcode';
export const COUNTRY = 'country';
export const OTHER_INFO = 'otherInfo';
export const CLIENT_NAME = 'clientName';
export const SUCCESS_MESSAGE = 'successMessage';
export const ERROR_MESSAGE = 'errorMessage';
export const DEAL_NAME = 'dealName';
export const DEAL_OVERVIEW = 'dealOverview';
export const ADVISER_FEE = 'adviserFee';
export const RENEWAL_DATE = 'renewalDate';
export const PRIORITY = 'priority';
export const IS_EXISTING = 'isExisting';
export const CLIENT_ID = 'clientId';
export const INDUSTRY = 'industry';
export const OWNER = 'owner';
export const COMPLETED_BY = 'completedBy';
export const CLIENT_ERROR = 'clientError';
export const IS_PROSPECT = 'isProspect';

export const ADDITIONAL_SPECIALIST_PRODUCTS = 'additionalSpecialistProducts';
export const NO_EMPLOYEES = 'numberofemployees';
export const ANNUAL_TURNOVER = 'annualrevenue';

// Client Category
export const CLIENT_CATEGORY = 'clientCategory';
export const WHOLESALE_CLIENT = 'wholesaleClient';
export const VULNERABLE_CLIENT = 'vulnerableClient';

// Goals of cover
export const GOALS_OF_COVER = 'goalsOfCover';
export const ADDITIONAL_GOALS_OF_COVER = 'additionalGoalsOfCover';
export const LOW_ANNUAL_PREMIUM = 'lowAnnualInsurancePremium';
export const HIGH_LEVEL_POLICY_COVERAGE = 'highLevelPolicyCoverage';
export const THOROUGH_TIMELY_ClAIMS_EXPERIENCE = 'thoroughTimelyClaimsExperience';
export const PERSONALISED_SERVICE_REACHABLE_TEAM = 'personalisedServicesReachableTeam';

// Client Type
export const CLIENT_TYPE = 'clientType';
export const COMMERCIAL = 'commercial';
export const DOMESTIC = 'domestic';

//Sharepoint
export const YEAR_FOLDER = 'yearFolder';

//Needs Anaylsis
export const NEEDS_ANALYSIS = 'needsAnalysis';
export const CLIENT_QUOTED = 'clientQuoted';
export const CLIENT_NOT_DISCUSS = 'clientNotDiscussed';
export const CLIENT_NOT_INTERESTED = 'clientNotInterested';
export const CLIENT_NOT_REQUIRED = 'clientNotRequired';
export const CLIENT_NOT_APPLICABLE = 'clientNotApplicable';

//Contact
export const CONTACTS = 'contacts';
export const IS_MAIN_CONTACT = 'isMainContact';
export const ROLE = 'role';
export const CONTACT_ERROR = 'contactError';

// Scope of Advice

export const FULL_NEEDS_ANALYSIS = 'fullNeedsAnalysis';

//If Commercial
export const SCOPE_OF_ADVICE = 'riskClasses';
export const MATERIAL_DAMAGE = 'materialDamage';
export const BUSINESS_INTERRUPTION = 'businessInterruption';
export const COMMERCIAL_MOTOR = 'commercialMotor';
export const GENERAL_LIABILITY = 'generalLiability';
export const STATUTORY_LIABILITY = 'statutoryLiability'; // Only show if GL is ticked
export const EMPLOYERS_LIABILITY = 'employersLiability'; // Only show if GL is ticked
export const MANAGEMENT_LIABILITY = 'managementLiability'; // If selected, select all GL, SL, EL
export const PROFESSIONAL_INDEMNITY = 'professionalIndemnity';
export const CYBER_LIABILITY = 'cyberLiability';
export const CARRIERS_LIABILITY = 'carriersLiability';
export const CONTRACTORS_PLANT_AND_MACHINERY = 'contractorsPlantAndMachinery';
export const MARINE_CARGO = 'marineCargo';

//If Domestic selected

export const HOUSE = 'house';
export const PRIVATE_VEHICLE = 'privateVehicle';
export const CONTENTS = 'contents';
export const BOAT = 'boat';

export const initialValues = {
	[FIRST_NAME]: '',
	[LAST_NAME]: '',
	[PHONE]: '',
	[EMAIL]: '',
	[WEBSITE]: '',
	[CONTACTS]: [
		{
			firstName: '',
			lastName: '',
			emailAddress: '',
			phoneNumber: '',
			isMainContact: false,
			role: '',
			existingContact: false,
		},
	],
	[FILES]: [],
	[ADDRESS]: '',
	[CITY]: '',
	[COUNTRY]: '',
	[OTHER_INFO]: '',
	[CLIENT_NAME]: '',
	[CLIENT_ERROR]: false,
	[CONTACT_ERROR]: false,
	[SUCCESS_MESSAGE]: '',
	[ERROR_MESSAGE]: '',
	[DEAL_NAME]: '',
	[DEAL_OVERVIEW]: '',
	[FOLDER_NAME]: '',
	[ADVISER_FEE]: null,
	[RENEWAL_DATE]: null,
	[PRIORITY]: '',
	[IS_EXISTING]: false,
	[CLIENT_ID]: null,
	[INDUSTRY]: null,
	[OWNER]: null,
	[COMPLETED_BY]: null,
	[POSTCODE]: '',
	[SUBURB]: '',
	[GOALS_OF_COVER]: [],
	[ADDITIONAL_GOALS_OF_COVER]: '',
	[CLIENT_CATEGORY]: [],
	[SCOPE_OF_ADVICE]: [],
	[NEEDS_ANALYSIS]: [],
	[CLIENT_TYPE]: '',
	[ADDITIONAL_SPECIALIST_PRODUCTS]: '',
	[NO_EMPLOYEES]: 0,
	[ANNUAL_TURNOVER]: 0,
	[IS_PROSPECT]: false,
	[YEAR_FOLDER]: `${currentYear}-${nextYear}`,
};

export const CLIENT_CATEGORY_LABELS = {
	[WHOLESALE_CLIENT]: 'Wholesale Client',
	[VULNERABLE_CLIENT]: 'Vulnerable Client',
};

export const NEEDS_ANALYSIS_LABELS = {
	[CLIENT_QUOTED]: 'Client would like to be quoted',
	[CLIENT_NOT_DISCUSS]: 'Client did not want to discuss',
	[CLIENT_NOT_INTERESTED]: 'Discussed but client was not interested in looking at options',
	[CLIENT_NOT_REQUIRED]: 'Discussed but we determined it was not required at this time',
	[CLIENT_NOT_APPLICABLE]: 'Discussed but was not applicable for client',
};

export const GOALS_OF_COVER_LABELS = {
	[LOW_ANNUAL_PREMIUM]: 'Low annual insurance premium',
	[HIGH_LEVEL_POLICY_COVERAGE]: 'High level of policy coverage',
	[THOROUGH_TIMELY_ClAIMS_EXPERIENCE]: 'Thorough and timely claims experience',
	[PERSONALISED_SERVICE_REACHABLE_TEAM]: 'Personalised service/reachable team',
};

export const SCOPE_OF_ADVICE_LABELS_DOMESTIC = {
	[FULL_NEEDS_ANALYSIS]: 'Full Needs Analysis',
	[HOUSE]: 'House',
	[PRIVATE_VEHICLE]: 'Private Vehicle',
	[CONTENTS]: 'Contents',
	[BOAT]: 'Boat',
};

export const SCOPE_OF_ADVICE_LABELS_COMMERCIAL = {
	[FULL_NEEDS_ANALYSIS]: 'Full Needs Analysis',
	[GENERAL_LIABILITY]: 'General Liability',
	[STATUTORY_LIABILITY]: 'Statutory Liability',
	[EMPLOYERS_LIABILITY]: 'Employers Liability',
	[MANAGEMENT_LIABILITY]: 'Management Liability',
	[PROFESSIONAL_INDEMNITY]: 'Professional Indemnity',
	[CYBER_LIABILITY]: 'Cyber Liability',
	[MATERIAL_DAMAGE]: 'Material Damage',
	[BUSINESS_INTERRUPTION]: 'Business Interruption',
	[COMMERCIAL_MOTOR]: 'Commercial Motor',
	[CARRIERS_LIABILITY]: 'Carriers Liability',
	[CONTRACTORS_PLANT_AND_MACHINERY]: 'Contractors Plant And Machinery',
	[MARINE_CARGO]: 'Marine Cargo',
};

export const validationSchema = Yup.object().shape({
	[IS_EXISTING]: Yup.boolean(),
	[IS_PROSPECT]: Yup.boolean(),
	[FIRST_NAME]: Yup.string().when(['isExisting', 'contacts'], {
		is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		then: () => Yup.string().required('First name is required.'),
		otherwise: () => Yup.string(),
	}),
	[LAST_NAME]: Yup.string().when(['isExisting', 'contacts'], {
		is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		then: () => Yup.string().required('Last name is required.'),
		otherwise: () => Yup.string(),
	}),
	[PHONE]: Yup.string().when(['isExisting', 'contacts'], {
		is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		then: () =>
			Yup.string()
				.required('Phone number is required.')
				.min(7, 'Phone number should be more than 7 characters')
				.max(15, 'Phone number should be no more than 15 characters'),
		otherwise: () => Yup.string(),
	}),
	[EMAIL]: Yup.string().when(['isExisting', 'contacts'], {
		is: (isExisting, contacts) => isExisting === false && (!contacts || contacts.length === 0),
		then: () =>
			Yup.string()
				.email('Email address must be a valid email')
				.required('Email address is required.')
				.test('domain-length', 'Email address must be a valid email', (value) => {
					if (!value) return false;
					const parts = value.split('@');
					if (parts.length !== 2 || parts[1].length <= 2) {
						return false;
					}
					const domainParts = parts[1].split('.');
					if (domainParts.length < 2 || domainParts.length > 3) {
						return false;
					}
					return domainParts.every((part) => part.length >= 2);
				}),
		otherwise: () => Yup.string(),
	}),
	[ADDRESS]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === false,
		then: () =>
			Yup.string()
				.required('Address is required')
				.max(40, 'Address cannot be longer than 40 characters'),
		otherwise: () => Yup.string(),
	}),
	[CITY]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === false,
		then: () => Yup.string().required('City is required'),
		otherwise: () => Yup.string(),
	}),
	[SUBURB]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === false,
		then: () => Yup.string().required('Suburb is required'),
		otherwise: () => Yup.string(),
	}),
	[POSTCODE]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === false,
		then: () => Yup.string().required('Postcode is required'),
		otherwise: () => Yup.string(),
	}),
	[COUNTRY]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === false,
		then: () => Yup.string().required('Country is required'),
		otherwise: () => Yup.string(),
	}),
	[CLIENT_NAME]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === false,
		then: () => Yup.string().required('Client name is required.'),
		otherwise: () => Yup.string(),
	}),
	[CLIENT_ERROR]: Yup.boolean(),
	[CLIENT_ID]: Yup.string().when('isExisting', {
		is: (isExisting) => isExisting === true,
		then: () => Yup.string().required('Client is required.'),
		otherwise: () => Yup.string().nullable(true),
	}),
	// [DEAL_NAME]: Yup.string().required('Deal name is required.'),
	// [FOLDER_NAME]: Yup.string()
	// 	.required('Folder name is required.')
	// 	.max(FOLDER_NAME_MAX, `Folder name can be maximum of ${FOLDER_NAME_MAX} characters`),
	[WEBSITE]: Yup.string(),
	[FILES]: Yup.array(),
	[OTHER_INFO]: Yup.string(),
	[RENEWAL_DATE]: Yup.date().nullable(true),
	[ADVISER_FEE]: Yup.number().nullable(true),
	[PRIORITY]: Yup.string().nullable(true),
	[CONTACT_ERROR]: Yup.boolean().test(
		'contact-error',
		'Contact error must be false',
		(value) => value === false
	),
	[CONTACTS]: Yup.array().when(['isExisting', 'contactsFeature'], {
		is: (isExisting, contactsFeature) => isExisting === false && contactsFeature === true,
		then: () =>
			Yup.array()
				.of(
					Yup.object().shape({
						firstName: Yup.string().required('First name is required.'),
						lastName: Yup.string().required('Last name is required.'),
						emailAddress: Yup.string()
							.email('Email address must be a valid email')
							.required('Email address is required.')
							.test(
								'domain-length',
								'Email address must be a valid email',
								(value) => {
									if (!value) return false;
									const parts = value.split('@');
									if (parts.length !== 2) return false;

									const domainParts = parts[1].split('.');
									return (
										domainParts.length >= 2 &&
										domainParts.length <= 3 &&
										domainParts.every((part) => part.length >= 2)
									);
								}
							),
						phoneNumber: Yup.string()
							.required('Phone number is required.')
							.min(7, 'Phone number should be more than 7 characters')
							.max(15, 'Phone number should be no more than 15 characters'),
					})
				)
				.min(1, 'At least one contact is required')
				.test('unique-emails', 'Email addresses must be unique', function (contacts) {
					if (!contacts || contacts.length === 0) return true;

					const emailSet = new Set();
					for (let i = 0; i < contacts.length; i++) {
						const email = contacts[i].emailAddress;
						if (emailSet.has(email)) {
							return this.createError({
								path: `${this.path}[${i}].emailAddress`,
								message: 'Email addresses must be unique',
							});
						}
						emailSet.add(email);
					}
					return true;
				}),
		otherwise: () => Yup.array().notRequired(),
	}),
}).concat(folderValidationSchema([], 'Deal name'));
