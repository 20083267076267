/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	TextField,
	FormHelperText,
	FormLabel,
	FormControl,
	FormControlLabel,
	Checkbox,
	Typography,
	Stack,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
	FIRST_NAME,
	LAST_NAME,
	EMAIL,
	PHONE,
	IS_MAIN_CONTACT,
	ROLE,
	EXISTINGCONTACT,
	CONTACT_ERROR,
} from './validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { NumericFormat } from 'react-number-format';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { InstantContactSelect } from '@/components/selects/ContactsSelect';
import { useQueryContactMutation } from '@/features/contacts/contactsApi';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const ContactFields = ({
	formik,
	loading,
	existingContact,
	index,
	hideHeader = false,
	allowNonPrimary = false,
}) => {
	const { getError, getErrorMessage } = useFormikHelper(formik);

	const [errorMessage, setErrorMessage] = useState('');

	const getFieldName = (field) =>
		index !== undefined ? `contacts.${index}.${field}` : field;

	const isFieldDisabled = (fieldValue) =>
		loading || (existingContact && existingContact[fieldValue] != null);

	const featureFlagContactsQuery = useGetFeatureFlagQuery({
		feature: 'contacts',
	});
	const contactsFeature = useMemo(
		() => featureFlagContactsQuery.data,
		[featureFlagContactsQuery.data]
	);

	const [queryContact, { isQueryingContact }] = useQueryContactMutation();

	useEffect(() => {
		if (formik.values.contacts?.length > 0 && !allowNonPrimary) {
			const hasMainContact = formik.values.contacts.some(
				(contact) => contact[IS_MAIN_CONTACT]
			);
			if (!hasMainContact) {
				formik.setFieldValue(`contacts[0].${IS_MAIN_CONTACT}`, true);
			}
		}
	}, [formik.values.contacts]);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
		isError,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;
	const brokerGroupId = userDetails.brokerGroupArray ?? userDetails?.brokerId;

	const isExistingContact = formik.values.contacts?.[index]?.[EXISTINGCONTACT];

	const handleCreateNewContact = (event) => {
		event.preventDefault();
		formik.setFieldValue(getFieldName(EXISTINGCONTACT), false);
	};

	const handleSearchContact = (event) => {
		event.preventDefault();
		formik.setFieldValue(getFieldName(EXISTINGCONTACT), true);
	};

	return (
		<>
			<Grid container alignItems="center" spacing={2}>
				{contactsFeature && (
					<>
						{(!hideHeader || index !== 0) && (
							<Grid>
								<Typography variant="form_label" fontSize={'16px'}>
              Additional contact details
								</Typography>
							</Grid>
						)}
						<Grid>
							<FormControlLabel
								control={<Checkbox
									name={getFieldName(IS_MAIN_CONTACT)}
									checked={formik.values.contacts?.[index]?.[IS_MAIN_CONTACT] || false}
									onChange={(e) => {
										const isChecked = e.target.checked;
										if (isChecked) {
											const updatedContacts = formik.values.contacts.map(
												(contact, i) => ({
													...contact,
													[IS_MAIN_CONTACT]: i === index,
												})
											);
											formik.setFieldValue('contacts', updatedContacts);
										} else if (allowNonPrimary) {
											formik.setFieldValue(
												getFieldName(IS_MAIN_CONTACT),
												false
											);
										} else {
											const updatedContacts = formik.values.contacts.map(
												(contact, i) => ({
													...contact,
													[IS_MAIN_CONTACT]: i === index ? false : contact[IS_MAIN_CONTACT],
												})
											);
											formik.setFieldValue('contacts', updatedContacts);
										}
									} }
									disabled={loading} />}
								label="Primary contact" />
						</Grid><Grid>
							<Stack alignItems="center" spacing={1} direction="row">
								{isExistingContact ? (
									<>
										<Typography>Search for existing contact or</Typography>
										<Typography
											component="button"
											onClick={handleCreateNewContact}
											sx={{
												color: '#505AFC',
												textDecoration: 'none',
												cursor: 'pointer',
												background: 'none',
												border: 'none',
												padding: 0,
											}}
										>
											create new contact
										</Typography>
									</>
								) : (
									<>
										<Typography>Create new contact or</Typography>
										<Typography
											component="button"
											onClick={handleSearchContact}
											sx={{
												color: '#505AFC',
												textDecoration: 'none',
												cursor: 'pointer',
												background: 'none',
												border: 'none',
												padding: 0,
											}}
										>
											search for existing contact
										</Typography>
									</>
								)}
							</Stack>
						</Grid></>
				)}
			</Grid>
			<Grid container spacing={1} sx={{ width: '100%' }}>
				{isExistingContact && contactsFeature && (
					<Grid size={{ xs: 12, md: 12 }} sx={{ mb: 2 }}>
						<InstantContactSelect
							contactIds={[]}
							setContact={(contact) => {
								if (contact) {
									formik.setValues({
										...formik.values,
										contacts: formik.values.contacts.map((c, i) =>
											i === index
												? {
													...c,
													[FIRST_NAME]: contact.firstName || '',
													[LAST_NAME]: contact.lastName || '',
													[EMAIL]: contact.email || '',
													[PHONE]: contact.phone || '',
													[EXISTINGCONTACT]: true,
												}
												: c
										),
									});
								} else {
									formik.setValues({
										...formik.values,
										contacts: formik.values.contacts?.map((c, i) =>
											i === index
												? {
													...c,
													[FIRST_NAME]: '',
													[LAST_NAME]: '',
													[EMAIL]: '',
													[PHONE]: '',
													[EXISTINGCONTACT]: true,
												}
												: c
										),
									});
								}
							}}
						/>
					</Grid>
				)}
				{(!isExistingContact ||
          formik.values.contacts.some(
          	(contact) =>
          		contact[FIRST_NAME] &&
              contact[LAST_NAME] &&
              contact[EMAIL] &&
              contact[PHONE] &&
              contact[EXISTINGCONTACT]
          )) && (
					<>
						<Grid size={{ xs: 12, md: 6 }}>
							<FormControl fullWidth>
								<FormLabel required>{'First Name'}</FormLabel>
								<TextField
									fullWidth
									name={getFieldName(FIRST_NAME)}
									{...formik.getFieldProps(getFieldName(FIRST_NAME))}
									error={
										getError(getFieldName(FIRST_NAME)) ||
                    (formik.errors.contacts?.[index]?.[FIRST_NAME] &&
                      formik.touched.contacts?.[index]?.[FIRST_NAME])
									}
									helperText={
										getErrorMessage(getFieldName(FIRST_NAME)) ||
                    formik.touched.contacts?.[index]?.[FIRST_NAME]
									}
									disabled={isFieldDisabled('firstname') || isExistingContact}
									required
								/>
							</FormControl>
						</Grid>
						<Grid size={{ xs: 12, md: 6 }}>
							<FormControl fullWidth>
								<FormLabel required>{'Last Name'}</FormLabel>
								<TextField
									fullWidth
									name={getFieldName(LAST_NAME)}
									{...formik.getFieldProps(getFieldName(LAST_NAME))}
									error={
										getError(getFieldName(LAST_NAME)) ||
                    (formik.errors.contacts?.[index]?.[LAST_NAME] &&
                      formik.touched.contacts?.[index]?.[LAST_NAME])
									}
									helperText={
										getErrorMessage(getFieldName(LAST_NAME)) ||
                    formik.touched.contacts?.[index]?.[LAST_NAME]
									}
									disabled={isFieldDisabled('lastname') || isExistingContact}
									required
								/>
							</FormControl>
						</Grid>
						<Grid size={{ xs: 12, md: 4 }}>
							<FormControl fullWidth>
								<FormLabel required>{'Phone Number'}</FormLabel>
								<NumericFormat
									customInput={TextField}
									decimalScale={0}
									fixedDecimalScale
									allowNegative={false}
									allowLeadingZeros
									onValueChange={({ value }) => {
										formik.setFieldValue(getFieldName(PHONE), value);
									}}
									{...formik.getFieldProps(getFieldName(PHONE))}
									required
									disabled={isFieldDisabled('phone') || isExistingContact}
									name={getFieldName(PHONE)}
									error={
										getError(getFieldName(PHONE)) ||
                    (formik.errors.contacts?.[index]?.phoneNumber &&
                      formik.touched.contacts?.[index]?.phoneNumber)
									}
									helperText={
										getErrorMessage(getFieldName(PHONE)) ||
                    formik.errors.contacts?.[index]?.phoneNumber
									}
								/>
							</FormControl>
						</Grid>
						<Grid size={{ xs: 12, md: 4 }}>
							<FormControl fullWidth>
								<FormLabel required>{'Email Address'}</FormLabel>
								<TextField
									fullWidth
									name={getFieldName(EMAIL)}
									{...formik.getFieldProps(getFieldName(EMAIL))}
									error={
										(formik.touched.contacts?.[index]?.emailAddress && 
     !!formik.errors.contacts?.[index]?.emailAddress) ||
    (formik.touched.contacts?.[index]?.emailAddress && 
     !!formik.errors.contacts?.[index]?.emailExists) || isQueryingContact ||
    !!errorMessage
									}
									helperText={
										errorMessage ||
    (formik.touched.contacts?.[index]?.emailAddress && formik.errors.contacts?.[index]?.emailExists) ||
    (formik.touched.contacts?.[index]?.emailAddress && formik.errors.contacts?.[index]?.emailAddress) ||
    'We will use this email to contact you.'
									}
									disabled={isFieldDisabled('email') || isQueryingContact || isExistingContact}
									required
									type="email"
									onBlur={async (e) => {
										const email = e.target.value;
										if (email) {
											try {
												const response = await queryContact({ email, brokerGroupId }).unwrap();
												formik.setFieldValue(`contacts[${index}].emailExists`, !!response.contactData);
												formik.setFieldValue(CONTACT_ERROR, !!response.contactData);
												setErrorMessage(
													response.contactData
														? `Email already exists for contact: ${response.contactData?.firstName} ${response.contactData?.lastName}`
														: ''
												);
											} catch (error) {
												console.error('Error checking contact:', error);
												formik.setFieldValue(`contacts[${index}].emailExists`, false);
												formik.setFieldValue(CONTACT_ERROR, false);
												setErrorMessage('');
											}
										} else {
											formik.setFieldValue(`contacts[${index}].emailExists`, false);
											formik.setFieldValue(CONTACT_ERROR, false);
											setErrorMessage('');
										}
									}}
								/>

							</FormControl>
						</Grid>

						{contactsFeature && (
							<Grid size={{ xs: 12, md: 4 }}>
								<FormControl fullWidth>
									<FormLabel>{'Role'}</FormLabel>
									<TextField
										fullWidth
										name={getFieldName(ROLE)}
										{...formik.getFieldProps(getFieldName(ROLE))}
										error={getError(getFieldName(ROLE))}
										helperText={getErrorMessage(getFieldName(ROLE))}
									/>
								</FormControl>
								<FormHelperText>
									{'Contact\'s role/relationship to the client (e.g director).'}
								</FormHelperText>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);
};
