/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useMemo } from 'react'; // Added useMemo
import {
	AppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	Box,
	Typography,
	Avatar,
	useTheme,
	useMediaQuery,
	ListItemText,
	ListItemButton,
	Button,
	Stack,
	ListItem,
	ListItemIcon,
	ListSubheader,
	Divider,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useMsal } from '@azure/msal-react';
import { toggleOpen } from '@/features/sideNav/sideNavSlice';
import { useMSALHelper } from '@/hooks/useMSALHelper';
import EditProfileDrawer from '@/components/layouts/EditProfileDrawer';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import CustomNavLink from '../SideNavBar/CustomNavLink';
import { paths } from '@/app/routes';
import { NewBusinessIcon } from '@/components/icons/newBusiness';
import { ClaimsIcon } from '@/components/icons/ClaimsIcon';
import { EndorsementIcon } from '@/components/icons/Endorsement';
import { RenewalIcon } from '@/components/icons/Renewal';
import { NavLink, useLocation } from 'react-router-dom';
import { folioBlue } from '@/utils/constants';

export const AccountDropdownMenu = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { handleLogout } = useMSALHelper();

	const location = useLocation();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

	const brokerDetails = userQuery.data?.broker;

	// Feature flag logic
	const featureFlagQuery = useGetFeatureFlagQuery(
		{ feature: 'profile' },
		{ skip: !userQuery.data } // Wait until user data is loaded
	);
	const showProfile = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const handleDrawerToggle = () => {
		dispatch(toggleOpen());
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAddMenuOpen = (event) => {
		setAddMenuAnchorEl(event.currentTarget);
	};

	const handleAddMenuClose = () => {
		setAddMenuAnchorEl(null);
	};

	const handleSignOut = () => {
		handleMenuClose();
		handleAddMenuClose();
		handleLogout();
	};

	const iconProps = {
		width: '25px',
		height: '25px',
		bgColor: 'transparent',
		color: folioBlue, //'#000000',
	};

	const addMenuList = [
		{
			text: 'Add New Business',
			icon: <NewBusinessIcon {...iconProps} />,
			link: paths.createNewBusiness,
		},
		{
			text: 'Add Claim',
			icon: <ClaimsIcon {...iconProps} />,
			link: paths.createClaim,
		},
		{
			text: 'Add Endorsement',
			icon: <EndorsementIcon {...iconProps} />,
			link: paths.createEndorsement,
		},
		{
			text: 'Add Renewal',
			icon: <RenewalIcon {...iconProps} />,
			link: paths.createRenewal,
		},
	];

	const AddMenu = () => (
		<Menu
			id='add-menu-appbar'
			anchorEl={addMenuAnchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={Boolean(addMenuAnchorEl)}
			onClose={handleAddMenuClose}
			sx={{
				'& .MuiTypography-root': {
					fontSize: '13px',
					color: 'rgba(17, 25, 39, 1)',
				},
				'& .MuiListItemIcon-root': {
					minWidth: '2.25em',
				},
			}}
		>
			{addMenuList.map((item, index) => (
				<MenuItem
					key={`${index}-${item.text}`}
					{...(!location.pathname.includes(item.link)
						? {
								component: NavLink,
								to: item.link,
						  }
						: { disabled: true })}
				>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={item.text} />
				</MenuItem>
			))}
		</Menu>
	);

	const MobileMenu = () => (
		<>
			{/* <Divider />
			{addMenuList.map((item, index) => (
				<MenuItem key={`${index}-${item.text}`} component={NavLink} to={item.link}>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={item.text} />
				</MenuItem>
			))}
			<Divider /> */}
		</>
	);

	const UserMenu = () => (
		<Menu
			id='menu-appbar'
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={Boolean(anchorEl)}
			onClose={handleMenuClose}
		>
			<ListSubheader sx={{ fontSize: '12px', lineHeight: '32px' }}>{email}</ListSubheader>
			{isMobile && <MobileMenu />}
			{showProfile && (
				<MenuItem component={NavLink} to={paths.profile}>
					Profile
				</MenuItem>
			)}
			<MenuItem onClick={handleSignOut}>Sign out</MenuItem>
		</Menu>
	);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position='static'
				style={{
					background: 'white',
					boxShadow: 'none',
					borderBottom: '1px solid #D9DCFE',
				}}
			>
				<Toolbar
					variant='dense'
					sx={{ justifyContent: isMobile ? 'space-between' : 'flex-end' }}
				>
					{isMobile && (
						<IconButton
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}
						>
							<MenuIcon />
						</IconButton>
					)}

					{userQuery.data && (
						<Stack direction='row' spacing={3}>
							<>
								{/* {!isMobile && (
                                
								<Stack
									direction='row'
									spacing={1}
									sx={{
										'& .MuiButtonBase-root': {
											textTransform: 'none',
										},
									}}
								>
									<Button variant='outlined' size='small' startIcon={<Add />}>
										Add Task
									</Button>
									<Button variant='outlined' size='small' startIcon={<Add />}>
										Add Note
									</Button>
									<Button
										variant='outlined'
										size='small'
										endIcon={<KeyboardArrowDownIcon />}
										onClick={handleAddMenuOpen}
										aria-controls='add-menu-appbar'
										aria-haspopup='true'
									>
										Add
									</Button>
									<AddMenu />
								</Stack>
                                
							)} */}
							</>
							<div>
								<Button
									variant='text'
									sx={{
										fontWeight: 700,
										fontSize: '1em',
										color: '#000000',
										textTransform: 'none',
									}}
									aria-controls='menu-appbar'
									aria-haspopup='true'
									startIcon={
										brokerDetails?.imageUrl ? (
											<Avatar
												sx={{
													width: '1em',
													height: '1em',
													mr: 2,
													backgroundColor: '#34495E',
												}}
												src={brokerDetails?.imageUrl ?? ''}
											/>
										) : (
											<AccountCircle />
										)
									}
									endIcon={<KeyboardArrowDownIcon />}
									onClick={handleMenuOpen}
								>
									{brokerDetails?.name ?? ''}
								</Button>
								<UserMenu />
							</div>

							{/* <Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								{brokerDetails?.imageUrl ? (
									<Avatar
										sx={{
											width: '1em',
											height: '1em',
											mr: 2,
											backgroundColor: '#34495E',
										}}
										src={brokerDetails?.imageUrl ?? ''}
									/>
								) : (
									<AccountCircle />
								)}
								<Typography variant='broker_header'>
									{brokerDetails?.name || ' '}
								</Typography>
								<IconButton
									size='small'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									onClick={handleMenuOpen}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
							</Box> */}
						</Stack>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	);
};
