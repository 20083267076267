import React, { useState, useMemo } from 'react';
import {
	Stack,
	FormLabel,
	MenuItem,
	Select,
	Typography,
	Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatDate, PRIORITIES } from '@/utils/constants';
import {
	useDeleteTaskMutation,
	useUpdateTaskMutation,
} from '@/features/engagements/engagementsApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { getUnixTime, isAfter, isValid } from 'date-fns';
import { theme } from '@/app/theme';
import '@/styles/index.scss';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { determineContext } from '@/hooks/determineContext';
import { TaskSubjectUpdateField } from '../cards/TaskCardValues/UpdateTaskSubjectField';
import EngagementsAccordion from '../cards/EngagementsAccordion';
import TaskCheckbox from './TaskCheckbox';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import ChecklistIcon from '@mui/icons-material/Checklist';

const minDate = new Date();

export const TaskAccordion = ({ task }) => {
	const { deal, objectType, client, contact } = determineContext();

	const objectId = (
		objectType === 'client' ? client : objectType === 'contact' ? contact : deal
	)?.hs_object_id;

	const location = useLocation();

	const openedTask = useMemo(() => {
		if (location.state?.task) {
			return location.state.task == task?.id;
		}
		return false;
	}, [location?.state?.task]);

	const [updateTask, { isLoading, isSuccess, isError }] =
    useUpdateTaskMutation();
	const [
		deleteTask,
		{
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			isError: isDeleteError,
		},
	] = useDeleteTaskMutation();

	const { data: owner } = useGetOwnerQuery(
		{ ownerId: task.ownerId },
		{ skip: !task.ownerId }
	);

	const minimalInputTheme =
    theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme =
    theme.components.MuiFormLabel.styleOverrides.minimal;

	const dueDate = useMemo(() => new Date(task.due), [task.due]);
	const lastUpdated = useMemo(
		() => new Date(task.lastUpdated),
		[task.lastUpdated]
	);

	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);

	const handleConfirmDialog = async () => {
		handleCloseDialog();
		await archiveTask();
	};

	const getFullName = () => {
		return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown';
	};

	const completeTask = async () => {
		await updateTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
			properties: {
				hs_task_status: task.completed ? 'NOT_STARTED' : 'COMPLETED',
			},
		}).unwrap();
	};

	const archiveTask = async () => {
		await deleteTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
		});
	};

	const updateTaskProperties = async ({ priority, dueDate }) => {
		const properties = {
			...(dueDate && { hs_timestamp: `${getUnixTime(dueDate) * 1000}` }),
			...(priority && { hs_task_priority: priority.toUpperCase() }),
		};

		await updateTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
			properties,
		}).unwrap();
	};


	const displayTaskIcon = useMemo(() => {
		switch (task?.metadata?.taskType) {
		case 'CALL':
			return <PhoneIcon fontSize="small" />;
		case 'EMAIL':
			return <EmailIcon fontSize="small" />;
		case 'MEETING':
			return <GroupsIcon fontSize="small" />;
		default:
			return <ChecklistIcon fontSize="small" />;
		}
	}, [task?.metadata?.taskType]);
	
	//Bundle up the client and deal with task data to send to task dialog
	const engagement = {
		...task,
		client: client ?? null,  
	};
	return (
		<>
			<EngagementsAccordion
				title={
					<Stack direction="row" spacing="0.5em">
						{displayTaskIcon}
						<span style={{ fontWeight: 'bold' }}>{task?.metadata?.taskType}</span>
						<span>{` assigned to ${getFullName()}`}</span>
						<span >{' | '}</span>
						<span style={{ fontWeight: '500' }}>
                            Due {formatDate(dueDate, false)}
						</span>
					</Stack>
				}
				expandedByDefault={openedTask}
				engagement={engagement}
				lastUpdated={lastUpdated}
				editable={true}
				loading={isLoading || isDeleteLoading}
				handleDelete={handleOpenDialog}
				contentPrefix={
					<TaskCheckbox
						task={task}
						isLoading={isLoading}
						completeTask={completeTask}
					/>
				}
				content={
					<TaskSubjectUpdateField
						title={task.subject}
						task={task}
						isSubject={true}
						isEditable={false}
					/>
				}
				footerContent={
					<Stack alignItems={'center'} direction={'row'} spacing={1}><Typography sx={minimalLabelTheme}>Priority</Typography><Typography variant="subtitle2">{task.priority}</Typography></Stack>
				}
				expandedContent={
					<TaskSubjectUpdateField
						title={parse(task.body ?? '')}
						task={task}
						isSubject={false}
						isEditable={false}
					/>
				}
			/>
			<ConfirmDialog
				openDialog={dialogOpen}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				actionName={'delete task'}
				additionalContent={`\nThis cannot be undone.\nPlease note that this will not delete any associated calendar invites.`}
			/>
		</>
	);
};
