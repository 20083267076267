/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useMemo } from 'react';
import {
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	FormHelperText,
} from '@mui/material';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { useGetClientPoliciesQuery } from '@/features/clients/clientsApi';
import { stableSort } from '@/components/table/functions';
// import { policyHeadCells } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { PolicyRow } from '@/components/tables/PolicyRow';
import { useGetDealPolicyTransactionsQuery } from '@/features/deals/dealsApi';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

const policyHeadCells = [
	{ id: 'checkbox', numeric: false, label: null, sortable: false },
    {
		id: 'policyNumber',
		numeric: false,
		label: 'Policy No. Class of Risk',
		align: 'left',
		sortable: false,
	},
	{
		id: 'invoiceNumber',
		numeric: false,
		label: 'Invoice Number',
		align: 'left',
		sortable: false,
	},
	{
		id: 'policyPeriod',
		numeric: false,
		label: 'Policy Period',
		align: 'left',
		sortable: false,
	},
	{
		id: 'description',
		numeric: false,
		label: 'Description',
		align: 'left',
		sortable: false,
	},
	{
		id: 'insurer',
		numeric: false,
		label: 'Insurer',
		align: 'left',
		sortable: false,
	},
];

export const CompleteDealPolicySelection = ({
	setSelectedPolicies,
	setLoading,
	editable = false,
	renewalPolicy = null,
}) => {
	const [policyRows, setPolicyRows] = useState([]);
	const { deal, client, policies } = determineContext();

	const isEndorsement =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_ENDORSEMENTS;
	const isRenewal =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_RENEWALS;
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const policyId = useMemo(() => {
		if (renewalPolicy) {
			return renewalPolicy?.Id;
		} else {
			return policies?.[0]?.Id;
		}
	}, [policies, isRenewal, renewalPolicy]);

	const {
		data: policyData,
		isLoading,
		isError: isPoliciesError,
		isFetching,
		isUninitialized: isPoliciesUninitialised,
	} = editable || isEndorsement || isRenewal
		? useGetDealPolicyTransactionsQuery(
			{
				policyId,
				dealId: deal?.id ?? deal?.hs_object_id,
				transactionType: isEndorsement
					? 'endorsement'
					: isRenewal
						? 'renewal'
						: 'newBusiness',
			},
			{
				skip: !policyId,
			}
		  )
		: useGetClientPoliciesQuery(
			{
				insightId: client?.clientRow?.insightId,
				hubspotId: client?.clientRow?.hubspotId,
				transactionType: 'newBusiness',
			},
			{ skip: !client?.clientRow?.insightId && !client?.clientRow?.hubspotId }
		  );

	const handleCheckboxChange = (event, policyTransId) => {
		console.log('here is the event and here is the policy transaction: ', event, policyTransId);

		const { checked } = event.target;
		const selectedPolicy = policyRows.find((p) => p.Id === policyTransId);
		const selectedpolicyId = policyId ?? selectedPolicy?.Id;
		setPolicyRows((prev) =>
			prev.map((p) => ({ ...p, selected: p.Id === policyTransId && checked }))
		);

		setSelectedPolicies((prev) => {
			if (checked) {
				const updatedPolicies = prev.filter(
					(policy) => policy.policyId !== selectedpolicyId
				);
				const policyToAdd = {
					policyTransId: policyId
						? policyTransId
						: selectedPolicy?.policyTransactions[0]?.Id,
					policy: selectedPolicy,
					policyId: selectedpolicyId,
				};

				//Need to think of a better approach for this part allow only renewals can select mutiple policies
				if (isRenewal) {
					return [...updatedPolicies, policyToAdd];
				} else {
					return [policyToAdd];
				}
			} else {
				return prev.filter((policy) => policy.policyId !== policyId);
			}
		});
	};

	// const handleCheckboxChange = (event, policyTransId) => {
	// 	const { checked } = event.target;
	// 	const selectedPolicy = policyRows.find((p) => p.Id === policyTransId);
	// 	const selectedpolicyId = policyId ?? selectedPolicy?.Id;

	// 	setPolicyRows((prev) =>
	// 		prev.map((p) => ({
	// 			...p,
	// 			selected: p.Id === policyTransId ? checked : p.selected,
	// 		}))
	// 	);

	// 	setSelectedPolicies((prev) => {
	// 		if (checked) {
	// 			const isAlreadySelected = prev.some((policy) => policy.policyId === selectedpolicyId);

	// 			if (!isAlreadySelected) {
	// 				const policyToAdd = {
	// 					policyTransId: policyId ? policyTransId : selectedPolicy?.policyTransactions[0]?.Id,
	// 					policy: selectedPolicy,
	// 					policyId: selectedpolicyId,
	// 				};
	// 				return [...prev, policyToAdd];
	// 			}
	// 		} else {
	// 			return prev.filter((policy) => policy.policyId !== selectedpolicyId);
	// 		}
	// 		return prev;
	// 	});
	// };

	// const handleSelectAllCheckbox = (event) => {
	// 	const { checked } = event.target;

	// 	const unassociatedPolicies = policyRows.filter(
	// 		(policy) =>
	// 			!policy.associated &&
	// 			(policy.policyTransactions == null || policy.policyTransactions.length > 0)
	// 	);
	// 	const updatedPolicyRows = policyRows.map((policy) => {
	// 		if (unassociatedPolicies.includes(policy)) {
	// 			return { ...policy, selected: checked };
	// 		}
	// 		return policy;
	// 	});

	// 	setPolicyRows(updatedPolicyRows);
	// 	setSelectedPolicies(checked ? unassociatedPolicies : []);
	// };

	const noPolicyId = (editable || isEndorsement || isRenewal) && !policyId;
	const error = isPoliciesError || noPolicyId;
	const loading = !error && (isLoading || isPoliciesUninitialised);
	useEffect(() => setLoading(loading), [loading]);

	useEffect(() => {
		if (policyData) {
			const updatedPolicies = policyData.map((policy) => ({
				...policy,
				selected: false,
				linkedToCurrentDeal: editable && policies.some((p) => p.Id === policy.Id),
			}));
			setPolicyRows(updatedPolicies);
		}
	}, [policyData, editable, policies]);

	const policyString = isEndorsement
		? 'endorsement'
		: isRenewal
			? 'policy transaction'
			: 'policy';

	return (
		<>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', marginTop: 3 }}>
				{isRenewal && showNewRenewalsFeature
					? `Choose the INSIGHT policy transaction that relates to policy: ${renewalPolicy?.PolicyNumber}`
					: `Choose the INSIGHT ${policyString} that relates to this deal`}
			</Typography>
			{!editable && !(isEndorsement || isRenewal) && (
				<Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
					{`*The selected ${policyString} will be linked to the renewal deal at the end of the term.`}
				</Typography>
			)}
			<TableContainer component={Paper}>
				<Table
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
					sx={{ minWidth: 700 }}
				>
					<FolioTableHead
						order={'desc'}
						orderBy={'createDate'}
						headCells={policyHeadCells}
						// onSelectAllCheck={isEndorsement ? null : handleSelectAllCheckbox}
					/>
					<TableBody>
						{error ? (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>
										{noPolicyId
											? 'Error: could not find policy assigned to deal.'
											: `${'There was an error. Please try again.'}`}
									</Typography>
								</TableCell>
							</TableRow>
						) : loading || isFetching ? (
							<TableProgressIndicator rowCount={policyHeadCells.length} />
						) : policyRows.length > 0 ? (
							stableSort(policyRows, (a, b) => a.Id - b.Id).map((policy, index) => (
								<PolicyRow
									handleCheckboxChange={handleCheckboxChange}
									key={`policy-row-index-${index}-${policy.Id}`}
									policy={policy}
								/>
							))
						) : (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>{`No unlinked ${policyString} found. Please create in INSIGHT first.`}</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
