/* eslint-disable no-mixed-spaces-and-tabs */
import { TextFieldProps, TextField } from '@mui/material';
import { useField } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { usePropagateRef } from './usePropagateRef';

/**
 * https://github.com/superjose/increase-formik-performance-react
 * This is kind of hacky solution, but it mostly works. Your mileage may vary
 */

const PerformantTextField = memo((props) => {
	const [field, meta] = useField(props.name);
	const error = !!meta.error && meta.touched;

	const { disablePerformance, loading, ...otherProps } = props;
	/**
	 * For performance reasons (possible due to CSS in JS issues), heavy views
	 * affect re-renders (Formik changes state in every re-render), bringing keyboard
	 * input to its knees. To control this, we create a setState that handles the field's inner
	 * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
	 * onFocus.
	 */
	const [fieldValue, setFieldValue] = useState(field.value);

	usePropagateRef({
		setFieldValue,
		name: props.name,
		value: field.value,
	});

	/**
	 * Using this useEffect guarantees us that pre-filled forms
	 * such as passwords work.
	 */
	useEffect(() => {
		if (meta.touched) {
			return;
		}
		if (field.value !== fieldValue) {
			setFieldValue(field.value);
		}
	}, [field.value]);

	const onChange = (event) => {
		setFieldValue(event.target.value);
	};

	const onBlur = (event) => {
		const val = event.target.value ?? '';
		window.setTimeout(() => {
			field.onChange({
				target: {
					name: props.name,
					value: props.type === 'number' ? parseInt(val, 10) : val,
				},
			});
		}, 0);
	};

	// Will set depending on the performance props
	const performanceProps = disablePerformance
		? {
			...field,
			value: loading ? 'Loading...' : fieldValue,
		  }
		: {
			...field,
			value: loading ? 'Loading...' : fieldValue,
			onChange,
			onBlur,
			onFocus: onBlur,
		  };

	// useEffect(() => console.log('PROPOS', otherProps, inputMode), [otherProps, inputMode]);
	return <TextField
			{...otherProps}
			InputProps={{
				...((props.type === 'number' && {
					inputProps: { min: props?.min, max: props?.max },
				}) ||
					undefined),
			}}
			error={error}
			helperText={meta.touched && meta.error}
			{...performanceProps}
		/>
	;
});

PerformantTextField.displayName = 'PerformantTextField';
export default PerformantTextField;
// const PerformantTextField = memo((props) => {
// 	const [field, meta] = useField(props.name);
// 	const error = !!meta.error && meta.touched;

//     const { disablePerformance, loading, ...otherProps } = props;
// 	/**
// 	 * For performance reasons (possible due to CSS in JS issues), heavy views
// 	 * affect re-renders (Formik changes state in every re-render), bringing keyboard
// 	 * input to its knees. To control this, we create a setState that handles the field's inner
// 	 * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
// 	 * onFocus.
// 	 */
// 	const [fieldValue, setFieldValue] = useState(field.value);

// 	usePropagateRef({
// 		setFieldValue,
// 		name: props.name,
// 		value: field.value,
// 	});

// 	/**
// 	 * Using this useEffect guarantees us that pre-filled forms
// 	 * such as passwords work.
// 	 */
// 	useEffect(() => {
// 		if (meta.touched) {
// 			return;
// 		}
// 		if (field.value !== fieldValue) {
// 			setFieldValue(field.value);
// 		}
// 	}, [field.value]);

// 	const onChange = (event) => {
// 		setFieldValue(event.target.value);
// 	};

// 	const onBlur = (event) => {
// 		const val = event.target.value ?? '';
// 		window.setTimeout(() => {
// 			field.onChange({
// 				target: {
// 					name: props.name,
// 					value: props.type === 'number' ? parseInt(val, 10) : val,
// 				},
// 			});
// 		}, 0);
// 	};

// 	// Will set depending on the performance props
// 	const performanceProps = disablePerformance
// 		? {
// 			...field,
// 			value: loading ? 'Loading...' : fieldValue,
// 		}
// 		: {
// 			...field,
// 			value: loading ? 'Loading...' : fieldValue,
// 			onChange,
// 			onBlur,
// 			onFocus: onBlur,
// 		};

// 	return (
// 		<TextField
// 			{...otherProps}
// 			InputProps={{
// 				...((props.type === 'number' && {
// 					inputProps: { min: props?.min, max: props?.max },
// 				}) ||
// 						undefined),
// 			}}
// 			error={error}
// 			helperText={meta.touched && meta.error}
// 			{...performanceProps}
// 		/>

// 	);
// });

// PerformantTextField.displayName = 'PerformantTextField';
// export default PerformantTextField;
