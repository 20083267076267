/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useMemo } from 'react';
import {
	Box,
	Typography,
	FormLabel,
	Stack,
	AccordionDetails,
	Accordion,
	AccordionSummary,
	Avatar,
	Button,
	Chip,
	IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ClientDetailField } from '@/components/ClientDetailEntry/clientDetailEntry';
import {
	useUpdateClientMutation,
	useUpdateInsightClientMutation,
} from '@/features/clients/clientsApi';
import {
	addressSubtitles,
	clientHeaders,
	contactHeaders,
} from '@/components/ClientDetailEntry/clientDetailData';
import { ClientCategory } from '@/components/cards/DealCardValues/ClientCategory';
import GooglePlacesAutocomplete from '@/components/selects/GooglePlacesAutocomplete';
import { determineContext } from '@/hooks/determineContext';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useUpdateClientContactRelationshipMutation } from '@/features/contacts/contactsApi';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { Add } from '@mui/icons-material';
import { AddContactDialog } from '@/components/dialogs/AddContactDialog';
import { openDialog } from '@/features/dialog/dialogSlice';

export const DetailsTab = ({ displayContactDetails = true, clientData }) => {
	const [address] = useState({
		address: clientData?.address || '',
		city: clientData?.city || '',
		suburb: clientData?.suburb || '',
		country: clientData?.country || '',
		postcode: clientData?.postcode || '',
	});

	const dispatch = useDispatch();

	const [updateClient, clientState] = useUpdateClientMutation();
	const [updateInsightClient, clientInsightState] =
		useUpdateInsightClientMutation();
	const { client, contacts, contact } = determineContext();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'contacts' });
	const contactsFeatureEnabled = useMemo(
		() => featureFlagQuery.data,
		[featureFlagQuery.data]
	);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const brokerGroupId = userDetails.brokerGroupArray ?? userDetails?.brokerId;
	const [expanded, setExpanded] = useState(false);
	const handleAccordionChange = (contactId) => (event, isExpanded) => {
		setExpanded(isExpanded ? contactId : null);
	  };

	const [selectedRelationshipDocId, setSelectedRelationshipDocId] =
		useState(null);
	const [selectedDocId, setSelectedDocId] = useState(null);
	const handleConfirmDialog = async () => {
		try {
			const isRemovingMainContact = contacts.find(
				(contact) => contact.id === selectedDocId && contact.isMainContact
			);
			if (isRemovingMainContact) {
				const newMainContact = contacts.find(
					(contact) => contact.id !== selectedDocId
				);
				if (newMainContact) {
					// Promotes another contact
					await updateClientRelationship({
						clientRelationshipId: newMainContact.relationshipDocId,
						clientDocId: client?.clientRow?.docId,
						property: { isMainContact: true },
						clientId: client?.hs_object_id,
						hubspotId: userDetails?.hubspotId,
						brokerGroupId: brokerGroupId,
					}).unwrap();
				}
			}
			await updateClientRelationship({
				clientRelationshipId: selectedRelationshipDocId,
				clientDocId: client?.clientRow?.docId,
				property: { delete: true },
				clientId: client?.hs_object_id,
				hubspotId: userDetails?.hubspotId,
				brokerGroupId: brokerGroupId,
			}).unwrap();
			handleCloseDialog();
		} catch (err) {
			console.error('Error removing association:', err);
		}
	};

	const [dialogOpen, setDialogOpen] = useState(false);
	const handleOpenDialog = (relationshipDocId, docId) => {
		setSelectedRelationshipDocId(relationshipDocId);
		setSelectedDocId(docId);
		setDialogOpen(true);
	};

	const handleCloseDialog = () => setDialogOpen(false);

	const [updateClientRelationship, clientRelationshipState] =
		useUpdateClientContactRelationshipMutation();

	const hubspotId = userDetails?.hubspotId;
	const loading = clientState.isLoading || clientInsightState.isLoading;

	const handleUpdateClient = async (
		address,
		city,
		suburb,
		country,
		postcode
	) => {
		const properties = {
			AddressLine1: address,
			Suburb: suburb,
			City: city,
			Postcode: postcode,
			Country: country,
		};

		try {
			await updateClient({
				clientId: client.hs_object_id,
				properties: { address, city },
			}).unwrap();
			await updateInsightClient({
				properties,
				ownerId: hubspotId,
				clientId: client?.id ?? client?.hs_object_id,
				insightId: client?.insight?.Id,
			}).unwrap();
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddressSelect = async (place) => {
		const addressComponents = place?.address_components || [];

		const streetNumber =
			addressComponents.find((comp) => comp.types.includes('street_number'))
				?.long_name || '';
		const streetAddress =
			addressComponents.find((comp) => comp.types.includes('route'))
				?.long_name || '';
		const combinedAddress = `${streetNumber} ${streetAddress}`.trim();

		const city =
			addressComponents.find((comp) => comp.types.includes('locality'))
				?.long_name || '';
		const suburb =
			addressComponents.find((comp) =>
				comp.types.includes('sublocality_level_1')
			)?.long_name || '';
		const country =
			addressComponents.find((comp) => comp.types.includes('country'))
				?.long_name || '';
		const postcode =
			addressComponents.find((comp) => comp.types.includes('postal_code'))
				?.long_name || '';

		await handleUpdateClient(combinedAddress, city, suburb, country, postcode);
	};

	const contactSection = (data) => (
		<Box style={{ marginBottom: '18px', padding: '12px' }}>
			{Object.entries(contactHeaders).map(([key, header]) => {	
				const firstName = data?.firstName ?? data?.firstname ?? '';
				const lastName = data?.lastName ?? data?.lastname ?? '';
				return (
					<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
						<Grid size={12}>
							{key === 'fullname' ? (
								<Grid container columns={2} spacing={1}>
									<Grid size={1}>
										<ClientDetailField
											type="contact"
											title={header}
											header={(contacts.length > 0 && contactsFeatureEnabled) ? 'firstName' : 'firstname'}
											value={firstName}
											contact={data}
										/>
									</Grid>
									<Grid size={1}>
										<ClientDetailField
											type="contact"
											title={header}
											header={(contacts.length > 0 && contactsFeatureEnabled) ? 'lastName' : 'lastname'}
											value={lastName}
											contact={data}
										/>
									</Grid>
								</Grid>
							) : (
								<ClientDetailField
									title={header}
									type="contact"
									header={key}
									value={data?.[key] || ''}
									readOnly={contacts?.length === 0 ? key === 'email' : null}
									contact={data}
									handleOpenDialog={handleOpenDialog}
								/>
							)}
						</Grid>
					</Grid>
				);
			})}
		</Box>
	);

	const clientSection = () => (
		<Box
			mt={5}
			style={{
				marginBottom: '18px',
				padding: displayContactDetails ? '12px' : '0',
			}}
		>
			{displayContactDetails && (
				<Typography variant="details_header">Client Details</Typography>
			)}
			<Box sx={{ mt: 2 }}> 
				<ClientCategory />
			</Box>
			{Object.entries(clientHeaders).map(([key, header]) => (
				<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
					<Grid size={12}>
						{key === 'address' ? (
							<Grid container columns={{ xs: 1, sm: 2 }} spacing={1}>
								<Grid size={12} key="google-places-autocomplete">
									<div>
										<FormLabel
											component="legend"
											style={{
												fontSize: '0.875rem',
												fontWeight: 'semibold',
												marginBottom: '0.2rem',
											}}
										>
											Find and update Address
										</FormLabel>
										<GooglePlacesAutocomplete onSelect={handleAddressSelect} />
									</div>
								</Grid>
								{Object.entries(addressSubtitles).map(([k, h]) => (
									<Grid size={1} key={`detail-entry-address-${k}`}>
										<ClientDetailField
											type="client"
											header={k}
											title={h}
											value={address[k]}
											readOnly={false}
											isLoading={loading}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<ClientDetailField
								type="client"
								header={key}
								title={header}
								value={clientData?.[key] || ''}
								readOnly={['name', 'email'].includes(key)}
								isLoading={loading}
							/>
						)}
					</Grid>
				</Grid>
			))}
		</Box>
	);

	return (
		<>
			<Box>
				{(displayContactDetails && contacts.length > 0 && contactsFeatureEnabled) ? (
					<Stack spacing={2} ml={1.5} mt={5}>
						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							sx={{ marginBottom: 3, padding: '16' }}
						>
							<Typography variant="details_header">Contact Details</Typography>
							<Button
								variant="tab_header"
								startIcon={<Add />}
								onClick={() => dispatch(openDialog('contact'))}
							>
								Add Contact
							</Button>
						</Stack>
						{[...contacts].sort((a, b) => (b.isMainContact === true ? 1 : 0) - (a.isMainContact === true ? 1 : 0)).map((contact, index) => (
							<Accordion
								key={`contact-accordion-${contact.id}`} 
								expanded={expanded === contact.id}
								onChange={handleAccordionChange(contact.id)}
								sx={{ background: 'none', boxShadow: 'none' }}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel-${index}-content`}
									id={`panel-${index}-header`}
									sx={{ background: 'none' }}
								>
									<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
										<Avatar sx={{ width: 48, height: 48 }}>
											<AccountCircleIcon
												sx={{ fontSize: 32, color: 'white' }}
											/>
										</Avatar>
										<Box>
											<Stack direction="row" alignItems="center" spacing={1}>
												<Typography variant="h6" fontWeight="bold">
													{contact?.firstName} {contact?.lastName}
												</Typography>

												{contact?.isMainContact && (
													<Chip
														label="Primary contact"
														color="primary"
														size="small"
														sx={{}}
													/>
												)}
											</Stack>

											{contact?.email && (
												<Typography variant="body2" color="textSecondary">
													{contact?.email}
												</Typography>
											)}
										</Box>
									</Box>
								</AccordionSummary>
								<AccordionDetails sx={{ background: 'none' }}>
									{contactSection(contact)}
								</AccordionDetails>
							</Accordion>
						))}
					</Stack>
				) : (
					displayContactDetails && contactSection(contact)
				)}
				{clientSection()}
			</Box>
			<ConfirmDialog
				openDialog={dialogOpen}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				canBeUndone={true}
				actionName={'remove'}
				additionalContent={
					'The contact will no longer be associated with this client.'
				}
			/>
			<AddContactDialog />
		</>
	);
};