import React from 'react';
import { Box, Drawer, Stack, IconButton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getProductContent } from '@/utils/constants';
import Markdown from 'react-markdown';

export const ProductGuidanceDialog = ({ open, handleClose, data }) => {
	const DrawerList = (
		<Stack sx={{ width: '100%', height: '100%', pb: '2em' }} spacing={1} role='presentation'>
			<Stack direction='row' sx={{ px: '1em', pt: '1em' }} spacing={1} alignItems='center'>
				<IconButton onClick={handleClose}>
					<ChevronRightIcon />
				</IconButton>
				<Typography variant='broker_header'>{data}</Typography>
			</Stack>

			<Box
				sx={{
					px: '2em',
					overflowY: 'auto',
					flexGrow: 1,
				}}
			>
				<Markdown>{getProductContent(data) ?? ''}</Markdown>
			</Box>
		</Stack>
	);

	return (
		<Drawer
			open={open}
			onClose={handleClose}
			anchor='right'
			sx={{
				width: '50%',
				'& .MuiDrawer-paper': {
					width: '50%',
					boxSizing: 'border-box',
					boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
					display: 'flex',
					flexDirection: 'column',
					height: '100vh', // Ensures full height
				},
			}}
		>
			{DrawerList}
		</Drawer>
	);
};
