import { useEffect } from 'react';

export const useFormikHelper = (formik) => {
	const submitting = formik?.isSubmitting;

	useEffect(() => {
		const el = document.querySelector('.Mui-error, [data-error]');
		const element = el?.parentElement ?? el;
		if (element) {
			try {
				const headerOffset = 45;
				const elementPosition = element.getBoundingClientRect().top;
				const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth',
				});
			} catch (e) {
				console.error('Error scrolling to error element', e);
			}
		}
	}, [submitting]);

	const getError = (title) => formik.touched[title] && Boolean(formik.errors[title]);
	const getErrorMessage = (title) => formik.touched[title] && formik.errors[title];

	return { getError, getErrorMessage };
};
