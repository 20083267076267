/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useMemo } from 'react';
import ClientContext from '@/context/clientContext';
import { DetailsTab } from './ClientTabs/DetailsTab';
import { ClaimsTab } from './ClientTabs/ClaimsTab';
import { PolicyTransactionTab } from './ClientTabs/PolicyTransactionTab';
import { ActivityTab } from '@/components/objectTabs/ActivityTab';
import { NotesTab } from '@/components/objectTabs/NotesTab';
import { CreateNoteButton } from '@/components/buttons/viewHeaderButtons/CreateNoteButton';
import { TabView } from '@/components/layouts/TabView';
import { TasksTab } from '@/components/objectTabs/TasksTab';
import { CreateTaskButton } from '@/components/buttons/viewHeaderButtons/CreateTaskButton';
import { AttachmentsTab } from '@/components/objectTabs/AttachmentsTab';
import { FormsTab } from '@/components/objectTabs/FormsTab';
import { AddNewClaimButton } from '@/components/buttons/AddNewClaimButton';
import { ClientIcon } from '@/components/icons/Client';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const ClientTabView = () => {
	const { client, contact, contacts } = useContext(ClientContext);


	const featureFlagContactsQuery = useGetFeatureFlagQuery({
		feature: 'contacts',
	  });
	  
	  const contactsFeature = useMemo(
		() => featureFlagContactsQuery.data,
		[featureFlagContactsQuery.data]
	  );
	  
	  const mainContact = contactsFeature && contacts?.length > 0 
		? contacts.find((c) => c.isMainContact) 
		: null;
	  
	  const subtitle = contactsFeature
		? mainContact
		  ? `${mainContact.firstName} ${mainContact.lastName}`
		  : contact?.firstname
				? `${contact.firstname} ${contact.lastname}`
				: 'Unknown'
		: contact?.firstname
		  ? `${contact.firstname} ${contact.lastname}`
		  : 'Unknown';

		  
	const tabs = [
		{
			label: 'Deals',
			value: 'policyTransactions',
			ContentComponent: PolicyTransactionTab,
		},
		{
			label: 'Activity',
			value: 'activity',
			ContentComponent: ActivityTab,
		},
		{
			label: 'Claims',
			value: 'claims',
			ContentComponent: ClaimsTab,
			HeaderComponent: AddNewClaimButton,
		},
		{
			label: 'Notes',
			value: 'notes',
			ContentComponent: NotesTab,
			HeaderComponent: CreateNoteButton,
		},
		{
			label: 'Tasks',
			value: 'tasks',
			ContentComponent: TasksTab,
			HeaderComponent: CreateTaskButton,
		},
		{
			label: 'Documents',
			value: 'attachments',
			ContentComponent: AttachmentsTab,
		},
		{
			label: 'Forms',
			value: 'forms',
			ContentComponent: FormsTab,
		},
		{
			label: 'Client details',
			value: 'details',
			ContentComponent: DetailsTab,
		},
	];

	return (
		<TabView
			tabs={tabs}
			title={client.name}
			icon={<ClientIcon height="55px" width="55px" />}
			subtitle={subtitle}
		/>
	);
};