import React from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DEAL_NAME, DEAL_OVERVIEW, YEAR_FOLDER } from '../../CreateNewBusinessForm/validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { FOLDER_NAME } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import { DEAL_NAME_HELP_TEXT, DEAL_OVERVIEW_HELP_TEXT, YEAR_FOLDER_HELP_TEXT } from '@/utils/helperText';
import FolderNameField from '../../CreateNewBusinessForm/FolderNameField';
import { YearFolderSelect } from '@/components/selects/YearFolderSelect';

const DealNaming = ({ formik, disabled, dealName='Deal name' }) => {
	const { getError, getErrorMessage} = useFormikHelper(formik);

	return (
		<>
			{/* <Grid size={12}>
				<FormLabel required>{'Deal name'}</FormLabel>
				<TextField
					fullWidth
					{...formik.getFieldProps(DEAL_NAME)}
					error={getError(DEAL_NAME)}
					helperText={getErrorMessage(DEAL_NAME)}
					disabled={disabled}
					required
					name={DEAL_NAME}
					onChange={(e) => {
						const name = e.target.value;
						formik.setFieldValue(DEAL_NAME, name);
						formik.setFieldValue(FOLDER_NAME, name.slice(0, 40));
					}}
				/>
				<FormHelperText>{DEAL_NAME_HELP_TEXT}</FormHelperText>
			</Grid> */}
			<Grid size={8}>
				<FolderNameField {...{ formik, disabled, dealName }} />
			</Grid>
			<Grid size={4}>
				<FormLabel>{'Year folder'}</FormLabel>
				<YearFolderSelect
					name={YEAR_FOLDER}
					value={formik.values[YEAR_FOLDER]}
					disabled={formik.isSubmitting}
					onChange={(e) => formik.setFieldValue(YEAR_FOLDER, e.target.value)}
				/>
				<FormHelperText>{YEAR_FOLDER_HELP_TEXT}</FormHelperText>
			</Grid>
			<Grid size={12}>
				<FormLabel>
					{'Deal overview'}
				</FormLabel>
				<TextField
					fullWidth
					name={DEAL_OVERVIEW}
					multiline
					rows={3}
					{...formik.getFieldProps(DEAL_OVERVIEW)}
					disabled={disabled}
				/>
				<FormHelperText>
					{DEAL_OVERVIEW_HELP_TEXT}
				</FormHelperText>
			</Grid>
		</>
	);
};

export default DealNaming;