/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useMemo, useEffect } from 'react';
import { Typography, List, Skeleton, FormHelperText } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useDeleteNoteMutation } from '@/features/engagements/engagementsApi';
import { attachmentsClientFolderPath, attachmentsFolderPath, formatDate } from '@/utils/constants';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { useLocation } from 'react-router-dom';
import EngagementsAccordion from '../EngagementsAccordion';
import AttachmentListItem from '@/components/AttachmentListItem';
import { useSelector } from 'react-redux';
import { useGetNoteFilesQuery } from '@/features/files/filesApi';

export const NoteAccordion = ({ note }) => {
	const { deal, dealRow, objectType, client } = determineContext();
	const ownerQueryParams = { ownerId: note.createdBy ?? note.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const id = (objectType === 'client' ? client : deal)?.hs_object_id;
	const attachmentsPath =
		objectType === 'client' ? attachmentsClientFolderPath : attachmentsFolderPath;

	const { data, isFetching, isUninitialized, isError, ...noteFilesQuery } = useGetNoteFilesQuery(
		{
			noteId: note.id,
			id,
			path: attachmentsPath,
		},
		{ skip: !note.id || !id || !attachmentsPath }
	);

	const files = useMemo(
		() => ((data ?? []).length > (note?.files ?? []).length ? data : note.files),
		[note.files, data]
	);

	const { refreshIndicator } = useSelector((state) => state.attachmentsTable);

	useEffect(() => {
		if (data) {
			noteFilesQuery.refetch();
		}
	}, [refreshIndicator]);

	const [deleteNote, { isLoading }] = useDeleteNoteMutation();
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);

	const handleConfirmDialog = async () => {
		handleCloseDialog();
		await archiveNote();
	};

	const archiveNote = async () => {
		await deleteNote({
			dealId: objectType === 'client' ? client.hs_object_id : deal.hs_object_id,
			noteId: note.id,
			objectType: objectType,
		});
	};

	const getFullName = () => {
		return owner ? `${owner?.firstName} ${owner?.lastName}` : 'Unknown';
	};

	const date = useMemo(() => new Date(note.timestamp), [note.timestamp]);
	const lastUpdated = useMemo(() => new Date(note.lastUpdated), [note.lastUpdated]);

	const location = useLocation();

	const noteOpened = useMemo(() => {
		if (location.state?.note) {
			return location.state.note === note?.id;
		}
		return false;
	}, [location?.state?.note]);

	const params = useMemo(
		() => ({
			...((isFetching || isUninitialized || isError) && {
				footerContent: isError ? (
					<Typography variant='task_updated' sx={{ color: '#d32f2f' }}>
						{'Error loading attachments'}
					</Typography>
				) : (
					<Skeleton variant='text' width='25%' height='14px' />
				),
			}),
			...((files ?? []).length > 0 && {
				expandedContent: (
					<>
						<List
							dense
							sx={{
								width: '100%',
								'& .MuiListItemIcon-root': {
									minWidth: 0,
									paddingRight: '0.25em',
								},
								'& .MuiSvgIcon-root': {
									fontSize: 18,
								},
							}}
						>
							{files.map((file, i) => (
								<AttachmentListItem
									key={`attachment-${file?.md5Hash ?? file?.id}-${i}`}
									{...(file?.md5Hash ? { file } : { fileId: file.id })}
								/>
							))}
						</List>
					</>
				),
				footerContent: (
					<Typography variant='task_updated'>{`${
						(files ?? []).length
					} attachments`}</Typography>
				),
			}),
		}),
		[note?.id, files, isFetching, isUninitialized, isError]
	);

	return (
		<>
			<EngagementsAccordion
				title={
					<>
						<span style={{ fontWeight: 'bold' }}>Note</span> by{' '}
						<span>{getFullName()}</span>
						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
						<span style={{ fontWeight: '500' }}>{formatDate(date, true)} </span>
					</>
				}
				expandedByDefault={noteOpened}
				engagement={note}
				// lastUpdated={lastUpdated}
				loading={isLoading}
				handleDelete={handleOpenDialog}
				content={note.content}
				{...params}
			/>
			<ConfirmDialog
				openDialog={dialogOpen}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				actionName={'delete'}
			/>
		</>
	);
};
