import React, { useMemo } from 'react';
import { AttachmentsTable, InfiniteScrollExample } from '@/components/tables/AttachmentsTable';
import { determineContext } from '@/hooks/determineContext';
import { AttachmentsContextProvider } from '@/context/attachmentsContext';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const AttachmentsTab = () => {
	
	const { deal, client } = determineContext();
	
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	return (
		<AttachmentsContextProvider deal={deal} client={client}>
			<AttachmentsTable canDownload={true} />
		</AttachmentsContextProvider>
	);
};
