import React, { useMemo, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormLabel,
	Stack,
	Typography,
	Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { showToast } from '@/features/toast/toastSlice';
import { determineContext } from '@/hooks/determineContext';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { folioBlue } from '@/utils/constants';
import { FolderSelectContextProvider } from '@/context/folderSelectContext';
import { determineIfCanCreateFolder } from '@/utils/folders';

export const MoveFileDialog = () => {
	const { handleMove, clientFolderDriveId, deal, dealRow, client } = useAttachmentsContext();

	const [loading, setLoading] = useState(false);

	const { selectedRows, parentItemId } = useSelector((state) => state.attachmentsTable);

	const dispatch = useDispatch();

	const open = useSelector((state) => state.dialog.open['moveFile']);

	const selectedItemsString = `${selectedRows.length} item${selectedRows.length > 1 ? 's' : ''}`;

	const [selectedFolder, setSelectedFolder] = useState('');

	const renderItems = () => {
		return selectedRows.map((item) => (
			<Stack
				direction='row'
				alignItems={'center'}
				key={`move-items-${item.id}`}
				spacing={'0.25em'}
			>
				{item.isFolder ? (
					<FolderIcon sx={{ color: '#fbce3a' }} />
				) : (
					<DescriptionOutlinedIcon sx={{ color: folioBlue }} />
				)}
				<Stack alignContent={'center'} spacing={'0.25em'}>
					<Typography variant='body1'>{item.name}</Typography>
					<Typography variant='avatar_subtitle'>{item.directory}</Typography>
				</Stack>
			</Stack>
		));
	};

	const handleClose = () => {
		dispatch(closeDialog('moveFile'));
	};

	const handleMoveItems = async () => {
		// const items = rowSelectionModel.map((id) => rows.find((r) => r.id == id));
		const itemLength = selectedRows.length;
		setLoading(true);
		await handleMove(selectedFolder)
			.then((res) => {
				console.log('handleMove RES', res);
				dispatch(
					showToast({
						message: `Successfully moved ${itemLength} item${
							itemLength > 1 ? 's' : ''
						}.`,
						success: true,
					})
				);
				dispatch(triggerRefresh());
				handleClose();
			})
			.catch((err) => {
				console.log('Error moving items', err);
				dispatch(
					showToast({
						message: `Error: ${err.message}`,
						error: true,
					})
				);
			})
			.finally(() => setLoading(false));
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{
				padding: '1em',
				'& .MuiDialog-paper': {
					width: '60%',
				},
			}}
		>
			<DialogTitle>Move items</DialogTitle>
			<DialogContent sx={{ paddingBottom: '2em' }}>
				<FormLabel>{selectedItemsString}</FormLabel>
				<Stack spacing={1}>
					<Box
						sx={{
							maxHeight: '10em',
							overflowY: 'auto',
							border: '1px solid #ddd',
							borderRadius: '4px',
							padding: '1em',
							backgroundColor: '#f9f9f9',
						}}
					>
						<Stack spacing={1}>{renderItems()}</Stack>
					</Box>
					<FolderSelectContextProvider
						{...{
							selectedFolder,
							setSelectedFolder,
							itemId: parentItemId,
							driveId: clientFolderDriveId,
							disableParent: !dealRow,
							cannotSelect: (folder) => {
								return (
									determineIfCanCreateFolder(folder) ||
									selectedRows.some((r) => r.id === folder.id)
								);
							},
						}}
					/>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
				<Button onClick={handleClose} color='primary'>
					Cancel
				</Button>
				<Button
					disabled={(selectedFolder ?? '').length === 0}
					variant='contained'
					color='primary'
					onClick={handleMoveItems}
				>
					{loading ? (
						<CircularProgress size='2em' sx={{ color: '#FFFFFF' }} />
					) : (
						`Move ${selectedItemsString}`
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
