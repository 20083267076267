import { theme } from '@/app/theme';
import * as Yup from 'yup';

export const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;

export const inputProps = {
	...minimalInputTheme,
	'& .MuiInput-underline:before': {
		borderBottom: 'none',
	},
	'& .MuiInputBase-input': {
		color: 'inherit',
		fontWeight: 'inherit',
		padding: '0 0 5px',
	},
};
	
export const claimHeaders = {
	hs_pipeline_stage: 'Claim status',
	Status: 'Claim type',
	ReportedDate: 'Date reported',
	LossDate: 'Date of loss',
	PolicyNumber: 'Policy number',
	PolicyPeriod: 'Policy period',
	InsurerName: 'Insurer',
	Excess: 'Excess',
	claimNumber: 'Claim number',
	EstimateOfLossAmount: 'Estimate of loss',
	Description: 'Description of loss'
	
};