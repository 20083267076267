/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	Typography,
	Box,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
	Breadcrumbs,
	Link,
	FormHelperText,
	Button,
	makeStyles,
	TablePagination,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	setRows,
	setLoading,
	triggerRefresh,
	setSearchValue,
	setItemId,
	// setBreadcrumbs,
	resetSearchFields,
	setSearchText,
	setSelectedRows,
} from '@/features/table/attachmentsTableSlice';
import {
	DataGrid,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GRID_CHECKBOX_SELECTION_COL_DEF,
	useGridApiContext,
	useGridApiRef,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid2';
import RefreshIcon from '@mui/icons-material/Refresh';
import DataGridMultilinePopper from '@/components/table/DataGridMultilinePopper';
import { folioBlue, formatDate, specialChars, truncateFilename } from '@/utils/constants';
import { showToast } from '@/features/toast/toastSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import SearchField from '@/components/SearchField';
import PreviewIcon from '@mui/icons-material/Preview';
import FolderIcon from '@mui/icons-material/Folder';
import { Add, Warning } from '@mui/icons-material';
import { FolderBreadcrumbs } from './FolderBreadcrumbs';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { openDialog } from '@/features/dialog/dialogSlice';
import useCellModes from '@/hooks/useCellModes';
import validationSchema from '@/components/dialogs/RenameFileDialog/validationSchema';
import {
	useAddMetadataToFileMutation,
	useRenameSharepointItemMutation,
} from '@/features/msGraph/msGraphApi';
import { TablePaginationActions } from '@/components/table/TablePaginationActions';

const NoRowsOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.5em'} pt='0.5em'>
			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>No files or folders found.</Typography>
		</Box>
	);
};

const NoRowsErrorOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.5em'} pt='0.5em'>
			<Typography sx={{ color: 'red' }}>Error fetching files and folders.</Typography>
		</Box>
	);
};

const AttachmentsToolbar = () => {
	const dispatch = useDispatch();

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<Box sx={{ flexGrow: 1 }} />
			<IconButton size={'small'} onClick={() => dispatch(triggerRefresh())}>
				<RefreshIcon sx={{ fontSize: '1.25rem', color: folioBlue }} />
			</IconButton>
		</GridToolbarContainer>
	);
};

const InfiniteTablePagination = ({
	count,
	page,
	setPage,
	rowsPerPage,
	hasNextPage,
	hasPreviousPage,
}) => {

	const handleChangePage = async (event, newPage) => {
		console.log('[handleChangePage] NEW PAGE', newPage, 'OLD PAGE', page);
		setPage(newPage);
	};

	const handleRowsPerPageChange = (event) => {
		console.log('handleRowsPerPageChange', event.target.value);
	};

	return (
		<Box width='100%' justifyItems='end'>
			<TablePagination
				count={count}
				// labelDisplayedRows={({ from, to, count, page }) => {
				// 	console.log({ from, to, count, page });
				// 	if (searchValue) {
				// 		return `${from}–${to} of ${!hasNextPage ? total : `more than ${to}`}`;
				// 	}
				// 	return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
				// }}
				rowsPerPageOptions={[10]}
				rowsPerPage={rowsPerPage ?? 0}
				page={page}
				component={'div'}
				SelectProps={{
					inputProps: {
						'aria-label': 'Rows per page',
					},
					native: true,
				}}
				sx={{
					display: 'block',
					width: '100%',
				}}
				showFirstButton
				showLastButton={false}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleRowsPerPageChange}
				ActionsComponent={TablePaginationActions}
				slotProps={{
					actions: {
						nextButton: {
							disabled: !hasNextPage,
						},
						previousButton: {
							disabled: !hasPreviousPage,
						},
					},
				}}
			/>
		</Box>
	);
};

const buttonProps = {
	variant: 'contained',
	color: 'primary',
	sx: { fontSize: '12px', textTransform: 'none' },
};

export const AttachmentsTable = ({ canDownload }) => {
	const dispatch = useDispatch();

	const {
		deal,
		client,
		clientFolderDriveId,
		files,
		count,
		paginationModel,
		setPaginationModel,
		sortModel,
		setSortModel,
		// exact,
		// setExact,
		// page,
		// handleNextPage,
		// handlePreviousPage,
		sharepoint,
		isLoading,
		isError,
		dealRow,
		handleDelete,
		canCreate,
		hasNextPage,
		hasPreviousPage,
		// handleRenameFile,
		// handleUpdateFileMetadata,
	} = useAttachmentsContext();

	const {
		// orderBy,
		// order,
		// page,
		// rows,
		itemId,
		parentItemId,
		loading,
		error,
		refreshIndicator,
		searchValue,
		searchText,
		selectedRows,
	} = useSelector((state) => state.attachmentsTable);

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const [rowSelectionModel, setRowSelectionModel] = useState([]);

	const { cellModesModel, handleCellModesModelChange } = useCellModes();

	const anyCellsBeingEdited = Object.values(cellModesModel).some((v) => v?.name?.mode == 'edit');

	const [renameSharepointItem] = useRenameSharepointItemMutation();
	const [addMetadataToFile] = useAddMetadataToFileMutation();

	const handleReset = () => {
		dispatch(resetSearchFields());
	};

	const setActiveFolder = (row) => {
		handleReset();
		dispatch(setItemId(row.id));
	};

	const allLoading = loading || isLoading;
	const allError = error || isError;

	const columns = [
		// ...(canDownload != null && canDownload != false
		// 	? [
		// 		{
		// 			field: 'webUrl',
		// 			headerName: '',
		// 			sortable: false,
		// 			width: GRID_CHECKBOX_SELECTION_COL_DEF.width,
		// 			resizable: false,
		// 			renderCell: ({ row }) => (
		// 				<IconButton
		// 					color='primary'
		// 					href={`${row.webUrl}?web=1`}
		// 					target='_blank'
		// 					rel='noopener noreferrer'
		// 					sx={{ color: folioBlue }}
		// 				>
		// 					<PreviewIcon />
		// 				</IconButton>
		// 			),
		// 		},
		// 	  ]
		// 	: []),
		{
			field: 'name',
			numeric: false,
			headerName: 'Document name',
			editable: true,
			hideable: false,
			align: 'left',
			flex: 2,
			minWidth: 200,
			display: 'flex',
			renderHeader: (params) => <strong>DOCUMENT NAME</strong>,
			renderCell: ({ row }) => {
				return (
					<Tooltip
						title={row.isFolder ? '' : 'Double-click to edit file name'}
						placement='top'
						arrow
					>
						<Stack direction='row' alignItems={'center'}>
							{!row.isFolder &&
								(specialChars.test(row.name) || row.name.length > 250) && (
								<Tooltip
									title={
										specialChars.test(row.name)
											? 'File name contains characters that could cause an error'
											: 'File name should be less than 250 characters'
									}
								>
									<Warning color='warning' sx={{ fontSize: '1.2em' }} />
								</Tooltip>
							)}
							<IconButton
								color='primary'
								{...(row.isFolder
									? {
										onClick: () => setActiveFolder(row),
										sx: { color: '#fbce3a' },
									  }
									: {
										href: `${row.webUrl}?web=1`,
										target: '_blank',
										rel: 'noopener noreferrer',
										sx: { color: folioBlue },
									  })}
							>
								{row.isFolder ? <FolderIcon /> : <PreviewIcon />}
							</IconButton>
							<Stack alignContent={'center'} spacing={'0.25em'}>
								{row.isFolder ? (
									<Link
										component='button'
										underline='hover'
										variant='body1'
										color={'inherit'}
										onClick={() => setActiveFolder(row)}
										noWrap
										textAlign={'left'}
									>
										{row?.name}
									</Link>
								) : (
									<Typography noWrap variant='body1'>
										{row?.name}
									</Typography>
								)}
								{(searchValue.length > 0 || !showNewFoldersFeature) && (
									<Typography noWrap variant='avatar_subtitle'>
										{row.directory}
									</Typography>
								)}
							</Stack>
						</Stack>
					</Tooltip>
				);
			},
			renderEditCell: (params) => (
				<DataGridMultilinePopper
					{...params}
					maxLength={250}
					validationSchema={validationSchema}
				/>
			),
		},
		{
			field: 'type',
			// sortable: true,
			sortable: false,
			headerName: 'File type',
			align: 'right',
			width: 85,
			resizable: false,
			display: 'flex',
			renderHeader: (params) => <strong>TYPE</strong>,
			renderCell: ({ row }) => {
				return row.isFolder ? (
					<></>
				) : (
					<Typography noWrap variant='body1'>
						{row.type}
					</Typography>
				);
			},
		},
		// {
		// 	field: 'cannotDelete',
		// 	sortable: false,
		// 	headerName: 'Cannot delete',
		// 	align: 'right',
		// 	width: 85,
		// 	resizable: false,
		// 	display: 'flex',
		// 	renderHeader: (params) => <strong>CANNOT DELETE</strong>,
		// 	renderCell: ({ row }) => {
		// 		<Typography noWrap variant='body1'>
		// 			{row.cannotDelete.toString()}
		// 		</Typography>;
		// 	},
		// },
		{
			// field: 'lastModifiedTimeUnix',
			field: 'lastModifiedDateTime',
			sortable: true,
			headerName: 'Last modified',
			align: 'right',
			flex: 1,
			minWidth: 200,
			display: 'flex',
			renderHeader: (params) => <strong>LAST MODIFIED</strong>,
			renderCell: ({ row }) => (
				<Typography noWrap variant='body1'>
					{row.lastModifiedDateTime
						? formatDate(new Date(row.lastModifiedDateTime), true)
						: ''}
				</Typography>
			),
		},
		{
			field: 'createdDateTimeUnix',
			// sortable: true,
			sortable: false,
			headerName: 'Date added',
			align: 'right',
			flex: 1,
			minWidth: 200,
			display: 'flex',
			renderHeader: (params) => <strong>DATE ADDED</strong>,
			renderCell: ({ row }) => (
				<Typography noWrap variant='body1'>
					{row.createdDateTime ? formatDate(new Date(row.createdDateTime), true) : ''}
				</Typography>
			),
		},
		{
			field: 'description',
			headerName: 'Description',
			sortable: false,
			flex: 2,
			minWidth: 200,
			editable: true,
			display: 'flex',
			renderHeader: (params) => <strong>DESCRIPTION</strong>,
			renderCell: ({ row }) => {
				const empty = (row.description ?? '').length === 0;
				return (
					<span style={{ ...(empty && { color: 'rgba(0,0,0,0.25)' }) }}>
						{empty && !row.isFolder ? 'Please enter a description' : row.description}
					</span>
				);
			},
			renderEditCell: (params) => <DataGridMultilinePopper {...params} newLine={false} />,
		},
	];

	const displayedFiles = useMemo(() => {
		// const display = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

		const search = searchValue.toLowerCase().trim();
		const filesToDisplay =
			!showNewFoldersFeature && search.length > 0
				? files.filter(
					(file) =>
						file.name.toLowerCase().includes(search) ||
							file.description.toLowerCase().includes(search)
				  )
				: files;
		return filesToDisplay;
		// return showNewFoldersFeature ? filesToDisplay : stableSort(filesToDisplay, getComparator('desc', 'createdDateTimeUnix'));
		// }, [rows, searchValue, showNewFoldersFeature]);
	}, [files, searchValue, showNewFoldersFeature]);

	const updateDescription = async (newRow, oldRow) => {
		const metadata = {
			// OData__ExtendedDescription: newRow.Description.trim(),
			_ExtendedDescription: newRow.description.trim(),
		};
		await addMetadataToFile({
			itemId: oldRow.id,
			driveId: oldRow.parentReference.driveId,
			metadata,
		}).unwrap();
		// await handleUpdateFileMetadata(oldRow, metadata);
		// await handleAddMetadataFileDocument(metadata, oldRow.serverRelativeUrl);
	};

	const updateFileName = async (newRow, oldRow) => {
		// const names = newRow.name.split('.');

		// const cleanedName = (newRow.name.includes('.') ? names.slice(0, -1).join('') : newRow.name)
		// 	.replace('.', '')
		// 	.replace(specialChars, '')
		// 	.trim()
		// 	.slice(0, 250);

		const cleanedName = newRow.name.trim().replace(specialChars, '').slice(0, 250);
		const type =
			!oldRow.isFolder && !cleanedName.endsWith(`.${oldRow.type}`) ? `.${oldRow.type}` : '';
		// const type = !oldRow.isFolder ? `.${oldRow.type}` : '';

		// const newName = cleanedName.length > 0 ? `${cleanedName}${type}` : oldRow.name;
		const newName =
			cleanedName.replace(type, '').length === 0 ? oldRow.name : `${cleanedName}${type}`;
		if (newName != oldRow.name) {
			await renameSharepointItem({
				driveId: oldRow.parentReference.driveId,
				itemId: oldRow.id,
				name: newName,
			}).unwrap();
			return true;
		} else {
			return false;
		}
	};

	const processRowUpdate = async (newRow, oldRow) => {
		if (oldRow.description !== newRow.description) {
			try {
				await updateDescription(newRow, oldRow);
				dispatch(
					showToast({
						message: 'Description updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update description: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		if (oldRow.name !== newRow.name) {
			console.log('NEW ROW', newRow);
			try {
				const renamed = await updateFileName(newRow, oldRow);
				if (renamed) {
					dispatch(
						showToast({
							message: `${oldRow.isFolder ? 'Folder' : 'File'} name updated!`,
							success: true,
						})
					);
					dispatch(triggerRefresh());
				}
			} catch (error) {
				console.log('error?.response?.data', error?.response?.data);
				const errorMessageString = error?.response?.data?.error?.message;
				const errorMessage =
					typeof errorMessageString === 'string'
						? errorMessageString
						: error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update ${
							oldRow.isFolder ? 'Folder' : 'File'
						} name: ${errorMessage}`,
						error: true,
					})
				);
				dispatch(triggerRefresh());
			}
		}
		return newRow;
	};

	const handleProcessRowUpdateError = (error) => {
		console.log('Error updating row', error);
	};

	const handleSearch = () => {
		// console.log('breadcrumbs', breadcrumbs);
		if (searchText != searchValue) {
			// dispatch(setRows([]));
			dispatch(setItemId(parentItemId));
			// dispatch(setBreadcrumbs([breadcrumbs[0]]));
			dispatch(setSearchValue(searchText));
		}
	};

	useEffect(() => handleReset(), []);

	useEffect(() => {
		if (rowSelectionModel.length > 0) {
			setRowSelectionModel([]);
		}
	}, [refreshIndicator]);

	useEffect(() => {
		const items = rowSelectionModel.map((id) => files.find((r) => r.id == id));
		dispatch(setSelectedRows(items.filter((i) => i)));
	}, [rowSelectionModel]);

	const TablePagination = useCallback(
		() => (
			<InfiniteTablePagination
				// count={count}
				// page={paginationModel.page}
				// setPage={(page) => setPaginationModel({ page })}
				// rowsPerPage={10}
				{...{
					count,
					page: paginationModel.page,
					setPage: (page) => setPaginationModel({ page }),
					rowsPerPage: 10,
					hasNextPage,
					hasPreviousPage,
				}}
			/>
		),
		[count, paginationModel.page, hasNextPage, hasPreviousPage]
	);

	return (
		<Box sx={{ width: '100%' }}>
			{/* <Stack direction='row' spacing={2} justifyContent={'space-between'}> */}
			<Grid container spacing={1} alignItems={'flex-start'}>
				<Grid size={{ sm: 12, md: 6 }}>
					<Box width='100%' pb='1em'>
						<Stack direction='row' spacing={1}>
							{showNewFoldersFeature ? (
								<SearchField
									value={searchText}
									disabled={
										allLoading ||
										rowSelectionModel.length > 0 ||
										anyCellsBeingEdited
									}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											handleSearch();
										}
									}}
									onChange={(e) => dispatch(setSearchText(e.target.value))}
									onClick={handleSearch}
									onReset={handleReset}
								/>
							) : (
								<SearchField
									value={searchValue}
									onChange={(event) =>
										dispatch(setSearchValue(event.target.value))
									}
									onReset={() => dispatch(setSearchValue(''))}
									helperText={''}
								/>
							)}
							{/* <FormControlLabel
								control={
									<Checkbox
										checked={exact}
										onChange={() => setExact(!exact)}
										size='small'
									/>
								}
								label={'Exact match'}
							/> */}
						</Stack>
					</Box>
				</Grid>
				<Grid
					size={{ sm: 12, md: 6 }}
					container
					justifyContent={'flex-end'}
					alignItems={'start'}
				>
					{showNewFoldersFeature && (
						<>
							{rowSelectionModel.length > 0 && (
								<>
									{rowSelectionModel.length === 1 && (
										<Box>
											<Button
												{...buttonProps}
												disabled={anyCellsBeingEdited}
												onClick={() => {
													dispatch(
														openDialog({ dialogName: 'renameFile' })
													);
												}}
											>
												Rename
											</Button>
										</Box>
									)}

									<Box>
										<Button
											{...buttonProps}
											disabled={anyCellsBeingEdited}
											onClick={() => {
												dispatch(openDialog({ dialogName: 'moveFile' }));
											}}
										>
											Move to
										</Button>
									</Box>

									<Box>
										<Button
											{...buttonProps}
											disabled={anyCellsBeingEdited}
											onClick={handleDelete}
										>
											Delete
										</Button>
									</Box>
								</>
							)}

							<Box>
								<Tooltip
									title={
										allLoading ||
										!itemId ||
										!canCreate ||
										searchValue.length > 0
											? 'Sorry, you cannot create a folder here.'
											: null
									}
								>
									<span>
										<Button
											disabled={
												allLoading ||
												!itemId ||
												!canCreate ||
												searchValue.length > 0 ||
												anyCellsBeingEdited
											}
											{...buttonProps}
											startIcon={<Add />}
											onClick={() => {
												dispatch(
													openDialog({ dialogName: 'createFolder' })
												);
											}}
										>
											Create folder
										</Button>
									</span>
								</Tooltip>
							</Box>
						</>
					)}
				</Grid>
			</Grid>

			{/* {showNewFoldersFeature && (
					<Stack
						direction='row'
						spacing={1}
						pt='0.25em'
						width='50%'
						justifyContent={'flex-end'}
					>
						{rowSelectionModel.length > 0 && (
							<>
								{rowSelectionModel.length === 1 && (
									<Box>
										<Button
											{...buttonProps}
											onClick={() => {
												dispatch(openDialog({ dialogName: 'moveFile' }));
											}}
										>
											Rename
										</Button>
									</Box>
								)}
								<Box>
									<Button
										{...buttonProps}
										onClick={() => {
											dispatch(openDialog({ dialogName: 'moveFile' }));
										}}
									>
										Move to
									</Button>
								</Box>
								<Box>
									<Button {...buttonProps} onClick={handleDelete}>
										Delete
									</Button>
								</Box>
							</>
						)}
						<Box>
							<Tooltip
								title={
									allLoading || !itemId || !canCreate || searchValue.length > 0
										? 'Sorry, you can\'t create a folder here.'
										: null
								}
							>
								<span>
									<Button
										disabled={
											allLoading ||
											!itemId ||
											!canCreate ||
											searchValue.length > 0
										}
										{...buttonProps}
										startIcon={<Add />}
										onClick={() => {
											dispatch(openDialog({ dialogName: 'createFolder' }));
										}}
									>
										Create folder
									</Button>
								</span>
							</Tooltip>
						</Box>
					</Stack>
				)}
			</Stack> */}
			{showNewFoldersFeature && <FolderBreadcrumbs />}
			{!allLoading && !sharepoint?.isValid ? (
				<Typography variant='subtitle'>
					{`Could not find valid Sharepoint site for ${
						deal ? 'deal' : 'client'
					} owner. Please contact support@folio.insure.`}
				</Typography>
			) : (
				<DataGrid
					loading={allLoading}
					autoHeight
					width='100%'
					getRowHeight={() => 'auto'}
					initialState={{
						sorting: {
							sortModel: [{ field: 'createdDateTimeUnix', sort: 'desc' }],
						},
					}}
					sortingOrder={['desc', 'asc']}
					sx={{
						backgroundColor: 'white',
						'& .MuiDataGrid-editInputCell': {
							backgroundColor: 'transparent',
						},
						'--DataGrid-overlayHeight': allLoading ? '5em' : '3em',
					}}
					isCellEditable={(params) => {
						if (params.row.isFolder) {
							if (params.field == 'name' && !params.row.cannotDelete) {
								return true;
							}
							return false;
						}
						if (params.row.cannotDelete && params.field == 'name') {
							return false;
						}
						return params.colDef?.editable;
					}}
					processRowUpdate={processRowUpdate}
					cellModesModel={cellModesModel}
					onCellModesModelChange={handleCellModesModelChange}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					rows={displayedFiles}
					columns={columns}
					{...(showNewFoldersFeature && {
						isRowSelectable: (params) => !params.row.cannotDelete,
						onRowSelectionModelChange: (newRowSelectionModel) => {
							setRowSelectionModel(newRowSelectionModel);
						},
						rowSelectionModel: rowSelectionModel,
						checkboxSelection: true,
					})}
					disableRowSelectionOnClick
					disableSelectionOnClick
					disableDensitySelector
					// disableColumnFilter
					// disableColumnSelector
					disableColumnMenu
					slots={{
						noRowsOverlay: allError ? NoRowsErrorOverlay : NoRowsOverlay,
						...(showNewFoldersFeature && { toolbar: AttachmentsToolbar }),
						// pagination: InfiniteTablePagination,
						pagination: TablePagination,
					}}
					slotProps={{
						loadingOverlay: {
							variant: 'skeleton',
							noRowsVariant: 'skeleton',
						},
					}}
					sortModel={sortModel}
					onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
					paginationMode='server'
					sortingMode='server'
					filterMode='server'
					rowCount={count}
					paginationModel={paginationModel}
					// onPaginationModelChange={(newModel) => {
					// 	console.log('onPaginationModelChange', newModel);
					// 	setPaginationModel(newModel);
					// }}
					pageSizeOptions={[10]}
				/>
			)}
			{/* <DevJSONView value={selectedRows} /> */}
		</Box>
	);
};
