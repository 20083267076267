// features/table/attachmentsTableSlice.js
import { createSlice, current } from '@reduxjs/toolkit';

export const attachmentsTableSlice = createSlice({
	name: 'attachmentsTable',
	initialState: {
		rows: [],
		itemId: null,
		parentItemId: null,
		// breadcrumbs: [],
		searchValue: '',
		searchText: '',
		loading: false,
		error: false,
		refreshIndicator: 0,
		selectedRows: [],
	},
	reducers: {
		setRows: (state, action) => {
			state.rows = action.payload ?? [];
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		triggerRefresh: (state) => {
			state.refreshIndicator += 1;
		},
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		},
		setSearchText: (state, action) => {
			state.searchText = action.payload;
		},
		resetSearchFields: (state) => {
			state.searchValue = '';
			state.searchText = '';
		},
		setItemId: (state, action) => {
			state.itemId = action.payload;
		},
        setParentItemId: (state, action) => {
			state.parentItemId = action.payload;
		},
		// setBreadcrumbs: (state, action) => {
		// 	const newValue = action.payload;
		// 	if (Array.isArray(newValue)) {
		// 		state.breadcrumbs = newValue;
		// 	} else {
                
		// 		const currentValues = current(state.breadcrumbs);
		// 		state.breadcrumbs = [...currentValues.filter((f) => f.id != newValue.id), newValue];
		// 		// if (currentValues.some((d) => d.id == newValue.id)) {
		// 		// 	state.breadcrumbs = currentValues.filter((f) => f.id != newValue.id);
		// 		// } else {
		// 		// 	state.breadcrumbs = [...currentValues, newValue];
		// 		// }
		// 	}
		// },
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload;
		}
	},
});

export const {
	setRows,
	setItemId,
	setParentItemId,
	// setBreadcrumbs,
	setError,
	setLoading,
	setSearchValue,
	setSearchText,
	resetSearchFields,
	triggerRefresh,
	setSelectedRows,
} = attachmentsTableSlice.actions;

export default attachmentsTableSlice.reducer;
