/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as Yup from 'yup';
import { ErrorMessage, useFormik } from 'formik';
import {
	engagementsApi,
	useCreateNoteMutation,
} from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '@/utils/firebase';
import {
	formatDate,
	attachmentsFolderPath,
	attachmentsClientFolderPath,
	folderStructure,
	tryDecodeFilePath,
} from '@/utils/constants';
import { jsPDF } from 'jspdf';
import FileUpload from '@/components/FileUpload';
import { dealsApi } from '@/features/deals/dealsApi';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { ticketsApi } from '@/features/claims/ticketsApi';
import { generatePDF } from '@/utils/pdf';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import {
	useAddFileToSharepointMutation,
	useLazyGetFoldersAndFilesQuery,
	useLazySearchFoldersAndFilesQuery,
} from '@/features/msGraph/msGraphApi';


const hasSpecialChars = (value) => /[<>:"'/\\|?%*]/.test(value);
const validationSchema = Yup.object().shape({
	body: Yup.string().required('Note is required.'),
	subject: Yup.string()
		.max(20, 'Subject should be no more than 20 characters')
		.test('no-special-characters', 'Name contains invalid characters.', (value) => {
			if (!value || value.trim() === '') return true;
			return !hasSpecialChars(value);
		})
});


export const AddNoteDialog = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);
	const open = useSelector((state) => state.dialog.open['note']);
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{ skip: !email }
	);

	const [createNote, { isLoading }] = useCreateNoteMutation();
	const { deal, client, contact, objectType, dealRow, folderOwnerId } = determineContext();
	const clientFolderDriveId = dealRow?.clientFolderDriveId ?? client?.clientRow?.clientFolderDriveId;
	const folderOwnerQuery = useGetUserDetailsQuery(
		{ hubspotId: folderOwnerId },
		{ skip: !folderOwnerId }
	);

	const isClient = objectType == 'client';

	const [getFoldersAndFiles] = useLazyGetFoldersAndFilesQuery();
	const [searchFoldersAndFiles] = useLazySearchFoldersAndFilesQuery();
	const [addFileToSharepoint] = useAddFileToSharepointMutation();

	const { getClientDriveId, getDealDriveId } = useClientStorageHooks(folderOwnerQuery.data);

	const handleClose = () => {
		dispatch(closeDialog('note'));
	};

	const isBlank = (str) => !str || /^\s*$/.test(str);

	const handleCreateNote = async () => {
		const id = (
			objectType === 'client'
				? client
				: objectType === 'contact'
					? contact
					: deal
		)?.hs_object_id;

		const subject = String(formik.values.subject || '').trim();
		const finalSubject = isBlank(subject) ? `Created Note - ${formatDate(new Date(), true).replace(':', '.')}` : subject;
		const existingFileName = `${finalSubject}.pdf`;
		const { driveId } = isClient
			? await getClientDriveId(id)
			: objectType == 'contact'
				? { driveId: null }
				: await getDealDriveId(id);
	
		let noteDriveId = isClient
			? client?.clientRow?.notesDriveId
			: dealRow?.notesDriveId;
	
		if (!noteDriveId) {
			const path = isClient
				? folderStructure.clientNotes
				: folderStructure.notes;
			const { folders: drives } = await searchFoldersAndFiles({
				itemId: driveId,
				driveId: clientFolderDriveId,
				searchText: decodeURI(path.normalize()),
				exact: true,
			}).unwrap();
			console.log('[fetchNoteDriveId] FOLDERS', drives);
			const drive = drives.find((d) => tryDecodeFilePath(d.name, path));
			noteDriveId = drive?.id;
		}

		console.log('Using noteDriveId:', noteDriveId);
	
		if (noteDriveId) {
			try {
				const { files: files } = await searchFoldersAndFiles({
					itemId: noteDriveId,
					driveId: clientFolderDriveId,
					searchText: encodeURIComponent(existingFileName),
					exact: true,
				}).unwrap();
				console.log('File existence check result:', files);
				if (files.length > 0) {
					console.log('file exists: ', files);
					return { fileExists: true };
				}
			} catch (error) {
				console.error('Error checking file name:', error);
				formik.setFieldValue(
					'errorMessage',
					'Error checking for existing files. Please try again.'
				);
				return { fileExists: false };
			}
		}
	
	
		const body = {
			dealId: id,
			noteBody: `${formik.values.subject ? `${formik.values?.subject?.trim()}:\n` : ''}${formik.values.body}`,
			hubspotId: userDetails?.hubspotId,
			objectType,
			subject,
		};
		console.log('📋 ~ Create note object', body);
		const response = await createNote(body).unwrap();
		await uploadFilesToStorage(response.id, noteDriveId);
		return response;
	};
	
	const formik = useFormik({
		initialValues: {
			body: '',
			subject: '',
			files: [],
			errorMessage: '',
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFieldValue('errorMessage', '');
			console.log('📋 ~ Formik values', values);

			try {
				const res = await handleCreateNote();
				console.log('here is the res: ', res);
	
				if (res) {
					if (res.fileExists) {
						setFieldValue(
							'errorMessage',
							`A file with the subject "${values.subject}" already exists.`
						);
					} else {
						console.log('🚀 ~ Successfully created note', res);
						dispatch(triggerRefresh());
						resetForm();
						handleClose();
					}
				} else {
			
					console.log('🙅 ~ Error creating note');
					setFieldValue('errorMessage', 'Error creating note. Please try again');
				}
			} catch (err) {
				console.log('🙅 ~ Error creating note', err);
				setFieldValue('errorMessage', 'Error creating note. Please try again');
			}
			setSubmitting(false);
		},
	});
	const generatePDFAndUpload = async (noteBody, noteDriveId, subject) => {

		const file = generatePDF(noteBody, subject);
		console.log('Generated PDF file:', file);

		try {
			if (noteDriveId) {
				await addFileToSharepoint({
					driveId: clientFolderDriveId,
					itemId: noteDriveId,
					file,
				})
					.unwrap()
					.catch((e) =>
						console.error('Error uploading generated PDF file via Graph API', file, e)
					);
			}
		} catch (error) {
			console.error('Error creating file:', error);
		}
	};

	const uploadFilesToStorage = async (noteId, noteDriveId) => {
		const type = objectType.charAt(0).toUpperCase() + objectType.slice(1);
		const id = (
			objectType === 'client'
				? client
				: objectType === 'contact'
					? contact
					: deal
		)?.hs_object_id;

		const attachmentsPath =
			isClient || objectType == 'contact'
				? attachmentsClientFolderPath
				: attachmentsFolderPath;

		let { driveId } = isClient
			? await getClientDriveId(id)
			: objectType == 'contact'
				? { driveId: null }
				: await getDealDriveId(id);

		const path = folderStructure.clientAttachments;

		const parentDriveId = driveId;
		if (isClient) {
			const { folders: drives } = await searchFoldersAndFiles({
				itemId: driveId,
				driveId: clientFolderDriveId,
				searchText: decodeURI(path.normalize()),
				exact: true,
			}).unwrap();
			console.log('[searchClientGraphFolders] FOLDERS', drives);
			const drive = drives.find(
				(d) => d?.folder && tryDecodeFilePath(d?.name, path)
			);
			driveId = drive?.id;
		}

		await Promise.all(
			formik.values.files.map(async (file) => {
				if (driveId) {
					await addFileToSharepoint({
						driveId: clientFolderDriveId,
						itemId: driveId,
						file,
					})
						.unwrap()
						.catch((e) =>
							console.error('Error uploading file via Graph API', file, e)
						);
				}

				const filePath = `/${attachmentsPath}/${id}/${file.name}`;
				const storageRef = ref(storage, filePath);
				const metadata = {
					customMetadata: {
						[`${type} ID`]: id.toString(),
						'Note ID': noteId.toString(),
					},
				};
				await uploadBytes(storageRef, file, metadata);
			})
		).then((r) => {
			console.log(
				`Uploaded ${formik.values.files.length} files`,
				formik.values.files,
				r
			);
		});

	
		if (objectType != 'contact') {
			const subject = formik.values.subject && formik.values.subject.trim() 
				? formik.values.subject.trim() 
				: `Created Note - ${formatDate(new Date(), true).replace(/:/g, '.')}`;
			await generatePDFAndUpload(formik.values.body, noteDriveId, subject);
		}

		setTimeout(async () => {
			try {
				const uppercaseType = (objectType ?? '').toUpperCase();
				dispatch(
					(objectType == 'deal' ? dealsApi : ticketsApi).util.invalidateTags([
						{ type: `${uppercaseType}S`, id: 'LIST' },
						{
							type: `${uppercaseType}S`,
							id: deal?.hs_pipeline_stage ?? deal?.dealstage,
						},
						{ type: uppercaseType, id },
					])
				);
				dispatch(dealsApi.util.invalidateTags([{ type: 'DEAL_ROW', id: id }]));
				dispatch(
					engagementsApi.util.invalidateTags([
						{ type: 'ENGAGEMENTS', id: id },
						{ type: 'TASKS', id: 'LIST' },
					])
				);
				dispatch(triggerRefresh());
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 2000);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			PaperProps={{
				sx: {
					minWidth: { xs: '100%', sm: '70%', md: '50%' },
					padding: '1em',
				},
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Create Note</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						{objectType != 'contact' && (
							<Grid size={12}>
								<FileUpload
									files={formik.values.files}
									setFiles={(files) =>
										formik.handleChange({
											target: {
												name: 'files',
												value: files,
											},
										})
									}
									loading={formik.isSubmitting}
								/>
							</Grid>
						)}
						<Grid size={12}>
							<TextField
								fullWidth={true}
								name="subject"
								placeholder="Subject (max 20 characters)"
								{...formik.getFieldProps('subject')}
								error={formik.touched.subject && Boolean(formik.errors.subject)}
								helperText={formik.touched.subject && formik.errors.subject}
								disabled={formik.isSubmitting}
							/>
						</Grid>
						<Grid size={12}>
							<TextField
								fullWidth={true}
								name="body"
								placeholder="Type here ..."
								multiline
								rows={4}
								{...formik.getFieldProps('body')}
								error={formik.touched.body && Boolean(formik.errors.body)}
								helperText={formik.touched.body && formik.errors.body}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
            Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={isLoading || formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};