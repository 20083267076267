import { configureStore } from '@reduxjs/toolkit';
import msalAccountSlice from '@/features/msalAccount/msalAccountSlice';
import { userApi } from '@/features/user/userApi';
import { ownerApi } from '@/features/user/ownerApi';
import { clientsApi } from '@/features/clients/clientsApi';
import { dealsApi } from '@/features/deals/dealsApi';
import { contactsApi } from '@/features/contacts/contactsApi';
import { pipelineApi } from '@/features/pipelines/pipelineApi';
import { engagementsApi } from '@/features/engagements/engagementsApi';
import { announcementsApi } from '@/features/announcements/announcementsApi';
import { invoicesApi } from '@/features/invoices/invoicesApi';
import { factFindApi } from '@/features/factFind/factFindApi';
import { ticketsApi } from '@/features/claims/ticketsApi';
import { propertiesApi } from '@/features/properties/propertiesApi';
import dealsTableSlice from '@/features/table/dealsTableSlice';
import clientsTableSlice from '@/features/table/clientsTableSlice';
import claimsTableSlice from '@/features/table/claimsTableSlice';
import attachmentsTableSlice from '@/features/table/attachmentsTableSlice';
import instantSearchSlice from '@/features/table/instantSearchSlice';
import invoicesTableSlice from '@/features/table/invoicesTableSlice';
import tasksTableSlice from '@/features/table/tasksTableSlice';
import clientSelectSlice from '@/features/select/clientSelectSlice';
import dealSelectSlice from '@/features/select/dealSelectSlice';
import userPrefsSlice from '@/features/groupView/groupViewSlice';
import toastSlice from '@/features/toast/toastSlice';
import dialogSlice from '@/features/dialog/dialogSlice';
import engagementsSlice from '@/features/engagements/engagementsSlice';
import contactsTableSlice from '@/features/table/contactsTableSlice';
import sideNavSlice from '@/features/sideNav/sideNavSlice';
import { formsApi } from '@/features/forms/formsApi';
import editButtonSlice from '@/features/editButton/editButtonSlice';
import { featureFlagsApi } from '@/features/featureFlags/featureFlagsApi';
import { filesApi } from '@/features/files/filesApi';
import drawerSlice from '@/features/menuSlice/menuSlice';
import { msGraphApi } from '@/features/msGraph/msGraphApi';

/**
 * In this configuration, we set up a Redux store using configureStore from Redux Toolkit.
 * This store includes various slices and API middleware.
 *
 * The msalAccountSlice manages the state related to Microsoft Authentication Library (MSAL) account,
 * while the API slices (userApi, ownerApi, clientsApi, etc.) manage the state and side-effects for
 * different data entities like users, owners, clients, deals, etc.
 *
 * Each API slice's reducer is dynamically added to the store using its reducerPath. The middleware
 * from each API slice is also included, allowing for efficient handling of async operations and
 * caching, which are common when working with APIs in a Redux application.
 *
 * This setup ensures a modular and scalable structure for your application's state management.
 */
const store = configureStore({
	reducer: {
		// Adding slices to the store
		msalAccount: msalAccountSlice,
		dealTable: dealsTableSlice,
		clientTable: clientsTableSlice,
		contactsTable: contactsTableSlice,
		claimTable: claimsTableSlice,
		attachmentsTable: attachmentsTableSlice,
		invoicesTable: invoicesTableSlice,
		taskTable: tasksTableSlice,
		clientSelect: clientSelectSlice,
		dealSelect: dealSelectSlice,
		userPrefs: userPrefsSlice,
		toast: toastSlice,
		dialog: dialogSlice,
		editButton: editButtonSlice,
		engagementsSlice: engagementsSlice,
		sideNavSlice: sideNavSlice,
		instantSearch: instantSearchSlice,
		menuDrawerSlice: drawerSlice,
		// Adding API slices to the store using dynamic keys from each API's reducerPath
		[userApi.reducerPath]: userApi.reducer,
		[ownerApi.reducerPath]: ownerApi.reducer,
		[clientsApi.reducerPath]: clientsApi.reducer,
		[contactsApi.reducerPath]: contactsApi.reducer,
		[dealsApi.reducerPath]: dealsApi.reducer,
		[ticketsApi.reducerPath]: ticketsApi.reducer,
		[pipelineApi.reducerPath]: pipelineApi.reducer,
		[engagementsApi.reducerPath]: engagementsApi.reducer,
		[invoicesApi.reducerPath]: invoicesApi.reducer,
		[factFindApi.reducerPath]: factFindApi.reducer,
		[propertiesApi.reducerPath]: propertiesApi.reducer,
		[announcementsApi.reducerPath]: announcementsApi.reducer,
		[msGraphApi.reducerPath]: msGraphApi.reducer,
		[formsApi.reducerPath]: formsApi.reducer,
		[featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
		[filesApi.reducerPath]: filesApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			// Adding API middlewares to intercept and handle API-related actions
			userApi.middleware,
			ownerApi.middleware,
			clientsApi.middleware,
			contactsApi.middleware,
			dealsApi.middleware,
			ticketsApi.middleware,
			pipelineApi.middleware,
			engagementsApi.middleware,
			invoicesApi.middleware,
			factFindApi.middleware,
			propertiesApi.middleware,
			announcementsApi.middleware,
			msGraphApi.middleware,
			formsApi.middleware,
			featureFlagsApi.middleware,
			filesApi.middleware
		),
});

export default store;
