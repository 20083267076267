import { useGetClientRowQuery, useUpdateClientRowMutation } from '@/features/clients/clientsApi';
import { useGetDealRowQuery, useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import {
	useGetFoldersAndFilesQuery,
	useLazyGetFoldersAndFilesQuery,
} from '@/features/msGraph/msGraphApi';
import { useGetBrokerGroupsQuery, useGetUserDetailsQuery } from '@/features/user/userApi';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useClientStorageHooks } from './useClientStorageHooks';

const getCorrectFolderOwner = ({ deal, client }) => {
	const dealOwnerId = deal?.hubspot_owner_id ?? deal?.properties?.hubspot_owner_id;
	const clientOwnerId = client?.hubspot_owner_id ?? client?.properties?.hubspot_owner_id;

	const [updateClientRow] = useUpdateClientRowMutation();
	const [updateDealRow] = useUpdateDealRowMutation();

	const { accessToken } = useSelector((state) => state.msalAccount);

	// const [loading, setLoading] = useState(false);
	const [loadingDealDrives, setLoadingDealDrives] = useState(false);
	const [loadingClientDrives, setLoadingClientDrives] = useState(false);

	const loading = useMemo(
		() => loadingDealDrives || loadingClientDrives,
		[loadingDealDrives, loadingClientDrives]
	);

	const dealOwnerQuery = useGetUserDetailsQuery(
		{ hubspotId: dealOwnerId },
		{ skip: !dealOwnerId }
	);

	const clientOwnerQuery = useGetUserDetailsQuery(
		{ hubspotId: clientOwnerId },
		{ skip: !clientOwnerId }
	);

	const brokerGroupIds = useMemo(() => {
		const dealBrokerIds = dealOwnerQuery.data?.brokerGroupArray ?? [];
		const clientBrokerIds = clientOwnerQuery.data?.brokerGroupArray ?? [];
		return Array.from(new Set(dealBrokerIds.concat(clientBrokerIds)));
	}, [dealOwnerQuery.data, clientOwnerQuery.data]);

	const brokerGroupsQuery = useGetBrokerGroupsQuery(brokerGroupIds, {
		skip: brokerGroupIds.length === 0,
	});

	const clientFolderDriveIds = useMemo(() => {
		const driveIds = (brokerGroupsQuery.data ?? [])
			.concat([dealOwnerQuery.data, clientOwnerQuery.data])
			.map((b) => b?.clientFolderDriveId)
			.filter((d) => d);
		return Array.from(new Set(driveIds));
	}, [brokerGroupsQuery.data, dealOwnerQuery.data, clientOwnerQuery.data]);

	const { data: clientRow, ...clientRowQuery } = useGetClientRowQuery(
		client?.hs_object_id ?? client?.id,
		{
			skip: !client?.hs_object_id && !client?.id,
		}
	);
	const { data: dealRow, ...dealRowQuery } = useGetDealRowQuery(deal?.hs_object_id ?? deal?.id, {
		skip: !deal?.hs_object_id && !deal?.id,
	});
	// const isLoadingDeal =
	// 	(dealRowQuery.isLoading || dealRowQuery.isUninitialized) &&
	// 	(deal?.hs_object_id || deal?.id);

	// const isLoadingClient =
	// 	(clientRowQuery.isLoading || clientRowQuery.isUninitialized) &&
	// 	(client?.hs_object_id || client?.id);

	const dealRowId = dealRow?.driveId;
	const clientRowId = clientRow?.driveId;

	const [getFolders] = useLazyGetFoldersAndFilesQuery();

	// const { getClientGraphFolderByItemId: getDealGraphFolder } = useClientStorageHooks(
	// 	dealOwnerQuery.data
	// );
	// const { getClientGraphFolderByItemId: getClientGraphFolder } = useClientStorageHooks(
	// 	clientOwnerQuery.data
	// );

	// const [correctOwner, setCorrectOwner] = useState(null);
	const clientFolderDriveId = useMemo(
		() => dealRow?.clientFolderDriveId ?? clientRow?.clientFolderDriveId,
		[dealRow?.clientFolderDriveId, clientRow?.clientFolderDriveId]
	);

	const dealFolderQuery = useGetFoldersAndFilesQuery(
		{
			itemId: dealRow?.driveId,
			driveId: dealRow?.clientFolderDriveId,
		},
		{ skip: !accessToken || !dealRow?.driveId || !dealRow?.clientFolderDriveId }
	);

	const clientFolderQuery = useGetFoldersAndFilesQuery(
		{
			itemId: clientRow?.driveId,
			driveId: clientRow?.clientFolderDriveId,
		},
		{ skip: !accessToken || !clientRow?.driveId || !clientRow?.clientFolderDriveId }
	);

	const getValidId = async ({ dealRow, clientRow }) => {
		const setLoading = dealRow ? setLoadingDealDrives : setLoadingClientDrives;
		const row = dealRow ?? clientRow;
		setLoading(true);
		let ids = [];
		for (const driveId of clientFolderDriveIds) {
			const newId = await validateFolderDriveId(
				driveId,
				row.driveId,
				dealRow ? 'deal' : 'client'
			);
			if (newId && !ids.includes(newId)) {
				ids.push(newId);
			}
		}
		ids = Array.from(new Set(ids));
		if (
			(!row?.clientFolderDriveId || !ids.includes(row?.clientFolderDriveId)) &&
			ids.length > 0
		) {
			try {
				if (dealRow) {
					await updateDealRow({
						dealId: dealRow.dealId,
						docId: dealRow.id,
						properties: { clientFolderDriveId: ids[0] },
					}).unwrap();
				} else if (clientRow) {
					await updateClientRow({
						clientId: clientRow.hubspotId,
						docId: clientRow.docId,
						properties: { clientFolderDriveId: ids[0] },
					}).unwrap();
				}
			} catch (e) {
				setLoading(false);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (dealFolderQuery.isError) {
			const { error } = dealFolderQuery;
			console.log('dealFolderQuery error', error);
			if (error?.code?.toString()?.toLowerCase()?.includes('itemnotfound')) {
				getValidId({ dealRow });
			}
		}
	}, [dealFolderQuery.isError, dealFolderQuery.error]);

	useEffect(() => {
		if (clientFolderQuery.isError) {
			const { error } = clientFolderQuery;
			console.log('clientFolderQuery error', error);
			if (error?.code?.toString()?.toLowerCase()?.includes('itemnotfound')) {
				getValidId({ clientRow });
			}
		}
	}, [clientFolderQuery.isError, clientFolderQuery.error]);

	const validateFolderDriveId = async (folderDriveId, itemId, type = 'deal') => {
		// const getFolder = type === 'deal' ? getDealGraphFolder : getClientGraphFolder;
		try {
			const res = await getFolders({
				driveId: folderDriveId,
				itemId,
			})
				.unwrap()
				.then(() => ({ error: '' }))
				.catch((e) => {
					console.log('ERROR in validation', e);
					if (e.code.toString().toLowerCase() === 'itemnotfound') {
						return { error: e.code };
					}
				});
			console.log('getCorrectDriveId response', { dealRowId, clientRowId, res });
			if (res?.error?.length === 0) {
				console.log(`Correct folder drive id is ${folderDriveId}`, {
					dealRow,
					clientRow,
				});
				return folderDriveId;
			}
		} catch (error) {
			console.error(`Error validating folder drive ID ${folderDriveId}:`, error);
		}
	};

	// const runUpdates = async ({ dealRow, clientRow }) => {
	// 	const setLoading = dealRow ? setLoadingDealDrives : setLoadingClientDrives;
	// 	const row = dealRow ?? clientRow;

	// 	const getValidId = async () => {
	// 		setLoading(true);
	// 		let ids = [];
	// 		for (const driveId of clientFolderDriveIds) {
	// 			const newId = await validateFolderDriveId(
	// 				driveId,
	// 				row.driveId,
	// 				dealRow ? 'deal' : 'client'
	// 			);
	// 			if (newId && !ids.includes(newId)) {
	// 				ids.push(newId);
	// 			}
	// 		}
	// 		ids = Array.from(new Set(ids));
	// 		if (
	// 			(!row?.clientFolderDriveId || !ids.includes(row?.clientFolderDriveId)) &&
	// 			ids.length > 0
	// 		) {
	// 			try {
	// 				if (dealRow) {
	// 					await updateDealRow({
	// 						dealId: dealRow.dealId,
	// 						docId: dealRow.id,
	// 						properties: { clientFolderDriveId: ids[0] },
	// 					}).unwrap();
	// 				} else if (clientRow) {
	// 					await updateClientRow({
	// 						clientId: clientRow.hubspotId,
	// 						docId: clientRow.docId,
	// 						properties: { clientFolderDriveId: ids[0] },
	// 					}).unwrap();
	// 				}
	// 			} catch (e) {
	// 				setLoading(false);
	// 			}
	// 		}
	// 		setLoading(false);
	// 	};
	// 	const validateDriveId = async () => {
	// 		const newId = await validateFolderDriveId(row?.clientFolderDriveId, row.driveId);
	// 		if (!newId) {
	// 			await getValidId();
	// 		}
	// 	};
	// 	if (row?.clientFolderDriveId && row?.driveId) {
	// 		validateDriveId();
	// 	} else if (row?.driveId && clientFolderDriveIds.length > 0) {
	// 		getValidId();
	// 	}
	// };

	// useEffect(() => {
	// 	if (accessToken) {
	// 		runUpdates({ clientRow });
	// 	}
	// }, [accessToken, clientFolderDriveIds, clientRow?.clientFolderDriveId, clientRow?.driveId]);

	// useEffect(() => {
	// 	if (accessToken) {
	// 		runUpdates({ dealRow });
	// 	}
	// }, [accessToken, clientFolderDriveIds, dealRow?.clientFolderDriveId, dealRow?.driveId]);

	// useEffect(() => {
	// 	const getCorrectDriveId = async () => {
	// 		if (!clientFolderDriveId && !loading) {
	// 			setLoading(true);
	// 			for (const folderDriveId of clientFolderDriveIds) {
	// 				const getFolder = dealRowId ? getDealGraphFolder : getClientGraphFolder;
	// 				const res = await getFolder(folderDriveId, dealRowId ?? clientRowId);
	// 				console.log('getCorrectDriveId response', { dealRowId, clientRowId, res });

	// 				if (res?.error?.length === 0) {
	// 					console.log(`Correct folder drive id is ${folderDriveId}`, {
	// 						dealRow,
	// 						clientRow,
	// 					});
	// 					if (dealRow) {
	// 						await updateDealRow({
	// 							dealId: dealRow.dealId,
	// 							docId: dealRow.id,
	// 							properties: { clientFolderDriveId: folderDriveId },
	// 						}).unwrap();
	// 					} else if (clientRow) {
	// 						await updateClientRow({
	// 							clientId: clientRow?.hubspotId,
	// 							docId: clientRow?.docId,
	// 							properties: { clientFolderDriveId: folderDriveId },
	// 						}).unwrap();
	// 					}
	// 					break;
	// 				}
	// 			}
	// 			setLoading(false);
	// 		} else if (clientFolderDriveId && loading) {
	// 			setLoading(false);
	// 		}
	// 	};
	// 	console.log('correct folder owner useEffect params', {
	// 		clientFolderDriveId,
	// 		clientFolderDriveIds,
	// 		loading,
	// 		dealRowId,
	// 		clientRowId,
	// 		isLoadingDeal,
	// 		isLoadingClient,
	// 	});
	// 	if (
	// 		!clientFolderDriveId &&
	// 		clientFolderDriveIds.length > 0 &&
	// 		!isLoadingDeal &&
	// 		!isLoadingClient
	// 	) {
	// 		getCorrectDriveId();
	// 	} else if (clientFolderDriveId && loading) {
	// 		setLoading(false);
	// 	}
	// }, [
	// 	clientFolderDriveId,
	// 	clientFolderDriveIds,
	// 	dealRowId,
	// 	clientRowId,
	// 	isLoadingDeal,
	// 	isLoadingClient,
	// ]);

	// useEffect(() => {
	// 	const getCorrectOwner = async () => {
	// 		if (dealOwnerQuery.data) {
	// 			setLoading(true);
	// 			const res = await getDealGraphFolder(
	// 				dealOwnerQuery.data?.clientFolderDriveId,
	// 				dealRowId
	// 			);
	// 			console.log('getCorrectOwner response DEAL', dealRowId, res);
	// 			if (res?.error?.length === 0) {
	// 				setCorrectOwner(dealOwnerId);
	// 				setLoading(false);
	// 				return;
	// 			}
	// 		}
	// 		if (clientOwnerQuery.data) {
	// 			const res = await getClientGraphFolder(
	// 				clientOwnerQuery.data?.clientFolderDriveId,
	// 				clientRowId
	// 			);
	// 			console.log('getCorrectOwner response CLIENT', clientRowId, res);
	// 			if (res?.error?.length === 0) {
	// 				setCorrectOwner(clientOwnerId);
	// 				setLoading(false);
	// 				return;
	// 			}
	// 		}
	// 		setCorrectOwner(null);
	// 		setLoading(false);
	// 	};

	// 	getCorrectOwner();
	// }, [
	// 	dealOwnerId,
	// 	clientOwnerId,
	// 	dealRowId,
	// 	dealOwnerQuery.data,
	// 	clientOwnerQuery.data,
	// 	clientRowId,
	// 	isLoadingDeal,
	// 	isLoadingClient
	// ]);

	return { correctOwner: clientOwnerId ?? dealOwnerId, loading, clientFolderDriveId };
};

export default getCorrectFolderOwner;
