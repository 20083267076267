/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { formatCurrency, formatKey, safelyParseFloat, toCamelCase } from '@/utils/constants';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid2';
import { CLASS_OF_RISK_COLUMNS } from '../validationSchema';
import { useFormikContext } from 'formik';

const NoRowsOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.75em'} pt='0.5em'>
			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
				Select one insurer per classes of risk from Results tables.
			</Typography>
		</Box>
	);
};

export const RecommendationTable = ({ rows }) => {
	// useEffect(() => console.log('ROWS', rows), [rows]);

	const { values } = useFormikContext();

	const classOfRiskKeys = rows.map((r) => toCamelCase(r.classOfRisk));
	// useEffect(() => console.log('classOfRiskKeys', classOfRiskKeys), [classOfRiskKeys]);

	// useEffect(() => console.log('Object.entries(CLASS_OF_RISK_COLUMNS)', Object.entries(CLASS_OF_RISK_COLUMNS)), [CLASS_OF_RISK_COLUMNS]);
	const validColumns = Object.entries(CLASS_OF_RISK_COLUMNS)
		.filter(([key]) => classOfRiskKeys.map((r) => r.toLowerCase()).includes(key.toLowerCase()))
		.map(([key, columns]) => columns);

	// useEffect(() => console.log('validColumns', validColumns), [validColumns]);

	const columnVisibilityModel = useMemo(
		() =>
			Object.keys(CLASS_OF_RISK_COLUMNS.contents).reduce(
				(acc, key) => {
					acc[key] = validColumns.some((columns) => columns[key]);
					return acc;
				},
				{ naturalDisaster: false, nhi: false, fsl: false }
			),
		[validColumns]
	);

	const totalRows = useMemo(() => {
		console.log(
			'CURRENT ROWS',
			rows,
			'broker fee',
			values,
			values?.myRecommendation?.brokerFee,
			'transaction fee',
			values?.myRecommendation?.transactionFee
		);
		const feeTotal =
			safelyParseFloat(values?.myRecommendation?.brokerFee ?? 0) +
			safelyParseFloat(values?.myRecommendation?.transactionFee ?? 0);
        
		const feeGst = feeTotal - (feeTotal / 1.15);
		const newRows = [
			...(rows.length > 0
				? [
					{
						title: 'Fees',
						value: feeTotal,
					},
					{
						title: 'GST',
						value: rows.reduce((acc, row) => {
							acc += safelyParseFloat(row.gst ?? 0);
							return acc;
						}, feeGst),
					},
					{
						title: 'Total amount payable (incl. GST)',
						value: rows.reduce((acc, row) => {
							acc += safelyParseFloat(row.premium ?? 0);
							return acc;
						}, feeTotal),
					},
				  ]
				: []),
		];
		console.log('NEW ROWS', newRows);
		return newRows;
	}, [rows, values?.myRecommendation?.brokerFee, values?.myRecommendation?.transactionFee]);
	// useEffect(() => console.log('columnVisibilityModel', columnVisibilityModel), [columnVisibilityModel]);

	const columns = [
		{
			field: 'classOfRisk',
			headerName: 'Class of Risk',
			minWidth: 250,
			flex: 1,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => formatKey(params.row.classOfRisk ?? ''),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			minWidth: 200,
			flex: 1,
			editable: false,
			sortable: false,
			display: 'flex',
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			minWidth: 150,
			flex: 0.5,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.sumInsured ?? 0);
				return formatCurrency(value, false);
			},
		},

		// {
		// 	field: 'excess',
		// 	headerName: 'Excess ($)',
		// 	minWidth: 150,
		// 	flex: 0.3,
		// 	editable: false,
		// 	sortable: false,
		// 	display: 'flex',
		// 	renderCell: (params) => {
		// 		const value = parseInt(params.row.excess ?? 0);
		// 		// value = isNaN(value) ? 0 : value;
		// 		return /[^0-9.]/.test(params.row.excess ?? '')
		// 			? params.row.excess
		// 			: formatCurrency(value, false);
		// 	},
		// },
		{
			field: 'companyPremium',
			headerName: 'Company Premium (excl.GST) ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.companyPremium ?? 0);
				return formatCurrency(value, true);
			},
		},
		{
			field: 'naturalDisaster',
			headerName: 'Natural Disaster Premium ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.naturalDisaster ?? 0);
				return formatCurrency(value, true);
			},
		},
		{
			field: 'nhi',
			headerName: 'Natural Hazards Insurance Levy ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.nhi ?? 0);
				return formatCurrency(value, true);
			},
		},
		{
			field: 'fsl',
			headerName: 'Fire Service Levy ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.fsl ?? 0);
				return formatCurrency(value, true);
			},
		},
		{
			field: 'gst',
			headerName: 'GST ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.gst ?? 0);
				return formatCurrency(value, true);
			},
		},
		{
			field: 'premium',
			headerName: 'Premium (incl.GST) ($)',
			minWidth: 150,
			flex: 0.3,
			editable: false,
			sortable: false,
			align: 'right',
			headerAlign: 'right',
			display: 'flex',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.premium ?? 0);
				return formatCurrency(value, true);
			},
			cellClassName: 'pinned-column',
			headerClassName: 'pinned-column',
		},
	];

	return (
		<Stack direction='column' spacing={'15px'}>
			<DataGrid
				sx={{
					'--DataGrid-overlayHeight': '3em',
					// '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
					'&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
					// '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
				}}
				disableVirtualization
				columnVisibilityModel={columnVisibilityModel}
				width='100%'
				autoHeight
				getRowHeight={() => 'auto'}
				// getEstimatedRowHeight={() => 200}
				rows={rows}
				getRowId={(row) => `${row.classOfRisk}-${row.insurer}`}
				columns={columns}
				disableRowSelectionOnClick
				disableSelectionOnClick
				disableColumnFilter
				disableColumnSelector
				disableColumnMenu
				hideFooter
				slots={{
					noRowsOverlay: NoRowsOverlay,
				}}
			/>
			<Grid container spacing={'15px'} px={'10px'}>
				{totalRows.map((r, i) => {
					const props = {
						...(i === totalRows.length - 1 && {
							fontWeight: 'bold',
						}),
					};
					return (
						<React.Fragment key={`total-row-${i}`}>
							<Grid size={6} />
							<Grid size={6}>
								<Stack
									direction='row'
									justifyContent='space-between'
									alignItems='center'
								>
									<Typography {...props}>{r.title}</Typography>
									<Typography {...props}>{formatCurrency(r.value)}</Typography>
								</Stack>
							</Grid>
						</React.Fragment>
					);
				})}
			</Grid>
		</Stack>
	);
};