/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';
import { useDispatch, useSelector } from 'react-redux';
import {
	setItemId,
	setLoading,
	setParentItemId,
	triggerRefresh,
} from '@/features/table/attachmentsTableSlice';
import { determineIfFolderDeletable, determineIfCanCreateFolder } from '@/utils/folders';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { AddFolderSharepointDialog } from '@/components/dialogs/AddFolderSharepointDialog';
import { MoveFileDialog } from '@/components/dialogs/MoveFileDialog';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { showToast } from '@/features/toast/toastSlice';
import { FileUploadMSGraph } from '@/components/FileUploadMSGraph';
import { attachmentsClientFolderPath, attachmentsFolderPath } from '@/utils/constants';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '@/utils/firebase';
import { RenameFileDialog } from '@/components/dialogs/RenameFileDialog';
import getCorrectFolderOwner from '@/hooks/getCorrectFolderOwner';
import {
	useAddFileToSharepointMutation,
	useDeleteSharepointItemMutation,
	useGetBreadcrumbsQuery,
	useGetDriveItemQuery,
	useGetItemsInfiniteQuery,
	useMoveSharepointItemMutation,
} from '@/features/msGraph/msGraphApi';

const initialState = {
	deal: {},
	client: {},
	sharepoint: {},
	isLoading: true,
	isError: false,
	dealRow: {},
	ownerId: null,
	files: [],
	allFiles: [],
	paginationModel: 0,
	setPaginationModel: (newModel) => {},
	count: 0,
	// handleNextPage: () => {},
	// handlePreviousPage: () => {},
	canCreate: false,
	clientFolderDriveId: null,
	handleDelete: async () => {},
	handleMove: async (newParentId) => {},
	handleUploadFile: async (file) => {},
	hasNextPage: false,
	hasPreviousPage: false,
	exact: false,
	setExact: (bool) => {}
	// handleRenameFile: async (item, newName) => {},
	// handleUpdateFileMetadata: async (item, metadata) => {},
};

const getFolderPaths = (folders) => {
	const folderMap = folders.reduce((acc, folder) => {
		acc[folder.id] = folder;
		return acc;
	}, {});

	const buildPath = (folder) => {
		const { name, parentReference } = folder;

		if (!parentReference || !parentReference.id) {
			return name;
		}

		const parentFolder = folderMap[parentReference.id];
		if (parentFolder) {
			return `${buildPath(parentFolder)}/${name}`;
		}

		return name;
	};

	return folders.map((folder) => buildPath(folder));
};

const AttachmentsContext = createContext(initialState);

export const useAttachmentsContext = () => {
	const context = useContext(AttachmentsContext);
	if (!context) {
		throw new Error('useAttachmentsContext must be used within an AttachmentsContextProvider');
	}
	return context;
};

export const AttachmentsContextProvider = ({ deal, client, children }) => {
	const dispatch = useDispatch();

	// const { accessToken } = useSelector((state) => state.msalAccount);

	const { correctOwner: ownerId, loading: loadingOwner } = getCorrectFolderOwner({
		deal,
		client,
	});

	const [exact, setExact] = useState(false);

	// const ownerId = client?.hubspot_owner_id ?? deal?.hubspot_owner_id;

	const ownerQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });
	// const clientOwnerQuery = useGetUserDetailsQuery({ hubspotId: clientOwnerId }, { skip: !clientOwnerId });

	const clientRow = client?.clientRow;
	// const [loading, setLoading] = useState(false);
	// const [files, setFiles] = useState([]);

	const dealRowQuery = useGetDealRowQuery(deal?.hs_object_id ?? deal?.id, {
		skip: !deal?.hs_object_id && !deal?.id,
	});

	const clientFolderDriveId =
		dealRowQuery.data?.clientFolderDriveId ?? clientRow?.clientFolderDriveId;

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const { refreshIndicator, parentItemId, itemId, searchValue, selectedRows } = useSelector(
		(state) => state.attachmentsTable
	);

	const { data: breadcrumbs, ...breadcrumbsQuery } = useGetBreadcrumbsQuery({
		endItemId: itemId,
		startItemId: parentItemId,
		driveId: clientFolderDriveId,
	},
	{ skip: !featureFlagQuery.isSuccess || !itemId || !parentItemId || !clientFolderDriveId });

	const canCreate = useMemo(() => {
		if (!breadcrumbs) return false;
		const currentItem = breadcrumbs[breadcrumbs.length - 1];
		if (breadcrumbs.length === 1) {
			// Can create from deal root folder but not client
			return deal != null;
		} else if (currentItem) {
			const cannotCreate = determineIfCanCreateFolder(currentItem);
			return !cannotCreate;
		} else {
			return false;
		}
	}, [breadcrumbs, deal]);

	const [moveSharepointItem] = useMoveSharepointItemMutation();
	const [deleteSharepointItem] = useDeleteSharepointItemMutation();
	const [addFileToSharepoint] = useAddFileToSharepointMutation();

	const isLoading =
		loadingOwner ||
		ownerQuery.isLoading ||
		ownerQuery.isUninitialized ||
		(deal && dealRowQuery.isLoading) ||
		featureFlagQuery.isLoading ||
		featureFlagQuery.isUninitialized;

	const isError = ownerQuery.isError || (!ownerId && !loadingOwner);

	const sharepoint = useMemo(() => {
		const isValidSharepoint =
			ownerQuery.data?.sharepointSite != null &&
			ownerQuery.data?.clientSiteSuffix != null &&
			ownerQuery.data?.clientFolder != null &&
			ownerQuery.data?.clientSite != null;
		return {
			sharepointSite: ownerQuery.data?.sharepointSite,
			clientSiteSuffix: ownerQuery.data?.clientSiteSuffix,
			clientFolder: ownerQuery.data?.clientFolder,
			clientSite: ownerQuery.data?.clientSite,
			isValid: isValidSharepoint,
		};
	}, [ownerQuery.data]);

	const baseSite = useMemo(() => {
		if (sharepoint?.clientSite && sharepoint?.clientFolder) {
			try {
				return decodeURI(`${sharepoint.clientSite}/${sharepoint.clientFolder}`);
			} catch (e) {
				console.log('Error decoding sharepoint URL', e);
				return `${sharepoint.clientSite}/${sharepoint.clientFolder}`;
			}
		}
		return '';
	}, [sharepoint?.clientSite, sharepoint?.clientFolder]);

	useEffect(() => {
		const rowId = deal ? dealRowQuery.data?.driveId : clientRow?.driveId;
		dispatch(setItemId(rowId));
		dispatch(setParentItemId(rowId));
	}, [clientRow?.driveId, dealRowQuery.data?.driveId]);

	const driveQuery = useGetDriveItemQuery(
		{
			itemId,
			driveId: clientFolderDriveId,
		},
		{ skip: !featureFlagQuery.isSuccess || !itemId || !clientFolderDriveId }
	);

	// const filesQuery = useGetFoldersAndFilesQuery(
	// 	{
	// 		itemId,
	// 		driveId: clientFolderDriveId,
	// 		recursive: !showNewFoldersFeature,
	// 	},
	// 	{ skip: !featureFlagQuery.isSuccess || !itemId || !clientFolderDriveId }
	// );

	// const searchFilesQuery = useSearchFoldersAndFilesQuery(
	// 	{
	// 		itemId,
	// 		driveId: clientFolderDriveId,
	// 		searchText: searchValue,
	// 	},
	// 	{
	// 		skip:
	// 			searchValue.length === 0 ||
	// 			!featureFlagQuery.isSuccess ||
	// 			!itemId ||
	// 			!clientFolderDriveId,
	// 	}
	// );

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	});

	// const [sortModel, setSortModel] = useState([{ field: 'createdDateTimeUnix', sort: 'desc' }]);
	const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }]);
	// const [page, setPage] = useState(0);

	const {
		data,
		fetchNextPage,
		fetchPreviousPage,
		isFetchingNextPage,
		isFetchingPreviousPage,
		hasNextPage,
		hasPreviousPage,
		...infiniteQuery
	} = useGetItemsInfiniteQuery(
		{
			itemId,
			driveId: clientFolderDriveId,
			orderBy: sortModel[0].field,
			direction: sortModel[0].sort,
			searchText: searchValue,
			exact,
		},
		{ skip: !featureFlagQuery.isSuccess || !itemId || !clientFolderDriveId }
	);

	useEffect(() => {
		if (paginationModel.page != 0) {
			// console.log('infinitequery PAGINATION MODEL', itemId, paginationModel);
			setPaginationModel((prev) => ({ ...prev, page: 0 }));
		}
	}, [itemId, sortModel]);

	useEffect(
		() =>
			console.log('Response from infiniteQuery', {
				data,
				page: paginationModel.page,
				searchValue,
				hasNextPage,
				hasPreviousPage,
			}),
		[data, paginationModel.page, searchValue, hasNextPage, hasPreviousPage]
	);

	const count = useMemo(() => {
		const sum = (data?.pages ?? []).reduce((acc, page) => {
			acc += (page?.folders ?? []).length + (page?.files ?? []).length;
			return acc;
		}, 0);
		if (searchValue.length === 0) {
			return driveQuery.data?.folder?.childCount ?? 0;
		} else if (hasNextPage && paginationModel.page + 1 === data?.pages?.length) {
			return -1;
		} else {
			return sum;
		}
	}, [driveQuery.data?.folder?.childCount, paginationModel.page, searchValue, hasNextPage]);

	const mapFile = (f, folderPaths) => {
		const suffix = f.name.split('.');
		const isFolder = !Object.keys(f).includes('file');
		// console.log('FILE TTTT', f);
		const cannotDelete = determineIfFolderDeletable(f);

		let webUrl;
		try {
			webUrl = decodeURI(f.webUrl).replace(baseSite, '').replace(decodeURI(f.name), '');
		} catch (e) {
			console.log('Error decoding uri', e);
		}

		const parentPath = f.parentReference?.path ? f.parentReference.path.split('/root:') : null;
		const directory =
			folderPaths.find((p) => p.endsWith(f.parentReference.name)) ??
			(parentPath ? parentPath[parentPath.length - 1] : webUrl) ??
			'';

		return {
			...f,
			type: Object.keys(f).includes('file') ? suffix[suffix.length - 1] : '000folder',
			description: f?.description ?? '',
			directory,
			createdDateTimeUnix: Date.parse(f.createdDateTime),
			lastModifiedTimeUnix: Date.parse(f.lastModifiedDateTime),
			cannotDelete,
			isFolder,
		};
	};

	const infiniteFiles = useMemo(() => {
		const page = paginationModel.page;
		console.log('infiniteQuery.data?.pages', page, data?.pages, data?.pages[page]);
		const { files: newFiles, folders } = data?.pages[page] ?? {
			files: [],
			folders: [],
		};

		console.log('INFINITE FILES AND FOLDERS', { page, files: newFiles, folders });
		const filesAndFolders = newFiles.concat(folders);
		const folderPaths = getFolderPaths(folders);

		return filesAndFolders.map((f) => mapFile(f, folderPaths));
	}, [data?.pages, paginationModel.page]);

	const allFiles = useMemo(() => {
		return (data?.pages ?? []).reduce((acc, dataPage) => {
			const { files: newFiles, folders } = dataPage ?? {
				files: [],
				folders: [],
			};
			const filesAndFolders = newFiles.concat(folders);
		    const folderPaths = getFolderPaths(folders);
			acc.push(filesAndFolders.map((f) => mapFile(f, folderPaths)));
			return acc;
		}, []);
	}, [data?.pages]);

	const handlePaginationModelChange = async (newModel) => {
		const page = paginationModel.page;
		const newPage = newModel?.page;
		console.log('[handlePaginationModelChange] New page', {
			newPage,
			newModel,
			page,
			paginationModel,
			data,
			hasNextPage,
			hasPreviousPage,
		});
		if (newPage > page && !data?.pages[newPage]) {
			await fetchNextPage();
		} else if (newPage < page && !data?.pages[newPage]) {
			await fetchPreviousPage();
		}
		setPaginationModel((prev) => ({ ...prev, page: newPage }));
	};

	const handleSortModelChange = async (newModel) => {
		setPaginationModel((prev) => ({ ...prev, page: 0 }));
		setSortModel(newModel);
	};

	// const searchFiles = useMemo(() => {
	// 	const { files: newFiles, folders } = searchFilesQuery.data ?? { files: [], folders: [] };

	// 	console.log('SEARCH FILES AND FOLDERS', { files: newFiles, folders });
	// 	const filesAndFolders = showNewFoldersFeature ? newFiles.concat(folders) : [...newFiles];
	// 	const folderPaths = getFolderPaths(folders);

	// 	return filesAndFolders.map((f) => mapFile(f, folderPaths));
	// }, [searchFilesQuery.data, showNewFoldersFeature]);

	// const files = useMemo(() => {
	// 	const { files: newFiles, folders } =
	// 		searchValue.length > 0
	// 			? searchFilesQuery.data ?? { files: [], folders: [] }
	// 			: filesQuery.data ?? { files: [], folders: [] };

	// 	console.log('FILES AND FOLDERS', { files: newFiles, folders });
	// 	const filesAndFolders = showNewFoldersFeature ? newFiles.concat(folders) : [...newFiles];
	// 	const folderPaths = getFolderPaths(folders);

	// 	return filesAndFolders.map((f) => mapFile(f, folderPaths));
	// }, [filesQuery.data, searchFilesQuery.data, searchValue, itemId, showNewFoldersFeature]);

	// useEffect(() => {
	// 	console.log({ filesQuery, searchFilesQuery });
	// }, [filesQuery, searchFilesQuery]);

	// useEffect(() => {
	// 	const nextBreadcrumb = infiniteFiles?.[0]?.parentReference;
	// 	// console.log('NEXT breadcrumb', nextBreadcrumb, infiniteFiles, breadcrumbs);
	// 	if (
	// 		searchValue.length === 0 &&
	// 		nextBreadcrumb &&
	// 		!breadcrumbs.some((b) => b.id == nextBreadcrumb?.id)
	// 	) {
	// 		dispatch(setBreadcrumbs(nextBreadcrumb));
	// 	}
	// }, [infiniteFiles]);

	useEffect(() => {
		// if (filesQuery.data) {
		// 	filesQuery.refetch();
		// }
		// if (searchFilesQuery.data) {
		// 	searchFilesQuery.refetch();
		// }
		if (data) {
			infiniteQuery.refetch();
			// if (paginationModel.page != 0) {
			// 	setPaginationModel((prev) => ({ ...prev, page: 0 }));
			// }
		}
	}, [refreshIndicator]);

	// useEffect(() => {
	// 	const updateMsGraph = async () => {
	// 		setLoading(true);
	// 		// const { files, folders } = await getFoldersFunction(userQuery.data?.clientFolderDriveId, itemId, searchValue);
	// 		const { files, folders } =
	// 			showNewFoldersFeature == false
	// 				? await getClientGraphFoldersAndFiles(
	// 					clientRow?.driveId,
	// 					clientRow?.clientFolderDriveId,
	// 					true
	// 				  )
	// 				: searchValue.length > 0
	// 					? await searchClientGraphFoldersAndFiles(
	// 						clientFolderDriveId,
	// 						itemId,
	// 						searchValue
	// 				  )
	// 					: await getClientGraphFolderByItemId(clientFolderDriveId, itemId);

	// 		console.log('FILES AND FOLDERS', { files, folders });
	// 		const filesAndFolders = showNewFoldersFeature ? files.concat(folders) : [...files];
	// 		const folderPaths = getFolderPaths(folders);

	// 		const nextBreadcrumb = filesAndFolders?.[0]?.parentReference;
	// 		// console.log('NEXT breadcrumb', nextBreadcrumb, filesAndFolders, breadcrumbs);
	// 		if (nextBreadcrumb && !breadcrumbs.some((b) => b.id == nextBreadcrumb?.id)) {
	// 			dispatch(setBreadcrumbs(nextBreadcrumb));
	// 		}

	// 		setFiles(
	// 			filesAndFolders.map((f) => {
	// 				const suffix = f.name.split('.');
	// 				const isFolder = !Object.keys(f).includes('file');
	// 				console.log('FILE TTTT', f);
	// 				const cannotDelete = determineIfFolderDeletable(f);

	// 				let webUrl;
	// 				try {
	// 					webUrl = decodeURI(f.webUrl)
	// 						.replace(baseSite, '')
	// 						.replace(decodeURI(f.name), '');
	// 				} catch (e) {
	// 					console.log('Error decoding uri', e);
	// 				}

	// 				const parentPath = f.parentReference?.path
	// 					? f.parentReference.path.split('/root:')
	// 					: null;
	// 				const directory =
	// 					folderPaths.find((p) => p.endsWith(f.parentReference.name)) ??
	// 					(parentPath ? parentPath[parentPath.length - 1] : webUrl) ??
	// 					'';

	// 				return {
	// 					...f,
	// 					type: Object.keys(f).includes('file')
	// 						? suffix[suffix.length - 1]
	// 						: '000folder',
	// 					Description: '',
	// 					directory,
	// 					createdDateTimeUnix: Date.parse(f.createdDateTime),
	// 					lastModifiedTimeUnix: Date.parse(f.lastModifiedDateTime),
	// 					cannotDelete,
	// 					isFolder,
	// 				};
	// 			})
	// 		);
	// 		console.log('Set loading to false here');
	// 		setLoading(false);
	// 	};
	// 	console.log({
	// 		refreshIndicator,
	// 		ownerQueryData: ownerQuery.data,
	// 		clientFolderDriveId,
	// 		itemId,
	// 		featureFlag: !(
	// 			featureFlagQuery.isLoading ||
	// 			featureFlagQuery.isFetching ||
	// 			featureFlagQuery.isUninitialized
	// 		),
	// 		featureFlagSuccess: featureFlagQuery.isSuccess,
	// 		showNewFoldersFeature,
	// 	});
	// 	if (
	// 		ownerQuery.data &&
	// 		clientFolderDriveId &&
	// 		itemId &&
	// 		!(
	// 			featureFlagQuery.isLoading ||
	// 			featureFlagQuery.isFetching ||
	// 			featureFlagQuery.isUninitialized
	// 		) &&
	// 		featureFlagQuery.isSuccess &&
	// 		(showNewFoldersFeature === true || showNewFoldersFeature === false)
	// 	) {
	// 		updateMsGraph();
	// 	} else {
	// 		if (files.length !== 0) {
	// 			setFiles([]);
	// 		}
	// 	}
	// }, [
	// 	ownerQuery.data,
	// 	clientFolderDriveId,
	// 	itemId,
	// 	refreshIndicator,
	// 	searchValue,
	// 	baseSite,
	// 	clientRow,
	// 	showNewFoldersFeature,
	// 	featureFlagQuery.isSuccess,
	// 	featureFlagQuery.isLoading,
	// 	featureFlagQuery.isFetching,
	// 	featureFlagQuery.isUninitialized,
	// ]);

	// useEffect(() => {
	// 	if (filesQuery.data) {
	// 		filesQuery.refetch();
	// 	}
	// }, [refreshIndicator]);

	const handleDelete = async () => {
		const itemLength = selectedRows.length;
		await Promise.all(
			selectedRows.map(
				async (item) =>
					await deleteSharepointItem({
						driveId: item.parentReference.driveId,
						itemId: item.id,
					}).unwrap()
			)
		)
			.then((res) => {
				console.log('handleDelete RES', res);
				dispatch(
					showToast({
						message: `Successfully deleted ${itemLength} item${
							itemLength > 1 ? 's' : ''
						}.`,
						success: true,
					})
				);
				dispatch(triggerRefresh());
			})
			.catch((err) => {
				console.log('Error deleting items', err);
				const message =
					err?.response?.data?.error?.message ??
					err?.message ??
					'Error deleting items; please try again.';
				dispatch(
					showToast({
						message: `Error: ${message}`,
						error: true,
					})
				);
			})
			.finally(() => setConfirmDialogOpen(false));
	};

	const handleMove = async (newParentId) => {
		return await Promise.all(
			selectedRows.map(
				async (item) =>
					await moveSharepointItem({
						driveId: item.parentReference.driveId,
						itemId: item.id,
						newParentId,
					}).unwrap()
			)
		);
	};

	// const handleUpdateFileMetadata = async (item, metadata) => {
	// 	console.log('ITEM TO UPDATE', item);
	// 	return await addMetadataToFile({
	// 		itemId: item.id,
	// 		driveId: item.parentReference.driveId,
	// 		metadata,
	// 	});
	// };

	// const handleRenameFile = async (item, newName) => {
	// 	return await renameSharepointItem(item.parentReference.driveId, item.id, newName);
	// };

	const uploadToFirebaseStorage = async (file) => {
		const isClient = deal ? false : true;

		const objectId = deal ? deal?.id ?? deal?.hs_object_id : client?.id ?? client?.hs_object_id;
		const attachmentsPath = isClient ? attachmentsClientFolderPath : attachmentsFolderPath;

		const filePath = `/${attachmentsPath}/${objectId}/${file.name}`;
		const storageRef = ref(storage, filePath);
		const metadata = {
			customMetadata: {
				[`${isClient ? 'Client' : 'Deal'} ID`]: objectId.toString(),
			},
		};

		await uploadBytes(storageRef, file, metadata);
	};

	const handleUploadFile = async (file) => {
		// console.log('UPLOADING FILE', file);
		dispatch(setLoading(true));
		try {
			await uploadToFirebaseStorage(file);
		} catch (e) {
			console.log('Error uploading file to Firebase Storage', e);
		}

		await addFileToSharepoint({
			driveId: clientFolderDriveId,
			itemId,
			file,
		})
			.unwrap()
			.then((res) => {
				console.log('handleUploadFile RES', res);
				dispatch(
					showToast({
						message: `Successfully uploaded '${file.name}'.`,
						success: true,
					})
				);
				dispatch(triggerRefresh());
			})
			.catch((e) => {
				console.error('Error uploading file via Graph API', file, e);
				dispatch(
					showToast({
						message: `Error: ${e?.message}`,
						error: true,
					})
				);
			});
		// await addFileToSharepointMsGraph(clientFolderDriveId, itemId, file)
		// 	.then((res) => {
		// 		console.log('handleUploadFile RES', res);
		// 		dispatch(
		// 			showToast({
		// 				message: `Successfully uploaded '${file.name}'.`,
		// 				success: true,
		// 			})
		// 		);
		// 		dispatch(triggerRefresh());
		// 	})
		// 	.catch((err) => {
		// 		console.log('Error uploading file', err);
		// 		const message =
		// 			err?.response?.data?.error?.message ??
		// 			err?.message ??
		// 			'Error uploading file; please try again.';
		// 		const pathIsTooLong =
		// 			err?.response?.data?.error?.innerError?.code == 'pathIsTooLong';
		// 		const additionalMessage = pathIsTooLong
		// 			? ' Please try shortening folder or file names, or reduce number of sub-folders.'
		// 			: '';
		// 		dispatch(
		// 			showToast({
		// 				message: `Error: ${message}${additionalMessage}`,
		// 				error: true,
		// 			})
		// 		);
		// 	});
		dispatch(setLoading(false));
	};

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	const contextValue = {
		deal,
		client,
		ownerId,
		dealRow: dealRowQuery.data ?? {},
		clientFolderDriveId,
		files: infiniteFiles,
		allFiles,
		paginationModel,
		setPaginationModel: handlePaginationModelChange,
		sortModel,
		setSortModel: handleSortModelChange,
		count,
		exact,
		setExact,
		// handleNextPage,
		// handlePreviousPage,
		isLoading:
			isLoading || infiniteQuery.isFetching || isFetchingNextPage || isFetchingPreviousPage,
		// filesQuery.isFetching ||
		// filesQuery.isUninitialized ||
		// (searchValue.length > 0 &&
		// 	(searchFilesQuery.isFetching || searchFilesQuery.isUninitialized)),
		isError,
		sharepoint,
		handleDelete: () => setConfirmDialogOpen(true),
		handleMove,
		handleUploadFile,
		hasNextPage: hasNextPage || paginationModel.page + 1 < (data?.pages?.length ?? 0),
		hasPreviousPage: paginationModel.page > 0,
		// handleRenameFile,
		// handleUpdateFileMetadata,
		canCreate,
	};

	useEffect(() => {
		console.log('📋 ~ ATTACHMENTS CONTEXT VALUE', contextValue, 'search value', searchValue);
	}, [contextValue, searchValue]);

	return (
		// Provide the context variables to the children components.
		<AttachmentsContext.Provider value={contextValue}>
			<FileUploadMSGraph />
			{/* <Typography>{`Page ${page}`}</Typography>
			<Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
				{page > 0 && (
					<Button variant='outlined' onClick={handlePreviousPage}>
						Previous
					</Button>
				)}
				{infiniteQuery.hasNextPage && (
					<Button variant='outlined' onClick={handleNextPage}>
						Next
					</Button>
				)}
			</Stack> */}

			{children}
			<MoveFileDialog />
			<RenameFileDialog />
			<AddFolderSharepointDialog />
			{/* <FileExistsDialog /> */}
			<ConfirmDialog
				openDialog={confirmDialogOpen}
				handleClose={() => setConfirmDialogOpen(false)}
				handleConfirm={handleDelete}
				actionName={'delete'}
			/>
		</AttachmentsContext.Provider>
	);
};

export default AttachmentsContext;
