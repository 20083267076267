/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import {
	Typography,
	Stack,
	TextField,
	CircularProgress,
	Box,
	Skeleton,
	FormControl,
	FormLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { formatKey } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { factFindApi, useCreateDealProposalMutation } from '@/features/factFind/factFindApi';
import { useDispatch, useSelector } from 'react-redux';
import { setEdit, setSubmit } from '@/features/editButton/editButtonSlice';
import Robot from '@/assets/images/ai-assistant.gif';
import { useFormik } from 'formik';
import { DetailsAccordion } from '@/components/forms/CreateSOAForm/fields/DetailsAccordion';

const GeneratingInProgress = ({ title }) => {
	return (
		<>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '41%',
					borderRadius: '50%',
					zIndex: 5,
					height: '150px',
					width: '150px',
					backgroundImage: `url(${Robot})`,
					backgroundPosition: 'left',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					display: 'flex',
					flexDirection: 'column',
				}}
			/>
			<Stack direction='row' spacing={1} alignItems='center' pb='1em'>
				<CircularProgress size={'1.25em'} />
				<Typography>{`Generating ${title.toLowerCase()} data. Please check back soon.`}</Typography>
			</Stack>
		</>
	);
};

export const FactFindForm = ({ title, factFindType, latestDoc }) => {
	const { deal, loading, soas } = determineContext();

	const dispatch = useDispatch();

	const editState = useSelector((state) => state.editButton.edit);
	const hasSubmitted = useSelector((state) => state.editButton.submit[factFindType]);
	const isEditing = editState[factFindType];

	useEffect(() => {
		dispatch(setEdit({ key: factFindType, value: false }));
	}, []);

	const isGeneratingSOA = useMemo(() => soas.length > 0 && soas[0]?.downloadUrl == null, [soas]);

	const isError = latestDoc?.error;
	const isGenerating = latestDoc?.generating || isGeneratingSOA;

	const isObject = (value) => value != null && typeof value === 'object' && !Array.isArray(value);
	const isArray = (value) => value != null && typeof value === 'object' && Array.isArray(value);

	// const createEmptyStructure = (fields) => {
	// 	const result = {};

	// 	console.log('FIELDS', fields);
	// 	Object.entries(fields ?? {}).forEach(([key, value]) => {
	// 		console.log('VALUE', value, typeof value);
	// 		if (isObject(value)) {
	// 			result[key] = Object.keys(value).reduce((acc, innerKey) => {
	// 				console.log('Inner value', fields[key][innerKey], typeof fields[key][innerKey]);
	// 				if (isObject(fields[key][innerKey])) {
	// 					acc[innerKey] = createEmptyStructure(fields[key][innerKey]);
	// 				} else {
	// 					acc[innerKey] = (fields[key][innerKey] ?? '').toString();
	// 				}
	// 				return acc;
	// 			}, {});
	// 		} else if (isArray(value)) {
	// 			result[key] = value.reduce((acc, value, innerKey) => {
	// 				console.log(
	// 					'ARRAY Inner value',
	// 					fields[key][innerKey],
	// 					typeof fields[key][innerKey]
	// 				);
	// 				if (isObject(fields[key][innerKey])) {
	// 					acc[innerKey] = createEmptyStructure(fields[key][innerKey]);
	// 				} else {
	// 					acc[innerKey] = (fields[key][innerKey] ?? '').toString();
	// 				}
	// 				return acc;
	// 			}, {});
	// 		} else {
	// 			if (value != null && Array.isArray(value)) {
	// 				result[key] = value.join(', ');
	// 			} else {
	// 				result[key] = (value ?? '').toString();
	// 			}
	// 		}
	// 	});

	// 	return result;
	// };

	const createEmptyStructure = (fields) => {
		// const result = {};

		// Object.entries(fields ?? {}).forEach(([key, value]) => {
		// 	if (isObject(value)) {
		// 		result[key] = createEmptyStructure(value);
		// 	} else if (isArray(value)) {
		// 		result[key] = value.map((item) =>
		// 			isObject(item) ? createEmptyStructure(item) : (item ?? '').toString()
		// 		);
		// 	} else {
		// 		result[key] = (value ?? '').toString();
		// 	}
		// });

		// return result;
		const arrays = {};
		const nonArrays = {};

		Object.entries(fields ?? {}).forEach(([key, value]) => {
			if (isArray(value)) {
				arrays[key] = value.map((item) =>
					isObject(item) ? createEmptyStructure(item) : (item ?? '').toString()
				);
			} else if (isObject(value)) {
				nonArrays[key] = createEmptyStructure(value);
			} else {
				nonArrays[key] = (value ?? '').toString();
			}
		});

		return { ...nonArrays, ...arrays };
	};

	const initialValues = useMemo(
		() => createEmptyStructure(latestDoc?.jsonData ?? {}),
		[latestDoc?.jsonData]
	);

	const formik = useFormik({
		initialValues,
		validateOnChange: false,
		// validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', {
				values,
			});
			const body = {
				dealId: deal.hs_object_id,
				threadId: latestDoc.threadId,
				jsonData: values,
				type: latestDoc.type,
				docId: latestDoc.id,
			};

			await createDealProposal(body)
				.unwrap()
				.then(async (res) => {
					console.log('🚀 ~ Created Proposal', res);
				})
				.catch((err) => {
					console.log('🙅 ~ Error creating Proposal', err);
				})
				.finally(() => {
					dispatch(setEdit({ key: factFindType, value: false }));
					dispatch(setSubmit({ key: factFindType, value: false }));
				});
			setSubmitting(false);
		},
	});

	useEffect(() => {
		let intervalId;

		const checkRunStatus = async () => {
			dispatch(
				factFindApi.util.invalidateTags([
					{ type: 'DEAL_NOTE_SUMMARY', id: deal?.hs_object_id },
				])
			);
		};

		if (isGenerating) {
			intervalId = setInterval(checkRunStatus, 10000); // Check every 10 seconds
		}

		return () => clearInterval(intervalId);
	}, [isGenerating, deal?.hs_object_id]);

	const [createDealProposal, { isLoading }] = useCreateDealProposalMutation();

	const loadingInProgress = isLoading || loading.deal || loading.aiDocs;

	useEffect(() => {
		if (hasSubmitted) {
			formik.submitForm();
		} else if (!isEditing) {
			formik.resetForm();
		}
	}, [isEditing, hasSubmitted]);

	const accordionProps = (objectField, index, greaterIndex) => {
		return {
			title: formatKey(objectField),
			key: `factfind-field-${index}-${objectField}`,
			titleProps: {
				sx: {
					fontSize: greaterIndex > 1 ? '14px' : '15px',
					fontWeight: greaterIndex > 1 ? 600 : 800,
				},
			},
			sx: {
				backgroundColor: 'transparent',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
				paddingBottom: 0,
				'.MuiAccordionSummary-root': { padding: 0 },
			},
		};
	};

	const renderFields = ({ data, path = '', index = 0 }) => {
		if (!isObject(data)) {
			return <></>;
		}
		return Object.entries(data).map(([key, value], i) => {
			const fieldPath = path ? `${path}.${key}` : key;
			const fieldKey = `factfind-field-${i}-${fieldPath}-${index}`;
			if (isObject(value)) {
				switch (index) {
				case 0:
					return (
						<Stack spacing={1} key={fieldKey}>
							<Typography
								variant='subtitle2'
								sx={{ fontWeight: 'bold', fontSize: '15px' }}
							>
								{formatKey(key)}
							</Typography>
							<Stack spacing={1}>
								{renderFields({
									data: value,
									path: fieldPath,
									index: index + 1,
								})}
							</Stack>
						</Stack>
					);
				case 1:
					return (
						<DetailsAccordion key={fieldKey} {...accordionProps(key, i, index)}>
							<Stack spacing={2}>
								{renderFields({
									data: value,
									path: fieldPath,
									index: index + 1,
								})}
							</Stack>
						</DetailsAccordion>
					);
				default: {
					return (
						<Stack spacing={1} key={fieldKey}>
							<Typography variant='broker_header'>{formatKey(key)}</Typography>
							{renderFields({ data: value, path: fieldPath, index: index + 1 })}
						</Stack>
					);
				}
				}
			} else if (isArray(value)) {
				// Render arrays as nested elements
				return (
					<DetailsAccordion key={fieldKey} {...accordionProps(key, i, index)}>
						{value.map((item, idx) => {
							const prevKeys = fieldPath.split('.');
							const prevTitle = prevKeys[prevKeys.length - 1];
							const subFieldTitle = `${prevTitle.substring(
								0,
								prevTitle.length - 1
							)} ${idx + 1}`;
							return (
								<Box pl={4} key={`box-${fieldKey}-${i}-${idx}-${index}`}>
									<DetailsAccordion
										{...accordionProps(subFieldTitle, i, index)}
										pb={1}
									>
										<Stack spacing={1}>
											{isObject(item)
												? renderFields({
													data: item,
													path: `${fieldPath}[${idx}]`,
													index: index + 1,
													  })
												: renderFormControl(`${fieldPath}[${idx}]`, i)}
										</Stack>
									</DetailsAccordion>
								</Box>
							);
						})}
					</DetailsAccordion>
				);
			} else {
				// Render primitive fields
				return renderFormControl(fieldPath, i);
			}
		});
	};

	const renderFormControl = (field, i) => {
		const fieldArray = field.split('.');
		const fieldName = fieldArray[fieldArray.length - 1];
		const isEndorsementField = fieldName.trim().includes('endorsement');
		return (
			<FormControl
				sx={{ '& .MuiInputBase-root': { p: 0 } }}
				key={`factfind-field-${i}-${field}`}
				fullWidth
			>
				<Grid container alignItems={'center'}>
					<Grid size={{ sm: 12, md: 3 }}>
						<FormLabel component='legend'>{formatKey(fieldName)}</FormLabel>
					</Grid>
					<Grid size={{ sm: 12, md: 9 }}>
						{loadingInProgress || isGenerating ? (
							<Skeleton width='100%' />
						) : (
							<TextField
								variant='standard'
								className='minimal-input'
								{...formik.getFieldProps(field)}
								fullWidth
								InputProps={{
									readOnly: !isEditing,
								}}
								inputProps={{
									...(!isEditing && { style: { cursor: 'not-allowed' } }),
								}}
								{...(isEndorsementField && {
									multiline: true,
									minRows: 2,
									maxRows: 5,
								})}
							/>
						)}
					</Grid>
				</Grid>
			</FormControl>
		);
	};

	if (loadingInProgress && !latestDoc) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					alignItems: 'center',
					pt: '0.5em',
				}}
			>
				<Skeleton animation='wave' height='15px' width='100%' />
			</Box>
		);
	}

	if (!latestDoc?.jsonData && !isGenerating) {
		return (
			<Typography variant='subtitle2' mt='1em'>
				No information available. Upload a document to generate.
			</Typography>
		);
	}

	if (isError) {
		return (
			<Typography variant='subtitle2' mt='1em'>
				There was an error extracting info from uploaded document. Please try again.
			</Typography>
		);
	}

	return (
		<Box pr='0.5em'>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', fontSize: '20px' }}>
				{`${title} details`}
			</Typography>
			<Stack spacing={1}>
				{isGenerating && <GeneratingInProgress title={title} />}
				{latestDoc?.jsonData && renderFields({ data: formik.values })}
				{/* {Object.keys(formik.values).map((field, i) => {
					return typeof formik.values[field] === 'object' ? (
						<Stack spacing={1} key={`factfind-field-${i}-${field}`}>
							<Typography
								variant='subtitle2'
								sx={{ fontWeight: 'bold', fontSize: '15px' }}
							>
								{formatKey(field)}
							</Typography>
							<Stack spacing={1}>
								{Object.keys(formik.values[field]).map((objectField, index) => {
									return typeof formik.values[field][objectField] === 'object' ? (
										<DetailsAccordion {...accordionProps(objectField, index)}>
											<Stack spacing={2}>
												{Object.keys(formik.values[field][objectField]).map(
													(subSubField, ind) => {
														if (
															typeof formik.values[field][
																objectField
															][subSubField] === 'object'
														) {
															return (
																<Stack
																	spacing={1}
																	key={`${field}.${objectField}.${subSubField}-${ind}`}
																>
																	<Typography variant='broker_header'>
																		{formatKey(subSubField)}
																	</Typography>

																	{Object.keys(
																		formik.values[field][
																			objectField
																		][subSubField]
																	).map(
																		(
																			subSubSubField,
																			indexx
																		) => {
																			if (
																				typeof formik
																					.values[field][
																						objectField
																					][subSubField][
																						subSubSubField
																					] === 'object'
																			) {
																				const arrayIndex =
																					parseInt(
																						subSubSubField.replace(
																							/\D/g,
																							''
																						)
																					);
																				const subFieldTitle =
																					isNaN(
																						arrayIndex
																					) ||
																					subSubSubField.length !==
																						arrayIndex.toString()
																							.length
																						? subSubSubField
																						: `${subSubField.substring(
																							0,
																							subSubField.length -
																									1
																						  )} ${
																							arrayIndex +
																								1
																						  }`;
																				return (
																					<Stack
																						spacing={1}
																						key={`${field}.${objectField}.${subSubField}.${subSubSubField}-${indexx}`}
																					>
																						<Typography variant='broker_header'>
																							{formatKey(
																								subFieldTitle
																							)}
																						</Typography>
																						{Object.keys(
																							formik
																								.values[
																									field
																								][
																									objectField
																								][
																									subSubField
																								][
																									subSubSubField
																								]
																						)
																							.filter(
																								(
																									v
																								) =>
																									typeof formik
																										.values[
																											field
																										][
																											objectField
																										][
																											subSubField
																										][
																											subSubSubField
																										][
																											v
																										] ===
																									'object'
																							)
																							.map(
																								(
																									subSubSubSubField,
																									indexxx
																								) => {
																									return renderFormControl(
																										`${field}.${objectField}.${subSubField}.${subSubSubField}.${subSubSubSubField}`,
																										indexxx
																									);
																								}
																							)}
																					</Stack>
																				);
																			} else {
																				return renderFormControl(
																					`${field}.${objectField}.${subSubField}.${subSubSubField}`,
																					indexx
																				);
																			}
																		}
																	)}
																</Stack>
															);
														} else {
															return renderFormControl(
																`${field}.${objectField}.${subSubField}`,
																ind
															);
														}
													}
												)}
											</Stack>
										</DetailsAccordion>
									) : (
										renderFormControl(`${field}.${objectField}`, index)
									);
								})}
							</Stack>
						</Stack>
					) : (
						renderFormControl(field, i)
					);
				})} */}
			</Stack>
		</Box>
	);
};
