import React, { useEffect, useState } from 'react';
import { TextField, Skeleton, Box, FormHelperText, Stack, FormLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '@/utils/firebase';
import { useDispatch } from 'react-redux';
import { ticketsApi } from '@/features/claims/ticketsApi';
import { dealsApi } from '@/features/deals/dealsApi';

export const DealOverview = () => {
	const dispatch = useDispatch();

	const { deal, dealRow, objectType, loading } = determineContext();
	const loadingDeal = loading?.deal;
	const [overview, setOverview] = useState(dealRow?.dealOverview ?? '');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setOverview(dealRow?.dealOverview ?? '');
	}, [dealRow, loadingDeal]);

	const updateOverview = async () => {
		setIsLoading(true);
		try {
			const docRef = doc(firestore, 'deals', dealRow.id);
			await updateDoc(docRef, { dealOverview: overview }).then((res) =>
				console.log(`Successfully updated doc ${dealRow.id}`)
			);
			setTimeout(async () => {
				try {
					const uppercaseType = (objectType ?? '').toUpperCase();
					dispatch(ticketsApi.util.invalidateTags([
						{ type: `${uppercaseType}S`, id: 'LIST' },
						{ type: uppercaseType, id: deal.hs_object_id ?? deal.id },
					]));
					dispatch(dealsApi.util.invalidateTags([
						{ type: 'DEAL_ROW', id: deal.hs_object_id ?? deal.id },
					]));
				} catch (error) {
					console.error('Error invalidating API:', error);
				}
			}, 1000);
		} catch (error) {
			console.log('Error updating Firebase document', deal, error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{loadingDeal ? (
				<Skeleton animation="wave" width="100%"  height={150} />
			) : (
				<Grid size={12}><FormLabel>
					{'Deal overview'}
				</FormLabel><TextField
					fullWidth
					multiline
					rows={3}
					disabled={isLoading}
					placeholder={'Add a short description to help capture the purpose or context of the deal. This helps keep track of what the deal involves, especially when managing multiple deals for the same client.'}
					value={overview}
					onChange={(event) => setOverview(event.target.value)}
					onBlur={updateOverview} />
				</Grid>
	
                    
			)}
            
		</>
	);
};
