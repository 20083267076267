/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useFormik } from 'formik';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { currentYear, nextYear } from '@/utils/date';
import { validationSchema } from './validationSchema';
import { default as folderValidationSchema } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import {
	useAddPoliciesToDealMutation,
	useCreateEndorsementMutation,
} from '@/features/deals/dealsApi';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import { PolicySelect } from '@/components/selects/PolicySelect';
import { PolicyTransactionSelect } from '@/components/selects/PolicyTransactionSelect';
import FileUpload from '@/components/FileUpload';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { PageHeader } from '@/components/layouts/PageHeader';
import { showToast } from '@/features/toast/toastSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { reset } from '@/features/select/clientSelectSlice';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { DEAL_NAME, DEAL_OVERVIEW, YEAR_FOLDER } from '../CreateNewBusinessForm/validationSchema';
import { paths } from '@/app/routes';
import { getPipelineIcon } from '@/utils/constants';
import { YearFolderSelect } from '@/components/selects/YearFolderSelect';
import { ASSIGNED_TO_HELP_TEXT, DEAL_NAME_HELP_TEXT, NOTES_HELP_TEXT, PRIORITY_HELP_TEXT, SEARCH_CLIENT_HELP_TEXT, UPLOAD_FILES_HELP_TEXT, YEAR_FOLDER_HELP_TEXT } from '@/utils/helperText';
import DealNaming from '../fields/DealNaming';
import { FOLDER_NAME } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';

export const CreateEndorsementForm = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const account = useSelector((state) => state.msalAccount.account);

	const existingClient = useMemo(() => location?.state?.client ?? {}, [location?.state]);
	const existingPolicy = useMemo(() => location?.state?.policy ?? {}, [location?.state]);
	// const existingPolicy = location?.state?.policy;
	// const existingTransaction = location?.state?.transaction;

	const isExistingClient = useMemo(
		() => Object.keys(existingClient).length > 0,
		[existingClient]
	);

	const [formLoading, setFormLoading] = useState(false);

	const email = account?.username;
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
		isError,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const initialValues = useMemo(() => {
		const values = {
			policy: existingPolicy,
			policyId: existingPolicy?.Id ?? -1,
			// transaction: existingTransaction,
			// policyTransactionId: existingTransaction?.Id,
			files: [],
			notes: '',
			[DEAL_OVERVIEW]: '',
			clientName: existingClient?.name ?? '',
			clientOwner: existingClient?.hubspot_owner_id ?? existingClient?.ownerId ?? hubspotId,
			successMessage: '',
			errorMessage: '',
			dealName: '',
			priority: '',
			[FOLDER_NAME]: '',
			[YEAR_FOLDER]: `${currentYear}-${nextYear}`,
			clientId: existingClient?.id ?? existingClient?.hs_object_id ?? '',
		};
		console.log('VALUES', values);
		return values;
	}, [existingPolicy, existingClient, hubspotId]);

	const [schema, setSchema] = useState(validationSchema);

	const formik = useFormik({
		initialValues,
		validateOnChange: false,
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', values);
			const createError = (message) => {
				setFieldValue('errorMessage', message ?? 'Error creating endorsement');
				setFieldValue('successMessage', '');
				setSubmitting(false);
				setFormLoading(false);
			};
			const res = await createNewEndorsement();
			if (res?.error) {
				console.log('🙅 ~ Error creating endorsement', res?.error);
				createError(res?.error?.message);
				return;
			}
			const response = await createEndorsementFolders(formik.values.clientId, res.dealId, res.noteId);
			if (response?.error) {
				createError(response?.error?.message);
				dispatch(
					showToast({
						message: response?.error?.message ?? 'Endorsement created',
						action: {
							path: `${paths.endorsements}/${res.dealId}`,
							label: 'GO TO ENDORSEMENT',
						},
						autohide: false,
					})
				);
				return;
			}
			console.log('🚀 ~ Successfully created new Endorsement', res);
			dispatch(
				showToast({
					message: 'New endorsement created!',
					action: {
						path: `${paths.endorsements}/${res.dealId}`,
						label: 'GO TO ENDORSEMENT',
					},
					autohide: false,
				})
			);
			resetForm();
			dispatch(reset());
			setFieldValue('successMessage', 'Successfully created Endorsement');
			setFieldValue('errorMessage', '');
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const { data: ownerData } = useGetOwnerQuery(
		{ ownerId: formik.values.clientOwner },
		{ skip: !formik.values.clientOwner }
	);

	const {
		data: ownerDetails,
	} = useGetUserDetailsQuery({ hubspotId: ownerData?.id }, { skip: !ownerData });

	const { setUpDealFilesAndFolders } = useClientStorageHooks(ownerDetails ?? userDetails);

	const [createEndorsement] = useCreateEndorsementMutation();
	// const [addPoliciesToDeal] = useAddPoliciesToDealMutation();

	const createEndorsementFolders = async (clientId, dealId, noteId) => {
		try {
			await setUpDealFilesAndFolders({
				dealId,
				noteId,
				clientId,
				clientName: formik.values.clientName,
				dealName: formik.values[FOLDER_NAME],
				pipelineId: process.env.REACT_APP_PIPELINE_ENDORSEMENTS,
				files: formik.values.files,
				notes: formik.values.notes,
				yearFolder: formik.values.yearFolder,
			});
		} catch (error) {
			console.log('🚀 ~ Error creating endorsement folders', error);
			return { error: { message: `Endorsement ${dealId} created, but there was an error creating endorsement folder or uploading files` } };
		}
	};

	const createNewEndorsement = async () => {
		const body = {
			hubspotId: formik.values.clientOwner,
			dealName: formik.values[FOLDER_NAME].trim(),
			dealOverview: (formik.values[DEAL_OVERVIEW] ?? '').trim(),
			notes: formik.values.notes,
			clientId: formik.values.clientId,
			policyId: formik.values.policyId,
			// policyTransactionId: formik.values.policyTransactionId,
			priority: formik.values.priority.length > 0 ? formik.values.priority : null,
			completedBy: hubspotId,
		};

		return await createEndorsement(body)
			.unwrap()
			.catch((err) => {
				console.log('🚀 ~ Error creating endorsement', err);
				return { error: { message: 'Error creating endorsement' } };
			});
	};

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const loading = formik.isSubmitting || formLoading;

	useEffect(() => {
		if (formik.values.existingFolders) {
			setSchema(
				validationSchema
					.omit([FOLDER_NAME])
					.concat(folderValidationSchema(formik.values.existingFolders, 'Deal name'))
			);
		}
	}, [formik.values.existingFolders]);

	return (
		<Container sx={{ pt: 3 }}>
			
			<Button
				variant='text'
				onClick={() => navigate(paths.endorsements)}
				sx={{ color: 'black', fontWeight: 'bold', mb: '1em' }}
				startIcon={<KeyboardBackspaceOutlinedIcon />}
			>
				{'Back to Endorsements'}
			</Button>
			<form onSubmit={formik.handleSubmit}>
				<CardHeader
					disableTypography
					title={<PageHeader title={'Add Endorsement'} icon={getPipelineIcon(process.env.REACT_APP_PIPELINE_ENDORSEMENTS)}/>}
				/>
				<CardContent>
					{/* <Typography variant="body1" paragraph>
              View our user guide on the steps to add an endorsement{' '}
							<Link>here</Link>
						</Typography> */}
					{isLoading || isUninitialized ? (
						<CircularProgress />
					) : (
						<Grid container spacing={4}>
							<DealNaming {...{formik, disabled: loading}} />
							<Grid size={'grow'}>
								<FormLabel required>{'Search client name'}</FormLabel>
								<InstantClientSelect
									clientId={formik.values.clientId}
									clientName={formik.values.clientName}
									filterProspects={true}
									setClient={(newClient) => {
										console.log('Here is the selected client: ', newClient);
										if (newClient) {
											formik.setValues({
												...formik.values,
												clientId: newClient.id,
												clientName: newClient.name,
												clientOwner: newClient.ownerId,
											});
										} else {
											formik.setValues({
												...formik.values,
												clientId: null,
												clientName: '',
												clientOwner: hubspotId,
												policy: {},
												policyId: null,
											});
										}
									}}
									disabled={loading || isExistingClient}
								/>
								<FormHelperText>
									{SEARCH_CLIENT_HELP_TEXT}
								</FormHelperText>
							</Grid>
							<Grid size={4}>
								<FormLabel>{'Assign to'}</FormLabel>
								<OwnerSelect
									initialId={formik.values.clientOwner}
									disabled={formik.isSubmitting}
									onChange={(value) => {
										formik.setFieldValue('clientOwner', value);
									}}
								/>
								<FormHelperText>
									{ASSIGNED_TO_HELP_TEXT}
								</FormHelperText>
							</Grid>
							<Grid size={12}>
								<FormLabel required sx={{ display: 'flex' }}>
									{'Policy'}
								</FormLabel>
								<PolicySelect
									clientId={formik.values.clientId}
									policy={formik.values.policy}
									setPolicy={(newPolicy) => {
										console.log('NEW POLICY', newPolicy);
										formik.setValues({
											...formik.values,
											policy: newPolicy,
											policyId: newPolicy?.Id ?? -1,
										});
									}}
									disabled={Object.keys(existingPolicy).length > 0 || loading}
								/>
								<FormHelperText error={getError('policyId')}>
									{getErrorMessage('policyId')}
								</FormHelperText>
							</Grid>
							{/* <Grid size={12}>
									<FormLabel required sx={{ display: 'flex' }}>
										{'Policy Transaction'}
									</FormLabel>
									<PolicyTransactionSelect
										policyId={formik.values.policyId}
										policyTransaction={formik.values.transaction}
										setPolicyTrans={(newPolicy) => {
											formik.setFieldValue(
												'policyTransactionId',
												newPolicy?.Id
											);
											formik.setFieldValue('transaction', newPolicy);
										}}
										disabled={
											Object.keys(existingTransaction ?? {}).length > 0 ||
											loading
										}
									/>
									<FormHelperText error={getError('policyTransactionId')}>
										{getErrorMessage('policyTransactionId')}
									</FormHelperText>
								</Grid> */}
							<Grid size={{ xs: 12, md: 4 }}>
								<PrioritySelect
									disabled={formik.isSubmitting}
									priority={formik.values.priority}
									setPriority={(value) =>
										formik.setFieldValue('priority', value)
									}
								/>
								<FormHelperText>
									{PRIORITY_HELP_TEXT}
								</FormHelperText>
							</Grid>
							<Grid size={12}>
								<FormLabel>{'Notes'}</FormLabel>
								<TextField
									fullWidth
									id='notes'
									multiline
									rows={4}
									{...formik.getFieldProps('notes')}
									disabled={loading}
									error={getError('notes')}
									helperText={getErrorMessage('notes')}
								/>
								<FormHelperText>
									{NOTES_HELP_TEXT}
								</FormHelperText>
							</Grid>
							<Grid size={12}>
								<FileUpload
									files={formik.values.files}
									setFiles={(files) =>
										formik.handleChange({
											target: {
												name: 'files',
												value: files,
											},
										})
									}
									loading={loading}
								/>
								<FormHelperText>
									{UPLOAD_FILES_HELP_TEXT}
								</FormHelperText>
							</Grid>
							{formik.values.errorMessage.length > 0 && (
								<FormHelperText sx={{ padding: '1em' }} error>
									{formik.values.errorMessage}
								</FormHelperText>
							)}
							{formik.values.successMessage.length > 0 && (
								<FormHelperText sx={{ color: 'green', padding: '1em' }}>
									{formik.values.successMessage}
								</FormHelperText>
							)}
						</Grid>
					)}
				</CardContent>
				{/* <DevJSONView value={formik} /> */}
				<Divider />
				<CardActions
					sx={{
						justifyContent: 'center',
						p: 2,
					}}
				>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={isLoading || isUninitialized || formLoading}
					>
						{formik.isSubmitting ? (
							<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
						) : (
							'Submit'
						)}
					</Button>
				</CardActions>
			</form>
			
		</Container>
	);
};
