import React, { useEffect, useMemo } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import {
	DataGrid,
	GridCellEditStopReasons,
	GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import DataGridMultilinePopper from '@/components/table/DataGridMultilinePopper';
import { isKeyboardEvent } from '@mui/x-data-grid/utils/keyboardUtils';

const NoRowsOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'58px'} pt={'1em'}>
			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
				Enter insurer in table above to add endorsements
			</Typography>
		</Box>
	);
};

export const EndorsementsTable = ({
	rows,
	updateRows,
	setRowSelectionModel,
	rowSelectionModel,
	disabled,
}) => {
	useEffect(() => {
		console.log('ROWS for endorsement table', rows);
	}, [rows]);

	const displayRows = useMemo(() => rows.filter((r) => r.insurer != ''), [rows]);

	const columns = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderHeader: () => <></>,
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<></>
				) : (
					GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
				),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			flex: 1,
			minWidth: 250,
			editable: false,
			sortable: false,
			display: 'flex',
		},
		{
			field: 'endorsements',
			headerName: 'Endorsements',
			flex: 2,
			minWidth: 300,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.insurer !== '' && (params.row.endorsements ?? '').length === 0 ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter endorsements</Typography>
				) : (
					<Stack spacing={1} py={1}>
						{params.row.endorsements.split('\n').map((endorsement, i) => (
							<Typography key={`${params.row.insurer}-endorsement-${i}`}>
								{endorsement}
							</Typography>
						))}
					</Stack>
				),
			renderEditCell: (params) => <DataGridMultilinePopper {...params} rows={8} />,
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
				'--DataGrid-overlayHeight': '3em',
			}}
			autoHeight
			width='100%'
			getRowHeight={() => 'auto'}
			processRowUpdate={updateRows}
			rows={displayRows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled || params.field === 'insurer') {
					return false;
				}
				return (params.row.insurer ?? '').length > 0;
			}}
			onCellEditStop={(params, event) => {
				if (params.field === 'endorsements') {
					if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
						return;
					}
					if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
						event.defaultMuiPrevented = true;
					}
				}
			}}
			isRowSelectable={() => false}
			getRowId={(row) => `${row.insurer}`}
			checkboxSelection
			onRowSelectionModelChange={setRowSelectionModel}
			rowSelectionModel={rowSelectionModel}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
			slots={{
				noRowsOverlay: NoRowsOverlay,
			}}
		/>
	);
};
