import { Button, FormLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useState } from 'react';
import { theme } from '@/app/theme';
import { useDispatch, useSelector } from 'react-redux';
import { ProductGuidanceDialog } from '@/components/dialogs/ProductGuidanceDialog';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const NeedsAnalysisSelect = ({
	analysisValue,
	label,
	disabled,
	setAnalysisValue,
	minimal,
	needsValues,
	...props
}) => {
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;
	const [openDialog, setOpenDialog] = useState(false);
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery({ email }, { skip: !email });
	const { data: showGuidance } = useGetFeatureFlagQuery({
		feature: 'marbleSOA',
	});

	const domestic = ['House', 'Contents', 'Boat', 'Private Vehicle'];

	const handleOpenDialog = (event) => {
		event.preventDefault();
		setOpenDialog(true);
	};

	return (
		<>
			<Stack
				direction='row'
				spacing={1}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<FormLabel sx={minimal ? minimalLabelTheme : undefined}>{label}</FormLabel>
				{!domestic.includes(label) &&
					(showGuidance || userDetails?.broker?.ledger === 'marble') && (
					<Button
						variant='text'
						onClick={handleOpenDialog}
						sx={{ textTransform: 'none' }}
					>
							View guidance
					</Button>
				)}
			</Stack>
			<Select
				name='needsAnalysis'
				value={analysisValue}
				disabled={disabled}
				onChange={(e) => setAnalysisValue(e.target.value, e.target.name)}
				fullWidth
				{...props}
			>
				{Object.entries(needsValues).map(([value, displayLabel]) => (
					<MenuItem value={value} key={`needs-analysis-selection-${value}`}>
						{displayLabel}
					</MenuItem>
				))}
			</Select>
			<ProductGuidanceDialog
				open={openDialog}
				handleClose={() => setOpenDialog(false)}
				data={label}
			/>
		</>
	);
};
