import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';
import { DealTabView } from './DealTabView';
import { DealAboutCard } from './DealAboutCard';
import { CompleteDealView } from './CompleteDealView';
import { determineContext } from '@/hooks/determineContext';
import { AddProposalDocumentDialog } from '@/components/dialogs/AddProposalDocumentDialog';
import { useDispatch, useSelector } from 'react-redux';
import { AddTaskDialog } from '@/components/dialogs/AddTaskDialog';
import { EditPolicyTransactionDialog } from '@/components/dialogs/EditLinkedPolicyTransactionDialog';

const DealViewWrapper = () => {
	const navigate = useNavigate();

	const { pipeline } = determineContext();

	const dialogOpen = useSelector((state) => state.dialog.open['completeDeal']);

	// Navigate to the previous page (e.g. new-business)
	const location = window.location.pathname.split('/').slice(0, -1).join('/');

	return (
		<div className='deal-view-wrapper'>
			<Grid container>
				<Grid size={{ xs: 8, sm: 9 }} sx={{ p: 1 }}>
					<Stack>
						<Box display='flex' px='1em' sx={{ alignItems: 'center' }}>
							<Button
								variant='text'
								onClick={() => navigate(location)}
								sx={{ color: 'black', fontWeight: 'bold' }}
								startIcon={<KeyboardBackspaceOutlinedIcon />}
							>
								{pipeline}
							</Button>
						</Box>
						<DealTabView />
					</Stack>
				</Grid>
				<Grid
					size={{ xs: 4, sm: 3 }}
					sx={{
						position: 'sticky',
						top: 0,
						alignSelf: 'flex-start',
					}}
				>
					<DealAboutCard />
				</Grid>
			</Grid>
			<CompleteDealView />
			<EditPolicyTransactionDialog />
			<AddProposalDocumentDialog documentType={'WYTU'} />
			<AddProposalDocumentDialog documentType={'Quote'} />
		</div>
	);
};
export default DealViewWrapper;
