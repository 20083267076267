/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	TextField,
	InputAdornment,
	IconButton,
	CircularProgress,
	Chip,
	Tooltip,
	Box,
	Stack,
	Skeleton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { folioBlue } from '@/utils/constants';
import { IS_DEV } from '@/utils/environment';
import { InstantSearch, Configure, useHits, useSearchBox, Hits } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetDealsListByIdsQuery } from '@/features/deals/dealsApi';

const AutocompleteInput = ({
	query,
	refine,
	clear,
	hits,
	isSearching,
	setDeal,
	dealIds = [],
	disabled,
	showRenewable,
	autoPopulate = false,
	multiple = false,
	disabledDealIds = [],
	loading = false,
}) => {
	const [open, setOpen] = useState(false);

	const [values, setValues] = useState(() => hits.filter((d) => dealIds.includes(d.id)));

	const pipelineQuery = useGetPipelinePropertiesQuery();

	const stages = useMemo(
		() =>
			pipelineQuery?.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? []).flatMap((r) =>
				r?.stages.map((s) => ({ ...s, pipeline: r?.label, pipelineId: r?.id }))
			) ??
			[],
		[pipelineQuery.data?.pipelines]
	);

	const { active } = useMemo(() => {
		return {
			active: stages.filter((stage) => stage.metadata?.isClosed != 'true'),
		};
	}, [stages]);

	const updatedHits = useMemo(() => {
		return hits.map((h) => ({
			...h,
			active: active.some((s) => s.id == h.dealStage && s.pipelineId == h.pipeline),
		}));
	}, [hits, active]);

	const handleSearch = () => {
		if (query.length > 0) {
			refine();
		}
	};

	const handleReset = () => {
		setDeal(null);
		setOpen(false);
		clear();
	};

	useEffect(() => {
		const dealName = hits.find((hit) => hit.id == dealIds[0])?.dealName ?? '';
		if (!multiple && dealIds.length === 1) {
			refine(dealName);
		}
		setValues(hits.filter((d) => dealIds.includes(d.id)));
	}, [dealIds]);

	return (
		<Autocomplete
			id='deal-select'
			loading={loading || isSearching || pipelineQuery.isLoading}
			multiple={multiple}
			loadingText='Loading deals...'
			options={autoPopulate ? updatedHits : query.length > 0 ? updatedHits : []}
			open={open}
			disabled={disabled}
			noOptionsText={query.length > 0 ? 'No deals / claims found' : 'Type to search'}
			filterOptions={(x) => x}
			renderOption={(props, option) => {
				return (
					<Box
						display={'flex'}
						{...props}
						sx={{
							...props.sx,
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
						key={`deal-select-${option.id}`}
					>
						<Box flexGrow={1} textAlign='left'>
							<Stack direction='row' alignItems={'center'}>
								<Chip
									size='small'
									variant='outlined'
									style={{
										borderColor:
											option.pipeline ===
											process.env.REACT_APP_PIPELINE_CLAIMS
												? '#fbca04'
												: folioBlue,
										color:
											option.pipeline ===
											process.env.REACT_APP_PIPELINE_CLAIMS
												? '#fbca04'
												: folioBlue,
									}}
									label={
										option.pipeline === process.env.REACT_APP_PIPELINE_CLAIMS
											? 'claim'
											: 'deal'
									}
									sx={{
										mr: '1em',
									}}
								/>
								{option.dealName}
								{showRenewable &&
									(disabledDealIds.includes(option.id) ||
										!option.isRenewable ||
										option.active ||
										(option.policies ?? []).length === 0) && (
										<span
											style={{
												paddingLeft: '0.25em',
												fontSize: '12px',
												color: 'rgba(0, 0, 0, 0.5)',
											}}
										>
											{option.isRenewable === false
												? '(non-renewable)'
												: (option.policies ?? []).length === 0
												? '(no policies linked)'
												: '(in progress)'}
										</span>
									)}
							</Stack>
						</Box>
						{dealIds.includes(option.objectID) && (
							<CheckIcon sx={{ fontSize: '18px' }} />
						)}
					</Box>
				);
			}}
			inputValue={query}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			getOptionLabel={(option) => option.dealName}
			onInputChange={(_, value, reason) => {
				if (reason !== 'reset') {
					refine(value);
				}
			}}
			renderInput={(params) => (
				<TextField
					placeholder={
						multiple || dealIds.length === 0
							? 'Search for deal'
							: hits.find((hit) => hit.id === dealIds[0])?.dealName
					}
					{...params}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSearch();
						}
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position='start'>
								<IconButton
									disabled={isSearching || disabled}
									sx={{ padding: '0' }}
									onClick={handleSearch}
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								{isSearching ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
								{query.length > 0 && (
									<IconButton
										size='small'
										disabled={isSearching || disabled}
										sx={{ padding: '0' }}
										onClick={handleReset}
									>
										<ClearIcon fontSize='20px' />
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
				/>
			)}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			onChange={(_, value) => {
				console.log('value', value);
				setDeal(value);
			}}
			getOptionDisabled={(option) =>
				showRenewable
					? disabledDealIds.includes(option.id) ||
					  !option.isRenewable ||
					  option.active ||
					  (option.policies ?? []).length === 0
					: false
			}
			{...(multiple && { value: values })}
		/>
	);
};

export const InstantDealSelect = ({
	setDeal,
	dealId,
	disabled,
	clientId,
	autoPopulate,
	showRenewable,
	displayClaims = true,
	multiple = false,
	disabledDealIds = [],
	hideInput = false,
}) => {
	// const [isTyping, setIsTyping] = useState(false);
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const dealIds = Array.isArray(dealId) ? dealId : dealId != null ? [dealId] : [];

	const userQuery = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const brokerGroupId = userQuery.data?.brokerId;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];

	const filterSchema = useMemo(() => {
		const clientIdFilter = `clientHubspotId := ${clientId}`;
		const ownerFilter = `brokerGroupId: [${brokerGroupIds.join(',')}]`;

		const dealIdFilter =
			(hideInput || !multiple) && dealIds.length > 0 ? `dealId: [${dealIds.join(',')}]` : ''; //dealId ? `dealId := ${dealId}` : '';

		const schema = {
			filters: clientId ? `${clientIdFilter} && ${ownerFilter}` : ownerFilter,
		};

		if (!displayClaims) {
			schema.filters += ` && pipeline :!= "${process.env.REACT_APP_PIPELINE_CLAIMS}"`;
		}

		// if (dealId && !isTyping) {
		if (dealIdFilter.length > 0) {
			// console.log('DEAL ID FILTER', dealIdFilter);
			schema.filters += ` && ${dealIdFilter}`;
			// schema.filters = clientId ? `${clientIdFilter} && ${dealIdFilter}` : dealIdFilter;
		}

		// console.log('FILTER SCHEMA', schema);
		return schema;
	}, [clientId, brokerGroupIds, dealIds, hideInput]);

	return (
		<InstantSearch searchClient={dealClient} indexName='deals'>
			<Configure hitsPerPage={25} {...filterSchema} />
			<InstantDealSelectWrapper
				autoPopulate={autoPopulate}
				setDeal={setDeal}
				dealIds={dealIds}
				disabled={disabled || userQuery.isLoading}
				disabledDealIds={disabledDealIds}
				clientId={clientId}
				// setIsTyping={setIsTyping}
				showRenewable={showRenewable}
				multiple={multiple}
				hideInput={hideInput}
			/>
		</InstantSearch>
	);
};

const InstantDealSelectWrapper = ({
	setDeal,
	dealIds,
	disabled,
	clientId,
	setIsTyping,
	autoPopulate,
	showRenewable,
	multiple = false,
	disabledDealIds = [],
	hideInput = false,
	...props
}) => {
	const { items } = useHits(props);

	const hits = items.map((hit) => ({
		...hit,
		id: hit.hubspotId ?? hit.dealId ?? hit.id,
		docId: hit.id,
		objectType:
			hit.pipeline === process.env.REACT_APP_PIPELINE_CLAIMS ||
			(IS_DEV && hit.pipeline === process.env.REACT_APP_PIPELINE_ENDORSEMENTS)
				? 'ticket'
				: 'deal',
	}));

	const dealsPropertiesQuery = useGetDealsListByIdsQuery(
		{
			clientId,
			dealIds: hits.map((h) => h.id),
			includePolicies: true,
		},
		{
			skip: hideInput || !clientId || hits.length === 0 || showRenewable !== true,
		}
	);

	const loading =
		hideInput || !clientId || hits.length === 0 || showRenewable !== true
			? false
			: dealsPropertiesQuery.isUninitialized ||
			  dealsPropertiesQuery.isLoading ||
			  dealsPropertiesQuery.isFetching;

	const dealsData = useMemo(() => {
		return (dealsPropertiesQuery.data ?? []).reduce((acc, deal) => {
			acc[deal.id] = deal?.policies ?? [];
			return acc;
		}, {});
	}, [dealsPropertiesQuery.data]);

	const updatedHits = useMemo(() => {
		const hhhits = hits.map((d) => ({
			...d,
			policies: dealsData?.[d.id] ?? [],
			isRenewable: d.isRenewable == null || d.isRenewable == true,
		}));
		console.log('DEAL SELECT HITS', hhhits);
		return hhhits;
	}, [hits, dealsData]);

	const selectedDeals = useMemo(
		() => updatedHits.filter((hit) => dealIds.includes(hit.id)),
		[updatedHits, dealIds]
	);

	useEffect(() => console.log('SELECTED DEALS', selectedDeals), [selectedDeals]);

	const searchBoxApi = useSearchBox(props);

	// useEffect(() => {
	// 	if (searchBoxApi.query.length > 0) {
	// 		setIsTyping(true);
	// 	} else {
	// 		setIsTyping(false);
	// 	}
	// }, [searchBoxApi.query, setIsTyping]);

	// Cannot remove deal if the id is in the disabledDealIds array
	const handleRemoveDeal = (dealId) => {
		const newIds = Array.from(new Set([...dealIds.filter((id) => id != dealId)]));
		setDeal(updatedHits.filter((hit) => newIds.includes(hit.id)));
	};

	const handleSetDeal = (newValue) => {
		console.log('NEW VALUE', newValue);
		if (multiple) {
			const newVals = Array.isArray(newValue) ? newValue.map((d) => d.id) : [newValue.id];
			const newIds = Array.from(new Set([...dealIds, ...newVals]));
			const toRemove = dealIds.filter(
				(id) => !newVals.includes(id) && !disabledDealIds.includes(id)
			);
			if (toRemove.length > 0) {
				toRemove.forEach((id) => handleRemoveDeal(id));
			} else {
				setDeal(updatedHits.filter((hit) => newIds.includes(hit.id)));
			}
		} else {
			setDeal(newValue);
		}
	};

	return (
		<Stack width={'100%'}>
			{multiple && selectedDeals.length > 0 && (
				<Grid container spacing={1} py={'1em'}>
					{selectedDeals.map((d) => (
						<Chip
							key={`selected-deal-${d.dealId}`}
							size={'small'}
							// color={colour}
							// sx={{
							//     backgroundColor: colour,
							//     color: 'rgb(255, 255, 255)'
							// }}
							label={d.dealName}
							{...(!disabledDealIds.includes(d.dealId) && {
								onDelete: () => handleRemoveDeal(d.dealId),
							})}
						/>
					))}
				</Grid>
			)}
			{!hideInput && (
				<>
					{loading ? (
						<Skeleton width={'100%'} height={'48px'} />
					) : (
						<AutocompleteInput
							hits={updatedHits}
							setDeal={handleSetDeal}
							dealIds={dealIds}
							disabled={disabled}
							autoPopulate={autoPopulate}
							showRenewable={showRenewable}
							multiple={multiple}
							disabledDealIds={disabledDealIds}
							// loading={loading}
							{...searchBoxApi}
						/>
					)}
				</>
			)}
		</Stack>
	);
};
