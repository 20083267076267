import { createSlice } from '@reduxjs/toolkit';

export const dialogSlice = createSlice({
	name: 'dialog',
	initialState: {
		open: {
			note: false,
			proposal: false,
			contact: false,
			task: false,
			summary: false,
			quote: false,
			wytu: false,
			completeDeal: false,
			editPolicyTransaction: false,
			createFolder: false,
			moveFile: false,
			fileExists: false,
			renameFile: false,
			selectDrive: false,
		},
		data: {},
	},
	reducers: {
		openDialog: (state, action) => {
			const dialogName = typeof action.payload === 'string' ? action.payload : action.payload.dialogName;
			const data = action.payload.data || {};
			state.open[dialogName] = true;
			state.data[dialogName] = data;
		},
		closeDialog: (state, action) => {
			state.open[action.payload] = false;
			state.data[action.payload] = {}; 
		},
	},
});

export const { openDialog, closeDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
