/* eslint-disable no-mixed-spaces-and-tabs */
import Cookies from 'js-cookie';
import { useMSALHelper } from './useMSALHelper';
import { msGraphAxios,  scopes } from '@/utils/msGraphUtils';

// Custom hook to interact with Microsoft graph
export const useMsGraph = (userId) => {

	const endpointPrefix = userId ? `users/${userId}` : '/me';

	const { getAccessToken } = useMSALHelper();

	const getGraphAccessToken = async () => {
		const token = await getAccessToken(scopes);
		Cookies.set('token', token, { secure: true });
		return token;
	};

	const createOnlineMeetingEvent = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.post(`${endpointPrefix}/events`, eventDetails, {
				headers,
			});
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};

	const getGraphEventData = async (taskType, graphEventId) => {
		let endpoint;
		if (taskType === 'MEETING') {
			endpoint = `${endpointPrefix}/calendar/events/${graphEventId}`;
		} else {
			endpoint = `${endpointPrefix}/events/${graphEventId}`;
		}

		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const response = await msGraphAxios.get(endpoint, { headers });
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error fetching graph event data:', error);
			throw error;
		}
	};

	const updateGraphEventData = async (taskType, graphEventId, eventData) => {
		let endpoint;
		if (taskType === 'MEETING') {
			endpoint = `${endpointPrefix}/calendar/events/${graphEventId}`;
		} else {
			endpoint = `${endpointPrefix}/events/${graphEventId}`;
		}

		console.log(
			`Params for updateGraphEventData for graph ID ${graphEventId} event type ${taskType}`,
			eventData
		);
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.patch(endpoint, eventData, { headers });
			console.log(
				`RESPONSE for updateGraphEventData for graph ID ${graphEventId} event type ${taskType}`,
				response.data
			);
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error updating graph event data:', error);
			throw error;
		}
	};

	const createEventTask = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.post(
				`${endpointPrefix}/calendar/events`,
				eventDetails,
				{
					headers,
				}
			);
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};

	const getUserCalendars = async (email) => {
		const userIdFromEmail = await getUserIdByEmail(email);
		const accessToken = await getGraphAccessToken();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		};
		try {
			const response = await msGraphAxios.get(`/users/${userIdFromEmail}/calendars`, {
				headers,
			});
			console.log('✅ ~ Calendars retrieved successfully:', response.data.value);
			return response.data.value;
		} catch (error) {
			console.error('❌ ~ Error retrieving calendars:', error);
			throw error;
		}
	};

	const getUserIdByEmail = async (email) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/users/${email}`, { headers });
			console.log('✅ ~ User retrieved successfully', response.data);
			return response.data.id;
		} catch (error) {
			console.error('❌ ~ Error retrieving user', error);
			throw error;
		}
	};

	// Returning the functions to be used elsewhere in the app
	return {
		getGraphAccessToken,
		createOnlineMeetingEvent,
		createEventTask,
		getUserIdByEmail,
		getUserCalendars,
		getGraphEventData,
		updateGraphEventData,
	};
};
