/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { stableSort, getComparator } from '@/components/table/functions';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { determineContext } from '@/hooks/determineContext';
import { PolicyRow } from '@/components/tables/PolicyRow';

export const PoliciesTab = () => {
	
    
	const { policies, deal, transactions } = determineContext();

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('CreatedWhen');
	const policyHeadCells = [
		{ id: 'policyNumber', numeric: false, label: 'Policy number', align: 'left', sortable: false },
		{
			id: 'invoiceNumber',
			numeric: false,
			label: 'Invoice Number',
			align: 'left',
			sortable: false,
		},
		{ id: 'renewalDate', numeric: false, label: 'Renewal date', align: 'left', sortable: false },
		{ id: 'description', numeric: false, label: 'Description', align: 'left', sortable: false },
		{ id: 'insurer', numeric: false, label: 'Insurer', align: 'left', sortable: false },
	];

	return (
		<TableContainer>
			<Table
				aria-labelledby="tableTitle"
				size="medium"
				aria-label="enhanced table"
				sx={{ minWidth: 600 }}
			>
				<FolioTableHead
					order={order}
					orderBy={orderBy}
					headCells={policyHeadCells}
				/>
				<TableBody>
					{(() => {
						//map over transactions if they are avaiable otherwise policies
						const policiesArray =  
						transactions.length > 0 && Object.keys(transactions[0]).length > 0 
							? transactions 
							: policies;
						const sortedItems = stableSort(policiesArray, getComparator(order, orderBy));

						return sortedItems.length > 0 ? (
							sortedItems.map((item, index) => (
								<PolicyRow
									hideCheckbox={true}
									key={`${policiesArray === transactions ? 'transaction' : 'policy'}-row-index-${index}-${item.Id}`}
									policy={item}
								/>
							))
						) : (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align="center">
									<Typography variant="subtitle1">No policies linked</Typography>
								</TableCell>
							</TableRow>
						);
					})()}
				</TableBody>
			</Table>
		</TableContainer>
	);
};