
import { specialChars } from '@/utils/constants';
import * as Yup from 'yup';

export const FOLDER_NAME = 'folderName';
export const ERROR_MESSAGE = 'errorMessage';
export const FOLDER_NAME_MAX = 40;

const validationSchema = (existingFolders = [], title='Folder name') =>
	Yup.object().shape({
		[FOLDER_NAME]: Yup.string()
			.required(`${title} is required`)
			.max(FOLDER_NAME_MAX, `${title} can be maximum of ${FOLDER_NAME_MAX} characters`)
			.test('no-special-characters', `${title} contains invalid characters.`, (value) => {
				if (!value) return false;
				return !specialChars.test(value.trim());
			})
			.test('no-end-in-fullstop', `${title} cannot end with full stop.`, (value) => {
				if (!value) return false;
				return !value.endsWith('.');
			})
			.test('unique-folder', 'A folder with this name already exists.', (value) => {
				if (!value) return false;
				const folderExists = existingFolders.some(
					(folder) => (folder ?? '').toLowerCase() === value.toLowerCase().trim()
				);
				return !folderExists;
			}),
	});

export default validationSchema;
