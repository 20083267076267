import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, InputAdornment, TextField, Box, Stack, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetClientPoliciesQuery, useGetClientRowQuery } from '@/features/clients/clientsApi';
import { formatDate } from '@/utils/constants';

export const PolicySelect = ({ clientId, policy, setPolicy, showRenewalDate, disabled }) => {
	const [open, setOpen] = useState(false);

	const { data: clientRow } = useGetClientRowQuery(clientId, { skip: !clientId });
	const insightId = useMemo(() => clientRow?.insightId, [clientRow?.insightId]);
	const hubspotId = useMemo(() => clientRow?.hubspotId, [clientRow?.hubspotId]);

	const {
		data: policyData,
		isLoading: isLoadingPolicies,
		isFetchingPolicies,
	} = useGetClientPoliciesQuery(
		{ insightId, hubspotId },
		{ skip: (!insightId && !hubspotId) || Object.keys(policy ?? {}).length > 0 }
	);

	const policyOptions = useMemo(() => policyData ?? [], [policyData]);

	return (
		<Autocomplete
			id='policy-select'
			loading={isLoadingPolicies || isFetchingPolicies}
			loadingText='Loading policies...'
			options={policyOptions}
			getOptionLabel={(policy) =>
				`${policy.Description}, ${policy.TypeDescription}, ${
					policy.PolicyNumber
				} - ${formatDate(new Date(policy.ToDate))}`
			}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			renderOption={(props, policy) => (
				<Box component='li' {...props}>
					<Stack width='100%'>
						<Stack direction='row' justifyContent={'space-between'} width='100%'>
							<Typography sx={{ fontWeight: 500 }}>{policy.Description}</Typography>
							<Typography sx={{ color: 'rgba(52, 73, 94, 0.5)' }}>
								{policy.PolicyNumber}
							</Typography>
						</Stack>
						{/* {(policy?.policyTransactions ?? []).length > 0 && (
							<Typography sx={{ fontSize: '12px' }}>{`Invoice: ${policy?.policyTransactions?.[0]?.InvoiceNumber}`}</Typography>
						)} */}
						<Stack direction='row' justifyContent={'space-between'} width='100%'>
							<Typography>{`${policy.TypeDescription}, ${policy.ClassOfRisk}`}</Typography>
							{policy.ToDate && (
								<Typography variant='task_updated'>
									{'Renewal date: '}
									{formatDate(new Date(policy.ToDate))}
								</Typography>
							)}
						</Stack>
					</Stack>
				</Box>
			)}
			value={Object.keys(policy ?? {}).length > 0 ? policy : null}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder='Search for policy'
					// sx={{ opacity: !policyData ? '0.5' : '1' }}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position='start'>
								{isLoadingPolicies || isFetchingPolicies ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
							</InputAdornment>
						),
					}}
				/>
			)}
			isOptionEqualToValue={(option, value) => option.Id === value.Id}
			onChange={(_, value) => {
				setPolicy(value);
			}}
			disabled={!clientId || disabled}
		/>
	);
};
