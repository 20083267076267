import { LOGIN_TYPE } from '@/app/authConfig';
import msalInstance from '@/app/msal';
import store from '@/app/store';
import { clearAccessToken, setAccessToken } from '@/features/msalAccount/msalAccountSlice';
import { Client } from '@microsoft/microsoft-graph-client';
import { scopes } from './msGraphUtils';

class MsalAuthProvider {
	extractToken(response) {
		const token = response.accessToken;
		const expiration = response.expiresOn ? new Date(response.expiresOn).getTime() : null;
		// console.log('RESPONSE MS GRAPH ACCESS TOKEN', expiration);
		store.dispatch(setAccessToken({ accessToken: token, expiration }));
		return token;
	}

	async getAccessToken() {
		const account = msalInstance.getActiveAccount();
		if (!account) {
			throw new Error('No active account found. Ensure the user is signed in.');
		}
		const request = {
			scopes,
			account,
		};
		return await msalInstance
			.acquireTokenSilent(request)
			.then(this.extractToken)
			.catch(async (error) => {
				console.error(
					'🙅 ~ [getMSAccessToken] Error retrieving access token silently',
					error
				);
				// Redirect user to login screen if there is an issue getting access token
				if (LOGIN_TYPE === 'popup') {
					return await msalInstance
						.acquireTokenPopup(request)
						.then(this.extractToken)
						.catch((e) => {
							console.log('🙅 ~ [getMSAccessToken] Token popup error', e);
							store.dispatch(clearAccessToken());
							return Promise.reject(e);
						});
				} else {
					return await msalInstance
						.acquireTokenRedirect(request)
						.then(this.extractToken)
						.catch((e) => {
							console.log('🙅 ~ [getMSAccessToken] Token redirect error', e);
							store.dispatch(clearAccessToken());
							return Promise.reject(e);
						});
				}
			});
		// try {
		// 	const account = msalInstance.getActiveAccount();
		// 	if (!account) {
		// 		throw new Error('No active account found. Ensure the user is signed in.');
		// 	}

		// 	const tokenResponse = await msalInstance.acquireTokenSilent({
		// 		...loginRequest,
		// 		account,
		// 	});

		// 	return tokenResponse.accessToken;
		// } catch (error) {
		// 	console.error('MSAL Authentication Error:', error);
		// 	store.dispatch(clearAccessToken());
		// 	throw error;
		// }
	}

	async getAccessTokenForRequest() {
		return await this.getAccessToken();
	}
}

const authProvider = new MsalAuthProvider();

const clientOptions = {
	authProvider, // Pass custom authentication provider
};

// Create Microsoft Graph Client instance with middleware
export const graphClient = Client.initWithMiddleware(clientOptions);
