/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	Stack,
	LinearProgress,
	Box,
	TextField,
	DialogActions,
	Button,
	FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { theme } from '@/app/theme';
import {
	claimFinalisedStatus,
	setDealStage,
	stabilisedTaskSort,
} from '@/utils/constants';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	useAddPoliciesToDealMutation,
	useGetDealPolicyTransactionsQuery,
	useUpdateDealPropertiesMutation,
} from '@/features/deals/dealsApi';
import { CompleteDealPolicySelection } from './CompletePipeline/CompleteDealPolicySelection';
import { determineContext } from '@/hooks/determineContext';
import { InsightClaimTable } from '@/components/tables/InsightClaimTable';
import { ComplianceTaskCheckbox } from '@/components/cards/DealCardValues/ComplianceTaskCheckbox';
import { LoadingButton } from '@mui/lab';
import {
	useUpdateInsightClaimMutation,
	useUpdateTicketPropertiesMutation,
} from '@/features/claims/ticketsApi';
import {
	useAddDocumentPropertiesToCollectionMutation,
	useCreateNoteMutation,
} from '@/features/engagements/engagementsApi';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { showToast } from '@/features/toast/toastSlice';
import { Timestamp } from 'firebase/firestore';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import ComplianceAccordion from '@/components/cards/ComplianceAccordion';
import { paths } from '@/app/routes';
import { useUpdateClientRowMutation, useUpdateInsightClientMutation } from '@/features/clients/clientsApi';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { MSG_TYPE } from '@/utils/folders';
import { useGetFoldersAndFilesQuery } from '@/features/msGraph/msGraphApi';

const CompleteDealTasks = () => {
	const { complianceTasks } = determineContext();

	const completedTasks = useMemo(
		() => complianceTasks.filter((t) => t.completed),
		[complianceTasks]
	);

	const incompleteTasks = useMemo(
		() => stabilisedTaskSort(complianceTasks.filter((t) => !t.completed)),
		[complianceTasks]
	);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<>
			<Stack direction='column' spacing={1}>
				<Typography sx={minimalLabelTheme}>Total Tasks Complete</Typography>
				<Typography
					variant='h6'
					sx={{ fontWeight: 'bold', marginLeft: '8px' }}
				>{`${completedTasks.length}/${complianceTasks.length}`}</Typography>
			</Stack>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ minWidth: 35 }}>
					<Typography sx={minimalLabelTheme}>COMPLETE</Typography>
				</Box>
				<Box sx={{ width: '100%', ml: 3, mr: 1 }}>
					<LinearProgress
						variant='determinate'
						value={
							complianceTasks.length === 0
								? 100
								: (completedTasks.length / complianceTasks.length) * 100
						}
						sx={{
							height: 10,
							borderRadius: 5,
							'& .MuiLinearProgress-bar': {
								borderRadius: 5,
								height: 10,
								backgroundColor: '#00C759',
							},
						}}
					/>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant='body2' color='text.secondary'>
						{complianceTasks.length === 0
							? '100%'
							: `${Math.round(
								(completedTasks.length / complianceTasks.length) * 100
							  )}%`}
					</Typography>
				</Box>
			</Box>
		</>
	);
};

const CompleteDealRecordKeeping = () => {

	const { dealRow } = determineContext();

	const { accessToken } = useSelector((state) => state.msalAccount);
	const filesQuery = useGetFoldersAndFilesQuery(
		{
			itemId: dealRow?.driveId,
			driveId: dealRow?.clientFolderDriveId,
			recursive: true,
		},
		{ skip: !accessToken || !dealRow?.driveId || !dealRow?.clientFolderDriveId }
	);

	const filteredFiles = useMemo(() => {
		return (filesQuery.data?.files ?? []).reduce((acc, f) => {
			switch (f?.file?.mimeType) {
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				acc.docx.push(f);
				break;
			case MSG_TYPE:
				acc.msg.push(f);
				break;
			case 'application/pdf':
				acc.pdf.push(f);
				break;
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				acc.xlsx.push(f);
				break;
			default:
				break;
			}
			return acc;
		}, { msg: [], pdf: [], docx: [], xlsx: []});
	}, [filesQuery.data?.files]);

	// useEffect(() => {
	// 	const getDealProperties = async () => {
	// 		try {
	// 			const { files } = await getClientGraphFoldersAndFiles(dealRow?.driveId, dealRow?.clientFolderDriveId, true);
	// 			const filteredFiles = (files ?? []).reduce((acc, f) => {
	// 				switch (f?.file?.mimeType) {
	// 				case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
	// 					acc.docx.push(f);
	// 					break;
	// 				case MSG_TYPE:
	// 					acc.msg.push(f);
	// 					break;
	// 				case 'application/pdf':
	// 					acc.pdf.push(f);
	// 					break;
	// 				case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
	// 					acc.xlsx.push(f);
	// 					break;
	// 				default:
	// 					break;
	// 				}
	// 				return acc;
	// 			}, { msg: [], pdf: [], docx: [], xlsx: []});
	// 			dispatch(setEmailFiles(filteredFiles.msg));
	// 			dispatch(setDocxFiles(filteredFiles.docx));
	// 			dispatch(setPdfFiles(filteredFiles.pdf));
	// 			dispatch(setXlsxFiles(filteredFiles.xlsx));
	// 		} catch (error) {
	// 			console.error('🙅 ~ Error getting files:', error);
	// 			dispatch(setEmailFiles([]));
	// 			dispatch(setDocxFiles([]));
	// 			dispatch(setPdfFiles([]));
	// 			dispatch(setXlsxFiles([]));
	// 		}
	// 	};
	// 	if (dealRow?.driveId) {
	// 		getDealProperties();
	// 	}
	// }, [dealRow]);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<Stack direction='column' spacing={1}>
			<Typography sx={minimalLabelTheme}>Record Keeping</Typography>
			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{filteredFiles.msg.length}
				</Typography>
				<Typography>Emails saved</Typography>
			</Stack>

			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{filteredFiles.docx.length}
				</Typography>
				<Typography>Word .docx saved</Typography>
			</Stack>

			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{filteredFiles.pdf.length}
				</Typography>
				<Typography>Application .Pdfs saved</Typography>
			</Stack>

			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{filteredFiles.xlsx.length}
				</Typography>
				<Typography>Excel .Xlsx saved</Typography>
			</Stack>
		</Stack>
	);
};

const CompleteDealStepsToComplete = () => {
	const { complianceTasks, deal } = determineContext();

	const pipelineId = deal?.pipeline ?? deal?.hs_pipeline;
	const pipelineQuery = useGetPipelinePropertiesQuery(pipelineId, {
		skip: !pipelineId,
	});
	const { data: pipelineData, isLoading, isError } = pipelineQuery;

	const stages = pipelineData?.stages ?? {};

	const taskList = useMemo(() => {
		const stageList = Object.entries(stages)
			.reduce((acc, [stageId, stage]) => {
				const tasks = (complianceTasks ?? []).filter((t) =>
					t.questionData?.stages.includes(stageId)
				);

				acc.push({
					stageId,
					order: stage.order,
					label: stage.label,
					tasks: (tasks ?? []).sort(
						(a, b) => a.questionData.order - b.questionData.order
					),
				});
				return acc;
			}, []);
		return stageList.sort((a, b) => a.order - b.order).filter(t => t.tasks.length > 0);
	}, [stages, complianceTasks]);

	return (
		<>
			<Typography variant='subtitle2'>Steps to complete:</Typography>
			<Stack>
				{taskList.map(({ stageId, label, tasks }) => (
					<ComplianceAccordion
						title={label}
						tasks={tasks ?? []}
						key={`compliance-accordion-${stageId}`}
						expandedByDefault={false}
					>
						<Grid container spacing={1}>
							{tasks.map((task) => {
								return (
									<Grid key={task.documentId} size={{ xs: 12, sm: 6, md: 3 }}>
										<ComplianceTaskCheckbox task={task} />
									</Grid>
								);
							})}
						</Grid>
					</ComplianceAccordion>
				))}
			</Stack>
		</>
	);
};

export const CompleteDealView = () => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector((state) => state.dialog.open['completeDeal']);

	const [additionalContent, setAdditionalContent] = useState('');

	const [confirmationOpen, setConfirmationOpen] = useState();

	const [selectedPolicies, setSelectedPolicies] = useState([]);

	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const { deal, claim, pipelines, client, dealRow, objectType, dealsPipelineData, policies } =
		determineContext();

	const isNewBusiness =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_NEW_BUSINESS;
	const isEndorsement =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_ENDORSEMENTS;
	const isRenewal =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_RENEWALS;

	useEffect(() => {
		if (isNewBusiness || isEndorsement || isRenewal) {
			setError(selectedPolicies.length === 0);
		}
	}, [selectedPolicies]);

	const pipelineName = isNewBusiness
		? 'New Business'
		: dealsPipelineData?.pipelines?.label?.slice(0, -1);

	const finalStage = useMemo(() => {
		const stage = pipelines?.stages?.find(
			(stage) =>
				stage?.metadata?.probability?.toString() === '1.0' ||
				stage?.metadata?.ticketState === 'CLOSED'
		);
		return stage;
	}, [deal?.hs_pipeline_stage, deal?.dealstage, pipelines?.stages]);

	const account = useSelector((state) => state.msalAccount.account);

	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const [updateDealProperties] = useUpdateDealPropertiesMutation();

	const policyId = useMemo(() => policies?.[0]?.Policy?.Id, [policies]);
	

	const { data: policyTransData } = useGetDealPolicyTransactionsQuery(
		{ policyId: policyId },
		{ skip: !policyId }
	);
	const policyTransId = useMemo(() => policyTransData?.[0]?.Id, [policyTransData]);

	const [updateInsightClaim] = useUpdateInsightClaimMutation();

	const [updateInsightClient] = useUpdateInsightClientMutation();
	const [updateClientRow ] = useUpdateClientRowMutation();

	const [addPoliciesToDeal, { isLoadingPolicies }] = useAddPoliciesToDealMutation();


	const [updateTicketProperties] = useUpdateTicketPropertiesMutation();

	const [addDocumentPropertiesToCollection, {isLoadingProperty}] = useAddDocumentPropertiesToCollectionMutation();

	const [createNote] = useCreateNoteMutation();

	const updateDealStage = async () => {
		const updateFunc = objectType === 'deal' ? updateDealProperties : updateTicketProperties;
		const updateRes = await setDealStage(
			objectType,
			updateFunc,
			finalStage.id,
			deal.hs_object_id
		);
		console.log('updated deal response: ', updateRes);
	};

	const updateClientType = async () => {
		await updateInsightClient({
			properties: {
				ClientTypeId: 0,
			},
			ownerId: hubspotId,
			clientId: client?.id ?? client?.hs_object_id,
			insightId: client?.insight?.Id,
		}).unwrap();
		await updateClientRow({
			clientId: client?.clientRow?.hubspotId,
			docId: client?.clientRow?.docId,
			properties: { isProspect: false },
		}).unwrap();
	};

	const updateDealPolicies = async (policiesToAdd) => {
		const response = await addPoliciesToDeal({
			dealId: deal.hs_object_id,
			clientId: client?.id ?? client?.hs_object_id,
			policies: policiesToAdd,
		});
		const todaysDate = new Date();
		const timestamp = Timestamp.fromDate(todaysDate);
		const responseDocProperties = await addDocumentPropertiesToCollection({
			dealId: deal.hs_object_id,
			docId: dealRow.id,
			objectType: objectType,
			collectionName: 'activities',
			properties: {
				dealId: deal.hs_object_id,
				activityType: 'policies linked',
				createdDate: timestamp,
				clientId: client.hs_object_id,
				isProspect: false,
			},
		});
	};

	const completeDeal = async () => {
		console.log('selected policies: ', selectedPolicies);
		setLoading(true);
		try {	
			//Makes sure that the transactions selected matches the policies array so 1 trans per policy
			if (isRenewal && policies.length !== selectedPolicies.length) {
				dispatch(
				  showToast({
						message: 'Error adding policies: must select one transaction per policy',
						error: true,
				  })
				);
				return;
			  }
			  
			  dispatch(closeDialog('completeDeal'));
		  switch (deal?.pipeline ?? deal?.hs_pipeline) {
			case process.env.REACT_APP_PIPELINE_NEW_BUSINESS: {
			  const policiesToAdd = selectedPolicies.map((policy) => ({
					policyId: policy.policyId,
					policyTransId: policy.policyTransId,
			  }));
			  await updateDealStage();
			  await updateDealPolicies(policiesToAdd);
			  await updateClientType();
			  break;
			}
			case process.env.REACT_APP_PIPELINE_CLAIMS: {
			  await completeClaim();
			  break;
			}
			case process.env.REACT_APP_PIPELINE_RENEWALS: {
			  const policiesToAdd = selectedPolicies.map((policyTransaction) => ({
					policyId: policyTransaction?.policyId,
					policyTransId: policyTransaction?.policyTransId,
			  }));
			  await updateDealStage();
			  await updateDealPolicies(policiesToAdd);
			  break;
			}
			// ENDORSEMENTS
			default: {
				await updateDealStage();
			  const policiesToAdd = selectedPolicies.map((policyTransaction) => ({
					policyId: policyTransaction?.policyId,
					policyTransId: policyTransaction?.policyTransId,
			  }));
			  await updateDealPolicies(policiesToAdd);
			  break;
			}
		  }
		  // If there’s additional content, create a note
		  if (additionalContent.length > 0) {
				const body = {
			  dealId: deal.hs_object_id,
			  noteBody: additionalContent,
			  hubspotId: hubspotId,
			  objectType: objectType,
				};
				console.log('📋 ~ Create note object', body);
				await createNote(body).unwrap();
		  }
		  dispatch(
				showToast({
			  message: `${pipelineName} completed!`,
			  action: {
						path: `${paths.clients}/${client.hs_object_id}`,
						label: 'GO TO CLIENT',
			  },
				})
		  );
		} catch (err) {
		  console.log(':no_good: ~ Error adding policies', err);
		  dispatch(
				showToast({
			  message: err.message || 'Error adding policies',
			  error: true,
				})
		  );
		} 
		finally {
			setLoading(false);  
		}
		
	  };
	  

	const completeClaim = async () => {
		await updateDealStage();
		await updateInsightClaim({
			claimId: claim.Id,
			properties: {
				StatusId: claimFinalisedStatus,
			},
		});
	};

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);
	
	const PolicySelectionContent = useCallback(() => {
		switch (deal?.hs_pipeline ?? deal?.pipeline) {
		case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
			return (
				<CompleteDealPolicySelection
					setSelectedPolicies={setSelectedPolicies}
					setLoading={setLoading}
				/>
			);
		case process.env.REACT_APP_PIPELINE_RENEWALS:
			return (
				<div>
					{policies?.length > 0 ? (
				  showNewRenewalsFeature ? (
						
							policies.map((policy) => (
					  <Stack key={policy.Id}>
									<CompleteDealPolicySelection
						  setSelectedPolicies={setSelectedPolicies}
						  setLoading={setLoading}
						  renewalPolicy={policy}
									/>
					  </Stack>
							))
				  ) : (

							<CompleteDealPolicySelection
					  setSelectedPolicies={setSelectedPolicies}
					  setLoading={setLoading}
							/>
				  )
					) : (
				
				  null
					)}
			  </div>
			  
			);
			  
		
		case process.env.REACT_APP_PIPELINE_CLAIMS:
			return <InsightClaimTable setLoading={setLoading} />;
			// case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
			// case process.env.REACT_APP_PIPELINE_RENEWALS:
		default:
			return (
				<CompleteDealPolicySelection
					setSelectedPolicies={setSelectedPolicies}
					setLoading={setLoading}
				/>
			);
		}
	}, [deal?.hs_pipeline, deal?.pipeline, setSelectedPolicies, setLoading, policies]);

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={(_, reason) => {
					if (reason === 'backdropClick') {
						return false;
					} else if (!loading) {
						dispatch(closeDialog('completeDeal'));
					}
				}}
				hideBackdrop={true}
				fullWidth={true}
				maxWidth='lg'
				sx={{ py: '2em', px: '3em' }}
			>
				<DialogTitle sx={{ fontWeight: 'bold' }} component='div'>
					{`Complete ${pipelineName}`}
					<Typography variant='subtitle2'>Your Deal Summary</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={2}>
						<Grid size={5} pt='1em'>
							<CompleteDealTasks />
						</Grid>
						<Grid size={'grow'} />
						<Grid size={{ xs: 4, sm: 5 }}>
							<CompleteDealRecordKeeping />
						</Grid>
						<Grid size={12}>
							<CompleteDealStepsToComplete />
						</Grid>

						<Grid size={12}>
							<Typography variant='subtitle2'>Note</Typography>
							<TextField
								fullWidth
								multiline
								rows={3}
								value={additionalContent}
								onChange={(e) => setAdditionalContent(e.target.value)}
							/>
						</Grid>
					</Grid>
					<PolicySelectionContent />
					{error && (
						<FormHelperText error>
							{`Please select the ${
								isEndorsement ? 'endorsement' : isRenewal ? 'policy transaction' : 'policies'
							} related to this deal`}
						</FormHelperText>
					)}
				</DialogContent>

				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => dispatch(closeDialog('completeDeal'))}
						color='primary'
						disabled={loading}
					>
						Cancel
					</Button>
					<LoadingButton
						loading={loading || finalStage?.id === null || isLoadingPolicies || isLoadingProperty}
						color='primary'
						onClick={() => {
							if (selectedPolicies.length === 0 && (isEndorsement || isRenewal || isNewBusiness)) {
								setError(true);
								return;
							} else {
								setError(false);
							}
							setConfirmationOpen(true);
						}}
						variant='contained'
						disabled={loading || isLoadingPolicies || isLoadingProperty}
					>
						Complete
					</LoadingButton>
				</DialogActions>
			</Dialog>

			{confirmationOpen && (
				<Dialog
					open={confirmationOpen}
					onClose={() => setConfirmationOpen(false)}
					maxWidth='xs'
					fullWidth={true}
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						<Typography>
							{`Are you sure you want to complete this ${pipelineName}?`}
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setConfirmationOpen(false)} color='primary'>
							Cancel
						</Button>
						<Button
							onClick={() => {
								setConfirmationOpen(false);
								completeDeal();
							}}
							color='primary'
							variant='contained'
							disabled={loading || isLoadingPolicies || isLoadingProperty}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};