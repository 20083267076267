/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { format, addYears } from 'date-fns';
import * as pdfjs from 'pdfjs-dist';
import { paths } from '@/app/routes';
import { NewBusinessIcon } from '@/components/icons/newBusiness';
import { RenewalIcon } from '@/components/icons/Renewal';
import { EndorsementIcon } from '@/components/icons/Endorsement';
import { ClaimsIcon } from '@/components/icons/ClaimsIcon';
import { currentYear, nextYear } from './date';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import {
	CLIENT_CONTACT_RELATIONSHIPS_COLLECTION,
	CONTACTS_COLLECTION,
	DEAL_CONTACT_RELATIONSHIPS_COLLECTION,
	firestore,
} from './firebase';

export const yearFormatRegex = /^(\d{2})-(\d{2})$/;

export const isYearFolder = (str) => {
	const match = str.match(yearFormatRegex);
	if (match) {
		const firstNum = parseInt(match[1], 10);
		const secondNum = parseInt(match[2], 10);
		return secondNum === firstNum + 1;
	}
	return false;
};

//dynamically creates folder year options
const generateYearOptions = () => {
	const years = [];
	for (let i = -3; i <= 3; i++) {
		const startYear = currentYear + i;
		const endYear = startYear + 1;
		years.push(`${startYear}-${endYear}`);
	}
	return years;
};

export const yearFolderOptions = generateYearOptions();

export const RENEWAL_MAX_DAYS = 60; // number of days out from renewal date

export const DEAL_STATUS_TYPES = {
	renewalCreated: 'Renewal Created',
	endorsementCreated: 'Endorsement Created',
	notStarted: 'Not Started',
	archived: 'Archived',
};

export const policyHeadCells = [
	{ id: 'checkbox', numeric: false, label: null, sortable: false },
	{
		id: 'invoiceNumber',
		numeric: false,
		label: 'Invoice Number',
		align: 'left',
		sortable: false,
	},
	{
		id: 'policyNumber',
		numeric: false,
		label: 'Policy No. Class of Risk',
		align: 'left',
		sortable: false,
	},
	{
		id: 'transactionType',
		numeric: false,
		label: 'Transaction Type',
		align: 'left',
		sortable: false,
	},
	{
		id: 'policyPeriod',
		numeric: false,
		label: 'Policy Period',
		align: 'left',
		sortable: false,
	},
	// {
	// 	id: 'description',
	// 	numeric: false,
	// 	label: 'Description',
	// 	align: 'left',
	// 	sortable: false,
	// },
	{
		id: 'insurer',
		numeric: false,
		label: 'Insurer',
		align: 'left',
		sortable: false,
	},
];

export function getNextStageId(dealStage, dealsPipelineData) {
	if (dealsPipelineData?.stages) {
		const stages = dealsPipelineData.stages;

		const currentStageOrder = stages[dealStage]?.order;
		if (currentStageOrder !== null) {
			let nextStageId;
			for (const stageId in stages) {
				const stage = stages[stageId];
				if (stage.order > currentStageOrder) {
					if (!nextStageId || stage.order < stages[nextStageId].order) {
						nextStageId = stageId;
					}
				}
			}
			if (nextStageId) {
				console.log('Next stage ID:', nextStageId);
				return nextStageId;
			} else {
				console.log('No next stage found.');
				return null;
			}
		} else {
			console.log('Invalid deal stage:', dealStage);
			return null;
		}
	} else {
		// console.log('No pipeline data available.');
		return null;
	}
}

export function getTimeFromDate(date) {
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
}

export async function setDealStage(objectType, updateFunc, nextStageId, dealId) {
	const paramType = objectType === 'deal' ? 'dealstage' : 'hs_pipeline_stage';
	const params = {
		[paramType]: nextStageId,
	};
	try {
		const response = await updateFunc({
			[`${objectType}Id`]: dealId,
			body: params,
		}).unwrap();
		console.log('👾 ~ transform update object response', response);
		return response;
	} catch (error) {
		console.error('Error setting deal stage:', error);
		throw error;
	}
}

export function formatTimeToHourMinutes(date) {
	const hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, '0');

	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12;

	return `${formattedHours}:${minutes} ${ampm}`;
}

export const folderStructure = {
	year: `${currentYear}-${nextYear}`,
	endorsements: 'Endorsements',
	renewal: 'Renewal',
	cover: 'Cover Information',
	claims: 'Claims',
	notes: 'Notes',
	clientAttachments: 'Client Attachments',
	clientNotes: 'Client Generated Notes',
};

export const replaceFolderName = (folderName) => {
	// Normalize and remove special characters
	return String(folderName)
		.normalize('NFD') // Normalize to decompose combined graphemes
		.replace(/[\u0300-\u036f]/g, '') // Remove accents
		.replace(/[\\/]/g, '-')
		.replace(/[^a-zA-Z0-9 _&-]/g, ''); // Remove remaining special characters
};

export const getFolderFromPipeline = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
		return folderStructure.cover;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
		return folderStructure.renewal;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
		return folderStructure.endorsements;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
		return folderStructure.claims;
	default:
		console.log('no pipeline id detected: ', pipelineId);
		break;
	}
};

export const getPipelineIcon = (pipelineId, width = '40px', transactionName = null) => {
	const props = { width, height: width };

	
	switch (transactionName || pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
	case 'New Business': 
		return <NewBusinessIcon {...props} />;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
	case 'Renewal': 
		return <RenewalIcon {...props} />;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
	case 'Endorsement': 
		return <EndorsementIcon {...props} />;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
	case 'Claim':
		return <ClaimsIcon {...props} />;
	default:
		return null;
	}
};

export const getUrlFromPipeline = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
		return paths.newBusiness;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
		return paths.renewals;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
		return paths.endorsements;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
		return paths.claims;
	default:
		console.log('no pipeline id detected: ', pipelineId);
		break;
	}
};

export const getPipelineFromSlug = (slug) => {
	switch (slug) {
	case paths.newBusiness:
	case paths.createNewBusiness:
		return process.env.REACT_APP_PIPELINE_NEW_BUSINESS;
	case paths.renewals:
	case paths.createRenewal:
		return process.env.REACT_APP_PIPELINE_RENEWALS;
	case paths.endorsements:
	case paths.createEndorsement:
		return process.env.REACT_APP_PIPELINE_ENDORSEMENTS;
	case paths.claims:
	case paths.createClaim:
		return process.env.REACT_APP_PIPELINE_CLAIMS;
	default:
		console.log('Could not find pipeline ID from url slug', slug);
		break;
	}
};

export const getCreateUrlFromPipeline = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
		return paths.createNewBusiness;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
		return paths.createRenewal;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
		return paths.createEndorsement;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
		return paths.createClaim;
	default:
		console.log('no pipeline id detected: ', pipelineId);
		break;
	}
};

const reduceToNestedObject = (paths) => {
	const root = {};

	paths.forEach((path) => {
		const parts = path.split('/').map((path) => decodeURI(path.normalize()));
		let current = root;

		parts.forEach((part) => {
			if (!current[part]) {
				current[part] = {};
			}
			current = current[part];
		});
	});

	return root;
};

export const getClientFolderStructure = (clientName, date, yearFolder) => {
	const replacedCompanyName = replaceFolderName(clientName);

	const structure = {
		base: replacedCompanyName,
		...folderStructure,
	};

	let yearRoot = `${structure.year}`;
	if (date) {
		const newDate = new Date(date);
		const currentYear = newDate.getFullYear() % 100;
		const nextYear = addYears(newDate, 1).getFullYear() % 100;
		yearRoot = `${currentYear}-${nextYear}`;
	}
	// console.log('here is the year folder: ', yearFolder);

	if (yearFolder) {
		yearRoot = `${yearFolder}`;
	}

	const clientSubFolders = [
		`${structure.clientAttachments}`,
		`${structure.clientNotes}`,
		`${yearRoot}`,
		`${yearRoot}/${structure.cover}`,
		`${yearRoot}/${structure.endorsements}`,
		`${yearRoot}/${structure.renewal}`,
		`${yearRoot}/${structure.claims}`,
	];
	const subs = reduceToNestedObject(clientSubFolders);

	return subs;
};

export const getFolderStructure = ({
	clientName,
	dealName,
	// dealId,
	pipelineFolder,
	date,
	yearFolder
}) => {
	const replacedCompanyName = replaceFolderName(clientName);
	const dealFolderName = replaceFolderName(dealName);

	const structure = {
		base: replacedCompanyName,
		dealName: dealFolderName,
		...folderStructure,
	};

	let yearRoot = `${structure.base}/${structure.year}`;
	if (date) {
		const newDate = new Date(date);
		const currentYear = newDate.getFullYear() % 100;
		const nextYear = addYears(newDate, 1).getFullYear() % 100;
		yearRoot = `${structure.base}/${currentYear}-${nextYear}`;
	}
	console.log('here is the year folder: ', yearFolder);

	if (yearFolder) {
		yearRoot = `${structure.base}/${yearFolder}`;
	}

	const baseFolders = [
		structure.base,
		`${structure.base}/${structure.clientAttachments}`,
		`${structure.base}/${structure.clientNotes}`,
		`${yearRoot}`,
		`${yearRoot}/${structure.cover}`,
		`${yearRoot}/${structure.endorsements}`,
		`${yearRoot}/${structure.renewal}`,
		`${yearRoot}/${structure.claims}`,
	];

	const yearRoots = yearRoot.split('/');
	const clientYearRoot = yearRoots[yearRoots.length - 1];

	const clientSubFolders = [
		`${structure.clientAttachments}`,
		`${structure.clientNotes}`,
		`${clientYearRoot}`,
		`${clientYearRoot}/${structure.cover}`,
		`${clientYearRoot}/${structure.endorsements}`,
		`${clientYearRoot}/${structure.renewal}`,
		`${clientYearRoot}/${structure.claims}`,
		`${clientYearRoot}/${pipelineFolder}/${structure.dealName}`,
		`${clientYearRoot}/${pipelineFolder}/${structure.dealName}/${structure.notes}`,
	];
	const subs = reduceToNestedObject(clientSubFolders);

	const dealFolders = [
		`${yearRoot}/${pipelineFolder}/${structure.dealName}`,
		`${yearRoot}/${pipelineFolder}/${structure.dealName}/${structure.notes}`,
	];

	return {
		folders: [...baseFolders, ...dealFolders],
		clientSubFolders: subs,
		dealFolder: {
			url: dealFolders[0],
			yearRoot,
			pipelineFolder,
			dealName: structure.dealName,
			yearFolder: clientYearRoot,
		},
	};
};

export const formatCurrency = (num, showDecimal = true) => {
	// Use the Intl.NumberFormat object to format the 'num' as currency.
	return Intl.NumberFormat('en-US', {
		style: 'currency', // Format as currency.
		currency: 'NZD', // Use NZD (NZ Dollars).
		currencyDisplay: 'narrowSymbol', // Display the currency symbol (NZD) in a narrow format.
		...(!showDecimal && { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
	}).format(parseFloat(num));
};

export const folioBlue = '#505AFC';

export const firstTwoCapsOrNumbers = (str) =>
	/^\d/.test(str)
		? (str.match(/\d/g) || []).slice(0, 2).join('')
		: (str.match(/[A-Z]/g) || []).slice(0, 2).join('');

export const formatDate = (date, includeTime) => {
	if (date == null) {
		return 'Date not found';
	}
	const dateFormat = includeTime ? 'd MMM yyyy \'at\' h:mm a' : 'd MMM yyyy';
	return format(new Date(date), dateFormat);
};

export const getFilePageCount = async (file) => {
	const arrayBuffer = await file.arrayBuffer();
	const pdf = await pdfjs.getDocument(arrayBuffer).promise;
	return pdf.numPages;
};

export const formatDateDayToYear = (dateValue) => {
	const date = new Date(dateValue);
	return format(new Date(date), 'dd/MM/yyyy');
};

export const PRIORITIES = ['LOW', 'MEDIUM', 'HIGH'];

export const closedPipelineStages = process.env.REACT_APP_CLOSED_PIPELINE_STAGES.split(',');

export const pipelineStagesBeforeInterm = process.env.REACT_APP_BEFORE_IN_TERM_STAGES.split(',');
export const claimFinalisedStatus = 12;

export const adjustSharepointUrl = (url) => {
	// console.log('adjusting url: ', url);
	const dotIndex = url.lastIndexOf('.');
	const firstPart = url.substring(0, dotIndex);
	const secondPart = url.substring(dotIndex + 1);
	const encodedFirstPart = encodeURIComponent(firstPart);
	const adjustedServerRelativeUrl = `${encodedFirstPart}.${secondPart}`;
	// console.log('here is the adjusted url: ', adjustedServerRelativeUrl);
	return adjustedServerRelativeUrl;
};

export const prioritySort = (a, b) => {
	const priorityA = PRIORITIES.indexOf(a.priority);
	const priorityB = PRIORITIES.indexOf(b.priority);

	if (priorityA === priorityB) {
		return a.created - b.created;
	}
	return priorityB - priorityA;
};

// Location of note attachments, etc., in Firebase Storage
export const attachmentsFolderPath = 'dealAttachments';
export const attachmentsClientFolderPath = 'clientAttachments';

export const stabilisedTaskSort = (array) => {
	const arrayCopy = [...array];
	arrayCopy.sort(prioritySort);
	return arrayCopy;
};

export const getPipelineName = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS: {
		return 'newBusiness';
	}
	case process.env.REACT_APP_PIPELINE_CLAIMS: {
		return 'claims';
	}
	case process.env.REACT_APP_PIPELINE_RENEWALS: {
		return 'renewals';
	}
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS: {
		return 'endorsements';
	}
	default: {
		return '';
	}
	}
};

export const createFormUrlQuery = (
	broker,
	insured,
	contactFname,
	contactLname,
	dealId,
	clientId
) => {
	return `?broker=${encodeURIComponent(broker)}&insured=${encodeURIComponent(
		insured
	)}&firstName=${encodeURIComponent(contactFname)}&lastName=${encodeURIComponent(
		contactLname
	)}&deal=${encodeURIComponent(dealId)}&client=${encodeURIComponent(clientId)}`;
};

export const formatDateString = (dateString) => {
	if (!dateString) return '-';
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
};

export const truncateFilename = (filename, maxLength) => {
	if ((filename ?? '').length <= maxLength) {
		return filename; // Return the original filename if it's short enough
	}
	const extensionIndex = filename.lastIndexOf('.'); // Find the last '.' to identify the extension
	const extension = extensionIndex !== -1 ? filename.substring(extensionIndex) : ''; // Extract the extension
	const baseName = extensionIndex !== -1 ? filename.substring(0, extensionIndex) : filename; // Extract the base filename
	const truncatedBaseName = baseName.slice(0, maxLength - extension.length - 3) + '... '; // Truncate and add ellipsis
	return truncatedBaseName + extension; // Reassemble the truncated filename with its extension
};

export const removeHtml = (htmlString) => {
	// First, replace </p> tags with a newline break
	const updatedHtmlString = htmlString.replace(/<\/p>/gi, '\n');
	const regex = /(<([^>]+)>)/gi;
	return updatedHtmlString.replace(regex, '');
};

// export const specialChars = /[!@#$%^&*()+=\\[\]{};:"\\|<>\\/?~]/;
export const specialChars = /[<>:"'/\\|?%*]/;

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const claimStatusColour = (displayOrder) => {
	return ['#1ABCFE', '#505AFC', '#9747FF', '#F3BA2F', '#00C650'][displayOrder] ?? folioBlue;
};

export const newBusinessStatusColour = (displayOrder) => {
	return ['#1ABCFE', '#505AFC', '#9747FF', '#F3BA2F', '#00C650'][displayOrder] ?? folioBlue;
};

export const taskTypes = ['TODO', 'CALL', 'EMAIL', 'MEETING'];

const isPrimitive = (obj) => {
	return obj !== Object(obj);
};

export const isEqual = (first, second) => {
	if (first === second) {
		return true;
	}

	if (
		isPrimitive(first) ||
		isPrimitive(second) ||
		typeof first === 'function' ||
		typeof second === 'function'
	) {
		return first === second;
	}

	if (Object.keys(first).length !== Object.keys(second).length) {
		return false;
	}

	for (const key of Object.keys(first)) {
		if (!(key in second)) {
			return false;
		}

		if (!isEqual(first[key], second[key])) {
			return false;
		}
	}

	return true;
};

export const getNameInitials = (name) => {
	const names = name.split(' ');
	if (/^[A-Z]{2}/.test(name)) {
		return name.substring(0, 2).toUpperCase();
	}
	if (names.length >= 2) {
		return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
	} else {
		return name.length >= 2 ? name.substring(0, 2).toUpperCase() : name.toUpperCase();
	}
};

export const formatKey = (key) => {
	return key
		.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camel case words
		.replace(/([a-z])([0-9])/g, '$1 $2') // Add space between lowercase and number
		.replace(/_/g, ' ') // Replace underscores with spaces
		.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

export const toCamelCase = (string) => {
	return string
		.replace(/[^a-zA-Z0-9\s]/g, '') // Remove symbols
		.trim() // Trim leading/trailing spaces
		.split(/\s+/) // Split by spaces (one or more)
		.map((word, index) =>
			index === 0
				? word.toLowerCase()
				: word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join('');
};

export const getNumberFromKey = (key) => {
	const match = key.match(/\d+/);
	return match ? match[0] : null;
};

export const generateDetailsObject = (details, parentKey = '') => {
	const result = {};

	if (!details) return result;

	Object.entries(details).forEach(([key, value], index) => {
		const uniqueKey = parentKey ? `${parentKey}_${key}` : key;
		const arrayKey = parentKey ? `${parentKey}_${index}` : key;

		if (!isNaN(Number(key)) && typeof key === 'string') {
			if (Array.isArray(value)) {
				value.forEach((item, idx) => {
					Object.assign(result, generateDetailsObject(item, `${arrayKey}_${idx}`));
				});
			} else if (typeof value === 'object') {
				Object.assign(result, generateDetailsObject(value, uniqueKey));
			}
		} else if (Array.isArray(value)) {
			value.forEach((item, idx) => {
				Object.assign(result, generateDetailsObject(item, `${arrayKey}_${idx}`));
			});
		} else if (typeof value === 'object') {
			if (value === null) {
				result[uniqueKey] = [key, '-'];
			} else {
				Object.assign(result, generateDetailsObject(value, uniqueKey));
			}
		} else {
			result[uniqueKey] = [key, value?.toString() || '-'];
		}
	});

	return result;
};

export const flattenToNested = (flattenedData) => {
	const nestedData = {};

	for (const [flatKey, value] of Object.entries(flattenedData)) {
		const keys = flatKey.split('_');
		let currentLevel = nestedData;

		keys.forEach((key, index) => {
			if (!currentLevel[key]) {
				currentLevel[key] = index === keys.length - 1 ? value : {};
			}
			currentLevel = currentLevel[key];
		});
	}

	return nestedData;
};

export const safelyParseFloat = (value) => {
	const val = parseFloat(value ?? 0);
	return isNaN(val) ? 0 : val;
};

export const safelyParseInt = (value) => {
	const val = parseInt(value ?? 0);
	return isNaN(val) ? 0 : val;
};

export const tryDecodeFilePath = (name, compareName) => {
	// const name = (file?.name ?? '').normalize();

	let isEqual = false;

	try {
		isEqual =
			name == compareName ||
			decodeURI(name.normalize()) == decodeURI(compareName.normalize());
	} catch (err) {
		console.log(`Error decoding ${name}`, err);
		isEqual = name == compareName;
	}
	return isEqual;
};

export const isKeyboardEvent = (event) => {
	return !!event.key;
};

//Firebase helper functions
export const getClientsAndDealsForContact = async (contactDocId) => {
	try {
		// Fetch client relationships
		const clientRelationshipsQuery = query(
			collection(firestore, CLIENT_CONTACT_RELATIONSHIPS_COLLECTION),
			where('contact', '==', doc(firestore, CONTACTS_COLLECTION, contactDocId))
		);

		const relationshipsSnapshot = await getDocs(clientRelationshipsQuery);
		console.log('relationship snap: ', relationshipsSnapshot);

		if (relationshipsSnapshot.empty) {
			console.log('No clients found for this contact.');
			return { clients: [], deals: [] };
		}

		const clientData = relationshipsSnapshot.docs.map((doc) => ({
			clientRef: doc.data()?.client,
			isMainContact: doc.data()?.isMainContact,
			role: doc.data()?.role,
			clientDocId: doc.id,
		}));
		console.log('client data: ', clientData);

		const clients = await Promise.all(
			clientData.map(async ({ clientRef, isMainContact, role }) => {
				const clientDoc = await getDoc(clientRef);
				const clientData = clientDoc.data();

				const brokerGroupPath = clientData?.brokerGroup
					? clientData?.brokerGroup.path
					: null;

				const createdAt = clientData?.createdAt
					? clientData?.createdAt.toDate().toISOString()
					: null;

				const finalClientData = {
					...clientData,
					isMainContact,
					clientId: clientRef.id,
					role: role,
					brokerGroup: brokerGroupPath,
					createdAt,
				};

				return finalClientData;
			})
		);
		//removed deals for now
		//   const dealRelationshipsQuery = query(
		// 		collection(firestore, DEAL_CONTACT_RELATIONSHIPS_COLLECTION),
		// 		where('contact', '==', doc(firestore, CONTACTS_COLLECTION, contactDocId))
		//   );

		//   const dealRelationshipsSnapshot = await getDocs(dealRelationshipsQuery);

		//   const dealData = dealRelationshipsSnapshot.docs.map((doc) => ({
		// 		dealRef: doc.data().deal,
		//   }));

		//   const deals = await Promise.all(
		// 		dealData.map(async ({ dealRef }) => {
		// 	  const dealDoc = await getDoc(dealRef);
		// 	  const dealData = dealDoc.data();
		// 	  const brokerGroupPath = dealData?.brokerGroup ? dealData?.brokerGroup.path : null;
		// 	  const nextDealRow = dealData?.nextDealRow ? dealData?.nextDealRow.path : null;
		// 	  const previousDealRow = dealData?.previousDealRow ? dealData?.previousDealRow?.path : null;

		// 	  const createdAt = dealData?.createdAt ? dealData?.createdAt.toDate().toISOString() : null;
		// 	  const finalDealData = {
		// 				...dealData,
		// 				brokerGroup: brokerGroupPath,
		// 				createDate: createdAt,
		// 				nextDealRow: nextDealRow,
		// 				previousDealRow: previousDealRow
		// 	  };

		// 	  return finalDealData;
		// 		})
		//   );
		const deals = [];

		return { clients, deals };
	} catch (error) {
		console.error('Error fetching clients and deals for contact:', error);
		throw error;
	}
};

export const getProductContent = (type) => {
	switch (type) {
	case 'Material Damage':
		return `Material damage insurance is coverage that protects against financial losses resulting from 
  physical damage or destruction to tangible properties, assets, or possessions. It includes protection 
  for buildings, contents, machinery, vehicles, and more, caused by covered perils like fire, theft, and 
  natural disasters.
  
  Claims Example:
  
  An earthquake hits a city, causing $50,000 worth of damage to a small electronics store. 
  The owner, Alex, who had material damage insurance, files a claim. The insurance company 
  approves the claim and provides $50,000 in financial compensation.
  
  Thieves break into John's work van and steal tools worth $10,000. Luckily, he has material 
  damage insurance, which covers theft from vehicles. The insurance company reimburses him $10,000.
  
  An employee accidentally knocks over a cup of coffee, causing $2,000 worth of damage to the 
  office computer and printer. Fortunately, the business had material damage insurance, and they 
  promptly file a claim. The insurance company reimburses them for $2,000 in repair costs minus policy excess.
		`.trim();
	case 'Commercial Motor':
		return `A commercial vehicle insurance policy covers vehicles used for business purposes for loss, accidental damage, and legal liability.

If your business requires the use of a commercial vehicle to transport employees and equipment, the risk of accidents, injury, property damage, loss, and theft comes with every trip. Commercial motor vehicle insurance can be used to cover cars, trailers, trucks, Utes, vans, buses, diggers, and light machinery.

Optional Extension - Loss of Use:
Loss of use is an extension option available for commercial motor vehicle insurance which provides financial compensation to businesses for the expenses of renting a replacement vehicle while their primary commercial vehicle is being repaired due to a covered loss. You select the daily rate to rent another vehicle (e.g., $200 per day) and also the amount of days you would like to have covered (e.g., Days Covered: 60). This cover is especially important to transport businesses that have contractual obligations to provide a vehicle regardless of if their vehicle is being repaired and additionally their large finance repayments do not stop due to the vehicle being repaired. As such, they can rent another Truck/Trailer and continue to operate their business.

Claims Examples:

- Builder: An apprentice of the insured went to collect some materials from their local suppliers in a company Ute and got into an accident on the way. The insurer covered the repairs to the client's Ute and the damage to the house he crashed into.

- Accountant: The client returned to his vehicle to find it had been stolen and lodged a claim. The police couldn't locate it, and the vehicle was ultimately paid out by the insurer for $20,000.

- Transport With Loss Of Use: The client got into an accident in his truck and trailer. The insurance policy reacted to repair the truck & trailer. The client had Loss Of Use coverage set for $300 per day for up to 60 days on each unit. Loss of use has a 7-day excess. The repair took 50 days. The LOU claim was for 43 days on the truck and trailer. The Loss Of Use extension paid out $25,800 to rent the replacement truck and trailer while his truck and trailer was being repaired.
		`.trim();
	case 'General Liability':
		return `General liability refers to a crucial form of insurance coverage designed to protect individuals and businesses from various financial risks arising from their day-to-day operations. It offers a broad scope of protection, shielding policyholders from potential legal claims and financial losses resulting from bodily injuries, property damage, or personal injury caused to third parties.

Claims Examples:

- Collapsed Retaining Wall: A building firm constructed a retaining wall on a steep site to allow the development of a new dwelling. The piles were not driven deeply enough due to poor workmanship on the Insured’s part, and a section of the wall collapsed, falling onto and damaging the neighbouring property. The total damage to the neighbour’s property was $90,000, and, of course, the wall had to be rebuilt. The Products Liability section of a General Liability Policy responded to the neighbour’s claim, and as the work had been completed and handed over to the owner, the Defective Workmanship extension covered the cost of rebuilding the wall.

- Sprinkler System Maintenance: A client was contracted to review and maintain the fire alarm and sprinkler system in an office building. Unfortunately, whilst undertaking the work, the sprinkler system was triggered on one of the floors, causing water damage to the building and contents in that area. The client's General Liability Policy covered the damage to third parties’ property and associated costs of the third party (expenses due to disruption are payable as there was a valid property damage claim). The total costs of the claim were $230,000.

- Compost Error: A fertiliser manufacturer was asked to supply a particular compost by a horticulturalist looking to nurture a specialised crop. Unfortunately, the wrong compost was provided, which burnt the seedlings, and as a result, the entire crop was lost. The fertiliser manufacturer was sued for loss of income, and the Products Liability component of his General Liability Policy responded, with the claim totalling $200,000.
		`.trim();
	case 'Professional Indemnity':
		return `If your business offers professional advice or services, this policy protects against an allegation of negligence resulting in financial loss or a breach of your professional duty. Solutions are available for a range of professions, including lawyers, accountants, engineers, architects, real-estate agents, management consultants, and medical and health workers. This insurance covers both the cost of claims for compensation that the business is legally liable to pay and also the costs of defending the claim. The cost of defending a claim can be significant, whether or not the claim is justified, and full cost recovery against the claimant is unlikely.

Claims Examples:

- Pre-purchase Property Inspector: The insured undertook a pre-purchase property inspection of a house that had been damaged but repaired after the Christchurch earthquake. Its report stated that the floor levels were within the acceptable range as specified in the relevant building code. The client purchased the house based on this. Two years later, when the client went to sell the property, a further property inspection report revealed that the floors, in fact, did not meet the building code. The client demanded the insured for the cost of rectifying the issue to bring the floor to an acceptable level. The insurer appointed a lawyer, and it was clear the insured was liable for negligently reporting that the floors were of an acceptable level. Insurer Settlement was $100,000. Legal and Experts Costs: $9,000.

- Investment Adviser: The insured's client instructed the insured to liquidate their client's investment. The insured had an administrative issue and did not action the instructions from their client. As such, the client's investment had decreased by the time their instructions were acted on. The policy responded and covered the drop in value of the client's investments. Insurer Settled $10,000.

- Real Estate Agent: The insured produced marketing material, including information provided by the vendor. The purchaser relied on this information when purchasing the property. The information turned out to be incorrect, and the purchaser brought a claim against the insured as a result. The policy responded to cover the defence costs of the Insured dealing with the REA process. Outcome: $10,000 claim.
		`.trim();
	case 'Carriers Liability':
		return `Cover is based on your liabilities under the Contract and Commercial Law Act 2017, but our policy also gives you some additional benefits. The policy is organised into sections, which provide cover for the Act's different contracts depending on what applies to your business.

Your cover can include:

- Liability for loss or damage to goods imposed on the carrier under the Contract and Commercial Law Act 2017 for goods carried on a Limited Carriers Risk basis.
- Specified cover for goods carried at Owner’s Risk.
- Cover specifically set up to cater for Declared Value or Declared Terms contracts.
- Costs following the establishment of a valid claim, and up to a specified limit, for:
  - Debris removal and clean-up following an accident.
  - Expediting expenses.
  - Consequential loss for which you’re legally liable.

Claims Examples:

- Transport Business: While a truck was heading down the motorway, the pins on the truck popped open, releasing a whole truckload of manure on the motorway. This closed the motorway down while the cow manure could be cleared and disposed of. The total claim for Traffic management and the use of machinery to collect the spilled load was in excess of $16,800.
		`.trim();
	case 'Cyber Liability':
		return `Cyber liability insurance can protect businesses from financial losses resulting from cyber threats and data breaches. It covers expenses for investigations, legal matters, customer notification, data restoration, and potential liability claims, helping businesses recover and mitigate cyber incidents' impacts while safeguarding their reputation.

Claims Examples:

- Ransomware Attack on an Insurance Broker: An insurance broker working as a sole trader sustained a ransomware attack when an employee opened a malicious email. Due to their business continuity plan, they were still able to operate on a limited scale using manual paperwork, but they were unable to access their online systems. Rather than pay the ransom or contact their IT provider, the broker notified their insurer, who was able to appoint a security specialist from their response panel. Although loss was minimized due to the use of backups, the removal of the infection from the system was time-consuming and difficult. The covered costs included:
  - Business Interruption losses.
  - The costs of identifying and removing the infection.
  - The restoration of the system from backup.

- Nurse at an Inner-city Hospital: A nurse at an inner-city hospital accidentally attached a confidential file, containing names, addresses, and medical records of approximately 3500 people in what was supposed to be a simple email to a patient with their test results. The hospital contacted their insurer, who assessed the situation and brought in external help. The policy covered:
  - Regulator Liability.
  - Data Forensic Expenses to identify what data had been released.
  - Breach Response Services, including notifying the patients, setting up a call centre, and carrying out ID monitoring for the affected patients.
  - Public Relations Expenses.
  - Legal Expenses.

- DDoS Attack on a Cosmetic Company: A cosmetics company specialising in makeup products suffered a distributed denial of service attack on their website, which shut their website down, rendering it unusable and preventing the company from trading. It is believed that the attack was targeted at the company by an animal welfare group in retaliation to allegations of animal testing. The owner contacted their insurer, who assigned an incident manager. An IT specialist was brought onboard to resolve the issue. The insurance policy covered:
  - Business Interruption losses.
  - Installing a shield to prevent the attack from continuing.
  - Network Improvement.
		`.trim();
	case 'Marine Cargo':
		return `If your business involves transporting goods by sea, air, or land, this policy protects against loss or damage to cargo during transit. Solutions are available for various types of goods, including perishables, high-value items, and hazardous materials. Marine cargo insurance covers both the cost of the damaged or lost cargo and the costs associated with claims processing and legal defense. The cost of defending a claim can be significant, whether or not the claim is justified, and full cost recovery against the claimant is unlikely.

Claims Examples:

- Electronics Exporter: The insured shipped a container of electronic goods from China to the United States. During the voyage, the ship encountered rough seas, causing water to enter the container and damage the goods. The client filed a claim for the damaged goods, and the insurer covered the loss. Insurer Settlement was $200,000.

- Perishable Goods Distributor: The insured was transporting a shipment of fresh produce from New Zealand to Japan. Due to a refrigeration unit malfunction on the ship, the produce spoiled before reaching its destination. The client filed a claim for the loss of the perishable goods. The insurer settled the claim, covering the cost of the spoiled produce. Insurer Settlement was $50,000.

- Furniture Manufacturer: The insured shipped custom-made furniture from Italy to Australia. Upon arrival, it was discovered that the furniture had been damaged due to improper handling during loading and unloading. The client demanded compensation for the damage. The insurer appointed a surveyor and determined that the insured was liable for the damage. The insurer covered the repair costs. Insurer Settlement was $30,000.
		`.trim();
	case 'Business Interruption':
		return `Business interruption insurance is a crucial risk management tool for businesses, providing financial protection during unexpected disruptions caused by events like fires, natural disasters, or equipment breakdowns. It compensates businesses for lost income based on their financial records and projected earnings during the interruption period, including coverage for gross profit. The policy also addresses ongoing operating expenses, helping businesses maintain stability during temporary closures. The coverage starts when business activities are halted and continues throughout the restoration period, aiding in recovery, meeting financial obligations, and reducing downtime, ultimately supporting businesses in their ability to bounce back from crises.

Claims Example:

- Restaurant Fire: A popular restaurant faces a devastating kitchen fire, leading to a three-month closure. With business interruption insurance, they file a claim for the projected $360,000 in lost wages and gross profit. The insurer promptly approves the claim, providing financial support for ongoing expenses and helping the restaurant recover during the restoration period, ensuring a smoother transition back to normal operations once repairs are completed.
		`.trim();

	case 'Management Liability':
		return `
Insurance protects you and your company against the risks and exposures of running the company (e.g., liability for mismanagement). Covers can include:

- Directors & Officers
- Fidelity/Crime
- Employment Practices
- General Liability
- Statutory Liability
- Employers Liability
- Consequential Loss
- Prosecution Legal Expenses (Criminal)
- Internet Liability



 Directors & Officers (D&O)

Directors & Officers (D&O) insurance is a liability coverage that protects company directors and officers from legal claims and personal financial losses resulting from their corporate duties. It covers defense costs, settlements, and judgments related to alleged wrongful acts. D&O insurance safeguards directors and officers' personal assets, making it essential in the corporate landscape where they can be held personally liable for their actions.

 Claims Examples:

- Breach of Directors' Duties: Proceedings were issued against directors alleging that they breached directors' duties by incurring an obligation without reasonable grounds the company could perform that obligation. Amount claimed was $6.5m. Matter was settled for $90,000, and legal costs were $83,000.

- Defamation Claim: A defamation claim was made against a director regarding a competitor’s financial standing. Legal costs of $171,000.



 Fidelity/Crime

Fidelity insurance protects businesses from financial losses caused by dishonest or fraudulent acts committed by employees. It covers theft, embezzlement, forgery, fraud, and related activities. This coverage is crucial for safeguarding a company's financial stability and assets. Crime cover is a comprehensive insurance policy against various criminal activities, whereas fidelity insurance specifically targets losses from dishonest employee acts.

 Claims Examples:

- Fidelity - Insured Builder: An employee working in the accounts department ordered materials through the company’s suppliers and paid for those items using company funds. The items were then sold by the employee to third parties. Total value of fraudulent transactions = $18,000. Settled to the insured less the excess. Recovery attempts from the employee or via a Police prosecution were not successful.

- Fidelity - Food Packing Operation: A food packing operation for food suppliers discovered that an employee had been taking empty crates and selling them to other suppliers, retaining the sale proceeds. The insured did not receive the benefit of the credits for the empty crates. Loss settled for $170,000 less the excess.

- Crime - Office: A professional services firm discovered that a dishonest consultant had been manipulating financial records to overcharge clients and embezzle money. The firm filed a crime insurance claim to address the fraudulent activities and recover the financial losses. The insurance company investigated the claim, verified the dishonest activities, and provided coverage for the losses incurred by the firm.



 Consequential Loss

Consequential Loss insurance responds to financial losses suffered by a business resulting from a business interruption caused by a liability insurance claim. The policy trigger is contingent upon an original claim being made and indemnity confirmed under either a Public, Statutory, or Employers’ liability policy underwritten by the same insurer. Consequential losses can include loss of Gross Profit and/or increased costs.

 Claims Examples:

- Manufacturer: An employee was severely injured using a machine that was only seven months old at the time of the accident. The insured had cover under their statutory liability policy. Production was completely stopped due to a Prohibition Notice from WorkSafe. The Prohibition Notice was later moved to an Improvement Notice, and the machine could only operate at 60% capacity. The insured incurred extra transportation costs and costs to outsource commissioned projects. Total loss paid was $90,000.

- Delicatessen: The insured's food was contaminated with listeria, resulting in a consumer becoming ill and hospitalized. The insured had cover under their broadform liability policy for the personal injury caused to the consumer. MPI investigated the listeria outbreak, and the delicatessen was shut down for cleaning. Lost revenue and additional labor costs totaled $26,000, which were paid under the insured’s consequential loss policy.



 Prosecution Legal Expenses

Prosecution legal expenses cover the costs associated with legal representation when an individual or organization faces criminal prosecution or enforcement actions, such as investigations, hearings, or trials, for alleged violations of the law or regulations. This insurance typically helps in paying for legal fees, court expenses, and related costs required to defend against the criminal charges or enforcement proceedings.

 Claims Examples:

- Careless Driving Charge: An employee delivering a container pulled out of a driveway onto a public road, causing two motorcycles to take evasive action. One motorcyclist swerved into a fence, causing injuries. The employee was charged with careless driving causing injury. Lawyers successfully defended the charge, proving the motorcycles were traveling at excessive speed. Total legal and expert costs: $30,000.

- Logging Truck Accident: An employee driving a dual trailer logging truck collided with an oncoming vehicle, resulting in four fatalities. The driver was not charged after police investigations. Total legal costs: $20,000.



 Employment Disputes (Normally Optional)

Employment Disputes insurance responds to claims for compensation brought by current, former, and future employees alleging a breach of their employment agreement, the Privacy Act, and/or the Human Rights Act. This insurance covers the costs of such claims, including defense costs. Common areas of exposure include personal grievance actions, claims for unjustified dismissal, or other alleged disadvantage under the Employment Relations Act.

 Claims Examples:

- Constructive Dismissal: An employee raised a personal grievance alleging constructive dismissal during a restructure. The employee was offered a lower role in the new structure. Mediation failed, but a settlement was reached. Total claim: $50,000 (defense costs: $20,000, settlement: $30,000).

- Unjustified Dismissal: An employee raised a personal grievance for unjustified dismissal during a restructure. The Employment Relations Authority found in favor of the insured. Total defense costs: $36,000.

- Unjustified Disadvantage: An employee doctor raised a personal grievance for unjustified disadvantage, alleging the insured medical practice was failing to meet its obligations, causing clinical risk. A settlement was reached, allowing the GP to continue working with additional safety measures. Total claim: $27,000 (defense costs: $15,000, compensation: $12,000).`.trim();

	case 'Statutory Liability':
		return `
Statutory Liability insurance provides legal defense protection and cover for fines for some breaches of statutes, such as the Resource Management Act, Financial Reporting Act, and Building Act. However, Occupational Health & Safety legislation prevents parties from insuring for workplace health & safety fines. Cover typically includes defense costs and is designed for defending unintentional breaches of NZ Acts of Parliament.



 Claims Examples:

 Industrial Installation - Health and Safety at Work Act 2015 – Enforceable Undertaking

- Scenario: The insured was installing a piece of heavy equipment in a pump station. While the equipment was being moved into position, it tipped forward and hit an employee, causing a crush injury to the abdomen area.
- Outcome: The insured and their appointed lawyers negotiated an Enforceable Undertaking with WorkSafe. This included:
  - Providing assistance to the injured employee.
  - Additional training for staff and supervisors.
  - Development of industry guidelines for installation works.
- Costs: The total costs for the Enforceable Undertaking were $170,000 (excluding legal fees).
- Policy Coverage: While fines under the Health and Safety at Work Act 2015 cannot be insured against, the policy contributed to reparations payments to the injured party and reasonable defense costs in preparation of the undertaking.



 Tourism Operator – Resource Management Act 1991

- Scenario: A tourism operator in an area of high natural significance made changes to improve access to and from their site. However, the changes were not automatically allowed as the area was protected, and a full resource consent from the relevant authority was required.
- Outcome: The Regional Council issued an abatement notice and prosecuted the company for a breach of the Resource Management Act 1991.
- Resolution: The insurer appointed a specialist lawyer to assist the company. An early guilty plea was entered, and an agreed fine and remediation of the site and works were negotiated.
- Costs: The fine and legal fees totaled $110,000, which were covered by the Statutory Liability policy.`;
	case 'Employers Liability':
		return `
An Employers' Liability policy (sometimes referred to as EL) protects employers against claims brought by their employees who have sustained an injury in the course of their employment where the Accident Compensation Commission (ACC) does not provide cover. Employers face exposure for a variety of conditions that may not be covered by ACC, including:

- Injury caused by stress
- Depression, anxiety, or mental anguish
- Disease or infection caused by air conditioning systems or passive smoking
- Bystander claims caused by witnessing a traumatic injury or fatality



 Claims Examples:

 Legal Secretary

- Scenario: A legal secretary was asked to temporarily pick up additional workload after the secretary of another partner left abruptly. She agreed to help until a replacement was recruited. However, eight months later, despite many requests for help, the position still had not been advertised, and she was working extended hours to meet the needs of both partners.
- Injury: She developed headaches, lost her ability to concentrate, and was eventually signed off work for clinical depression. This condition was not covered under ACC.
- Legal Action: After recovering, she brought a civil suit against the law firm in the District Court, successfully suing them for stress and loss of earnings.
- Outcome: The firm’s Employers Liability cover paid for:
  - Legal defense costs.
  - The costs and damages ultimately awarded against them.
- Claim Total: $78,650.`;
	case 'Contractors Plant And Machinery':
		return `
If your business uses machinery and equipment on construction sites, this policy protects against damage or loss of plant and machinery used in construction projects. Solutions are available for various types of equipment, including cranes, excavators, bulldozers, and scaffolding. Contractors Plant and Machinery (CPM) insurance covers both the cost of replacing or repairing damaged equipment and the costs associated with claims processing and legal defense. The cost of defending a claim can be significant, whether or not the claim is justified, and full cost recovery against the claimant is unlikely.



 Claims Examples:

 Excavator Damage

- Scenario: The insured's excavator was damaged while working on a construction site due to falling debris.
- Action: The client filed a claim for the repair costs.
- Outcome: Insurer Settlement was $50,000.



 Crane Collapse

- Scenario: The insured's crane collapsed on-site due to operator error, causing significant damage to the crane and nearby structures.
- Action: The client demanded compensation for the repairs.
- Outcome: The insurer covered the cost of the crane repair. Insurer Settlement was $200,000.


 Bulldozer Theft

- Scenario: The insured's bulldozer was stolen from a construction site overnight.
- Action: The client filed a claim for the loss of the equipment.
- Outcome: The insurer covered the cost of replacing the stolen bulldozer. Insurer Settlement was $80,000.`;

	default:
		return 'No description available.';
	}
};