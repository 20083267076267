import store from '@/app/store';
import { clearAccessToken } from '@/features/msalAccount/msalAccountSlice';
import axios from 'axios';
import Cookies from 'js-cookie';

export const GRAPH_URL = 'https://graph.microsoft.com/v1.0/';
export const msGraphAxios = axios.create({ baseURL: GRAPH_URL });

// Exponential delay function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

msGraphAxios.interceptors.request.use(
	async (config) => {
		const token = Cookies.get('token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		config.headers['Content-Type'] = 'application/json';
		config.retryCount = config.retryCount ?? 0;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

msGraphAxios.interceptors.response.use(
	response => response, // Pass successful responses as is
	async (error) => {
		const config = error.config;
		const code = error.response?.data?.error?.code;

		if (code === 'InvalidAuthenticationToken' && config && config.retryCount < 5) {
			store.dispatch(clearAccessToken());
            
			const retryDelay = Math.pow(2, config.retryCount) * 1000; // Exponential backoff: 1s, 2s, 4s, 8s, 16s
            
			console.warn(`⚠️ InvalidAuthenticationToken. Retrying request in ${retryDelay / 1000} seconds... (Attempt ${config.retryCount + 1}/5)`);

			await delay(retryDelay); // Wait before retrying
            
			config.retryCount += 1; // Increment retry count
            
			const token = Cookies.get('token'); // Get updated token
			if (token) {
				config.headers.Authorization = `Bearer ${token}`; // Update token
			}

			return msGraphAxios(config); // Retry the request
		}

		// Reject the promise if max retries are reached or for other errors
		return Promise.reject(error);
	}
);

export const MAX_SIMPLE_UPLOAD_SIZE = 4 * 1024 * 1024; // 4MB

export const scopes = [
	'User.Read',
	'Calendars.ReadWrite',
	'OnlineMeetings.ReadWrite',
	'Calendars.Read',
	'User.Read.All',
	'Files.ReadWrite.All', //'Files.Read.All', 'Files.ReadWrite',
	// new scopes for email
	// 'Mail.ReadWrite',
	// 'Mail.Send',
	// 'Sites.ReadWrite.All',
];

