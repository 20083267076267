/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchema } from './validationSchema';
import { default as folderValidationSchema } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useCreateClaimMutation } from '@/features/claims/ticketsApi';
import { PolicySelect } from '@/components/selects/PolicySelect';
import { PolicyTransactionSelect } from '@/components/selects/PolicyTransactionSelect';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import FileUpload from '@/components/FileUpload';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { PageHeader } from '@/components/layouts/PageHeader';
import { showToast } from '@/features/toast/toastSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClaimTypeSelect } from '@/components/selects/ClaimTypeSelect';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { paths } from '@/app/routes';
import { getPipelineIcon } from '@/utils/constants';
import { DEAL_NAME, DEAL_OVERVIEW } from '../CreateNewBusinessForm/validationSchema';
import FolderNameField from '../CreateNewBusinessForm/FolderNameField';
import { FOLDER_NAME } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import DealNaming from '../fields/DealNaming';

const CreateClaimForm = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const account = useSelector((state) => state.msalAccount.account);

	const existingClient = location?.state?.client ?? {};
	const existingPolicy = location?.state?.policy;
	const existingTransaction = location?.state?.transaction;

	const isExistingClient = useMemo(
		() => Object.keys(existingClient).length > 0,
		[existingClient]
	);

	const email = account?.username;
	const userQuery = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userQuery.data?.hubspotId;

	const [formLoading, setFormLoading] = useState(false);

	const initialValues = useMemo(() => {
		return {
			policy: existingPolicy ?? {},
			policyId: existingPolicy?.Id,
			transaction: existingTransaction,
			policyTransactionId: existingTransaction?.Id,
			files: [],
			describeClaim: '',
			notes: '',
			clientName: existingClient?.name ?? '',
			clientOwner: existingClient?.hubspot_owner_id ?? existingClient?.ownerId,
			successMessage: '',
			errorMessage: '',
			dealName: '',
			[FOLDER_NAME]: '',
            [DEAL_OVERVIEW]: '',
			dateOfLoss: null,
			dateReported: null,
			priority: '',
			claimType: '',
			insurer: existingPolicy?.InsurerName ?? '',
			clientId: existingClient?.id ?? existingClient?.hs_object_id,
			ownerId: hubspotId,
		};
	}, [existingPolicy, existingTransaction, existingClient, hubspotId]);

	const [schema, setSchema] = useState(validationSchema);

	const formik = useFormik({
		initialValues,
		validateOnChange: false,
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', values);
			const createError = (message) => {
				setFieldValue('errorMessage', message ?? 'Error creating claim');
				setFieldValue('successMessage', '');
				setSubmitting(false);
				setFormLoading(false);
			};
			const res = await createNewClaim();
			if (res?.error) {
				console.log('🙅 ~ Error creating claim', res?.error);
				createError(res?.error?.message);
				return;
			}
			const response = await createClaimFolders(
				formik.values.clientId,
				res.dealId,
				res.noteId
			);
			if (response?.error) {
				createError(response?.error?.message);
				dispatch(
					showToast({
						message: response?.error?.message ?? 'Claim created',
						action: {
							path: `${paths.claims}/${res.dealId}`,
							label: 'GO TO CLAIM',
						},
						autohide: false,
					})
				);
				return;
			}
			console.log('🚀 ~ Successfully created new claim', res);

			dispatch(
				showToast({
					message: 'New claim created!',
					action: {
						path: `${paths.claims}/${res.dealId}`,
						label: 'GO TO CLAIM',
					},
					autohide: false,
				})
			);
			window.scrollTo(0, 0);
			window.history.replaceState({}, '');
			resetForm();
			setFieldValue('successMessage', 'Successfully created claim');
			setFieldValue('errorMessage', '');
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const { data: ownerData } = useGetOwnerQuery(
		{ ownerId: formik.values.clientOwner },
		{ skip: !formik.values.clientOwner }
	);

	const { data: ownerDetails } = useGetUserDetailsQuery(
		{ hubspotId: ownerData?.id },
		{ skip: !ownerData }
	);

	const { setUpDealFilesAndFolders } = useClientStorageHooks(ownerDetails ?? userQuery.data);

	const [createClaim] = useCreateClaimMutation();

	const extractTimestamp = (dateValue) => {
		// Get the local time zone offset in minutes
		const timezoneOffset = dateValue.getTimezoneOffset();
		// Adjust the date to UTC by adding the time zone offset
		dateValue.setMinutes(dateValue.getMinutes() - timezoneOffset);
		// Set the time to midnight UTC
		const timestamp = dateValue.setUTCHours(0, 0, 0, 0);
		return timestamp;
	};

	const createNewClaim = async () => {
		const body = {
			hubspotId: formik.values.ownerId,
			dealName: formik.values[FOLDER_NAME].trim(),
			dealOverview: (formik.values[DEAL_OVERVIEW] ?? '').trim(),
			description: formik.values.describeClaim,
			clientId: formik.values.clientId,
			policyId: formik.values.policyId,
			policyTransId: formik.values.policyTransactionId,
			insurer: formik.values.insurer,
			claimType: formik.values.claimType,
			...(formik.values.priority.length > 0 && { priority: formik.values.priority }),
			...(formik.values.notes && { notes: formik.values.notes }),
			completedBy: hubspotId,
			...(formik.values.dateOfLoss && {
				dateOfLoss: extractTimestamp(formik.values.dateOfLoss),
			}),
			...(formik.values.dateReported && {
				dateReported: extractTimestamp(formik.values.dateReported),
			}),
		};

		console.log('Create Claim BODY', body);
		return await createClaim(body)
			.unwrap()
			.catch((err) => {
				console.log('🚀 ~ Error creating claim', err);
				return { error: { message: 'Error creating claim' } };
			});
	};

	const createClaimFolders = async (clientId, dealId, noteId) => {
		try {
			await setUpDealFilesAndFolders({
				dealId,
				noteId,
				clientId,
				clientName: formik.values.clientName,
				dealName: formik.values[FOLDER_NAME],
				pipelineId: process.env.REACT_APP_PIPELINE_CLAIMS,
				files: formik.values.files,
				notes: formik.values.notes,
			});
		} catch (error) {
			console.log('🚀 ~ Error creating claim folders', error);
			return {
				error: {
					message: `Claim ${dealId} created, but there was an error creating claim folder or uploading files`,
				},
			};
		}
	};

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const loading = formik.isSubmitting || formLoading;

	useEffect(() => {
		if (formik.values.existingFolders) {
			setSchema(
				validationSchema
					.omit([FOLDER_NAME])
					.concat(folderValidationSchema(formik.values.existingFolders, 'Deal name'))
			);
		}
	}, [formik.values.existingFolders]);

	return (
		<Container sx={{ pt: 3 }}>
			<Button
				variant='text'
				onClick={() => navigate(paths.claims)}
				sx={{ color: 'black', fontWeight: 'bold', mb: '1em' }}
				startIcon={<KeyboardBackspaceOutlinedIcon />}
			>
				{'Back to Claims'}
			</Button>
			<form onSubmit={formik.handleSubmit}>
				<CardHeader
					disableTypography
					title={
						<PageHeader
							title={'Report a Claim'}
							icon={getPipelineIcon(process.env.REACT_APP_PIPELINE_CLAIMS)}
						/>
					}
				/>
				<CardContent>
					{/* ///TODO: add link when available */}
					{/* <Typography variant="body1" paragraph>
              View our user guide on the steps to process a claim{' '}
							<Link>here</Link>
						</Typography> */}
					{userQuery.isLoading || userQuery.isUninitialized ? (
						<CircularProgress />
					) : (
						<Grid container spacing={4}>
							<DealNaming {...{ formik, disabled: loading, dealName: 'Claim name' }} />
							{/* <Grid size={12}>
								<FormLabel required>{'Claim name'}</FormLabel>
								<TextField
									fullWidth
									{...formik.getFieldProps(DEAL_NAME)}
									error={getError(DEAL_NAME)}
									helperText={getErrorMessage(DEAL_NAME)}
									disabled={loading}
									required
								/>
							</Grid>
							<Grid size={12}>
								<FolderNameField {...{ formik, disabled: loading }} />
							</Grid> */}
							<Grid size={'grow'}>
								<FormLabel required>{'Search client name'}</FormLabel>
								<InstantClientSelect
									clientId={formik.values.clientId}
									clientName={formik.values.clientName}
									filterProspects={true}
									setClient={(client) => {
										console.log('client', client);
										formik.setValues({
											...formik.values,
											clientId: client?.id,
											clientName: client?.name,
											clientOwner: client?.ownerId,
											policy: {},
											policyId: null,
											transaction: {},
											policyTransactionId: null,
										});
									}}
									disabled={loading || isExistingClient}
								/>
								<FormHelperText error={getError('clientId')}>
									{getErrorMessage('clientId')}
								</FormHelperText>
							</Grid>
							<Grid size={4}>
								<FormLabel>{'Assign to'}</FormLabel>
								<OwnerSelect
									initialId={formik.values.ownerId}
									disabled={formik.isSubmitting}
									onChange={(value) => {
										formik.setFieldValue('ownerId', value);
									}}
								/>
							</Grid>
							<Grid size={12}>
								<FormLabel required sx={{ display: 'flex' }}>
									{'Policy'}
								</FormLabel>
								<PolicySelect
									clientId={formik.values.clientId}
									policy={formik.values.policy}
									setPolicy={(newPolicy) => {
										formik.setValues({
											...formik.values,
											policy: newPolicy,
											policyId: newPolicy?.Id,
											transaction: {},
											policyTransactionId: null,
										});
									}}
									disabled={existingPolicy != null || loading}
								/>
								{!formik.values.clientId && (
									<FormHelperText>
										{'Please select a client to view policies'}
									</FormHelperText>
								)}
								<FormHelperText error={getError('policyId')}>
									{getErrorMessage('policyId')}
								</FormHelperText>
							</Grid>
							<Grid size={12}>
								<FormLabel required sx={{ display: 'flex' }}>
									{'Policy Transaction'}
								</FormLabel>
								<PolicyTransactionSelect
									policyId={formik.values.policyId}
									policyTransaction={formik.values.transaction}
									setPolicyTrans={(newPolicy) => {
										formik.setValues({
											...formik.values,
											policyTransactionId: newPolicy?.Id,
											transaction: newPolicy,
											insurer: newPolicy?.InsurerName,
										});
									}}
									disabled={Object.keys(existingTransaction ?? {}) > 0 || loading}
								/>
								{!formik.values.policyId && (
									<FormHelperText>
										{'Please select a policy to view policy transactions'}
									</FormHelperText>
								)}
								<FormHelperText error={getError('policyTransactionId')}>
									{getErrorMessage('policyTransactionId')}
								</FormHelperText>
							</Grid>
							<Grid size={{ xs: 12, md: 6 }}>
								<ClaimTypeSelect
									required={true}
									disabled={formik.isSubmitting}
									claimType={formik.values.claimType}
									setClaimType={(value) =>
										formik.setFieldValue('claimType', value)
									}
								/>
							</Grid>
							<Grid size={{ xs: 12, md: 6 }}>
								<FormLabel>{'Insurer'}</FormLabel>
								<TextField
									fullWidth
									{...formik.getFieldProps('insurer')}
									disabled={true}
									value={formik.values.insurer}
								/>
							</Grid>
							<Grid size={{ xs: 12, md: 6 }}>
								<FormLabel required>{'Date of loss'}</FormLabel>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										name='dateOfLoss'
										sx={{ width: '100%' }}
										disabled={
											!formik.values.policyId ||
											!formik.values.policyTransactionId ||
											formik.isSubmitting
										}
										format='dd/MM/yyyy'
										disableFuture={true}
										value={formik.values.dateOfLoss}
										{...formik.getFieldProps('dateOfLoss')}
										onChange={(value) => {
											formik.setFieldValue('dateOfLoss', value);
										}}
									/>
								</LocalizationProvider>
								<FormHelperText error={getError('dateOfLoss')}>
									{getErrorMessage('dateOfLoss')}
								</FormHelperText>
							</Grid>
							<Grid size={{ xs: 12, md: 6 }}>
								<FormLabel required>{'Date Reported'}</FormLabel>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										name='dateReported'
										sx={{ width: '100%' }}
										disabled={formik.isSubmitting}
										format='dd/MM/yyyy'
										disableFuture={true}
										value={formik.values.dateReported}
										{...formik.getFieldProps('dateReported')}
										onChange={(value) => {
											formik.setFieldValue('dateReported', value);
										}}
									/>
								</LocalizationProvider>
								<FormHelperText error={getError('dateReported')}>
									{getErrorMessage('dateReported')}
								</FormHelperText>
							</Grid>
							<Grid size={{ xs: 12, md: 6 }}>
								<PrioritySelect
									disabled={formik.isSubmitting}
									priority={formik.values.priority}
									setPriority={(value) => formik.setFieldValue('priority', value)}
								/>
							</Grid>
							<Grid size={{ xs: 12, md: 6 }}>
								<FormLabel required>
									{'Brief description of the incident'}
								</FormLabel>
								<TextField
									fullWidth
									id='describeClaim'
									{...formik.getFieldProps('describeClaim')}
									disabled={loading}
									error={getError('describeClaim')}
									// helperText={getErrorMessage('describeClaim')}
									inputProps={{ maxLength: 100 }}
								/>
								<Grid container>
									<Grid size={10}>
										<FormHelperText error={getError('describeClaim')}>
											{getErrorMessage('describeClaim')}
										</FormHelperText>
									</Grid>
									<Grid size={2}>
										<FormHelperText
											sx={{
												textAlign: 'right',
												mr: '14px',
												...(formik.values.describeClaim.length == 100 && {
													fontWeight: 'bold',
												}),
											}}
										>
											{`${formik.values.describeClaim.length}/100`}
										</FormHelperText>
									</Grid>
								</Grid>
							</Grid>
							<Grid size={12}>
								<FormLabel>{'Additional Notes'}</FormLabel>
								<TextField
									fullWidth
									id='notes'
									multiline
									rows={4}
									{...formik.getFieldProps('notes')}
									disabled={loading}
								/>
							</Grid>
							<Grid size={12}>
								<FileUpload
									files={formik.values.files}
									setFiles={(files) =>
										formik.handleChange({
											target: {
												name: 'files',
												value: files,
											},
										})
									}
									loading={loading}
								/>
							</Grid>
							{formik.values.errorMessage.length > 0 && (
								<FormHelperText sx={{ padding: '1em' }} error>
									{formik.values.errorMessage}
								</FormHelperText>
							)}
							{formik.values.successMessage.length > 0 && (
								<FormHelperText sx={{ color: 'green', padding: '1em' }}>
									{formik.values.successMessage}
								</FormHelperText>
							)}
						</Grid>
					)}
				</CardContent>
				<Divider />
				<CardActions
					sx={{
						justifyContent: 'center',
						p: 2,
					}}
				>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={userQuery.isLoading || userQuery.isUninitialized || formLoading}
					>
						{formik.isSubmitting ? (
							<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
						) : (
							'Submit'
						)}
					</Button>
				</CardActions>
			</form>
		</Container>
	);
};

export default CreateClaimForm;