import { FOLDER_NAME, default as folderValidationSchema } from '@/components/dialogs/AddFolderSharepointDialog/validationSchema';
import * as Yup from 'yup';
import { CLIENT_ID, DEAL_NAME, FILES } from '../CreateNewBusinessForm/validationSchema';

export const validationSchema = Yup.object().shape({
	[CLIENT_ID]: Yup.string().required('Client is required.'),
	// [DEAL_NAME]: Yup.string().required('Deal name is required.'),
    // [FOLDER_NAME]: Yup.string().required('Deal name is required.'),
	[FILES]: Yup.array(),
	notes: Yup.string(),
	priority: Yup.string().nullable(true),
	// policyTransactionId: Yup.number().nullable(false).required('Please select a policy Transaction'),
	policyId: Yup.number().required('Please select a policy').min(0, 'Please select a policy'),
}).concat(folderValidationSchema([], 'Deal name'));