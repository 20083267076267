/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useEffect, useState } from 'react';
import {
	TextField,
	FormHelperText,
	FormLabel,
	Tooltip,
	Grid,
	FormControlLabel,
	Checkbox,
	Typography,
	Chip,
} from '@mui/material';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import {
	useUpdateClientMutation,
	useUpdateInsightClientMutation,
} from '@/features/clients/clientsApi';
import {
	useUpdateClientContactRelationshipMutation,
	useUpdateContactMutation,
} from '@/features/contacts/contactsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { inputProps, createValidationSchema, clientHeaders, contactHeaders } from './clientDetailData';
import { IndustrySelect } from './industrySelect';
import { DrawNumeric } from './drawNumeric';
import { ResetButton } from './resetButton';
import { determineContext } from '@/hooks/determineContext';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { setData } from '@/features/menuSlice/menuSlice';

export const ClientDetailField = ({
	type,
	header,
	title,
	readOnly,
	isLoading,
	contact: propContact,
	disabled = false,
	handleOpenDialog = null,
}) => {
	const { client, contact: contextContact, contacts } = determineContext();
	const contact = propContact || contextContact;
	const dispatch = useDispatch();
	const { data: contactsFeature } = useGetFeatureFlagQuery({
		feature: 'contacts',
	});
	const [updateClient, clientState] = useUpdateClientMutation();
	const [updateClientRelationship, clientRelationshipState] =
		useUpdateClientContactRelationshipMutation();
	const [updateInsightClient, clientInsightState] =
		useUpdateInsightClientMutation();
	const [updateContact, contactState] = useUpdateContactMutation();

	const loading =
		clientState.isLoading ||
		contactState.isLoading ||
		clientInsightState.isLoading ||
		clientRelationshipState.isLoading ||
		isLoading;

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{ skip: !email }
	);
	const hubspotId = userDetails?.hubspotId;

	const isMainContact = useMemo(() => {
		return contact?.isMainContact || false;
	}, [contact, contacts]);

	const brokerGroupId = userDetails.brokerGroupArray ?? userDetails?.brokerId;

	const handleReset = (event) => {
		event.preventDefault();
		event.stopPropagation();
		formik.resetForm();
	};

	const handleBlur = (event) => {
		if (!readOnly) {
			formik.handleSubmit(event);
		}
	};

	// const handleRemoveContactAssociation = async (relationshipDocId, docId) => {
	// 	console.log('Removing contact:', relationshipDocId, docId);
	// 	try {
	// 		await updateClientRelationship({
	// 			clientRelationshipId: relationshipDocId,
	// 			clientDocId: docId,
	// 			property: { delete: true },
	// 			clientId: client?.hs_object_id,
	// 			hubspotId: userDetails?.hubspotId,
	// 			brokerGroupId: brokerGroupId,
	// 		}).unwrap();
	// 	} catch (error) {
	// 		console.error('Error removing contact association:', error);
	// 	}
	// };

	const handleRemoveContactAssociation = () => {
		if (handleOpenDialog && contact?.relationshipDocId && contact?.id) {
			handleOpenDialog(contact.relationshipDocId, contact.id, contact.isMainContact);
		}
	};

	const handleUpdateClient = async (value) => {
		const properties = { [header]: value };
		console.log('Updating client:', properties);
	
		try {
			if (type === 'contact') {
				if (contact) {
					if (header === 'isMainContact' && value === true) {
						const currentlySelected = contacts.filter((c) => c.isMainContact);
						if (
							currentlySelected.length === 1 &&
							currentlySelected[0].id === contact?.id
						) {
							return;
						}
						await updateClientRelationship({
							clientRelationshipId: contact?.relationshipDocId,
							property: { isMainContact: value },
							clientDocId: client?.clientRow?.docId,
							clientId: client?.hs_object_id,
						}).unwrap();
					}
					if (header === 'isMainContact' || header === 'role') {
						await updateClientRelationship({
							clientRelationshipId: contact?.relationshipDocId,
							property: { [header]: value },
							clientDocId: client?.clientRow?.docId,
							clientId: client?.hs_object_id,
						}).unwrap();
					}
					if (contact?.hs_object_id || contact?.id) {
						await updateContact({
							contactId: contact?.hs_object_id,
							properties: { [header]: value },
							docId: contact?.id,
							brokerGroupId: brokerGroupId,
							hubspotId: userDetails?.hubspotId,
							clientId: client?.hs_object_id,
						}).unwrap().then(() => {
						}).catch((err) => {
							if (err?.data?.message) {
								formik.setFieldError(header, err.data.message);
							}
						});
					}
				}
			} else {
				if (!['Postcode', 'Suburb'].includes(header)) {
					await updateClient({
						clientId: client.hs_object_id,
						properties,
					}).unwrap();
				}
				if (['city', 'address', 'Postcode', 'Suburb'].includes(header)) {
					const updatedKey =
						header === 'address'
							? 'AddressLine1'
							: header.charAt(0).toUpperCase() + header.slice(1);
					await updateInsightClient({
						properties: { [updatedKey]: value },
						ownerId: hubspotId,
						clientId: client?.id ?? client?.hs_object_id,
						insightId: client?.insight?.Id,
					}).unwrap();
				}
			}
		} catch (error) {
			console.error(`Error updating ${type}:`, error);
		}
	};

	const validationSchema = useMemo(
		() => createValidationSchema([header]),
		[header]
	);

	const formik = useFormik({
		initialValues: {
			[header]:
				type === 'contact'
					? contact?.[header]
					: client?.[header] ?? client?.insight?.[header],
		},
		validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: async (values, { setSubmitting }) => {
			const value = values[header]?.trim() || null;
			const initialValue =
				type === 'contact'
					? contact?.[header]
					: client?.[header] ?? client?.insight?.[header];

			if (
				formik.touched[header] &&
				!formik.errors[header] &&
				value !== initialValue
			) {
				await handleUpdateClient(value);
			}
			setSubmitting(false);
		},
	});

	useEffect(() => {
		formik.setValues({
			[header]:
				type === 'contact'
					? contact?.[header]
					: client?.[header] ?? client?.insight?.[header],
		});
	}, [client, contact, header, type]);

	const setFloatValue = ({ floatValue }) =>
		formik.setFieldValue(header, floatValue);

	const drawComponent = () => {
		switch (header) {
		case 'industry':
			return (
				<IndustrySelect
					initialValue={client.industry}
					disabled={loading || formik.isSubmitting || disabled}
					onChange={handleUpdateClient}
				/>
			);
		case 'numberofemployees':
		case 'annualrevenue':
			return (
				<DrawNumeric
					prefix={header === 'annualrevenue' ? '$' : undefined}
					error={formik.touched[header] && Boolean(formik.errors[header])}
					helperText={formik.touched[header] && formik.errors[header]}
					isLoading={formik.isSubmitting || loading}
					clickHandler={handleReset}
					onBlur={handleBlur}
					onValueChange={setFloatValue}
					value={formik.values[header] ?? 0}
				/>
			);
		case 'phone':
			return (
				<NumericFormat
					customInput={TextField}
					fullWidth
					decimalScale={0}
					allowNegative={false}
					allowLeadingZeros
					onValueChange={({ value }) => formik.setFieldValue(header, value)}
					{...formik.getFieldProps(header)}
					required
					disabled={loading || disabled}
					name="phone"
					error={formik.touched[header] && Boolean(formik.errors[header])}
					helperText={formik.touched[header] && formik.errors[header]}
					onBlur={handleBlur}
					InputProps={{
						endAdornment: (
							<ResetButton
								isLoading={loading || formik.isSubmitting}
								onMouseDown={handleReset}
							/>
						),
					}}
				/>
			);
		case 'role':
			return (
				contactsFeature &&
					contacts?.length > 0 && (
					<>
						<FormLabel>Role</FormLabel>
						<TextField
							{...formik.getFieldProps(header)}
							fullWidth
							value={formik.values[header] ?? ''}
							error={formik.touched[header] && Boolean(formik.errors[header])}
							helperText={formik.touched[header] && formik.errors[header]}
							onBlur={handleBlur}
							disabled={loading || disabled}
							variant="outlined"
						/>
					</>
				)
			);
		case 'isMainContact':
			return contactsFeature && contacts?.length > 0 ? (
				<>
					<FormControlLabel
						control={
							<Checkbox
								checked={isMainContact}
								onChange={async (event) => {
									const newValue = event.target.checked;
									if (contacts) {
										const currentlySelected = contacts.filter((c) => c.isMainContact);
										if (
											!newValue &&
												currentlySelected.length === 1 &&
												currentlySelected[0].id === contact?.id
										) {
											return;
										}
										await handleUpdateClient(newValue);
									}
								}}
								disabled={loading || disabled}
							/>
						}
						label="Primary contact"
					/>
					{contacts.length > 1 && contactsFeature && (
						<Chip
							label="Remove"
							// onClick={() =>
							// 	handleRemoveContactAssociation(
							// 		contact?.relationshipDocId,
							// 		contact?.id
							// 	)
							// }
							onClick={handleRemoveContactAssociation}
							sx={{
								backgroundColor: 'white',
								color: 'red',
								border: '1px solid red',
								borderRadius: '12px',
								textTransform: 'uppercase',
								fontWeight: 'bold',
								fontSize: '0.75rem',
								px: 2,
								py: 1,
								'&:hover': {
									backgroundColor: 'red',
									color: 'white',
								},
							}}
						/>
					)}
				</>
			) : null;
		default:
			return (
				<Tooltip
					title={['email', 'name'].includes(header) && readOnly ? 'Currently uneditable' : ''}
					placement="top"
			  >
					<TextField
				  {...formik.getFieldProps(header)}
				  fullWidth
				  InputProps={{ readOnly }}
				  value={formik.values[header] ?? ''}
				  error={formik.touched[header] && Boolean(formik.errors[header])}
				  helperText={formik.touched[header] && formik.errors[header]}
				  onBlur={handleBlur}
				  disabled={
							(disabled || loading) && ['name', 'email', 'firstName', 'lastName'].includes(header)
				  }
					/>
			  </Tooltip>
			);
		}
	};
	

	return (
		<>

			<FormLabel>
				{type === 'client'
					? clientHeaders[header] !== 'Address'
						? clientHeaders[header]
						: null
					: contactHeaders[header] !== 'role' && contactHeaders[header] !== 'Main contact'
						? header === 'firstName' || header === 'firstname'
							? 'First name'
							: header === 'lastName' || header === 'lastname'
								? 'Last name'
								: contactHeaders[header]
						: null
				}
			</FormLabel>

			{drawComponent()}
		</>
	);
};