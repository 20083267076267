/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState, useEffect } from 'react';
import {
	Box,
	Drawer,
	Typography,
	List,
	Divider,
	Avatar,
	CircularProgress,
	Stack,
	IconButton,
	Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setClose, setData } from '@/features/menuSlice/menuSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { paths } from '@/app/routes';
import { useNavigate } from 'react-router-dom';
import { folioBlue, getUrlFromPipeline } from '@/utils/constants';
import Grid from '@mui/material/Grid2';
import { contactHeaders } from '@/components/ClientDetailEntry/clientDetailData';
import { ClientDetailField } from '@/components/ClientDetailEntry/clientDetailEntry';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetContactPropertiesQuery, useRetrieveAllContactAssociationsQuery } from '@/features/contacts/contactsApi';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const ContactsMenu = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { open, data, width } = useSelector((state) => state.menuDrawerSlice);
	const [isEditMode, setIsEditMode] = useState(false);
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userData = useGetUserDetailsQuery({ email }, { skip: !email });
	const ownerId = useMemo(() => userData.data?.hubspotId, [userData.data?.hubspotId]);
	
	const brokerGroupId = userData.data?.brokerGroupArray ?? userData?.data?.brokerId;

	const contactId = useMemo(() => {
		return data?.contactDocId;
	}, [data?.contactDocId]);

	const contactsQuery = useRetrieveAllContactAssociationsQuery(
		{
			brokerGroupId: brokerGroupId,
			ownerId: data?.ownerId,
			contactDocId: contactId, 
			hubspotId: ownerId,
		},
		{ skip: !contactId || !brokerGroupId } 
	);

	const getContactsQuery = useGetContactPropertiesQuery(
		{
			brokerGroupId: brokerGroupId,
			email: data?.email,
			hubspotId: ownerId,
		},
		{ skip: !data?.email || !brokerGroupId || !contactsQuery.isError }
	);

	const toggleDrawer = () => {
		setIsEditMode(false);
		dispatch(setClose());
		dispatch(setData({}));
	};

	const { data: associations, isLoading, isError } = contactsQuery.isError ? getContactsQuery : contactsQuery;

	const contactData = useMemo(() => {
		if (!associations) return null;

		const newContactData = {
			...(contactsQuery.isError ? associations?.properties : associations?.contactData),
			firstname: associations?.contactData?.firstName ?? associations?.properties?.firstname,
			lastname: associations?.contactData?.lastName ?? associations?.properties?.lastname,
			isHubspotContact: !!associations?.properties?.hs_object_id,
		};

		if (newContactData?.id !== data?.contactDocId) {
			return null;
		}
		return newContactData;
	}, [associations, contactsQuery.isError, data?.contactDocId]);

	const clients = associations?.clients || [];
	const deals = associations?.deals || [];
	
 	const showLoading = isLoading || (!data?.contactDocId && !contactData);

	return (
		<div>
			<Drawer
				key={data} 
				open={open}
				onClose={toggleDrawer}
				anchor="right"
				variant="temporary"
				sx={{
					width: width,
					'& .MuiDrawer-paper': {
						width: width,
						boxSizing: 'border-box',
						boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
					},
				}}
			>
				<Box sx={{ p: 2 }}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconButton onClick={toggleDrawer}>
							<ChevronRightIcon />
						</IconButton>
						<Typography variant="details_header">Contact Details</Typography>
					</Stack>

					{showLoading && ( 
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '200px',
							}}
						>
							<CircularProgress />
						</Box>
					)}

					{isError && ( 
						<Typography variant="body1" color="error">
              Error retrieving contact information.
						</Typography>
					)}

					{!showLoading && !isError && contactData && (
						<>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 2,
									mb: 3,
									p: 2,
									borderRadius: 2,
									boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
									backgroundColor: '#f9f9f9',
								}}
							>
								<Avatar sx={{ width: 48, height: 48 }}>
									<AccountCircleIcon sx={{ fontSize: 32, color: 'white' }} />
								</Avatar>
								<Box>
									<Typography variant="h6" fontWeight="bold">
										{contactData?.firstName ?? contactData?.firstname}{' '}
										{contactData?.lastName ?? contactData?.lastname}
									</Typography>
									{contactData.email && (
										<Typography variant="body2" color="textSecondary">
											{contactData.email}
										</Typography>
									)}
								</Box>
							</Box>

							<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									onClick={() => setIsEditMode(!isEditMode)}
									sx={{ color: !isEditMode ? folioBlue : 'inherit' }}
									startIcon={!isEditMode ? <EditIcon /> : <CloseIcon />}
								>
									{!isEditMode ? 'Edit' : 'Close'}
								</Button>
							</Box>

							<Box sx={{ marginBottom: '18px', padding: '12px' }}>
								{Object.entries(contactHeaders).map(([key, header]) => (
									<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
										<Grid size={12}>
											{key === 'fullname' ? (
												<Grid container columns={2} spacing={1}>
													<Grid size={1}>
														<ClientDetailField
															type="contact"
															title={header}
															header="firstName"
															value={contactData?.firstName || ''}
															contact={contactData}
															disabled={!isEditMode}
														/>
													</Grid>
													<Grid size={1}>
														<ClientDetailField
															type="contact"
															title={header}
															header="lastName"
															value={contactData?.lastName || ''}
															contact={contactData}
															disabled={!isEditMode}
														/>
													</Grid>
												</Grid>
											) : (
												<ClientDetailField
													title={header}
													type="contact"
													header={key}
													value={contactData?.[key] || ''}
													contact={contactData}
													// readOnly={key === 'email'}
													disabled={!isEditMode}
												/>
											)}
										</Grid>
									</Grid>
								))}
							</Box>

							{!contactData?.isHubspotContact && (
								<>
									<Typography variant="subtitle1" gutterBottom sx={{ ml: 2 }}>
                    Associated Clients
									</Typography>
									{clients?.length > 0 ? (
										<List sx={{ paddingLeft: 0 }}>
											{clients.map((client, index) => (
												<Box key={index} sx={{ mb: 2, ml: 2 }}>
													<Typography
														sx={{ color: folioBlue, cursor: 'pointer' }}
														onClick={() => {
															toggleDrawer();
															navigate(`${paths.clients}/${client?.hubspotId}`);
														}}
													>
														{client?.name}
													</Typography>
												</Box>
											))}
										</List>
									) : (
										<Typography variant="body2" sx={{ ml: 2 }}>
                      No clients associated with this contact.
										</Typography>
									)}
								</>
							)}
						</>
					)}
				</Box>
			</Drawer>
		</div>
	);
};

export default ContactsMenu;