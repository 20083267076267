import { useCallback } from 'react';
import { storage } from '@/utils/firebase';
import { ref, uploadBytes } from 'firebase/storage';
import { useLazyGetDealRowQuery, useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import {
	useLazyGetClientRowQuery,
	useUpdateClientRowMutation,
} from '@/features/clients/clientsApi';
import {
	folderStructure,
	getFolderFromPipeline,
	getFolderStructure,
	getPipelineName,
	replaceFolderName,
	formatDate,
	tryDecodeFilePath,
	getClientFolderStructure,
} from '@/utils/constants';
import { useUpdateBrokerMutation } from '@/features/user/userApi';
import { useMsGraph } from '@/hooks/useMsGraph'; // don't remove this import for some reason
import { generatePDF } from '@/utils/pdf';
import {
	useAddFileToSharepointMutation,
	useAddFolderToSharepointMutation,
	useLazyGetFoldersAndFilesQuery,
	useLazyGetSharepointDrivesQuery,
	useLazyGetSharepointListsQuery,
	useLazySearchFoldersAndFilesQuery,
	useLazySearchSharepointSitesQuery,
	useLazyGetDriveItemQuery,
	useLazyGetDriveItemFromUrlQuery,
} from '@/features/msGraph/msGraphApi';
import { FieldValue } from 'firebase/firestore';

export const useClientStorageHooks = (userDetails) => {
	const [updateClientRow] = useUpdateClientRowMutation();
	const [updateBroker] = useUpdateBrokerMutation();
	const [updateDeal] = useUpdateDealRowMutation();
	const [triggerGetClientRow, clientRowQuery] = useLazyGetClientRowQuery();
	const [triggerGetDealRow, dealRowQuery] = useLazyGetDealRowQuery();

	const [getFoldersAndFiles] = useLazyGetFoldersAndFilesQuery();
	const [searchFoldersAndFiles] = useLazySearchFoldersAndFilesQuery();
	const [addFileToSharepoint] = useAddFileToSharepointMutation();
	const [addFolderToSharepoint] = useAddFolderToSharepointMutation();
	const [getSharepointDrives] = useLazyGetSharepointDrivesQuery();
	const [getSharepointLists] = useLazyGetSharepointListsQuery();
	const [searchSharepointSites] = useLazySearchSharepointSitesQuery();
	const [getDriveItem] = useLazyGetDriveItemQuery();
	const [getDriveItemByUrl] = useLazyGetDriveItemFromUrlQuery();

	const isValidSharepoint =
		userDetails?.sharepointSite != null &&
		userDetails?.clientSiteSuffix != null &&
		userDetails?.clientFolder != null &&
		userDetails?.clientSite != null;

	const uploadFilesToStorage = async ({
		dealId,
		noteId,
		driveId,
		dealFolder,
		pipelineId,
		files,
	}) => {
		const dealType = getPipelineName(pipelineId);
		const dealDriveId = driveId ?? (await getDealDriveId(dealId))?.driveId;
		console.log('[uploadFilesToStorage]', {
			dealId,
			noteId,
			driveId,
			dealDriveId,
			dealFolder,
			pipelineId,
			files,
		});
		await Promise.all(
			files.map(async (file) => {
				await addFileToSharepoint({
					driveId: userDetails?.clientFolderDriveId,
					itemId: dealDriveId,
					file,
				})
					.unwrap()
					.catch((e) => console.error('Error uploading file via Graph API', file, e));
				// await addFileToSharepointMsGraph(
				// 	userDetails?.clientFolderDriveId,
				// 	dealDriveId,
				// 	file
				// ).catch((e) => console.error('Error uploading file via Graph API', file, e));

				const filePath = `/${dealType}Files/${file.name}`;
				const storageRef = ref(storage, filePath);
				const metadata = {
					customMetadata: {
						'Deal ID': dealId.toString(),
						'Note ID': noteId.toString(),
					},
				};
				await uploadBytes(storageRef, file, metadata);
			})
		).then((r) => {
			console.log(`Uploaded ${files.length} files`, files, r);
		});
	};

	const createFoldersFromNestedObject = async (driveId, rootItemId, folderStructure) => {
		const createdFolders = {}; // To keep track of created folders by their paths

		const createFolderRecursively = async (parentItemId, currentPath, structure) => {
			for (const folderName in structure) {
				try {
					const fullPath = currentPath ? `${currentPath}/${folderName}` : folderName;

					if (Object.keys(createdFolders).includes(fullPath)) {
						continue;
					}

					// Create the folder in SharePoint
					let folder;
					if (!parentItemId || !driveId) {
						console.log('[createFolderRecursively] No parentItemId or driveId', {
							driveId,
							parentItemId,
						});
						continue;
					}
					try {
						folder = await addFolderToSharepoint({
							itemId: parentItemId,
							driveId,
							folderName,
						}).unwrap();
					} catch (err) {
						console.log('ERROR creating folder', err);
						continue;
					}
					console.log('Here is the created folder', folder);

					const itemId = folder?.id;
					createdFolders[fullPath] = folder;

					// Recursively create subfolders
					await createFolderRecursively(itemId, fullPath, structure[folderName]);
				} catch (error) {
					console.error(
						`❌ Error creating folder: ${folderName} at path: ${currentPath}`,
						error
					);
					throw error;
				}
			}
		};

		// Start the recursive folder creation from the root
		await createFolderRecursively(rootItemId, '', folderStructure);

		console.log('✅ All folders created successfully!', createdFolders);
		return createdFolders;
	};

	const getDealDriveId = async (dealId) => {
		let driveId;
		let dealRow;
		let clientFolderDriveId;
		try {
			dealRow = await triggerGetDealRow(dealId).unwrap();
			console.log('DEAL ROW DATA', dealRow);
			driveId = dealRow?.driveId;
			clientFolderDriveId = dealRow?.clientFolderDriveId;
			if (driveId && clientFolderDriveId) {
				const item = await getDriveItem({
					driveId: clientFolderDriveId,
					itemId: driveId,
				})
					.unwrap()
					.catch((e) => console.log('[getDealDriveId] Error getting drive item', e));
				if (!item?.folder) {
					driveId = null;
					clientFolderDriveId = null;
					await updateDeal({
						dealId,
						properties: {
							driveId: FieldValue.delete(),
							// clientFolderDriveId: FieldValue.delete(),
						},
					})
						.unwrap()
						.catch((e) => console.log('[getDealDriveId] error updating deal', e));
				}
			}
		} catch (e) {
			console.log('Error getting deal row', e);
		}

		if (!driveId || !clientFolderDriveId) {
			if (!driveId) {
				const { clientRow, clientFolderDriveId: folderDriveId } = await getClientDriveId(
					dealRow?.clientHubspotId
				);
				try {
					const drive = await getDealFolderDrive(
						dealRow,
						clientRow?.driveId,
						folderDriveId
					);
					console.log('[getDealDriveId] DRIVE', drive);
					driveId = drive?.id;
					clientFolderDriveId = drive?.parentReference?.driveId;
				} catch (e) {
					console.log('Error getting deal drive ID', e);
				}

				clientFolderDriveId = folderDriveId;

				if (!driveId) {
					const year = dealRow?.yearFolder;
					const years = (dealRow?.yearRoot ?? '').split('/');
					const yearFolder = year ?? years[years.length - 1] ?? '';

					const { driveId: dealDriveId } = await createDealFolders({
						dealId,
						dealName: dealRow?.dealName?.trim(),
						clientId: clientRow?.hubspotId,
						clientName: clientRow?.name,
						pipelineId: dealRow?.pipeline,
						yearFolder,
						// ...(dealRow?.yearRoot ? { yearFolder } : { date }),
					});
					driveId = dealDriveId;
				}
			}
		}
		return { dealRow, driveId, clientFolderDriveId };
	};

	const getClientDriveId = async (clientId) => {
		let driveId;
		let clientFolderDriveId;
		let clientRow;
		console.log('getClientDriveId', { clientId });
		try {
			clientRow = await triggerGetClientRow(clientId).unwrap();
			console.log('CLIENT ROW DATA', { clientRow, driveId: clientRow?.driveId });
			driveId = clientRow?.driveId;
			clientFolderDriveId = clientRow?.clientFolderDriveId;
			if (driveId && clientFolderDriveId) {
				const item = await getDriveItem({
					driveId: clientFolderDriveId,
					itemId: driveId,
				})
					.unwrap()
					.catch((e) => console.log('[getClientDriveId] Error getting drive item', e));
				if (!item?.folder) {
					driveId = null;
					clientFolderDriveId = null;
					await updateClientRow({
						clientId: clientRow?.hubspotId,
						docId: clientRow?.docId,
						properties: {
							driveId: FieldValue.delete(),
							// clientFolderDriveId: FieldValue.delete(),
						},
					}).unwrap();
				}
			}
		} catch (e) {
			console.log('Error getting client row', e);
		}

		if (!driveId || !clientFolderDriveId) {
			try {
				const drive = await getFolderDrive(clientRow, userDetails?.clientFolderDriveId);
				driveId = drive?.id;
				clientFolderDriveId = drive?.parentReference?.driveId;
			} catch (e) {
				console.log('Error getting client drive ID', e, clientRow);
			}
			if (!driveId && userDetails?.clientFolderDriveId) {
				const item = await addFolderToSharepoint({
					driveId: userDetails?.clientFolderDriveId,
					itemId: 'root',
					folderName: replaceFolderName(clientRow?.name),
				}).unwrap();
				console.log('[getClientDriveId] ITEM', item);
				clientFolderDriveId = userDetails?.clientFolderDriveId;
				driveId = item?.id;
				if (driveId) {
					await updateClientRow({
						clientId: clientRow?.hubspotId,
						docId: clientRow?.docId,
						properties: { driveId, clientFolderDriveId },
					}).unwrap();
				}
			}
		}

		if (driveId && clientFolderDriveId) {
			const { folders } = await getFoldersAndFiles({
				itemId: driveId,
				driveId: clientFolderDriveId,
			})
				.unwrap()
				.catch((e) => {
					console.log('Error getting client folders', e);
					return { folders: [] };
				});

			console.log('[getClientDriveId] FOLDERS', folders);

			const foldersToCreate = getClientFolderStructure(clientRow?.name, new Date());
			const existingFolderNames = folders.map((f) => f.name);
			const foldersToCreateKeys = Object.keys(foldersToCreate);
			const shouldCreate = foldersToCreateKeys.some(
				(folder) => !existingFolderNames.includes(folder)
			);

			if (folders.length === 0 || shouldCreate) {
				console.log('FOLDERS TO CREATE', foldersToCreate);
				const folderItems = await createFoldersFromNestedObject(
					clientFolderDriveId,
					driveId,
					foldersToCreate
				).catch((e) => console.log('Error creating folders that should exist', e));
			}
		}

		return { clientRow, driveId, clientFolderDriveId };
	};

	const createDealFolders = async ({
		dealId,
		dealName,
		clientId,
		clientName,
		pipelineId,
		date,
		yearFolder,
	}) => {
		const pipelineFolder = getFolderFromPipeline(pipelineId);
		const trimmedDealName = replaceFolderName(dealName.trim());

		const replacedCompanyName = replaceFolderName(clientName);
		console.log('get client folders year folder: ', yearFolder);

		const { folders, dealFolder, clientSubFolders } = getFolderStructure({
			clientName: replacedCompanyName,
			dealName: trimmedDealName,
			// dealId,
			pipelineFolder,
			date,
			yearFolder,
		});

		if (isValidSharepoint) {
			console.log('FOLDERS TO CREATE', clientSubFolders, folders);

			const { driveId, clientRow, clientFolderDriveId } = await getClientDriveId(clientId);

			const folderItems = await createFoldersFromNestedObject(
				clientFolderDriveId,
				driveId,
				clientSubFolders
			);

			const dealKey = Object.keys(folderItems).find((f) => f.endsWith(trimmedDealName));
			const dealNotesKey = Object.keys(folderItems).find((f) =>
				f.endsWith(`${trimmedDealName}/${folderStructure.notes}`)
			);
			const clientNotesKey = Object.keys(folderItems).find((f) =>
				f.includes(decodeURI(folderStructure.clientNotes.normalize()))
			);

			const dealDriveId = folderItems[dealKey]?.id;
			const dealNotesDriveId = folderItems[dealNotesKey]?.id;
			const clientNotesDriveId = folderItems[clientNotesKey]?.id;

			console.log('FOLDER ITEMS', {
				folderItems,
				dealDriveId,
				dealNotesDriveId,
				clientNotesDriveId,
			});

			const dealFolderUrl = folderItems[dealKey]?.webUrl; //await createFolder(dealFolder.url);

			const doc = await updateDeal({
				dealId,
				properties: {
					...(dealFolderUrl && { dealFolderUrl }),
					dealFolderPath: dealFolder.url,
					yearRoot: dealFolder.yearRoot,
					yearFolder: dealFolder.yearFolder,
					pipelineFolder: dealFolder.pipelineFolder,
					clientName,
					...(dealDriveId && { driveId: dealDriveId }),
					...(dealNotesDriveId && { notesDriveId: dealNotesDriveId }),
					...(clientFolderDriveId && { clientFolderDriveId }),
				},
			}).unwrap();

			if (clientNotesDriveId && !clientRow?.notesDriveId) {
				await updateClientRow({
					clientId,
					docId: clientRow?.docId,
					properties: { notesDriveId: clientNotesDriveId },
				})
					.unwrap()
					.catch((e) => console.log('Error updating client row', e));
			}

			return {
				success: 'Created client folders',
				dealFolder,
				dealFolderUrl,
				documentId: doc.id,
				driveId: dealDriveId,
				notesDriveId: dealNotesDriveId,
			};
		} else {
			console.log('Could not create client folders, invalid Sharepoint site', userDetails);
			return {
				error: 'Could not create client folders, invalid Sharepoint site',
				userDetails,
			};
		}
	};

	const setUpDealFilesAndFolders = async ({
		dealId,
		noteId,
		clientId,
		clientName,
		dealName,
		pipelineId,
		files,
		date,
		yearFolder,
		notes,
	}) => {
		console.log('[setUpDealFilesAndFolders] params ', {
			dealId,
			noteId,
			clientId,
			clientName,
			dealName,
			pipelineId,
			files,
			date,
			yearFolder,
			notes,
		});
		const { success, dealFolder, dealFolderUrl, documentId, driveId, notesDriveId } =
			await createDealFolders({
				dealId,
				dealName: dealName.trim(),
				clientId,
				clientName,
				pipelineId,
				date,
				yearFolder,
			});
		console.log('[setUpDealFilesAndFolders] createDealFolders response', {
			success,
			dealFolder,
			dealFolderUrl,
			documentId,
			driveId,
			notesDriveId,
		});
		if ((files ?? []).length > 0) {
			await uploadFilesToStorage({ dealId, noteId, driveId, dealFolder, pipelineId, files });
		}
		if ((notes ?? '').trim().length > 0) {
			try {
				const date = new Date();
				const file = generatePDF(
					notes,
					`Created Note - ${formatDate(date, true).replace(':', '.')}`
				);

				console.log('Creating note', { file, notesDriveId });
				try {
					let noteDriveId = notesDriveId;
					if (!noteDriveId) {
						const path = folderStructure.notes;
						console.log(
							'Path for notes',
							path,
							driveId,
							userDetails?.clientFolderDriveId
						);
						const { folders: drives } = await searchFoldersAndFiles({
							itemId: driveId,
							driveId: userDetails?.clientFolderDriveId,
							searchText: decodeURI(folderStructure.notes.normalize()),
							exact: true,
						})
							.unwrap()
							.catch((e) => {
								console.log('Error searching for note folder', e);
								return { folders: [] };
							});
						// const drives = await searchClientMsGraphFoldersByItemId(
						// 	userDetails?.clientFolderDriveId,
						// 	driveId,
						// 	decodeURI(folderStructure.notes.normalize())
						// );

						let drive = drives.find((d) => tryDecodeFilePath(d.name, path));
						if (!drive) {
							console.log('Could not find drive through search');

							const { folders } = await getFoldersAndFiles({
								itemId: driveId,
								driveId: userDetails?.clientFolderDriveId,
							})
								.unwrap()
								.catch((e) => {
									console.log('Error getting note folder', e);
									return { folders: [] };
								});
							// const { folders } = await getClientGraphFolderByItemId(
							// 	userDetails?.clientFolderDriveId,
							// 	driveId
							// );
							console.log('FOLDERS', noteDriveId, folders);
							drive = folders.find((d) => tryDecodeFilePath(d.name, path));
						}
						noteDriveId = drive?.id;
						console.log('[searchClientGraphFolders] notes folders', {
							drives,
							drive,
							noteDriveId,
						});
					}

					if (noteDriveId) {
						console.log('[uploadClientSharepointFile]', { noteDriveId, file });
						await addFileToSharepoint({
							driveId: userDetails?.clientFolderDriveId,
							itemId: noteDriveId,
							file,
						})
							.unwrap()
							// await addFileToSharepointMsGraph(
							// 	userDetails?.clientFolderDriveId,
							// 	noteDriveId,
							// 	file
							// )
							.catch((e) =>
								console.error(
									'Error uploading generated pdf file via Graph API',
									file,
									e
								)
							);
					}
				} catch (error) {
					console.log('🙅 ~ Error creating file', error);
				}
				// await addFileToSharepoint(`${dealFolder.url}/Notes`, file);
			} catch (error) {
				console.log('🚀 ~ Error creating note pdf', error);
			}
		}
		return { success, dealFolder, dealFolderUrl, documentId, driveId };
	};

	const createClientFolder = useCallback(
		async (dealId, contextValue) => {
			const { deal, client, objectType, yearFolder, date: dealDate } = contextValue;
			const date = dealDate ?? deal.createdAt;

			console.log('Create client folder hook');

			if (!client || !deal) {
				console.log('Could not create folder: could not locate client/deal', dealId);
				return;
			}

			const dealName =
				deal.dealName ?? deal.dealname ?? deal.description ?? deal.subject ?? deal.content;
			await setUpDealFilesAndFolders({
				dealId,
				clientName: client?.name,
				clientId: client?.id ?? client?.hs_object_id ?? client?.hubspotId,
				dealName: dealName.trim(),
				pipelineId: deal.pipeline ?? deal.hs_pipeline,
				date,
				yearFolder,
			});
		},
		[setUpDealFilesAndFolders]
	);

	const getMatchingItem = (list, name) =>
		list.find(
			(listItem) =>
				tryDecodeFilePath(listItem?.name ?? '', name) ||
				(listItem?.webUrl ?? '').toLowerCase().includes(name.toLowerCase())
		);

	const getSite = useCallback(
		async (query) => {
			const siteId = await searchSharepointSites({
				searchText: query[query.length - 1],
			})
				.unwrap()
				.catch((e) =>
					console.log(
						`Error searching sharepoint sites by query ${query[query.length - 1]}`,
						e
					)
				);
			console.log('SITE ID', siteId);
			if (siteId) {
				await updateBroker({
					docId: userDetails?.brokerId,
					properties: { sharepointSiteId: siteId },
				}).unwrap();
			}
		},
		[userDetails]
	);

	const getSiteItems = useCallback(
		async (siteId) => {
			const drives = await getSharepointDrives({ siteId })
				.unwrap()
				.catch((e) => console.log('Error getting all sharepoint drives', e));
			console.log(`[getSharepointDrives] ${siteId}`, drives);
			// const drive = getMatchingItem(drives, userDetails?.clientFolder);
			// console.log(
			// 	'DRIVES',
			// 	drives,
			// 	'DRIVE',
			// 	drive,
			// 	'userDetails?.clientFolder',
			// 	userDetails?.clientFolder
			// );

			// const lists = await getSharepointLists({ siteId }).unwrap();
			// const list = getMatchingItem(lists, userDetails?.clientFolder);
		},
		[userDetails]
	);

	const getSiteFiles = useCallback(
		async (siteId) => {
			console.log('GET SITE FILES', siteId);
			const drives = await getSharepointDrives({ siteId }).unwrap();
			const drive = getMatchingItem(drives, userDetails?.clientFolder);
			console.log(
				'DRIVES',
				drives,
				'DRIVE',
				drive,
				'userDetails?.clientFolder',
				userDetails?.clientFolder
			);

			const lists = await getSharepointLists({ siteId }).unwrap();
			const list = getMatchingItem(lists, userDetails?.clientFolder);
			console.log('LISTS', lists, 'LIST', list);
			if (drive != null || list != null) {
				await updateBroker({
					docId: userDetails?.brokerId,
					properties: {
						...(drive && { clientFolderDriveId: drive.id }),
						...(list && { clientFolderListId: list.id }),
					},
				}).unwrap();
			}
		},
		[userDetails]
	);

	const updateUserGraphProperties = useCallback(async () => {
		console.log('USER DETAILS', userDetails);
		if (userDetails) {
			const siteQuery = (userDetails?.clientSiteSuffix ?? '').split('/');
			if (siteQuery.length > 0 && !userDetails?.sharepointSiteId) {
				getSite(siteQuery);
			} else if (userDetails?.sharepointSiteId) {
				if (!userDetails?.clientFolderDriveId || !userDetails?.clientFolderListId) {
					getSiteFiles(userDetails?.sharepointSiteId);
				}
			}
		}
	}, [userDetails]);

	const getFolderDrive = async (clientRow, driveId) => {
		const replacedCompanyName = replaceFolderName(clientRow?.name);
		// const filterFunction = (list) => {
		// 	return getMatchingItem(list, replacedCompanyName);
		// };
		const { folders: drives } = await searchFoldersAndFiles({
			itemId: 'root',
			driveId,
			searchText: replacedCompanyName,
			exact: true,
		})
			.unwrap()
			.catch((e) => {
				console.log('Error searching for note folder', e);
				return { folders: [] };
			});
		// const drives = await searchClientMsGraphFolders(driveId, replacedCompanyName);
		// const drives = await getClientFolderDrive(driveId, filterFunction);
		const drive = getMatchingItem(drives, replacedCompanyName);

		console.log(
			'CLIENT DRIVES',
			drives,
			'CLIENT DRIVE',
			drive,
			'FOLDER NAME',
			replacedCompanyName
		);
		if (drive && drive?.parentReference?.driveId && drive?.id) {
			const item = await getDriveItem({
				driveId: drive?.parentReference?.driveId,
				itemId: drive?.id,
			})
				.unwrap()
				.catch((e) => console.log('[getFolderDrive] Error getting drive item', e));
			console.log('[getFolderDrive] Here is the item', item);
			if (item?.folder) {
				const clientFolderDriveId = drive?.parentReference?.driveId;
				await updateClientRow({
					clientId: clientRow?.hubspotId,
					docId: clientRow?.docId,
					properties: {
						driveId: drive.id,
						...(clientFolderDriveId && { clientFolderDriveId }),
					},
				}).unwrap();
				return drive;
			}
		}
	};

	const searchForDealFolder = async (name, driveId, clientDriveId) => {
		const replacedName = replaceFolderName(name);
		const { folders: drives } = await searchFoldersAndFiles({
			itemId: clientDriveId, //'root',
			driveId,
			searchText: replacedName,
		})
			.unwrap()
			.catch((e) => {
				console.log('Error searching for deal folder', e, { name: replacedName });
				return { folders: [] };
			});
		// const drives = await searchClientMsGraphFolders(driveId, dealRow?.dealId);
		console.log(`DEAL FOLDER DRIVES from search: ${replacedName}`, drives);

		return getMatchingItem(drives, replacedName);
	};

	const getDealFolderDrive = async (dealRow, driveId, clientDriveId) => {
		// const path = (dealRow?.dealFolderPath ?? '').split('/');
		// const pathName = dealRow?.dealFolderPath
		// 	? path[path.length - 1]
		// 	: `${dealRow?.dealName} ${dealRow?.dealId}`;
		// console.log('GET DEAL FOLDER DRIVE', path, pathName);
		let drive = await getDriveItemByUrl(dealRow?.dealFolderUrl)
			.unwrap()
			.catch((e) => console.log('Error getting drive from url'));
		const name = replaceFolderName(dealRow?.dealName);
		if (!drive?.id) {
			drive = await searchForDealFolder(name, driveId, clientDriveId);
		}
		if (!drive?.id) {
			drive = await searchForDealFolder(dealRow?.dealId, driveId, clientDriveId);
		}

		console.log(`DEAL DRIVE for ${dealRow?.dealId} ${dealRow?.dealName}`, drive);
		if (drive?.parentReference?.driveId && drive?.id) {
			const clientFolderDriveId = drive?.parentReference?.driveId;
			// dealDrive = drive;
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				properties: {
					driveId: drive.id,
					...(clientFolderDriveId && { clientFolderDriveId }),
				},
			}).unwrap();
			return drive;
		}
		// else {
		// 	Promise.all(
		// 		drives
		// 			.filter((d) => (d?.folder?.childCount ?? 0) > 0)
		// 			.map(async (d) => {
		// 				if (!dealDrive) {
		// 					await getDealFolderDrive(driveId, d.id);
		// 				}
		// 			})
		// 	);
		// }
	};

	const updateClientGraphProperties = useCallback(
		async (clientRow, dealRow) => {
			console.log('USER DETAILS', userDetails);
			if (userDetails && clientRow) {
				if (
					!userDetails?.sharepointSiteId ||
					!userDetails?.clientFolderDriveId ||
					!userDetails?.clientFolderListId
				) {
					updateUserGraphProperties();
				} else {
					console.log(
						'User details',
						userDetails?.clientFolderDriveId,
						'client row',
						clientRow?.driveId,
						'dealrow',
						dealRow,
						dealRow?.driveId,
						dealRow?.clientFolderDriveId
					);
					if (userDetails?.clientFolderDriveId) {
						if (!clientRow.driveId || !clientRow.clientFolderDriveId) {
							return getClientDriveId(clientRow?.hubspotId);
						} else if (dealRow && (!dealRow.driveId || !dealRow.clientFolderDriveId)) {
							return getDealDriveId(dealRow?.dealId);
						}
					}
				}
			}
		},
		[userDetails]
	);

	return {
		createClientFolder,
		createDealFolders,
		uploadFilesToStorage,
		setUpDealFilesAndFolders,
		updateClientGraphProperties,
		// getClientGraphFolderByItemId,
		// searchClientGraphFolders,
		getClientDriveId,
		getDealDriveId,
		updateUserGraphProperties,
		getSiteItems,
	};
};
