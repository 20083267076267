/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardContent,
	CardHeader,
	TextField,
	FormLabel,
	Container,
	Typography,
	Accordion,
	AccordionSummary,
	IconButton,
	InputAdornment,
	Select,
	MenuItem,
	CardActions,
	Divider,
	CircularProgress,
	Backdrop,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Stack,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import { Delete, Download, ExpandMore } from '@mui/icons-material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Form, Formik, useFormik, useFormikContext } from 'formik';
import { determineContext } from '@/hooks/determineContext';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import {
	folioBlue,
	formatDate,
	formatKey,
	getUrlFromPipeline,
	safelyParseFloat,
	safelyParseInt,
	toCamelCase,
} from '@/utils/constants';
import { PageHeader } from '@/components/layouts/PageHeader';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { FIELDS } from './validationSchema';
import {
	COMMERCIAL_MOTOR,
	GOALS_OF_COVER_LABELS,
	MATERIAL_DAMAGE,
	NEEDS_ANALYSIS_LABELS,
	SCOPE_OF_ADVICE_LABELS_DOMESTIC,
	SCOPE_OF_ADVICE_LABELS_COMMERCIAL,
	STATUTORY_LIABILITY,
	COMMERCIAL,
	PRIVATE_VEHICLE,
	CLIENT_TYPE,
	DOMESTIC,
	HOUSE,
	BOAT,
	CONTENTS,
} from '../CreateNewBusinessForm/validationSchema';
import { factFindApi, useGenerateSOAMutation } from '@/features/factFind/factFindApi';
import { showToast } from '@/features/toast/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RecommendationTable } from './fields/RecommendationTable';
import { ResultsTable } from './fields/ResultsTable';
import { WhatHasBeenSentToMarket } from './fields/WhatHasBeenSentToMarket';
import { ChangeProductSelection } from './fields/ChangeProductSelection';
import { DetailsAccordion } from './fields/DetailsAccordion';
import { AddProductButton } from './fields/AddProductButton';
import { FormTextField } from './fields/FormTextField';
import Robot from '@/assets/images/ai-assistant.gif';
import { EndorsementsTable } from './fields/EndorsementsTable';
import { MotorVehicleTable } from './fields/MotorVehicleTable';
import { NumericFormat } from 'react-number-format';
import { MaterialDamageLocationTable } from './fields/MaterialDamageLocationTable';
import ConfirmEmailDialog from '@/components/dialogs/ConfirmEmailDialog';
import DevJSONView from '@/components/DevJSONView';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { InsurerRatingTable } from './fields/InsurerRatingTable';
import { DownloadSOAButton } from '@/components/buttons/DownloadSOAButton';
import { VehicleScheduleTable } from './fields/VehicleScheduleTable';
import { useRetrieveClaimInsurersQuery } from '@/features/claims/ticketsApi';
import PerformantTextField from '@/components/fields/PerformantTextField';
import {
	CONTENTS_INSURANCE_FSL_DEFAULT,
	HEAVY_VEHICLE_FSL_MULTIPLIER,
	HOME_INSURANCE_FSL_DEFAULT,
	HOME_INSURANCE_NHI_DEFAULT,
	LIGHT_VEHICLE_FSL_MULTIPLIER,
	MATERIAL_DAMAGE_FSL_MULTIPLIER,
	SOA_MULTIPLIER,
} from '@/utils/soa';

const calculateGstFromRow = (row) => {
	const premium = safelyParseFloat(row.premium ?? 0);
	const gst = premium / 1.15;
	const companyPremium =
		gst -
		Object.entries(row)
			.filter(([key]) => ['naturalDisaster', 'nhi', 'fsl'].includes(key))
			.reduce((acc, [key, val]) => {
				const value = safelyParseFloat(val);
				acc += value;
				return acc;
			}, 0);
	return { premium, gst: premium - gst, companyPremium };
};

const GenerateScopeOfAdvice = ({
	field,
	fieldKey,
	products,
	setProducts,
	disabled,
	formFields,
}) => {
	const formikKey = `${field}.${fieldKey}`;
	const formik = useFormikContext();

	const showProduct = useMemo(
		() => products.map((p) => p.title).includes(fieldKey),
		[products, fieldKey]
	);

	return (
		<Grid size={6} key={formikKey}>
			{showProduct ? (
				<ChangeProductSelection
					product={products.find((p) => p.title === fieldKey)}
					products={products}
					removeProduct={() =>
						setProducts((prev) => prev.filter((p) => p.title !== fieldKey))
					}
					onBlur={(title, value) => {
						const index = products.findIndex((p) => p.title === fieldKey);
						const newProducts = [...products];
						newProducts[index] = {
							title,
							value,
						};
						setProducts(newProducts);
					}}
					disabled={disabled}
				/>
			) : (
				<>
					<FormLabel>{formatKey(fieldKey)}</FormLabel>
					<Select
						disabled={Object.keys(formFields[field]).includes(fieldKey)}
						fullWidth
						{...formik.getFieldProps(formikKey)}
						value={formik.values[field][fieldKey] ?? ''}
						onChange={(e) => formik.setFieldValue(formikKey, e.target.value)}
					>
						{Object.entries(NEEDS_ANALYSIS_LABELS).map(([value, displayLabel]) => (
							<MenuItem
								value={value}
								key={`${formikKey}-needs-analysis-selection-${value}`}
							>
								{displayLabel}
							</MenuItem>
						))}
					</Select>
				</>
			)}
		</Grid>
	);
};

const GenerateRecommendationField = ({ field, fieldKey, disabled }) => {
	const formik = useFormikContext();

	return (
		<>
			<FormLabel>{`${formatKey(`${fieldKey}`)} recommendation`}</FormLabel>
			<PerformantTextField
				fullWidth
				multiline
				rows={2}
				name={`${field}.${fieldKey}`}
				{...formik.getFieldProps(`${field}.${fieldKey}`)}
				disabled={disabled}
			/>
		</>
	);
};

const GenerateStockAndOtherProperty = ({
	locations,
	addLocation,
	disabled,
	processLocationDeletion,
	processLocationRowUpdate,
	processLocationUpdate,
	setStockAndOtherProperty,
}) => {
	const field = 'whatHasBeenSentToMarket';
	const key = 'stockAndOtherProperty';
	const formikKey = `${field}.${key}`;

	const { dealRow } = determineContext();
	const isDomestic = dealRow?.[CLIENT_TYPE] == DOMESTIC;

	const formik = useFormikContext();

	useEffect(() => {
		setStockAndOtherProperty(
			safelyParseFloat(formik.values?.whatHasBeenSentToMarket?.stockAndOtherProperty)
		);
	}, [formik.values?.whatHasBeenSentToMarket?.stockAndOtherProperty]);

	return (
		<Grid size={12} key={`render-fields-${field}-${key}`}>
			<Stack spacing={2}>
				<Typography fontWeight='bold'>
					{isDomestic ? 'Home and Contents ' : 'Material Damage'}
				</Typography>
				{!isDomestic && (
					<Stack spacing={1}>
						<FormLabel>{'Stock/Other Property Anywhere In New Zealand ($)'}</FormLabel>
						<NumericFormat
							prefix={'$'}
							decimalScale={0}
							fixedDecimalScale
							thousandSeparator
							allowNegative={false}
							customInput={TextField}
							fullWidth
							value={formik.values?.whatHasBeenSentToMarket?.stockAndOtherProperty}
							onValueChange={({ floatValue }) => {
								formik.setFieldValue(formikKey, floatValue);
							}}
							disabled={disabled}
							name={formikKey}
						/>
					</Stack>
				)}
				{locations.map(({ address, rows }, index) => {
					const isLocationError =
						address.length === 0 ||
						(locations.length > 1 &&
							locations.some(
								(l, i) =>
									i !== index &&
									l.address.trim().toLowerCase() == address.trim().toLowerCase()
							));
					return (
						<Stack key={`${field}-${key}-locations-${index}`} spacing={1}>
							<FormControl fullWidth>
								<FormLabel>{`Location ${index + 1}`}</FormLabel>
								<Stack
									direction='row'
									justifyContent={'space-between'}
									alignItems={'flex-start'}
									spacing={1}
								>
									<TextField
										fullWidth
										name={`${field}-${key}-locations-${index}`}
										value={address}
										onChange={(e) =>
											processLocationUpdate(index, e.target.value)
										}
										disabled={disabled}
										placeholder={'Street address, Suburb, City, Postcode'}
										error={isLocationError}
										helperText={
											isLocationError
												? address.length === 0
													? 'Please enter location'
													: 'Location must be unique'
												: ''
										}
									/>
									{locations.length > 1 && (
										<IconButton
											size='small'
											onClick={() => processLocationDeletion(index)}
											sx={{ marginTop: '0.5em' }}
										>
											<Delete />
										</IconButton>
									)}
								</Stack>
							</FormControl>

							<MaterialDamageLocationTable
								rows={rows}
								updateRows={(newRow, oldRow) =>
									processLocationRowUpdate(index, newRow, oldRow)
								}
								disabled={disabled}
							/>
						</Stack>
					);
				})}
				<Box py={'1em'}>
					<Button
						variant='text'
						onClick={addLocation}
						sx={{
							textTransform: 'none',
						}}
						disabled={disabled || locations.some((l) => l.address.trim().length === 0)}
					>
						Add new location
					</Button>
				</Box>
			</Stack>
		</Grid>
	);
};

const SOATableEffects = ({
	products,
	formFields,
	setMotorVehicles,
	setLocations,
	setMarketRows,
	setStockAndOtherProperty,
}) => {
	const formik = useFormikContext();

	const { deal, client, dealRow, quotes, riskInfo, soas, ownerId, loading } = determineContext();

	const isDomestic = dealRow?.[CLIENT_TYPE] == DOMESTIC;
	const latestRiskInfo = useMemo(
		() => (isDomestic ? {} : riskInfo[0] ?? {}),
		[riskInfo, isDomestic]
	);

	const extractVehicles = (vehicleData) => {
		return vehicleData.reduce((acc, vehicle, index) => {
			let sumInsured = parseInt(
				(vehicle?.sumInsured ?? vehicle?.SumInsured ?? vehicle['Sum Insured'])
					.toString()
					.replace(/\D/g, '')
			);
			sumInsured = isNaN(sumInsured) ? 0 : sumInsured;
			console.log('VEHICLE BEING EXTRACTED', vehicle);
			acc.push({
				year: vehicle.year ?? '',
				make: vehicle.make ?? '',
				model: vehicle.model ?? '',
				registration: vehicle.registration ?? vehicle.vehicleId ?? '',
				coverage: vehicle.coverOption ?? vehicle.coverage ?? vehicle['Cover Option'] ?? '',
				sumInsured,
				heavy: false,
				...(isDomestic && { lossOfUse: '' }),
				id: index,
			});
			return acc;
		}, []);
	};

	const extractLocations = (locations) => {
		return locations.reduce((acc, location) => {
			const materialDamageCovers =
				location.materialDamageCovers &&
				typeof location.materialDamageCovers === 'object' &&
				!Array.isArray(location.materialDamageCovers)
					? location.materialDamageCovers
					: {};
			const sumInsureds = ['buildings', 'contents', 'stock'].reduce((acc, coverage) => {
				let sumInsured = parseInt(
					(
						materialDamageCovers?.[coverage]?.sumInsured ??
						materialDamageCovers?.[coverage]?.SumInsured ??
						materialDamageCovers?.[coverage]?.['Sum Insured'] ??
						''
					)
						.toString()
						.replace(/\D/g, '')
				);
				sumInsured = isNaN(sumInsured) ? '' : sumInsured.toString();
				acc[coverage] = sumInsured;
				return acc;
			}, {});

			acc.push({
				address: location.addressLine1,
				rows: [
					{
						coverage: 'Buildings',
						basisOfSettlement: materialDamageCovers?.buildings?.basisOfSettlement ?? '',
						sumInsured: sumInsureds.buildings,
						id: 0,
					},
					{
						coverage: 'Contents',
						basisOfSettlement: materialDamageCovers?.contents?.basisOfSettlement ?? '',
						sumInsured: sumInsureds.contents,
						id: 1,
					},
					{
						coverage: 'Stock',
						basisOfSettlement: 'Indemnity',
						sumInsured: sumInsureds.stock,
						id: 2,
					},
				],
			});
			return acc;
		}, []);
	};

	useEffect(() => {
		const newValues = products.reduce((acc, product) => {
			acc[product.title] = product.value;
			return acc;
		}, {});

		formik.setFieldValue('scopeOfAdvice', {
			...formik.values.scopeOfAdvice,
			...newValues,
		});
	}, [products]);

	useEffect(() => {
		const businessDetails = {
			...(dealRow?.clientName && { companyName: dealRow?.clientName }),
			...(client?.description && { businessDescription: client?.description }),
			...(client?.insight?.OneLineAddress && {
				insuredAddress: client?.insight?.OneLineAddress,
			}),
			...(client?.annualrevenue && {
				annualTurnover: parseInt(client?.annualrevenue),
			}),
			...(deal?.adviser_fee && { currentlyPaying: parseInt(deal?.adviser_fee) }),
			...(client?.numberofemployees && {
				numberOfEmployees: parseInt(client?.numberofemployees),
			}),
			...(dealRow?.renewalDate && {
				currentRenewalDate: formatDate(new Date(dealRow?.renewalDate)),
			}),
		};

		formik.setFieldValue('businessDetails', {
			...formik.values.businessDetails,
			...businessDetails,
		});
	}, [
		dealRow?.clientName,
		// dealRow?.description,
		client?.insight?.OneLineAddress,
		client?.numberofemployees,
		client?.annualrevenue,
		client?.description,
		dealRow?.clientType,
		dealRow?.goalsOfCover,
		dealRow?.additionalGoalsOfCover,
		dealRow?.renewalDate,
		deal?.adviser_fee,
	]);

	useEffect(() => {
		const clientTypeTitleCase = (dealRow?.clientType ?? ' ').replace(
			/\w\S*/g,
			(text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
		);
		const focus = {
			...(dealRow?.clientType && {
				clientType: clientTypeTitleCase,
			}),
			...(dealRow?.goalsOfCover && {
				goalsOfCover: [
					dealRow?.goalsOfCover
						.map((v) => GOALS_OF_COVER_LABELS[v] ?? formatKey(v ?? ' '))
						.join(', '),
					dealRow?.additionalGoalsOfCover?.trim(),
				]
					.filter(Boolean)
					.join(', '),
			}),
		};

		formik.setFieldValue('whatWeAgreedToFocusOn', {
			...formik.values.whatWeAgreedToFocusOn,
			...focus,
		});
	}, [dealRow?.clientType, dealRow?.goalsOfCover]);

	useEffect(() => {
		const scope = (dealRow?.needsAnalysis ?? []).reduce((acc, scope) => {
			acc[scope.key] = scope.value;
			return acc;
		}, {});

		formik.setFieldValue('scopeOfAdvice', {
			...formik.values.scopeOfAdvice,
			...scope,
		});
	}, [dealRow?.needsAnalysis]);

	useEffect(() => {
		const newValues = Object.keys(formFields.whatHasBeenSentToMarket).reduce((acc, val) => {
			acc[val] = '';
			return acc;
		}, {});

		console.log('RISK INFO', latestRiskInfo?.jsonData);
		let vehicles = [];
		let locationData = [];
		const riskInfoFields = Object.keys(latestRiskInfo?.jsonData?.classOfRisk ?? {});
		(dealRow?.needsAnalysis ?? []).forEach((scope) => {
			const scopeKey = formatKey(scope.key).toUpperCase();
			const dataField = riskInfoFields.find((k) =>
				formatKey(k).toUpperCase().includes(scopeKey)
			);
			if (dataField) {
				const field = latestRiskInfo.jsonData?.classOfRisk?.[dataField];
				const newValue = (
					field?.sumInsured ??
					field?.SumInsured ??
					field['Sum Insured'] ??
					0
				)
					.toString()
					.replace(/\D/g, '');
				newValues[scope.key] = newValue;

				if (
					field?.vehicles &&
					typeof field?.vehicles === 'object' &&
					Array.isArray(field?.vehicles)
				) {
					vehicles = extractVehicles(field?.vehicles);
				}
				if (
					field?.locations &&
					typeof field?.locations === 'object' &&
					Array.isArray(field?.locations)
				) {
					locationData = extractLocations(field?.locations);
				}
				if (
					field?.stockAnywhereInNZ ||
					field?.['Stock/Other Property Anywhere in New Zealand'] ||
                    field?.stockOrPropertyAnywhereInNZ
				) {
					const stockValue =
						field?.stockAnywhereInNZ ??
						field?.['Stock/Other Property Anywhere in New Zealand'] ??
                        field?.stockOrPropertyAnywhereInNZ;
					console.log('STOCK VALUE FIELD', stockValue);
					let newValue = '0';
					if (typeof stockValue === 'string' || typeof stockValue === 'number') {
						newValue = String(parseInt(stockValue.toString().replace(',', '')));
						console.log('NEW VALUE FIELD', newValue);
					} else {
						newValue = (
							stockValue?.sumInsured ??
							stockValue?.SumInsured ??
							stockValue?.['Sum Insured'] ??
							0
						)
							.toString()
							.replace(/\D/g, '');
					}
					if (['', '0'].includes(newValues[scope.key])) {
						newValues[scope.key] = newValue;
					}
					formik.setFieldValue('whatHasBeenSentToMarket.stockAndOtherProperty', newValue);
					setStockAndOtherProperty(newValue);
				}
			} else {
				newValues[scope.key] = '0';
			}
		});

		if (vehicles.length > 0) {
			setMotorVehicles([
				...vehicles,
				{
					year: '',
					make: '',
					model: '',
					registration: '',
					coverage: '',
					sumInsured: '',
					heavy: false,
					...(isDomestic && { lossOfUse: '' }),
					id: vehicles.length,
				},
			]);
		}

		if (locationData.length > 0) {
			setLocations([...locationData]);
		}
		setMarketRows(
			Object.entries(newValues)
				.map(([key, value]) => ({
					id: key,
					classOfRisk: formatKey(key),
					sumInsured: value,
				}))
				.concat({
					classOfRisk: '',
					sumInsured: '',
					id: 0,
				})
		);
	}, [
		formFields?.whatHasBeenSentToMarket,
		dealRow?.needsAnalysis,
		latestRiskInfo?.jsonData,
		isDomestic,
	]);

	useEffect(() => {
		formik.setFieldValue('paymentOptions.numberOfInstalments', '');
	}, [formik.values.paymentOptions?.instalmentFrequency]);

	return <></>;
};

const CreateSOAForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'soaPayments' });
	const showSoaPayments = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const { width } = useSelector((state) => state.sideNavSlice);

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);

	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });
	const hubspotId = userQuery?.data?.hubspotId;

	const [formLoading, setFormLoading] = useState(false);

	// Deal context
	const { deal, client, dealRow, quotes, riskInfo, soas, ownerId, loading } = determineContext();
	const isDomestic = dealRow?.[CLIENT_TYPE] == DOMESTIC;

	const isGenerating = useMemo(() => soas.length > 0 && soas[0]?.downloadUrl == null, [soas]);

	const [generateSOA] = useGenerateSOAMutation();

	const latestQuote = useMemo(() => (isDomestic ? {} : quotes[0] ?? {}), [quotes, isDomestic]);
	const latestRiskInfo = useMemo(
		() => (isDomestic ? {} : riskInfo[0] ?? {}),
		[riskInfo, isDomestic]
	);

	const isGeneratingData = latestRiskInfo?.generating || latestQuote?.generating;

	const isLoading =
		isGenerating ||
		isGeneratingData ||
		formLoading ||
		loading?.deal ||
		loading?.client ||
		loading?.aiDocs;

	const downloadButtonDisabled =
		formLoading || isGenerating || isGeneratingData || !soas?.[0]?.downloadUrl;

	useEffect(() => {
		let intervalId;

		const checkRunStatus = async () => {
			dispatch(
				factFindApi.util.invalidateTags([{ type: 'GENERATED_SOA', id: deal?.hs_object_id }])
			);
		};

		if (isGenerating) {
			intervalId = setInterval(checkRunStatus, 10000); // Check every 10 seconds
		}

		return () => clearInterval(intervalId);
	}, [isGenerating, deal?.hs_object_id]);

	const [products, setProducts] = useState([]);
	const [marketRows, setMarketRows] = useState([]);
	const [motorVehicles, setMotorVehicles] = useState([
		{
			year: '',
			make: '',
			model: '',
			registration: '',
			coverage: '',
			sumInsured: '',
			heavy: false,
			...(isDomestic && { lossOfUse: '' }),
			id: 0,
		},
	]);
	const [vehicleSchedule, setVehicleSchedule] = useState(false);
	const [vehicleScheduleRows, setVehicleScheduleRows] = useState([
		{ id: 0, numberOfVehicles: 1, sumInsured: '' },
		{ id: 1, numberOfVehicles: 0, sumInsured: '' },
	]);

	const [pleasureCrafts, setPleasureCrafts] = useState([
		{
			year: '',
			type: '',
			make: '',
			coverage: '',
			sumInsured: '',
			id: 0,
		},
	]);

	const [stockAndOtherProperty, setStockAndOtherProperty] = useState(0);

	const [locations, setLocations] = useState([
		{
			address: '',
			rows: [
				{
					coverage: 'Buildings',
					basisOfSettlement: '',
					sumInsured: '',
					fslIndemnity: '',
					noOfResidential: 0,
					id: 0,
				},
				{
					coverage: 'Contents',
					basisOfSettlement: '',
					sumInsured: '',
					id: 1,
				},
				...(!isDomestic
					? [
							{
								coverage: 'Stock',
								basisOfSettlement: 'Indemnity',
								sumInsured: '',
								id: 2,
							},
					  ]
					: []),
			],
		},
	]);

	useEffect(() => {
		if (isDomestic) {
			setLocations((prev) => [
				...prev.map((p) => ({
					...p,
					rows: p.rows.filter((r) => r.coverage !== 'Stock'),
				})),
			]);
		}
	}, [isDomestic]);

	const [resultsTables, setResultsTables] = useState({});
	const [insurerTables, setInsurerTables] = useState([
		{ insurer: '', rating: '', id: 0, code: -1, ratingId: -1, data: {} },
	]);
	const [insurerSelection, setInsurerSelection] = useState({});

	const formFields = useMemo(() => {
		const { scopeOfAdvice, whatHasBeenSentToMarket, businessDetails, paymentOptions } = FIELDS;

		const needsAnalysis = dealRow?.needsAnalysis ?? [];
		const needs = needsAnalysis.map((v) => v.key);

		const clientTypeNeeds = Object.keys(
			!isDomestic ? SCOPE_OF_ADVICE_LABELS_COMMERCIAL : SCOPE_OF_ADVICE_LABELS_DOMESTIC
		).filter((v) => v !== 'fullNeedsAnalysis');

		const filteredScopeOfAdvice = Object.keys(scopeOfAdvice)
			.filter((key) => clientTypeNeeds.includes(key) && needs.includes(key))
			.reduce((acc, key) => {
				acc[key] = scopeOfAdvice[key];
				return acc;
			}, {});

		const filteredSentToMarket = Object.keys(whatHasBeenSentToMarket)
			.filter((key) => clientTypeNeeds.includes(key) && needs.includes(key))
			.reduce((acc, key) => {
				acc[key] = whatHasBeenSentToMarket[key];
				return acc;
			}, {});

		products.forEach((product) => {
			filteredScopeOfAdvice[product.title] = { columns: 6 };
		});

		filteredScopeOfAdvice['commentsOnScope'] = FIELDS.scopeOfAdvice['commentsOnScope'];

		const businessDetailsKeys = Object.keys(businessDetails).filter((key) =>
			isDomestic
				? !['annualTurnover', 'numberOfEmployees', 'businessActivities'].includes(key)
				: key
		);

		const newBusinessDetails = businessDetailsKeys.reduce((acc, k) => {
			acc[k] = businessDetails[k];
			return acc;
		}, {});

		const newFields = { ...FIELDS };
		newFields.scopeOfAdvice = filteredScopeOfAdvice;
		newFields.whatHasBeenSentToMarket = filteredSentToMarket;
		newFields.businessDetails = newBusinessDetails;
		if (showSoaPayments) {
			newFields.paymentOptions = paymentOptions;
		}
		return newFields;
	}, [products, dealRow?.needsAnalysis, isDomestic, showSoaPayments]);

	const createEmptyStructure = (fields) => {
		const result = {};
		Object.entries(fields).forEach(([key, value]) => {
			if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
				result[key] = Object.keys(value).reduce((acc, innerKey) => {
					acc[innerKey] = '';
					return acc;
				}, {});
			} else {
				result[key] = '';
			}
		});

		return result;
	};

	const initialValues = useMemo(() => createEmptyStructure(formFields), [formFields]);

	const path = getUrlFromPipeline(deal.pipeline);

	const resetFormFields = () => {
		setProducts([]);
		setMarketRows([]);
		setResultsTables({});
		setInsurerTables([{ insurer: '', rating: '', id: 0, code: -1, ratingId: -1, data: {} }]);
		setInsurerSelection({});
	};

	//     LIGHT_VEHICLE_FSL_MULTIPLIER = 9.53;
	// export const HEAVY_VEHICLE_FSL_MULTIPLIER = 11.95;
	// export const MATERIAL_DAMAGE_FSL_MULTIPLIER = 11.95;
	// export const HOME_INSURANCE_FSL_DEFAULT = 119.50;
	// export const CONTENTS_INSURANCE_FSL_DEFAULT = 23.90;

	// export const HOME_INSURANCE_NHI_DEFAULT = 480.00;
	const getRowFsl = (row, heavy, light) => {
		const fsl =
			light * LIGHT_VEHICLE_FSL_MULTIPLIER +
			(heavy > 0
				? (HEAVY_VEHICLE_FSL_MULTIPLIER *
						safelyParseFloat(
							vehicleSchedule ? vehicleScheduleRows[1].sumInsured : row.sumInsured
						)) /
				  SOA_MULTIPLIER
				: 0);

		const { gst, premium, companyPremium } = calculateGstFromRow({
			premium: row.premium,
			nhi: row.nhi,
			fsl,
		});
		return {
			...row,
			fsl,
			gst,
			companyPremium,
		};
	};

	const vehicleWeights = useMemo(() => {
		return vehicleSchedule
			? {
					light: parseInt(vehicleScheduleRows[0].numberOfVehicles),
					heavy: parseInt(vehicleScheduleRows[1].numberOfVehicles),
			  }
			: motorVehicles
					.filter((row) => row.year.toString().length > 0 && row.make.length > 0)
					.reduce(
						(acc, row) => {
							return {
								...acc,
								...(row.heavy
									? { heavy: acc.heavy + 1 }
									: { light: acc.light + 1 }),
							};
						},
						{ heavy: 0, light: 0 }
					);
	}, [motorVehicles, vehicleSchedule, vehicleScheduleRows]);

	useEffect(() => {
		const { heavy, light } = vehicleWeights;
		console.log('VEHICLE WEIGHTS', vehicleWeights);
		setResultsTables((prev) => {
			return {
				...prev,
				...(prev[COMMERCIAL_MOTOR] && {
					[COMMERCIAL_MOTOR]: prev[COMMERCIAL_MOTOR].map((row) =>
						getRowFsl(row, heavy, light)
					),
				}),
				...(prev[PRIVATE_VEHICLE] && {
					[PRIVATE_VEHICLE]: prev[PRIVATE_VEHICLE].map((row) =>
						getRowFsl(row, heavy, light)
					),
				}),
			};
		});
	}, [vehicleWeights]);

	const generateFormJsonData = (values) => {
		const { businessDetails, whatWeAgreedToFocusOn, scopeOfAdvice, results, myRecommendation } =
			values;

		const stockAndOtherProperty = values?.whatHasBeenSentToMarket?.stockAndOtherProperty;

		const scopeOfAdviceProducts = Object.entries(scopeOfAdvice)
			.filter(([key, value]) => {
				return (value ?? '').length > 0 && key !== 'commentsOnScope';
			})
			.map(([key, value]) => ({
				title: formatKey(key),
				value: NEEDS_ANALYSIS_LABELS[value] ?? value,
				outOfScope: value !== 'clientQuoted',
			}));

		const filteredMarketRows = marketRows.filter((r) => {
			const sum = r.sumInsured.length > 0 ? parseInt(r.sumInsured) : 0;
			return r.classOfRisk.length > 0 && !isNaN(sum) && sum > 0;
		});

		const recs = recommendations.map((r) => ({
			...r,
			classOfRisk: formatKey(r.classOfRisk),
		}));

		const resTables = Object.entries(resultsTables).reduce((acc, [key, value]) => {
			const newValue = value
				.filter((v) => v.insurer.length > 0)
				.map((v) => {
					const { gst, premium, companyPremium } = calculateGstFromRow(v);
					return {
						insurer: v.insurer,
						// premium: parseFloat(v.premium),
						excess: v.excess, //parseFloat(v.excess),
						sumInsured: parseInt(v.sumInsured),
						// companyPremium: parseFloat(v.companyPremium ?? 0),
						naturalDisaster: parseFloat(v.naturalDisaster ?? 0),
						nhi: parseFloat(v.nhi ?? 0),
						fsl: parseFloat(v.fsl ?? 0),
						gst,
						premium,
						companyPremium,
						endorsements: (v.endorsements ?? '').toString(),
					};
				});
			acc[formatKey(key)] = newValue;
			return acc;
		}, {});

		const filteredVehicles = motorVehicles.filter(
			({ year, make, model, registration, sumInsured }) => {
				const sum = safelyParseFloat(sumInsured);
				return (
					year.toString().length > 0 &&
					make.length > 0 &&
					registration.length > 0 &&
					model.length > 0 &&
					sum > 0
				);
			}
		);

		const filteredPleasureCrafts = pleasureCrafts.filter(({ year, make, type, sumInsured }) => {
			const sum = safelyParseFloat(sumInsured);
			return year.toString().length > 0 && make.length > 0 && type.length > 0 && sum > 0;
		});

		const filteredLocations = locations.filter(({ address, rows }) => {
			return (
				address.trim().length > 0 &&
				rows.every((r) => {
					const sum = safelyParseFloat(r.sumInsured);
					return r.basisOfSettlement.trim().length > 0 && sum > 0;
				})
			);
		});

		const ratings = insurerTables.filter((r) => r.code !== -1);

		const formJson = {
			businessDetails,
			whatWeAgreedToFocusOn,
			scopeOfAdvice: {
				comments: scopeOfAdvice.commentsOnScope,
				products: scopeOfAdviceProducts,
			},
			whatHasBeenSentToMarket: filteredMarketRows,
			motorVehicles: filteredVehicles,
			...(vehicleSchedule && {
				vehicleSchedule: { vehicles: vehicleScheduleRows, showVehicleSchedule: true },
			}),
			pleasureCrafts: filteredPleasureCrafts,
			locations: filteredLocations,
			...(stockAndOtherProperty && !isDomestic && { stockAndOtherProperty }),
			results: {
				...results,
				products: resTables,
			},
			// insurers: insurersArray,
			myRecommendation: {
				comments: myRecommendation.summaryOfRecommendations,
				products: recs,
				...myRecommendation,
			},
			...(showSoaPayments && { paymentOptions: values.paymentOptions }),
			...(ratings.length > 0 && { insurerRatings: ratings }),
		};

		console.log(formJson);
		return formJson;
	};

	// const formik = useFormik({
	// 	initialValues,
	// 	validateOnChange: false,
	// 	// validationSchema,
	// 	// enableReinitialize: true,
	// 	onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
	// 		setFormLoading(true);
	// 		console.log('📋 ~ Formik values', {
	// 			values,
	// 			'What has been sent to market': marketRows,
	// 			Results: resultsTables,
	// 			// Endorsements: endorsements,
	// 		});

	// 		const formJson = generateFormJsonData();

	// 		await generateSOA({
	// 			dealId: deal?.hs_object_id,
	// 			quoteId: latestQuote?.id,
	// 			riskInfoId: latestRiskInfo?.id,
	// 			userId: hubspotId,
	// 			formJson,
	// 		})
	// 			.unwrap()
	// 			.then((res) => {
	// 				dispatch(
	// 					showToast({
	// 						message: 'Proposal generation in progress! Check back soon.',
	// 						success: true,
	// 					})
	// 				);
	// 				// resetForm();
	// 				// resetFormFields();
	// 			})
	// 			.catch((err) => {
	// 				console.log('Error generating proposal', err);
	// 				dispatch(
	// 					showToast({
	// 						message: 'Error generating proposal; please try again.',
	// 						error: true,
	// 					})
	// 				);
	// 			});
	// 		setSubmitting(false);
	// 		setFormLoading(false);
	// 	},
	// });

	useEffect(() => {
		const commercialMotor = marketRows.find(
			(r) =>
				r?.id === 'commercialMotor' || r?.classOfRisk.toLowerCase() === 'commercial motor'
		);
		if (commercialMotor) {
			setVehicleScheduleRows((prev) =>
				prev.map((r, i) => ({
					...r,
					...(i === 0 && { sumInsured: parseInt(commercialMotor.sumInsured ?? 0) }),
				}))
			);
		}
	}, [marketRows]);

	// useEffect(() => {
	// 	formik.setFieldValue('paymentOptions.numberOfInstalments', '');
	// }, [formik.values.paymentOptions?.instalmentFrequency]);

	// const { getError, getErrorMessage } = useFormikHelper(formik);

	const getDefaultExcess = (classOfRisk) =>
		classOfRisk === COMMERCIAL_MOTOR ? '1% minimum $500' : '';

	const getDefaultNhi = (classOfRisk) => {
		switch (classOfRisk) {
			case HOUSE:
				return HOME_INSURANCE_NHI_DEFAULT;
			case MATERIAL_DAMAGE: {
				const sum = locations
					.flatMap((l) => l.rows.filter((r) => r.coverage === 'Buildings'))
					.reduce((acc, row) => acc + safelyParseInt(row.noOfResidential), 0);
				return HOME_INSURANCE_NHI_DEFAULT * sum;
			}
			default:
				return '';
		}
	};

	const getDefaultFsl = (classOfRisk, row = {}) => {
		const { heavy, light } = vehicleWeights;

		switch (classOfRisk) {
			case HOUSE:
				return HOME_INSURANCE_FSL_DEFAULT;
			case CONTENTS:
				return CONTENTS_INSURANCE_FSL_DEFAULT;
			case MATERIAL_DAMAGE: {
				const totalLevy = locations.reduce((acc, location) => {
					return (
						acc +
						location.rows.reduce((val, row) => {
							return (
								val +
								safelyParseFloat(
									row.coverage === 'Buildings' ? row.fslIndemnity : row.sumInsured
								)
							);
						}, 0)
					);
				}, safelyParseFloat(stockAndOtherProperty));
				const fsl = (totalLevy / SOA_MULTIPLIER) * MATERIAL_DAMAGE_FSL_MULTIPLIER;
				return fsl;
			}
			case COMMERCIAL_MOTOR:
			case PRIVATE_VEHICLE: {
				const { fsl } = getRowFsl(row, heavy, light);
				return fsl;
			}
			default:
				return '';
		}
	};

	const getBlankInsurerRow = ({
		totalPayable,
		insurer,
		endorsements,
		naturalDisaster,
		classOfRisk,
	}) => {
		const nhi = getDefaultNhi(classOfRisk);
		const fsl = getDefaultFsl(classOfRisk);

		const { gst, premium, companyPremium } = calculateGstFromRow({
			premium: totalPayable ?? '',
			nhi,
			fsl,
		});
		return {
			insurer: insurer ?? '',
			excess: getDefaultExcess(classOfRisk),
			premium,
			companyPremium,
			gst,
			naturalDisaster: naturalDisaster ?? '',
			nhi,
			fsl,
			endorsements: (endorsements ?? '').toString(),
			id: insurer ?? 0,
		};
	};

	const transformQuoteData = (details) => {
		if (details && Array.isArray(details)) {
			const res = details.reduce((acc, insurer) => {
				Object.entries(insurer.classOfRisk).forEach(([classOfRisk, values]) => {
					const categoryString = toCamelCase(classOfRisk);
					const classOfRiskString = categoryString
						.toLowerCase()
						.includes('materialdamage')
						? 'materialDamage'
						: categoryString;
					const endorsements =
						values?.endorsements && Array.isArray(values?.endorsements)
							? values?.endorsements.join(', ')
							: typeof values?.endorsements === 'string'
							? values?.endorsements
							: '';
					if (!acc[classOfRiskString]) {
						acc[classOfRiskString] = [];
					}
					console.log('QUOTE', {
						classOfRisk,
						values,
						classOfRiskString,
						categoryString,
					});
					const totalPayable = values?.totalPayable ?? values?.totalPayableExclND;
					if (
						(typeof totalPayable == 'number' && totalPayable > 0) ||
						((totalPayable ?? '').length > 0 && parseInt(totalPayable ?? '') > 0)
					) {
						acc[classOfRiskString].push({
							...getBlankInsurerRow({
								totalPayable: totalPayable ?? 0,
								insurer: insurer?.insurer,
								endorsements,
								naturalDisaster: values?.totalPayableND ?? 0,
								classOfRisk: classOfRiskString,
							}),
						});
					}
				});
				return acc;
			}, {});
			console.log('transformQuoteData', res);
			return res;
		}

		const result = Object.entries(details ?? {}).reduce((acc, [classOfRisk, values]) => {
			console.log('classOfRisk', classOfRisk, 'values', values);
			let insurers = [];
			const categoryString = toCamelCase(classOfRisk);
			const classOfRiskString = categoryString.toLowerCase().includes('materialdamage')
				? 'materialDamage'
				: categoryString;

			if (typeof values?.totalPayable == 'object') {
				const endorsements =
					values?.endorsements && Array.isArray(values?.endorsements)
						? values?.endorsements.join(', ')
						: typeof values?.endorsements === 'string'
						? values?.endorsements
						: '';
				insurers = Object.entries(values?.totalPayable).map(([insurer, totalPayable]) => {
					return getBlankInsurerRow({
						totalPayable: totalPayable ?? 0,
						insurer: insurer,
						endorsements,
						naturalDisaster: 0,
						classOfRisk: classOfRiskString,
					});
				});
			} else if (Object.keys(values).includes('insurer')) {
				insurers = [
					{
						...getBlankInsurerRow({
							totalPayable: values?.totalPayable ?? '',
							insurer: values?.insurer ?? '',
							endorsements: (values?.endorsements ?? '').toString(),
							naturalDisaster: 0,
							classOfRisk: classOfRiskString,
						}),
					},
				];
			} else {
				insurers = Object.entries(values ?? {}).map(([insurer, v]) => {
					return getBlankInsurerRow({
						totalPayable: v?.totalPayable ?? '',
						insurer: insurer ?? '',
						endorsements: (v?.endorsements ?? '').toString(),
						naturalDisaster: 0,
						classOfRisk: classOfRiskString,
					});
				});
			}

			if (!acc[classOfRiskString]) {
				acc[classOfRiskString] = [];
			}

			insurers = insurers.filter(
				(insurer) =>
					(typeof insurer.premium == 'number' && insurer.premium > 0) ||
					(insurer.premium.length > 0 && parseInt(insurer.premium) > 0)
			);

			insurers.forEach((value) => acc[classOfRiskString].push(value));
			return acc;
		}, {});

		console.log('transformQuoteData', result);
		return result;
	};

	useEffect(() => {
		const newRows = marketRows.filter((r) => {
			const value = parseInt(r.sumInsured);
			return !isNaN(value) && value > 0;
		});

		const table = newRows.reduce((acc, r) => {
			acc[r.id] = [
				{
					insurer: r.insurer ?? '',
					sumInsured: r.sumInsured ?? '',
					excess: '',
					premium: '',
					companyPremium: '',
					gst: '',
					// companyPremium: r.companyPremium ?? '',
					naturalDisaster: '',
					nhi: '',
					fsl: '',
					id: r.id ?? 0,
				},
			];
			return acc;
		}, {});

		// [
		//     {
		//         "insurer": "QBE NZ",
		//         "classOfRisk": {
		//             "General Liability": {
		//                 "endorsements": [],
		//                 "variations": [],
		//                 "quoteNumber": "",
		//                 "totalPayable": 0,
		//                 "limitOfLiability": 0
		//             },
		//             "Statutory Liability": {
		//                 "endorsements": [],
		//                 "variations": [],
		//                 "quoteNumber": "",
		//                 "totalPayable": 0,
		//                 "limitOfLiability": 0
		//             },
		//             "Employers Liability": {
		//                 "endorsements": [],
		//                 "variations": [],
		//                 "quoteNumber": "",
		//                 "totalPayable": 0,
		//                 "limitOfLiability": 0
		//             },
		//             "Material Damage": {
		//                 "endorsements": [],
		//                 "variations": [],
		//                 "quoteNumber": "",
		//                 "totalPayable": 0,
		//                 "limitOfLiability": 0
		//             },
		//             "Commercial Motor": {
		//                 "variations": [],
		//                 "quoteNumber": "#P000596225SVU-2R",
		//                 "endorsements": [
		//                     "Steadfast Basis of Settlement Endorsement"
		//                 ],
		//                 "totalPayable": 1176,
		//                 "limitOfLiability": 0
		//             }
		//         }
		//     },
		// ]

		const results = transformQuoteData(
			latestQuote?.jsonData?.insurers ?? latestQuote?.jsonData?.classOfRisk
		);

		Object.entries(results ?? {}).forEach(([key, value]) => {
			const row = marketRows.find((r) => r.id === key || r.classOfRisk === key);
			const sumInsured = parseInt(row?.sumInsured);
			if (row && !isNaN(sumInsured) && sumInsured > 0) {
				console.log('VALUE', value, row?.classOfRisk);
				const categoryString = toCamelCase(row?.classOfRisk ?? '');
				table[key] = [
					...value.map((v) => {
						const nhi = v.nhi ?? getDefaultNhi(categoryString) ?? 0;
						const fsl = v.fsl ?? getDefaultFsl(categoryString, v) ?? 0;
						const { gst, premium, companyPremium } = calculateGstFromRow({
							...v,
							nhi,
							fsl,
						});

						return {
							...v,
							sumInsured: row?.sumInsured,
							id: v.insurer,
							classOfRisk: key,
							excess: (v.excess ?? getDefaultExcess(categoryString)).toString(),
							premium: premium.toString(),
							companyPremium: companyPremium.toString(),
							gst: gst.toString(),
							// companyPremium: (v.companyPremium ?? 0).toString(),
							naturalDisaster: (v.naturalDisaster ?? 0).toString(),
							nhi: nhi.toString(),
							fsl: fsl.toString(),
							endorsements: (v.endorsements ?? '').toString(),
						};
					}),
					{
						insurer: '',
						sumInsured: '',
						excess: '',
						premium: '',
						companyPremium: '',
						gst: '',
						naturalDisaster: '',
						nhi: '',
						fsl: '',
						classOfRisk: key,
						endorsements: '',
						id: 0,
					},
				].slice();
			}
		});
		setResultsTables(table);
	}, [latestQuote, marketRows]);

	const processResultsTableRowDeletion = (key, rowId) => {
		// [prev[key]]: prev[key].filter((p) => p.id !== rowId)
		setResultsTables((prev) => ({
			...prev,
			[key]: prev[key].filter((p) => p.id !== rowId),
		}));
	};

	const processResultsTableRowUpdate = (key, newRow, oldRow) => {
		const row = marketRows.find((r) => r.id === key);

		newRow.sumInsured = row?.sumInsured;
		const fsl = getDefaultFsl(key, newRow);
		const nhi = getDefaultNhi(key);
		if (oldRow.insurer != newRow.insurer) {
			if (fsl) newRow.fsl = fsl;
			if (nhi) newRow.nhi = nhi;
		}
		const { gst, premium, companyPremium } = calculateGstFromRow(newRow);

		newRow.gst = gst;
		newRow.companyPremium = companyPremium;

		setResultsTables((prevResultsTables) => {
			const table = prevResultsTables[key];
			if (!table) {
				return prevResultsTables;
			}
			const index = table.findIndex((r) => r.insurer === oldRow.insurer);
			if (index >= 0) {
				const updatedTable = [...table].slice();

				if ((newRow.insurer ?? '').length > 0) {
					newRow.id = newRow.insurer;
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				if (lastRow && lastRow.insurer !== '') {
					updatedTable.push({
						insurer: '',
						sumInsured: '',
						excess: '',
						premium: '',
						companyPremium: '',
						naturalDisaster: '',
						gst: '',
						nhi: '',
						fsl: '',
						classOfRisk: key,
						endorsements: '',
						id: 0,
					});
				}
				console.log('Updated table', {
					...prevResultsTables,
					[key]: updatedTable,
				});
				return {
					...prevResultsTables,
					[key]: updatedTable,
				};
			}
			console.log('Old table', prevResultsTables);
			return prevResultsTables;
		});
		return newRow;
	};

	useEffect(() => {
		console.log('USE EFFECT [resultsTables]', resultsTables);
		const newSelections = Object.keys(resultsTables).reduce((acc, r) => {
			if (r?.id) {
				acc[r.id] = [];
			}
			return acc;
		}, {});
		setInsurerSelection((prev) => ({ ...newSelections, ...prev }));
	}, [resultsTables]);

	const insurersList = useMemo(() => {
		const list = Object.values(resultsTables)
			.flat()
			.map((r) => r.insurer)
			.filter((l) => l);
		return Array.from(new Set(list));
	}, [resultsTables]);

	const { data: insurerList, isSuccess } = useRetrieveClaimInsurersQuery();

	const insurers = useMemo(() => insurerList?.data ?? [], [insurerList?.data]);

	const fuse = useMemo(() => {
		const options = { keys: ['Name'], shouldSort: true, threshold: 0.5 };
		const index = Fuse.createIndex(options.keys, insurers);
		return new Fuse(insurers, options, index);
	}, [insurers]);

	useEffect(() => {
		const searches = insurersList.map((insurer) => {
			const result = fuse.search(insurer === 'Ando' ? 'Ando Insurance' : insurer);
			// console.log('RESULTS FOR SEARCH', insurer, result);
			return (result ?? [])
				.map((r) => r?.item)
				.filter((i) => insurer !== 'NZI' || !i.Name.includes('Marine'));
			// return result?.[0]?.item;
		});

		console.log('SEARCHES', searches);
		if (isSuccess) {
			setInsurerTables((prevRows) => {
				const maxIds = Math.max(prevRows.map((r) => r.id));
				const newId = isNaN(maxIds) ? 0 : maxIds + 1;

				const newRows = searches
					.filter(
						(search) => !search.some((s) => prevRows.map((r) => r.code).includes(s?.Id))
					)
					.map((search, index) => {
						if (!search.some((s) => prevRows.map((r) => r.code).includes(s?.Id))) {
							return {
								code: search[0]?.Id,
								insurer: search[0]?.Name ?? '',
								rating: search[0]?.FinancialStrengthRating ?? '',
								ratingId: search[0]?.FinancialStrengthRatingScaleId ?? -1,
								data: search[0] ?? {},
								id: newId + index,
							};
						}
					});

				console.log(
					'NEW INSURER RATING ROWS',
					newRows,
					'PREV INSURER RATING ROWS',
					prevRows
				);

				return [
					...newRows,
					...prevRows,
					...(prevRows.find((r) => r.code === -1)
						? []
						: [
								{
									insurer: '',
									rating: '',
									data: {},
									code: -1,
									ratingId: -1,
									id: newId + newRows.length,
								},
						  ]),
				];
			});
		}
	}, [insurersList, fuse, isSuccess]);

	const recommendations = useMemo(() => {
		return Object.entries(insurerSelection).map(([key, insurer]) => {
			const row =
				resultsTables[key]?.find((r) => r.insurer === insurer || r.id === insurer) ?? {};
			row.classOfRisk = key;
			row.id = key;
			return row;
		});
	}, [insurerSelection, resultsTables]);

	const processInsurerTableRowDeletion = (rowId) => {
		// [prev[key]]: prev[key].filter((p) => p.id !== rowId)
		setInsurerTables((prev) => [...prev.filter((r) => r.id !== rowId)]);
	};

	const processInsurerTableRowUpdate = (newRow, oldRow) => {
		console.log('processInsurerTableRowUpdate', { newRow, oldRow });

		setInsurerTables((prevRows) => {
			const newRows = prevRows.map((row) => ({
				...row,
				...(row.code === oldRow.code && newRow),
			}));

			const maxIds = Math.max(newRows.map((r) => r.id));
			const newId = isNaN(maxIds) ? 0 : maxIds + 1;
			return [
				...newRows,
				...(newRows.find((r) => r.code === -1)
					? []
					: [
							{
								insurer: '',
								rating: '',
								data: {},
								code: -1,
								ratingId: -1,
								id: newId,
							},
					  ]),
			];
		});
		return newRow;
	};

	const handleInsurerSelection = (row, key) => {
		const latest = row[row.length - 1];
		setInsurerSelection((prev) => ({ ...prev, [key]: latest ?? [] }));
	};

	const processWHBSTMRowDeletion = (rowId) => {
		setMarketRows((prev) => prev.filter((p) => p.id !== rowId));
	};

	const processWHBSTMRowUpdate = (newRow, oldRow) => {
		// const row = rows.find((r) => r.id === key);

		const newValue = parseInt(newRow.sumInsured);
		newRow.sumInsured = isNaN(newValue) ? oldRow.sumInsured : `${newValue}`;
		setMarketRows((prevRows) => {
			const index = prevRows.findIndex((r) => r.classOfRisk === oldRow.classOfRisk);
			if (index >= 0) {
				const updatedTable = [...prevRows].slice();

				if ((newRow.classOfRisk ?? '').length > 0) {
					newRow.id = toCamelCase(newRow.classOfRisk);
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				if (lastRow && lastRow.classOfRisk !== '') {
					updatedTable.push({
						classOfRisk: '',
						sumInsured: '',
						id: 0,
					});
				}
				return [...updatedTable].slice();
			}
			return prevRows;
		});

		return newRow;
	};

	const processMotorVehicleRowDeletion = (rowId) => {
		setMotorVehicles((prev) => prev.filter((p) => p.id !== rowId));
	};

	const processMotorVehicleRowUpdate = (newRow, oldRow) => {
		const rowValues = Object.entries(newRow)
			.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
			.join('');
		// console.log('ROW VALUES', rowValues);

		setMotorVehicles((prevRows) => {
			const index = prevRows.findIndex((r) => r.id === oldRow.id);
			if (index >= 0) {
				const updatedTable = [...prevRows].slice();

				if (rowValues.length > 0) {
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
					updatedTable.forEach((r, i) => (r.id = i));
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				const lastRowValues = lastRow
					? Object.entries(lastRow)
							.filter(([key]) => !['lossOfUse', 'heavy', 'id'].includes(key))
							.map(([key, r]) => (r ?? '').toString().trim())
							.join('')
					: '';
				if (lastRow && lastRowValues !== '') {
					const nextId = parseInt(lastRow.id);
					updatedTable.push({
						year: '',
						make: '',
						model: '',
						registration: '',
						coverage: '',
						sumInsured: '',
						...(isDomestic && { lossOfUse: '' }),
						heavy: false,
						id: isNaN(nextId) ? updatedTable.length : nextId + 1,
					});
				}
				return [...updatedTable].slice();
			}
			return prevRows;
		});

		return newRow;
	};

	const processPleasureCraftRowDeletion = (rowId) => {
		setPleasureCrafts((prev) => prev.filter((p) => p.id !== rowId));
	};

	const processPleasureCraftRowUpdate = (newRow, oldRow) => {
		const rowValues = Object.entries(newRow)
			.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
			.join('');
		console.log('ROW VALUES', rowValues);

		setPleasureCrafts((prevRows) => {
			const index = prevRows.findIndex((r) => r.id === oldRow.id);
			if (index >= 0) {
				const updatedTable = [...prevRows].slice();

				if (rowValues.length > 0) {
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
					updatedTable.forEach((r, i) => (r.id = i));
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				const lastRowValues = lastRow
					? Object.entries(lastRow)
							.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
							.join('')
					: '';
				if (lastRow && lastRowValues !== '') {
					const nextId = parseInt(lastRow.id);
					updatedTable.push({
						year: '',
						make: '',
						type: '',
						coverage: '',
						sumInsured: '',
						id: isNaN(nextId) ? updatedTable.length : nextId + 1,
					});
				}
				return [...updatedTable].slice();
			}
			return prevRows;
		});

		return newRow;
	};

	const processLocationDeletion = (index) => {
		setLocations((prev) => prev.filter((v, i) => i !== index));
	};

	const addLocation = () => {
		setLocations((prev) => {
			return [
				...prev,
				{
					address: '',
					rows: [
						{
							coverage: 'Buildings',
							basisOfSettlement: '',
							sumInsured: '',
							id: 0,
						},
						{
							coverage: 'Contents',
							basisOfSettlement: '',
							sumInsured: '',
							id: 1,
						},
						...(!isDomestic
							? [
									{
										coverage: 'Stock',
										basisOfSettlement: 'Indemnity',
										sumInsured: '',
										id: 2,
									},
							  ]
							: []),
					],
				},
			];
		});
	};

	const processLocationRowUpdate = (index, newRow, oldRow) => {
		setLocations((prevRows) => {
			return prevRows.map((row, i) => {
				if (i === index) {
					return {
						...row,
						rows: row.rows.map((r) => (r.id === newRow.id ? newRow : r)),
					};
				}
				return row;
			});
		});

		return newRow;
	};

	const processLocationUpdate = (index, location) => {
		setLocations((prevRows) => {
			return prevRows.map((row, i) => {
				if (i === index) {
					return {
						...row,
						address: location,
					};
				}
				return row;
			});
		});
	};

	useEffect(() => {
		setResultsTables((prev) => {
			return {
				...prev,
				...(prev[MATERIAL_DAMAGE] && {
					[MATERIAL_DAMAGE]: prev[MATERIAL_DAMAGE].map((row) => {
						const nhi = getDefaultNhi(MATERIAL_DAMAGE);
						const fsl = getDefaultFsl(MATERIAL_DAMAGE);
						const { gst, premium, companyPremium } = calculateGstFromRow({
							premium: row.premium,
							nhi,
							fsl,
						});
						return {
							...row,
							fsl,
							nhi,
							gst,
							companyPremium,
						};
					}),
				}),
			};
		});
	}, [locations, stockAndOtherProperty]);

	const generateRecommendationTable = () => {
		const field = 'myRecommendation';
		const index = Object.keys(formFields).indexOf(field);
		return (
			<>
				<Stack spacing={3} width='100%'>
					<RecommendationTable rows={recommendations} />
				</Stack>
				{generateFormFields(field, index)}
				<Stack spacing={1} width='100%'>
					<Typography fontWeight='bold'>{'Insurer Ratings Selection'}</Typography>
					<InsurerRatingTable
						rows={insurerTables}
						updateRows={(newRow, oldRow) =>
							processInsurerTableRowUpdate(newRow, oldRow)
						}
						removeRow={(rowId) => processInsurerTableRowDeletion(rowId)}
						disabled={isLoading}
					/>
				</Stack>
			</>
		);
	};

	const generateResultsTable = () => {
		const field = 'results';
		return (
			<Grid size={12}>
				<Stack spacing={6}>
					{Object.keys(resultsTables).length > 0 ? (
						Object.entries(resultsTables).map(([key, value]) => {
							return (
								<Stack key={`stack-${field}-${key}`} spacing={3}>
									<Stack spacing={1}>
										<Typography fontWeight='bold'>{formatKey(key)}</Typography>
										<ResultsTable
											key={`table-${field}-${key}`}
											setRowSelectionModel={(row) =>
												handleInsurerSelection(row, key)
											}
											classOfRisk={key}
											rowSelectionModel={insurerSelection[key]}
											rows={value}
											updateRows={(newRow, oldRow) =>
												processResultsTableRowUpdate(key, newRow, oldRow)
											}
											removeRow={(rowId) =>
												processResultsTableRowDeletion(key, rowId)
											}
											disabled={isLoading}
										/>
										<EndorsementsTable
											key={`endorsements-table-${field}-${key}`}
											setRowSelectionModel={(row) =>
												handleInsurerSelection(row, key)
											}
											rowSelectionModel={insurerSelection[key]}
											rows={value}
											updateRows={(newRow, oldRow) =>
												processResultsTableRowUpdate(key, newRow, oldRow)
											}
											disabled={isLoading}
										/>
									</Stack>
									<Stack spacing={1}>
										<GenerateRecommendationField
											{...{ field, fieldKey: key, disabled: isLoading }}
										/>
									</Stack>
								</Stack>
							);
						})
					) : (
						<Typography color={'rgba(0,0,0,0.5)'}>
							{'Please enter Sum Insured values in '}
							<span style={{ fontWeight: 500 }}>
								{'What Has Been Sent To Market'}
							</span>
							{' section'}
						</Typography>
					)}
				</Stack>
			</Grid>
		);
	};

	const generateMarketTable = () => {
		const field = 'whatHasBeenSentToMarket';
		return (
			<>
				<Grid size={12}>
					<WhatHasBeenSentToMarket
						key={`table-${field}`}
						rows={marketRows}
						updateRows={processWHBSTMRowUpdate}
						removeRow={processWHBSTMRowDeletion}
						disabled={isLoading}
					/>
				</Grid>
				{(Object.keys(resultsTables).includes(COMMERCIAL_MOTOR) ||
					Object.keys(resultsTables).includes(PRIVATE_VEHICLE)) &&
					generateMotorVehicleTable()}
				{Object.keys(resultsTables).includes(BOAT) && generatePleasureCraftTable()}
				{(Object.keys(resultsTables).includes(MATERIAL_DAMAGE) ||
					Object.keys(resultsTables).includes(HOUSE)) && (
					<GenerateStockAndOtherProperty
						key={`generate-market-table-${HOUSE}`}
						{...{
							locations,
							addLocation,
							disabled: isLoading,
							processLocationDeletion,
							processLocationRowUpdate,
							processLocationUpdate,
							setStockAndOtherProperty,
						}}
					/>
				)}
			</>
		);
	};

	const generateMotorVehicleTable = () => {
		const field = 'motorVehicle';
		return (
			<Stack spacing={1} py='1em' width='100%'>
				<Typography fontWeight='bold'>Vehicle information</Typography>
				<FormGroup row>
					<FormControlLabel
						control={
							<Checkbox
								checked={vehicleSchedule}
								onChange={() => setVehicleSchedule((prev) => !prev)}
							/>
						}
						label='See schedule for vehicle list'
					/>
				</FormGroup>
				{vehicleSchedule ? (
					<VehicleScheduleTable
						key={`table-${field}-vehicle-schedule`}
						rows={vehicleScheduleRows}
						updateRows={(newRow, oldRow) => {
							setVehicleScheduleRows((prev) =>
								prev.map((r) => ({
									...r,
									...(r.id === newRow.id && { ...newRow }),
								}))
							);
							return newRow;
						}}
						disabled={isLoading}
					/>
				) : (
					<MotorVehicleTable
						key={`table-${field}`}
						rows={motorVehicles}
						updateRows={processMotorVehicleRowUpdate}
						removeRow={processMotorVehicleRowDeletion}
						disabled={isLoading}
					/>
				)}
				{/* <DevJSONView value={motorVehicles} /> */}
			</Stack>
		);
	};

	const generatePleasureCraftTable = () => {
		const field = 'boat';
		return (
			<Stack spacing={1} py='1em' width='100%'>
				<Typography fontWeight='bold'>Pleasure Craft information</Typography>
				<MotorVehicleTable
					key={`table-${field}`}
					rows={pleasureCrafts}
					updateRows={processPleasureCraftRowUpdate}
					removeRow={processPleasureCraftRowDeletion}
					disabled={isLoading}
					isPleasureCraft={true}
				/>
				{/* <DevJSONView value={pleasureCrafts} /> */}
			</Stack>
		);
	};

	// const generateScopeOfAdvice = (field, key) => {
	// 	const formikKey = `${field}.${key}`;

	// 	return (
	// 		<Grid size={6} key={formikKey}>
	// 			{products.map((p) => p.title).includes(key) ? (
	// 				<ChangeProductSelection
	// 					product={products.find((p) => p.title === key)}
	// 					products={products}
	// 					removeProduct={() =>
	// 						setProducts((prev) => prev.filter((p) => p.title !== key))
	// 					}
	// 					onBlur={(title, value) => {
	// 						const index = products.findIndex((p) => p.title === key);
	// 						const newProducts = [...products];
	// 						newProducts[index] = {
	// 							title,
	// 							value,
	// 						};
	// 						setProducts(newProducts);
	// 					}}
	// 					disabled={isLoading}
	// 				/>
	// 			) : (
	// 				<>
	// 					<FormLabel>{formatKey(key)}</FormLabel>
	// 					<Select
	// 						disabled={Object.keys(formFields[field]).includes(key)}
	// 						fullWidth
	// 						{...formik.getFieldProps(formikKey)}
	// 						value={formik.values[field][key] ?? ''}
	// 						onChange={(e) => formik.setFieldValue(formikKey, e.target.value)}
	// 					>
	// 						{Object.entries(NEEDS_ANALYSIS_LABELS).map(([value, displayLabel]) => (
	// 							<MenuItem
	// 								value={value}
	// 								key={`${formikKey}-needs-analysis-selection-${value}`}
	// 							>
	// 								{displayLabel}
	// 							</MenuItem>
	// 						))}
	// 					</Select>
	// 				</>
	// 			)}
	// 		</Grid>
	// 	);
	// };

	const generateFormFields = (field, i) => {
		return Object.entries(formFields[field]).map(([key, value], index) => {
			const formikKey = `${field}.${key}`;

			if (field === 'scopeOfAdvice' && key !== 'commentsOnScope') {
				return (
					<GenerateScopeOfAdvice
						key={`generate-scope-of-advice-${formikKey}-${i}`}
						{...{
							field,
							fieldKey: key,
							products,
							setProducts,
							disabled: isLoading,
							formFields,
						}}
					/>
				);
				// return generateScopeOfAdvice(field, key);
			}
			return (
				<React.Fragment key={`render-fields-${field}-${i}-${index}`}>
					{field === 'scopeOfAdvice' && key === 'commentsOnScope' && (
						<AddProductButton
							onClick={() => {
								setProducts((prev) => [
									...prev,
									{
										title: `Product ${prev.length + 1}`,
										value: '',
									},
								]);
							}}
							disabled={isLoading}
						/>
					)}
					<Grid
						size={{
							xs: 12,
							sm: value.columns,
						}}
						key={formikKey}
					>
						<FormTextField
							field={field}
							formKey={key}
							value={value}
							disabled={isLoading}
						/>
					</Grid>
				</React.Fragment>
			);
		});
	};

	return (
		<Formik
			{...{
				initialValues,
				validateOnChange: false,
				// validationSchema,
				// enableReinitialize: true,
				onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
					setFormLoading(true);
					console.log('📋 ~ Formik values', {
						values,
						marketRows,
						resultsTables,
						motorVehicles,
						locations,
						// Endorsements: endorsements,
					});

					const formJson = generateFormJsonData(values);

					await generateSOA({
						dealId: deal?.hs_object_id,
						quoteId: latestQuote?.id,
						riskInfoId: latestRiskInfo?.id,
						userId: hubspotId,
						formJson,
					})
						.unwrap()
						.then((res) => {
							dispatch(
								showToast({
									message: 'Proposal generation in progress! Check back soon.',
									success: true,
								})
							);
							// resetForm();
							// resetFormFields();
						})
						.catch((err) => {
							console.log('Error generating proposal', err);
							dispatch(
								showToast({
									message: 'Error generating proposal; please try again.',
									error: true,
								})
							);
						});
					setSubmitting(false);
					setFormLoading(false);
				},
			}}
		>
			{({ isSubmitting, setFieldValue, values, handleSubmit }) => (
				<Form>
					<SOATableEffects
						{...{
							products,
							formFields,
							setMotorVehicles,
							setLocations,
							setMarketRows,
							setStockAndOtherProperty,
						}}
					/>
					<Stack>
						<Box display='flex' sx={{ alignItems: 'center' }}>
							<Button
								variant='text'
								onClick={() => navigate(`${path}/${deal.hs_object_id}`)}
								sx={{ color: 'black', fontWeight: 'bold' }}
								startIcon={<KeyboardBackspaceOutlinedIcon />}
							>
								{'Back to deal'}
							</Button>
						</Box>
						<Container sx={{ pt: 3 }}>
							<CardHeader
								disableTypography
								title={
									<Stack direction='row' justifyContent={'space-between'}>
										<PageHeader title={'Generate Statement of Advice'} />
										<DownloadSOAButton disabled={downloadButtonDisabled} />
									</Stack>
								}
							/>
							<CardContent>
								{isGenerating ? (
									<Stack direction='row' spacing={1} alignItems='center'>
										<CircularProgress size='1.25em' />
										<Typography>
											Currently generating SOA document. Please check back
											soon.
										</Typography>
									</Stack>
								) : (
									<Typography paragraph>
										Fill out the following form to generate a Statement of
										Advice
									</Typography>
								)}

								{Object.keys(formFields).map((field, i) => {
									return (
										<DetailsAccordion
											title={formatKey(field)}
											key={`field-${i}`}
										>
											<Grid container spacing={4}>
												{field === 'whatHasBeenSentToMarket'
													? generateMarketTable()
													: field === 'results'
													? generateResultsTable()
													: field === 'myRecommendation'
													? generateRecommendationTable()
													: generateFormFields(field, i)}
											</Grid>
										</DetailsAccordion>
									);
								})}
							</CardContent>
							<CardActions
								sx={{
									width: '100%',
									justifyContent: 'space-between',
									position: 'sticky',
									bottom: 0,
									py: '1em',
									backgroundColor: 'rgb(250, 253, 255)',
									zIndex: 9,
									borderTop: '1px solid rgba(224, 224, 224, 1)',
									// boxShadow: '0px -4px 6px -6px rgba(0,0,0,0.2)'
								}}
							>
								<Button
									variant='text'
									onClick={(e) => {
										e.preventDefault();
										navigate(`${path}/${deal.hs_object_id}`);
									}}
								>
									Exit
								</Button>
								<Button
									variant='contained'
									onClick={(e) => {
										e.preventDefault();
										setConfirmationOpen(true);
									}}
								>
									<Box px={'2em'}>Create</Box>
								</Button>
							</CardActions>
							{confirmationOpen && (
								<ConfirmEmailDialog
									{...{
										emailType: 'Statement of Advice',
										confirmationOpen,
										setConfirmationOpen,
										email,
										handleSendEmail: handleSubmit,
										emailExplanation: '',
									}}
								/>
							)}
							<DevJSONView value={{ ...values, locations, motorVehicles }} />
						</Container>
					</Stack>
					<Backdrop sx={{ backgroundColor: 'transparent' }} open={isGenerating}>
						<Box
							sx={{
								borderRadius: '50%',
								zIndex: 5,
								height: '150px',
								width: '150px',
								marginLeft: `${width}px`,
								backgroundImage: `url(${Robot})`,
								backgroundPosition: 'left',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
							}}
						/>
					</Backdrop>
				</Form>
			)}
		</Formik>
	);
};
export default CreateSOAForm;
