/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import {
	TextField,
	Typography,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Accordion,
	AccordionSummary,
	FormGroup,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import '@/styles/fileuploader.scss';
import {
	GOALS_OF_COVER,
	CLIENT_CATEGORY,
	CLIENT_CATEGORY_LABELS,
	SCOPE_OF_ADVICE,
	GOALS_OF_COVER_LABELS,
	CLIENT_TYPE,
	COMMERCIAL,
	DOMESTIC,
	SCOPE_OF_ADVICE_LABELS_COMMERCIAL,
	SCOPE_OF_ADVICE_LABELS_DOMESTIC,
	NEEDS_ANALYSIS,
	NEEDS_ANALYSIS_LABELS,
	FULL_NEEDS_ANALYSIS,
	ADDITIONAL_SPECIALIST_PRODUCTS,
	GENERAL_LIABILITY,
	MANAGEMENT_LIABILITY,
	STATUTORY_LIABILITY,
	EMPLOYERS_LIABILITY,
	BUSINESS_INTERRUPTION,
	MATERIAL_DAMAGE,
	ADDITIONAL_GOALS_OF_COVER,
} from './validationSchema';
import { ExpandMore } from '@mui/icons-material';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { NeedsAnalysisSelect } from '@/components/selects/NeedsAnalysisSelect';
import { RiskClasses } from '@/components/cards/DealCardValues/RiskClasses';

export const AdditionalScopeFields = ({ formik, loading, disabled }) => {
	const scopeOfAdviceArray = useMemo(
		() => formik.values[SCOPE_OF_ADVICE] ?? [],
		[formik.values[SCOPE_OF_ADVICE]]
	);

	const handleAnalysisChange = (name, value) => {
		formik.setFieldValue(name, value);
		const currentAnalysis = formik.values[NEEDS_ANALYSIS] || [];
		const updatedAnalysis = currentAnalysis.filter((item) => item.key !== name);
		updatedAnalysis.push({ key: name, value: value });
		console.log('updating array: ', updatedAnalysis);
		formik.setFieldValue(NEEDS_ANALYSIS, updatedAnalysis);
	};

	const getScopeOfAdviceLabels = () => {
		if (formik.values[CLIENT_TYPE] === COMMERCIAL) {
			return SCOPE_OF_ADVICE_LABELS_COMMERCIAL;
		}
		if (formik.values[CLIENT_TYPE] === DOMESTIC) {
			return SCOPE_OF_ADVICE_LABELS_DOMESTIC;
		}
		return {};
	};
	

	const scopeOfAdviceLabels = getScopeOfAdviceLabels();

	return (
		<><Grid container spacing={4}>
			<Grid size={12}>
				<FormLabel>{'Goals of Cover'}</FormLabel>
				<FormGroup row sx={{ width: '100%' }}>
					<Grid container sx={{ width: '100%' }}>
						{Object.entries(GOALS_OF_COVER_LABELS).map((entry) => {
							const [value, label] = entry;
							return (
								<Grid size={{ xs: 6, sm: 4 }} key={`goals-of-cover-${value}`}>
									<FormControlLabel
										size='small'
										control={<ClientCheckbox
											value={value}
											limitSelect={true}
											valuesArray={formik.values[GOALS_OF_COVER] ?? []}
											setValues={(newValues) => {
												formik.setFieldValue(
													GOALS_OF_COVER,
													newValues
												);
											} }
											disabled={loading} />}
										label={label} />
								</Grid>
							);
						})}
					</Grid>
				</FormGroup>
			</Grid>
			<Grid size={12}>
				<FormLabel>
					{'Additional goals of cover'}
				</FormLabel>
				<TextField
					fullWidth
					name={ADDITIONAL_GOALS_OF_COVER}
					multiline
					rows={4}
					{...formik.getFieldProps(ADDITIONAL_GOALS_OF_COVER)}
					disabled={loading} />
			</Grid>
			<Grid size={12}>
				<FormLabel>{'Risk Type'}</FormLabel>
				<FormGroup row>
					<RadioGroup
						row
						name={CLIENT_TYPE}
						value={formik.values[CLIENT_TYPE]}
						onChange={(e) => {
							formik.setFieldValue(CLIENT_TYPE, e.target.value);
							formik.setFieldValue(SCOPE_OF_ADVICE, []);
						} }
					>
						<FormControlLabel
							value={COMMERCIAL}
							disabled={disabled}
							control={<Radio />}
							label={'Commercial'} />
						<FormControlLabel
							value={DOMESTIC}
							disabled={disabled}
							control={<Radio />}
							label='Domestic' />
					</RadioGroup>
				</FormGroup>
			</Grid>
			<Grid size={12}>
				<FormLabel>{'Risk classes considered'}</FormLabel>
				<RiskClasses formik={formik} />
				{/* <FormGroup row>
        <Grid container>
            {Object.entries(scopeOfAdviceLabels)
                .filter(([value, label]) => {
                    if (
                        (value === STATUTORY_LIABILITY ||
                            value === EMPLOYERS_LIABILITY) &&
                        !scopeOfAdviceArray.includes(GENERAL_LIABILITY)
                    ) {
                        return false;
                    }
                    if (
                        value === BUSINESS_INTERRUPTION &&
                        !scopeOfAdviceArray.includes(MATERIAL_DAMAGE)
                    ) {
                        return false;
                    }
                    return true;
                })

                .map(([value, label]) => (
                    <Grid size={{ xs: 6, md: 4 }} key={`scope-of-advice-${value}`}>
                        <FormControlLabel
                            size='small'
                            control={
                                <ClientCheckbox
                                    value={value}
                                    valuesArray={scopeOfAdviceArray}
                                    setValues={(newValues) => {
                                        console.log(
                                            'here are the new values: ',
                                            newValues
                                        );
                                        if (
                                            newValues.includes(
                                                MANAGEMENT_LIABILITY
                                            ) &&
                                            !scopeOfAdviceArray.includes(
                                                MANAGEMENT_LIABILITY
                                            )
                                        ) {
                                            newValues = Array.from(
                                                new Set(
                                                    newValues.concat([
                                                        GENERAL_LIABILITY,
                                                        STATUTORY_LIABILITY,
                                                        EMPLOYERS_LIABILITY,
                                                    ])
                                                )
                                            );
                                        }
                                        formik.setFieldValue(
                                            SCOPE_OF_ADVICE,
                                            newValues
                                        );

                                        const updatedAnalysis = [
                                            ...(
                                                formik.values[NEEDS_ANALYSIS] || []
                                            ).filter(
                                                (item) =>
                                                    !newValues.includes(item.key)
                                            ),
                                            ...newValues.map((v) => {
                                                const existing = (
                                                    formik.values[NEEDS_ANALYSIS] ||
                                                    []
                                                ).find((k) => k.key == v);
                                                return {
                                                    key: v,
                                                    value:
                                                        existing?.value ??
                                                        'clientQuoted',
                                                };
                                            }),
                                        ];

                                        formik.setFieldValue(
                                            NEEDS_ANALYSIS,
                                            updatedAnalysis
                                        );
                                    }}
                                    disabled={loading}
                                />
                            }
                            label={label}
                        />
                    </Grid>
                ))}
        </Grid>
    </FormGroup> */}
			</Grid>
		</Grid><Grid container spacing={2}>
			{scopeOfAdviceArray.includes('fullNeedsAnalysis')
				? Object.entries(scopeOfAdviceLabels)
					.filter(([value]) => value !== FULL_NEEDS_ANALYSIS)
					.map(([value, label]) => (
						<Grid size={6} key={`needs-scope-selection-${value}`}>
							<NeedsAnalysisSelect
								label={label}
								analysisValue={(formik.values[NEEDS_ANALYSIS] ?? []).find(
									(v) => v.key == value
								)?.value}
								disabled={disabled}
								needsValues={NEEDS_ANALYSIS_LABELS}
								setAnalysisValue={(val) => handleAnalysisChange(value, val)} />
						</Grid>
					))
				: scopeOfAdviceArray.map((value) => (
					<Grid size={6} key={`needs-scope-selection-${value}`}>
						<NeedsAnalysisSelect
							analysisValue={(formik.values[NEEDS_ANALYSIS] ?? []).find(
								(v) => v.key == value
							)?.value}
							disabled={disabled}
							needsValues={NEEDS_ANALYSIS_LABELS}
							setAnalysisValue={(val) => handleAnalysisChange(value, val)}
							label={formik.values[CLIENT_TYPE] === COMMERCIAL
								? SCOPE_OF_ADVICE_LABELS_COMMERCIAL[value]
								: SCOPE_OF_ADVICE_LABELS_DOMESTIC[value]} />
					</Grid>
				))}
		</Grid><Grid size={12} sx={{ marginTop: 5 }}>
			<FormLabel>
				{'Additional specialist insurance products discussed and the outcomes'}
			</FormLabel>
			<TextField
				fullWidth
				name={ADDITIONAL_SPECIALIST_PRODUCTS}
				multiline
				rows={4}
				{...formik.getFieldProps(ADDITIONAL_SPECIALIST_PRODUCTS)}
				disabled={loading} />
		</Grid></>
	);
};
