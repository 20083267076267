import React, { useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { showToast } from '@/features/toast/toastSlice';
import { contactsApi, useCreateContactMutation, useUpdateClientContactRelationshipMutation, useQueryContactMutation } from '@/features/contacts/contactsApi'; // added useQueryContactMutation
import validationSchema from './validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { ContactFields } from '@/components/forms/CreateNewBusinessForm/ContactFields';
import { CONTACT_ERROR, EMAIL, EXISTINGCONTACT, FIRST_NAME, IS_MAIN_CONTACT, LAST_NAME, PHONE, ROLE } from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';

export const AddContactDialog = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);
	const { client } = determineContext();
	const open = useSelector((state) => state.dialog.open['contact']);

	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const hubspotId = userDetails?.hubspotId;

	const brokerGroupId = userDetails.brokerGroupArray ?? userDetails?.brokerId;
	const initialValues = {
		contacts: [{
			[FIRST_NAME]: '',
			[LAST_NAME]: '',
			[PHONE]: '',
			[EMAIL]: '',
			[IS_MAIN_CONTACT]: false,
			[ROLE]: '',
			[EXISTINGCONTACT]: false,
		}],
		[CONTACT_ERROR]: false,
		errorMessage: ''
	};

	const [createContact] = useCreateContactMutation();
	const [updateClientRelationship, clientRelationshipState] = useUpdateClientContactRelationshipMutation();
	const [queryContact] = useQueryContactMutation();  

	const handleClose = () => {
		dispatch(closeDialog('contact'));
	};
	const handleCreateContact = async (values) => {
		const body = {
			contacts: formik.values.contacts,
			hubspotId: hubspotId,
			clientDocId: client?.clientRow?.docId,
			clientInsightId: client?.clientRow?.insightId,
		};
		console.log('📋 ~ Create contact object', body);
	
		try {
			const res = await createContact(body).unwrap();
			console.log('res:', res);
			if (res?.createdContacts?.length > 0) {
				await Promise.all(
					res.createdContacts.map(async (contact) => {
						if (contact.relationshipDocId) {
							await updateClientRelationship({
								clientRelationshipId: contact.relationshipDocId, 
								property: { isMainContact: contact?.isMainContact }, 
								clientDocId: client?.clientRow?.docId,
								clientId: client?.hs_object_id,
							}).unwrap().catch((err) => console.log('erroring with relationship: ', err)
							);
						}
					})
				);
			}
			console.log('✅ Contacts created successfully', res);
			return res;
		} catch (error) {
			console.error('❌ Error creating contact:', error);
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			try {
				const res = await handleCreateContact(values);
				if (res) {
					console.log('🚀 ~ Successfully created contact', res);
					resetForm();
					handleClose();
				} else {
					console.log('🙅 ~ Error creating contact', res?.error);
				}
			} catch (err) {
				console.log('🙅 ~ Error creating contact', err);
				// setFieldValue('errorMessage', 'Error creating contact. Please try again');
			}
			setSubmitting(false);
		},
	});

	const { getError, getErrorMessage } = useFormikHelper(formik);
	

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{ minWidth: '100%', padding: '1em' }}
			maxWidth="md"
			maxHeight="md"
			fullWidth
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Add Contact</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<ContactFields formik={formik} loading={formik.isSubmitting} index={0} hideHeader={true} allowNonPrimary={true} />
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
    Cancel
					</Button>
					<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>

						<Button
							color="primary"
							type="submit"
							variant="contained"
							disabled={formik.isSubmitting || formik.errors.emailExists || formik.errors.contactError}
						>
							{formik.isSubmitting ? (
								<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
							) : (
								formik.values.contacts?.some(contact => contact?.[EXISTINGCONTACT]) ? 'Add' : 'Create'
							)}
						</Button>
					</DialogActions>

				</DialogActions>

			</form>
		</Dialog>
	);
};
