import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency, safelyParseFloat, safelyParseInt } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import DataGridYearPicker from '@/components/table/DataGridYearPicker';
import useCellModes from '@/hooks/useCellModes';
import { determineContext } from '@/hooks/determineContext';
import { CLIENT_TYPE, DOMESTIC } from '../../CreateNewBusinessForm/validationSchema';

export const MaterialDamageLocationTable = ({ rows, updateRows, disabled }) => {
	const { dealRow } = determineContext();
	const isDomestic = dealRow?.[CLIENT_TYPE] == DOMESTIC;

    const columns = [
		{
			field: 'coverage',
			headerName: 'Coverage',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			// renderCell: (params) =>
			// 	params.row.year === '' ? (
			// 		<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>{'Enter year'}</Typography>
			// 	) : (
			// 		params.row.year
			// 	),
			// renderEditCell: (params) => <DataGridYearPicker {...params} />,
		},
		{
			field: 'basisOfSettlement',
			headerName: 'Basis of Settlement',
			flex: 0.5,
			minWidth: 200,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.basisOfSettlement === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
						Enter basis of settlement
					</Typography>
				) : (
					params.row.basisOfSettlement
				),
		},
		{
			field: 'fslIndemnity',
			headerName: 'FSL Indemnity Value ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => {
				if (params.row.coverage === 'Buildings') {
					const value = safelyParseFloat(params.row.fslIndemnity ?? 0);
					if (value > 0) {
						return formatCurrency(value, false);
					} else {
						return (
							<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
								Enter FSL indemnity value
							</Typography>
						);
					}
				} else {
					return <></>;
				}
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} isCurrency={true} />,
		},
		{
			field: 'noOfResidential',
			headerName: 'Number of Residential Units',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => {
				if (params.row.coverage === 'Buildings') {
					const value = safelyParseInt(params.row.noOfResidential ?? 0);
					return value;
				} else {
					return <></>;
				}
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} />,
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			align: 'right',
			headerAlign: 'right',
			renderCell: (params) => {
				const value = safelyParseFloat(params.row.sumInsured ?? 0);
				if (value > 0) {
					return formatCurrency(value, false);
				} else {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter sum insured</Typography>
					);
				}
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} isCurrency={true} />,
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (params.field === 'fslIndemnity' || params.field === 'noOfResidential') {
					return params.row.coverage === 'Buildings' && !disabled;
				}
				return !disabled;
			}}
			// getRowId={(row) => `${row.year}-${row.make}-${row.model}`}
			// checkboxSelection
			// onRowSelectionModelChange={setRowSelectionModel}
			// rowSelectionModel={rowSelectionModel}
			// cellModesModel={cellModesModel}
			// onCellModesModelChange={handleCellModesModelChange}
			// onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
