import React, { useEffect, useMemo, useState } from 'react';
import { TextField, FormLabel, Select, MenuItem } from '@mui/material';
import { formatKey } from '@/utils/constants';
import { NumericFormat } from 'react-number-format';
import PerformantTextField from '@/components/fields/PerformantTextField';
import { useFormikContext } from 'formik';

const getNestedValue = (obj, path) => {
	return (path ?? '').split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};

export const FormTextField = ({ field, formKey, value, disabled }) => {
	const formik = useFormikContext();

	const formikKey = `${field}.${formKey}`;

	const values = useMemo(() => {
		if (value.type != 'dropdown') return [];
		if (Array.isArray(value?.values)) return value.values;
		const nestedValue = (getNestedValue(formik.values, value.dependsOn) ?? '').toLowerCase();
		return value?.values?.[nestedValue] ?? [];
	}, [value.dependsOn, formik.values, value.type]);

	return (
		<>
			<FormLabel>{`${formatKey(formKey)}${
				value.type === 'currency' ? ' ($)' : ''
			}`}</FormLabel>
			{value.type === 'currency' || value.type === 'numeric' ? (
				<NumericFormat
					name={formikKey}
					{...(value.type === 'currency' && { prefix: '$' })}
					decimalScale={value.decimals ?? 0}
					fixedDecimalScale
					thousandSeparator
					allowNegative={false}
					customInput={TextField}
					
					fullWidth
					{...formik.getFieldProps(formikKey)}
					onChange={() => {}}
					// value={formValue}
					// value={formik.values[formikKey]}
					onValueChange={({ floatValue }) => {
						formik.setFieldValue(formikKey, floatValue);
					}}
					disabled={disabled}
				/>
			) : value.type === 'dropdown' ? (
				<Select
					fullWidth
					// value={formik.values[formikKey] ?? ''}
					name={formikKey}
					{...formik.getFieldProps(formikKey)}
					onChange={(e) => formik.setFieldValue(formikKey, e.target.value)}
					disabled={(values ?? []).length === 0 || disabled}
				>
					{values.map((val, index) => (
						<MenuItem key={`${formikKey}-${val}-${index}`} value={val}>
							{val}
						</MenuItem>
					))}
				</Select>
			) : (
				<PerformantTextField
					fullWidth
					{...(value.lines && {
						multiline: true,
						rows: value.lines,
					})}
					name={formikKey}
					{...formik.getFieldProps(formikKey)}
					disabled={disabled}
				/>
			)}
		</>
	);
};
