
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	TextField,
	InputAdornment,
	IconButton,
	CircularProgress,
	Chip,
	Tooltip,
	Box,
	Stack,
	Skeleton,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { folioBlue } from '@/utils/constants';
import { IS_DEV } from '@/utils/environment';
import { InstantSearch, Configure, useHits, useSearchBox, Hits } from 'react-instantsearch';
import { contactsClient } from '@/utils/typesense';


const AutocompleteInput = ({
	query,
	refine,
	clear,
	hits,
	isSearching,
	setContact,
	contactIds,
	disabled,
	autoPopulate = false,
	multiple = false,
	loading = false,
}) => {
	const [open, setOpen] = useState(false);    
	const values = useMemo(() => hits.filter((c) => c.email !== null), [hits]);

	const handleSearch = () => {
		if (query.length > 0) {
			refine();
		}
	};

	const handleReset = () => {
		setContact(null);
		setOpen(false);
		clear();
		refine(''); 
	};


	return (
		<Autocomplete
			id='contact-select'
			loading={loading || isSearching}
			multiple={multiple}
			loadingText='Loading contacts...'
			options={autoPopulate ? values : query.length > 0 ? values : []}
			open={open}
			disabled={disabled}
			noOptionsText={query.length > 0 ? 'No contacts' : 'Type to search'}
			filterOptions={(x) => x}
			renderOption={(props, option) => (
				<Stack
					direction='row'
					alignItems={'center'}
					justifyContent={'space-between'}
					{...props}
					key={`client-select-${option.id}`}
					width={'100%'}
				>
					<Stack
						direction='row'
						alignItems={'center'}
						justifyContent={'space-between'}
						width={'100%'}
					>
						<Typography>{`${option.firstName} ${option.lastName}`}</Typography>
						<Typography variant='task_updated'>{option.email}</Typography>
					</Stack>
				</Stack>
			)}
			inputValue={query}
			onOpen={() => setOpen(true)}
			onClose={() => {
				setOpen(false);
				if(query.length === 0) {
					handleReset();
				}
			}}
			getOptionLabel={(option) => option.email}
			onInputChange={(_, value, reason) => {
				if (reason !== 'reset') {
					refine(value);
				}
			}}
			renderInput={(params) => (
				<TextField
					placeholder={'Search by name, phone and email or create a new contact'}
					{...params}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSearch();
						}
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position='start'>
								<IconButton
									disabled={isSearching || disabled}
									sx={{ padding: '0' }}
									onClick={handleSearch}
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								{isSearching ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
								{query.length > 0 && (
									<IconButton
										size='small'
										disabled={isSearching || disabled}
										sx={{ padding: '0' }}
										onClick={handleReset}
									>
										<ClearIcon fontSize='20px' />
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
				/>
			)}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			onChange={(_, value) => {
				setContact(value);
			}}
			{...(multiple && { value: values })}
		/>
	);
};

export const InstantContactSelect = ({
	setContact,
	contactIds,
	disabled,
	autoPopulate,
	multiple = false,
	hideInput = false,
}) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const userQuery = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const brokerGroupId = userQuery.data?.brokerId;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];

	const filterSchema = useMemo(() => {
		const ownerFilter = `brokerGroupId: [${brokerGroupIds.join(',')}]`;


		const schema = {
			filters: ownerFilter,
		};

		// console.log('FILTER SCHEMA', schema);
		return schema;
	}, [brokerGroupIds, hideInput]);

	return (
		<InstantSearch searchClient={contactsClient} indexName='contacts'>
			<Configure hitsPerPage={25} {...filterSchema} />
			<InstantContactSelectWrapper
				autoPopulate={autoPopulate}
				setContact={setContact}
				contactIds={contactIds}
				disabled={disabled || userQuery.isLoading}
				// setIsTyping={setIsTyping}
				multiple={multiple}
				hideInput={hideInput}
			/>
		</InstantSearch>
	);
};

const InstantContactSelectWrapper = ({
	setContact,
	contactIds,
	disabled,
	setIsTyping,
	autoPopulate,
	multiple = false,
	hideInput = false,
	...props
}) => {
	const { items } = useHits(props);

	const hits = items.map((hit) => ({
		...hit,
		id: hit.hubspotId ?? hit.dealId ?? hit.id,
		docId: hit.id,
	}));

	const updatedHits = useMemo(() => {
		const hhhits = hits?.map((d) => ({
			...d,
		}));
		return hhhits;
	}, [hits]);

	// const selectedContacts = useMemo(
	// 	() => updatedHits.filter((hit) => contactIds?.includes(hit.id)),
	// 	[updatedHits, contactIds]
	// );

	// useEffect(() => console.log('SELECTED CONTACTS', selectedDeals), [selectedDeals]);

	const searchBoxApi = useSearchBox(props);

	// useEffect(() => {
	// 	if (searchBoxApi.query.length > 0) {
	// 		setIsTyping(true);
	// 	} else {
	// 		setIsTyping(false);
	// 	}
	// }, [searchBoxApi.query, setIsTyping]);

	// Cannot remove deal if the id is in the disabledDealIds array
	const handleRemoveContact = (contact) => {
		console.log('here is the contact: ', contact);
		// const newIds = Array.from(new Set([...contactIds.filter((id) => id != dealId)]));
		// setContact(updatedHits.filter((hit) => newIds.includes(hit.id)));
	};

	const handleSetContact = (newValue) => {
		const currentContactIds = contactIds || []; 
		// if (multiple) {
		// 	const newVals = Array.isArray(newValue) ? newValue.map((d) => d.id) : [newValue.id];
		// 	const newIds = Array.from(new Set([...currentContactIds, ...newVals]));
		// 	const toRemove = currentContactIds.filter((id) => !newVals.includes(id));
		// 	if (toRemove.length > 0) {
		// 		toRemove.forEach((id) => handleRemoveContact(id));
		// 	} else {
		// 		setContact(updatedHits.filter((hit) => newIds.includes(hit.id)));
		// 	}
		// } else {
		setContact(newValue);
		// }
	};

	return (
		<Stack width={'100%'}>
			{/* {multiple && selectedDeals.length > 0 && (
				<Grid container spacing={1} py={'1em'}>
				{selectedDeals.map((d) => (
						<Chip
							key={`selected-deal-${d.dealId}`}
							size={'small'}
							// color={colour}
							// sx={{
							//     backgroundColor: colour,
							//     color: 'rgb(255, 255, 255)'
							// }}
							// label={d.dealName}
							// {...(!disabledDealIds.includes(d.dealId) && {
							// 	onDelete: () => handleRemoveDeal(d.dealId),
							// })}
						/>
					))}	
				</Grid>
			)} */}
			{!hideInput && (
				<>
					<AutocompleteInput
						hits={updatedHits}
						setContact={handleSetContact}
						contactIds={contactIds}
						disabled={disabled}
						autoPopulate={autoPopulate}
						multiple={multiple}
						// disabledDealIds={disabledDealIds}
						// loading={loading}
						{...searchBoxApi}
					/>
				</>
			)}
		</Stack>
	);
};
