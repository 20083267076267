/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo } from 'react';
import {
	useDeleteDealMutation,
	useGetDealPoliciesQuery,
	useGetDealPropertiesQuery,
	useGetDealRowQuery,
} from '@/features/deals/dealsApi';
import {
	useGetAIDocsQuery,
	useGetDealNoteSummariesQuery,
	useGetDealThreadIdsQuery,
	useGetLatestDealSOAsQuery,
} from '@/features/factFind/factFindApi';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetClientPropertiesQuery } from '@/features/clients/clientsApi';
import {
	useGetAllDealActivitiesQuery,
	useGetDealComplianceTasksQuery,
	useGetEngagementsQuery,
} from '@/features/engagements/engagementsApi';
import { useGetContactPropertiesQuery, useRetrieveAllContactsByIdQuery } from '@/features/contacts/contactsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	useGetTicketInsightClaimQuery,
	useGetTicketPropertiesQuery,
} from '@/features/claims/ticketsApi';
import { useClientStorageHooks } from './useClientStorageHooks';
import {
	setCount,
	setLoading,
	setLoadingActivities,
} from '@/features/engagements/engagementsSlice';
import { getPipelineName } from '@/utils/constants';
import { useGetFormsQuery } from '@/features/forms/formsApi';
import getCorrectFolderOwner from './getCorrectFolderOwner';
import { useGetFoldersAndFilesQuery } from '@/features/msGraph/msGraphApi';
import { MSG_TYPE } from '@/utils/folders';

export const initialState = {
	deal: {},
	folderOwnerId: null,
	dealstage: null,
	client: {},
	isProspect: false,
	forms: [],
	pipeline: '',
	pipelines: {},
	tasks: [],
	complianceTasks: [],
	activities: [],
	notes: [],
	calls: [],
	emails: [],
	noteSummaries: [],
	aiDocs: [],
	quotes: [],
	riskInfo: [],
	soas: [],
	dealRow: {},
	threadIds: {},
	loading: {
		deal: true,
		client: true,
		contact: true,
		aiDocs: true,
		noteSummaries: true,
	},
	sharepoint: {
		sharepointSite: '',
		clientSiteSuffix: '',
		clientFolder: '',
		clientSite: '',
		isValid: false,
	},
	files: [],
	policies: [],
	claim: {},
	dealstageId: '',
	dealsPipelineData: {},
	contact: {},
	contacts: [],
	ownerId: null,
};

const useDealData = (objectType) => {
	const dispatch = useDispatch();

	const id = useMemo(() => {
		const pathnames = window.location.pathname.split('/');
		return pathnames.find((i) => !isNaN(parseInt(i)));
	}, [window.location.pathname]);

	const { account, accessToken } = useSelector((state) => state.msalAccount);
	const { refreshIndicator } = useSelector((state) => state.attachmentsTable);
	const email = account?.username;

	const { data: loggedInUser } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const dealRowQuery = useGetDealRowQuery(id, { skip: !id });
	const dealRow = useMemo(() => dealRowQuery.data, [dealRowQuery.data]);

	const filesQuery = useGetFoldersAndFilesQuery(
		{
			itemId: dealRow?.driveId,
			driveId: dealRow?.clientFolderDriveId,
			recursive: true,
		},
		{ skip: !accessToken || !dealRow?.driveId || !dealRow?.clientFolderDriveId }
	);

	useEffect(() => {
		if (filesQuery.data) {
			filesQuery.refetch();
		}  
	}, [refreshIndicator]);

	const msgFiles = useMemo(
		() => (filesQuery.data?.files ?? []).filter((f) => f?.file?.mimeType == MSG_TYPE),
		[filesQuery.data?.files]
	);

	const canViewDeal = (loggedInUser?.brokerGroupArray ?? [loggedInUser?.brokerId]).includes(
		dealRow?.brokerGroupId
	);

	const dealQuery =
		objectType == 'deal' ? useGetDealPropertiesQuery(id) : useGetTicketPropertiesQuery(id);
	const pipelineId = dealQuery.data?.pipeline;

	const [deleteDeal, { isLoading: isDeleting }] = useDeleteDealMutation({
		fixedCacheKey: `delete-deal-${id}-${objectType}`,
	});

	const dealstage =
		dealQuery.data?.properties?.dealstage ?? dealQuery.data?.properties?.hs_pipeline_stage;
	const pipelineQuery = useGetPipelinePropertiesQuery(pipelineId, { skip: !pipelineId });

	const ownerId = dealQuery.data?.properties?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });

	const clientId = dealRow?.clientHubspotId;
	const clientQuery = useGetClientPropertiesQuery(clientId, {
		skip: !clientId || !canViewDeal,
	});

	const clientRow = clientQuery.data?.properties?.clientRow;
	const isProspect = clientRow?.isProspect;

	const { correctOwner: folderOwnerId, loading: loadingOwner } = getCorrectFolderOwner({
		deal: dealQuery.data,
		client: clientQuery.data,
	});

	const folderOwnerQuery = useGetUserDetailsQuery(
		{ hubspotId: folderOwnerId },
		{ skip: !folderOwnerId }
	);

	const isValidSharepoint =
		folderOwnerQuery.data?.sharepointSite != null &&
		folderOwnerQuery.data?.clientSiteSuffix != null &&
		folderOwnerQuery.data?.clientFolder != null &&
		folderOwnerQuery.data?.clientSite != null;

	const brokerGroupId = clientRow?.brokerGroupId;

	const contactId = dealQuery.data?.contactId ?? clientQuery.data?.contacts[0];
	const contactEmail = clientRow?.contactEmail;

	//gets the specific forms based on the deals pipeline
	const dealPipeline = getPipelineName(pipelineId);
	const formsQuery = useGetFormsQuery(
		{ dealPipeline: dealPipeline, brokerRef: brokerGroupId },
		{ skip: !dealPipeline || !brokerGroupId || !canViewDeal }
	);

	const contactQuery = useGetContactPropertiesQuery(
		{ hubspotId: contactId, email: contactEmail },
		{ skip: !contactId || !contactEmail || !canViewDeal }
	);

	const engagementsQuery = useGetEngagementsQuery(
		{ dealId: id, objectType },
		{ skip: !id || !canViewDeal }
	);

	const complianceTasksQuery = useGetDealComplianceTasksQuery(
		{ dealId: id },
		{ skip: !id || !canViewDeal }
	);

	const activitiesQuery = useGetAllDealActivitiesQuery(
		{ dealId: id },
		{ skip: !id || !canViewDeal }
	);

	const insightClaimQuery = useGetTicketInsightClaimQuery(id, {
		skip: pipelineId != process.env.REACT_APP_PIPELINE_CLAIMS || !canViewDeal,
	});

	const threadIdsQuery = useGetDealThreadIdsQuery(id, { skip: !id || !canViewDeal });

	// const [msgFiles, setMsgFiles] = useState([]);

	const latestDealSOAsQuery = useGetLatestDealSOAsQuery(
		{ dealId: id },
		{ skip: !id || !canViewDeal }
	);

	//Fetchs Clents contacts
	const { data: contacts } = useRetrieveAllContactsByIdQuery(
		{
			docId: clientRow?.docId,
			objectType: 'client',
			objectId: id,
			hubspotId: loggedInUser?.hubspotId,
			brokerGroupId: brokerGroupId,
		},
		{ skip: !clientRow?.docId }
	);
	

	const { createClientFolder, updateClientGraphProperties } = useClientStorageHooks(
		folderOwnerQuery.data
	);

	const createSharepoint = async () => {
		try {
			const date = dealRow?.createDate ? new Date(dealRow?.createDate) : new Date();

			await createClientFolder(id, {
				deal: dealQuery.data?.properties,
				client: clientQuery.data?.properties,
				objectType,
				...(dealRow?.yearRoot ? { yearRoot: dealRow.yearRoot.split('/')[1] } : { date }),
			});
		} catch (error) {
			console.log(`Error creating deal folder for deal ${dealRow?.name}`, error);
		}
	};

	useEffect(() => {
		const updateMsGraph = async () => {
			if (dealRow?.driveId && dealRow?.clientFolderDriveId) {
				// console.log(
				// 	'dealRow?.driveId && dealRow?.clientFolderDriveId',
				// 	dealRow?.driveId,
				// 	dealRow?.clientFolderDriveId
				// );
				// const { files } = await getClientGraphFoldersAndFiles(
				// 	dealRow?.driveId,
				// 	dealRow?.clientFolderDriveId,
				// 	true
				// );
				// const newMsgFiles = (files ?? []).filter(
				// 	(f) => f?.file?.mimeType == 'application/vnd.ms-outlook'
				// );
				// setMsgFiles(
				// 	newMsgFiles.slice().map((f) => ({
				// 		...f,
				// 		lastUpdated: new Date(f.lastModifiedDateTime).getTime(),
				// 	}))
				// );
			} else {
				console.log('no drive id or clientfolderdrive id', clientRow, dealRow);
				const res = await updateClientGraphProperties(clientRow, dealRow);
				console.log('const res = await updateClientGraphProperties', res);
				if (
					!res?.driveId &&
					isValidSharepoint &&
					folderOwnerQuery.data?.sharepointSiteId &&
					folderOwnerQuery.data?.clientFolderDriveId
				) {
					await createSharepoint();
				}
			}
			dispatch(setLoading(false));
		};
		console.log('useEffect', { canViewDeal, clientRow, dealRow });
		if (canViewDeal && clientRow && dealRow) {
			dispatch(setLoading(true));
			updateMsGraph();
		}
	}, [
		folderOwnerQuery.data,
		canViewDeal,
		clientRow,
		dealRow,
		clientRow?.driveId,
		dealRow?.driveId,
		dealRow?.clientFolderDriveId,
		isValidSharepoint,
	]);

	useEffect(() => {
		dispatch(
			setLoading(
				engagementsQuery.isFetching ||
					activitiesQuery.isFetching ||
					engagementsQuery.isLoading ||
					activitiesQuery.isLoading ||
					loadingOwner
			)
		);
		dispatch(setLoadingActivities(activitiesQuery.isFetching || activitiesQuery.isLoading));
	}, [
		engagementsQuery.isFetching,
		activitiesQuery.isFetching,
		engagementsQuery.isLoading,
		activitiesQuery.isLoading,
		loadingOwner,
	]);

	useEffect(() => {
		if (engagementsQuery.data?.tasks) {
			const tasks = engagementsQuery.data?.tasks ?? [];
			const incompleteTasks = tasks.filter((t) => !t.completed);
			dispatch(setCount({ key: 'tasks', count: tasks.length }));
			dispatch(setCount({ key: 'incompleteTasks', count: incompleteTasks.length }));
		}
	}, [engagementsQuery.data]);

	const policiesQuery = useGetDealPoliciesQuery({ dealId: id }, { skip: !id || !canViewDeal });
	// console.log('deal policies data: ', policiesQuery.data);
	const noteSummariesQuery = useGetDealNoteSummariesQuery(
		{ dealId: id },
		{ skip: !id || !canViewDeal }
	);
	const aiDocsQuery = useGetAIDocsQuery(
		{ dealId: id, type: 'ALL' },
		{ skip: !id || !canViewDeal }
	);

	const quotes = useMemo(() => {
		const data = (aiDocsQuery.data ?? []).filter((e) => e.type === 'quote');
		const sortedData = data.sort((a, b) => {
			if (a.createdWhen._seconds !== b.createdWhen._seconds) {
				return b.createdWhen._seconds - a.createdWhen._seconds;
			}
			return b.createdWhen._nanoseconds - a.createdWhen._nanoseconds;
		});
		console.log('Sorted quotes', sortedData);
		return sortedData;
	}, [aiDocsQuery.data]);

	const riskInfo = useMemo(() => {
		const data = (aiDocsQuery.data ?? []).filter((e) => e.type === 'wytu');
		const sortedData = data.sort((a, b) => {
			if (a.createdWhen._seconds !== b.createdWhen._seconds) {
				return b.createdWhen._seconds - a.createdWhen._seconds;
			}
			return b.createdWhen._nanoseconds - a.createdWhen._nanoseconds;
		});
		console.log('Sorted risk info', sortedData);
		return sortedData;
	}, [aiDocsQuery.data]);

	// Aggregate data and status from all queries to manage loading and error states
	const isLoading = [
		pipelineQuery.isLoading,
		clientQuery.isLoading,
		contactQuery.isLoading,
		// engagementsQuery.isLoading,
		// activitiesQuery.isLoading,
		policiesQuery.isLoading,
		aiDocsQuery.isLoading,
		dealRowQuery.isLoading,
		formsQuery.isLoading,
	].some((status) => status === true);

	const isError = [
		pipelineQuery.isError,
		clientQuery.isError,
		activitiesQuery.isError,
		contactQuery.isError,
		engagementsQuery.isError,
		policiesQuery.isError,
		aiDocsQuery.isError,
		dealRowQuery.isError,
	].some((status) => status === true);

	const contextValue = {
		objectType,
		deal: {
			...(dealQuery.data && {
				...dealQuery.data?.properties,
				createdAt: dealQuery.data?.createdAt,
			}),
		},
		folderOwnerId,
		forms: formsQuery.data?.data ?? [],
		dealRow: dealRowQuery.data ?? {},
		threadIds: threadIdsQuery.data ?? {},
		policies: policiesQuery.data?.policies ?? [],
		transactions: policiesQuery.data?.transactions ?? [],
		claim: insightClaimQuery?.data?.data?.Claim ?? {},
		dealstage: pipelineQuery.data?.stages[dealstage]?.label ?? dealstage,
		dealstageId: dealstage,
		dealsPipelineData: pipelineQuery.data ?? {},
		client: clientQuery.data?.properties ?? {},
		isProspect,
		contact: contactQuery.data?.properties ?? {},
		contacts: contacts?.data || [],
		pipeline: pipelineQuery.data?.pipelines?.label ?? '',
		pipelines: pipelineQuery.data?.pipelines ?? {},
		ownerId,
		tasks: engagementsQuery.data?.tasks ?? [],
		notes: engagementsQuery.data?.notes ?? [],
		calls: engagementsQuery.data?.calls ?? [],
		emails: engagementsQuery.data?.emails ?? [],
		complianceTasks: complianceTasksQuery.data ?? [],
		msgFiles,
		activities: activitiesQuery.data?.data ?? [],
		sharepoint: {
			sharepointSite: folderOwnerQuery.data?.sharepointSite,
			clientSiteSuffix: folderOwnerQuery.data?.clientSiteSuffix,
			clientFolder: folderOwnerQuery.data?.clientFolder,
			clientSite: folderOwnerQuery.data?.clientSite,
			isValid: isValidSharepoint,
		},
		aiDocs: aiDocsQuery.data ?? [],
		noteSummaries: noteSummariesQuery.data ?? [],
		quotes,
		riskInfo,
		soas: latestDealSOAsQuery.data ?? [],
		loading: {
			deal:
				dealQuery.isLoading ||
				isDeleting ||
				pipelineQuery.isLoading ||
				userQuery.isLoading ||
				dealQuery.isUninitialized ||
				pipelineQuery.isUninitialized ||
				userQuery.isUninitialized,
			client: clientQuery.isLoading || clientQuery.isUninitialized,
			contact: contactQuery.isLoading || contactQuery.isUninitialized,
			forms: formsQuery.isLoading || formsQuery.isUninitialized,
			aiDocs: aiDocsQuery.isLoading || aiDocsQuery.isUninitialized,
			noteSummaries: noteSummariesQuery.isLoading || noteSummariesQuery.isUninitialized,
		},
	};

	// const { createClientFolder } = useClientStorageHooks((userQuery.data));

	// useEffect(() => {
	// 	const createIfNecessary = async () => {
	// 		await createClientFolder(id, contextValue);
	// 	};
	// 	if (!isLoading && clientQuery.data && (!dealRowQuery.data || !dealRowQuery.data?.dealFolderPath)) {
	// 		createIfNecessary();
	// 	}
	// }, [dealRowQuery.data, clientQuery.data, id, isLoading]);

	return { id, contextValue, isLoading, isError };
};

export default useDealData;
