import { folderStructure, isYearFolder } from './constants';

export const MSG_TYPE = 'application/vnd.ms-outlook';

const nonDeletableFolderNames = Object.values(folderStructure).map((folder) =>
	decodeURI(folder.normalize())
);

export const noteFolders = [folderStructure.clientNotes, folderStructure.notes].map((folder) =>
	decodeURI(folder.normalize())
);

const nonDeleteParents = [
	folderStructure.claims,
	folderStructure.cover,
	folderStructure.endorsements,
	folderStructure.renewal,
].map((folder) => decodeURI(folder.normalize()));

export const determineIfFolderDeletable = (file) => {
	const isFolder = !Object.keys(file).includes('file');
	const name = (file.name ?? '').normalize();

	let cannotDelete = false;

	try {
		cannotDelete =
			(isFolder &&
				(nonDeletableFolderNames.includes(decodeURI(name)) ||
					isYearFolder(name) ||
					nonDeleteParents.includes(decodeURI(file.parentReference.name)))) ||
			(!isFolder && noteFolders.includes(decodeURI(file.parentReference.name)));
	} catch (err) {
		console.log(`Error decoding ${file.name}`, err);
		cannotDelete =
			(isFolder &&
				(nonDeletableFolderNames.includes(name) ||
					isYearFolder(name) ||
					nonDeleteParents.includes(file.parentReference.name))) ||
			noteFolders.includes(file.parentReference.name);
	}
	return cannotDelete;
};

export const determineIfCanCreateFolder = (file) => {
	console.log('FILE', file);
	const isFolder = !Object.keys(file).includes('file');
	if (!isFolder) {
		return false;
	}
	const name = (file.name ?? '').normalize();

	let cannotCreate = false;

	try {
		cannotCreate =
			nonDeleteParents.includes(decodeURI(name)) ||
			isYearFolder(decodeURI(name)) ||
			noteFolders.includes(decodeURI(name)) ||
			(file?.parentReference?.name &&
				isYearFolder(decodeURI(file.parentReference.name)));
		// || nonDeleteParents.includes(decodeURI(file.parentReference.name))
	} catch (err) {
		console.log(`Error decoding ${file.name}`, err);
		cannotCreate =
			nonDeleteParents.includes(name) ||
			isYearFolder(name) ||
			noteFolders.includes(name) ||
			(file?.parentReference?.name &&
				isYearFolder(file.parentReference.name));
		// || nonDeleteParents.includes(file.parentReference.name)
	}
	return cannotCreate;
};

export const createUniqueFileName = (originalName) => {
	const timestamp = new Date().getTime();
	const [name, extension] = originalName.split('.');
	return `${name}_${timestamp}.${extension}`;
};