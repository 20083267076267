import React, { useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useSelector } from 'react-redux';

export const DealTableRefresh = ({ table = 'dealTable' }) => {
	const { results, refresh } = useInstantSearch();

	const refreshIndicator = useSelector((state) => state[table]?.refreshIndicator);

	useEffect(() => console.log('RESULTS', results), [results]);

	useEffect(() => {
		console.log('REFRESH INDICATOR', refreshIndicator);
		refresh();
	}, [refreshIndicator]);

	return null;
};
