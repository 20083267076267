import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import BASE_QUERY from '@/features/baseQuery';
import { IS_DEV } from '@/utils/environment';
import uniq from 'lodash.uniq';
import { featureFlagsApi } from '../featureFlags/featureFlagsApi';
import { collection, doc, updateDoc, query, where, getDocs, getDoc } from 'firebase/firestore';
import { firestore } from '@/utils/firebase';

export const userApi = createApi({
	reducerPath: 'userApi',
	tagTypes: ['USER', 'BROKER_GROUP'],
	baseQuery: fetchBaseQuery(BASE_QUERY),
	keepUnusedDataFor: 300,
	endpoints: (builder) => ({
		// Defining an endpoint to get properties for user by email
		getUserDetails: builder.query({
			providesTags: (result, error, arg) => [
				{ type: 'USER', id: arg.email },
				{ type: 'USER', id: arg.hubspotId },
			],
			query: ({ email, hubspotId }) => ({
				url: process.env.REACT_APP_INIT_USER,
				method: 'POST',
				body: { email, hubspotId },
			}),
			transformErrorResponse: (response) => {
				console.log('👾 ~ User error response', response);
				return response;
			},
			transformResponse: (response) => {
				console.log('👾 ~ User response', response);
				const broker = response.broker.data;
				const ledger = broker.ledger ?? (IS_DEV ? 'tstfolio' : 'folio');
				const brokerGroupArray = uniq([
					...(response.data.brokerGroupArray ?? []),
					...(response.broker ? [response.broker.id] : []),
				]);
				console.log('IS EXTERNAL?', !['folio', 'tstfolio'].includes(ledger));
				return {
					userId: response.id,
					brokerId: response.broker.id,
					broker,
					brokerGroupArray,
					hubspotId: response.data.hubspotId,
					name: response.data.name,
					email: response.data.email,
					salesTeamId: response.data.salesTeamId,
					authorisedRepId: response.data.authorisedRepId,
					external: !['folio', 'tstfolio'].includes(ledger),
					canViewGroup: response.data.canViewGroup,
					sharepointSite: broker.sharepointDomain,
					sharepointSiteId: broker.sharepointSiteId,
					clientSiteSuffix: broker.clientSiteSuffix,
					clientFolder: broker.clientFolder,
					clientFolderDriveId: broker.clientFolderDriveId,
					clientFolderListId: broker.clientFolderListId,
					clientSite: `${broker.sharepointDomain}${broker.clientSiteSuffix}`,
					token: response.token,
					phoneNumber: response.data.phoneNumber,
					linkedIn: response.data.linkedIn,
					fspNumber: response.data.fspNumber,
					claimsEmail: broker.claimsEmail,
					website: broker.website,
					about: broker.about,
					aboutUser: response.data.aboutUser,
					businessAddress: broker.address,
					colours: broker.colours,
					brokerAdmin: response.data.brokerAdmin,
					disclosureUrl: response.data.disclosureUrl,
					privacyStatement: broker.privacyStatement,
					termsOfEngagement: broker.termsOfEngagement,

					imageUrl: response.data.imageUrl,
					fullLogo: broker.fullLogo,

					brokerSupportId: response.data.brokerSupportId,
					claimsAdviserId: response.data.claimsAdviserId,
					complianceAdviserId: response.data.complianceAdviserId,
				};
			},
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				try {
					await queryFulfilled;
				} catch (error) {
					console.log('Error invalidating tags', error);
				} finally {
					dispatch(featureFlagsApi.util.invalidateTags(['FEATURE', 'FEATURES']));
				}
			},
		}),
		getBrokerGroupDetails: builder.query({
			providesTags: (result, error, { docId }) => {
				return [{ type: 'BROKER_GROUP', id: docId }];
			},
			queryFn: async ({ docId }) => {
				const collectionRef = collection(firestore, 'brokerGroup');
				const ref = doc(collectionRef, docId);
				const docSnapshot = await getDoc(ref);

				const brokerDoc = {
					id: docSnapshot.id,
					...docSnapshot.data(),
				};
				return { data: brokerDoc };
			},
		}),
		getBrokerGroups: builder.query({
			providesTags: (result, error, ids) =>
				ids.map((id) => ({
					type: 'BROKER_GROUP',
					id,
				})),
			queryFn: async (ids) => {
				const collectionRef = collection(firestore, 'brokerGroup');
				const docs = await Promise.all(
					ids.map(async (docId) => {
						const ref = doc(collectionRef, docId);
						const docSnapshot = await getDoc(ref);

						const brokerDoc = {
							id: docSnapshot.id,
							...docSnapshot.data(),
						};
						return brokerDoc;
					})
				);

				return { data: docs };
			},
		}),
		// Defining an endpoint to updateproperties for user by email
		updateUserProfile: builder.mutation({
			queryFn: async ({ email, properties }) => {
				try {
					const userRef = collection(firestore, 'user');
					let q = query(userRef, where('email', '==', email));
					let querySnap = await getDocs(q);

					if (querySnap.empty) {
						q = query(userRef, where('aliasEmails', 'array-contains', email));
						querySnap = await getDocs(q);
					}
					if (querySnap.empty) {
						return {
							error: { status: 404, error: `No user doc found for email: ${email}` },
						};
					}
					const userDoc = querySnap.docs[0];
					await updateDoc(userDoc.ref, {
						...properties,
					});

					return { data: { success: true } };
				} catch (error) {
					return {
						error: {
							status: 'CUSTOM_ERROR',
							error: error.message,
						},
					};
				}
			},
			invalidatesTags: ['USER', 'BROKER_GROUP'],
		}),
		updateBroker: builder.mutation({
			queryFn: async ({ docId, properties }, { getState, dispatch }) => {
				try {
					const collectionRef = collection(firestore, 'brokerGroup');
					const ref = doc(collectionRef, docId);
					console.log('PROPERTIES for update', ref, docId, properties);
					const result = await updateDoc(ref, {
						...properties,
					});
					console.log('RESULTS', result);
					return { data: result };
				} catch (error) {
					return { error: { status: 'CUSTOM_ERROR', error: error.message } };
				}
			},
			invalidatesTags: ['USER', 'BROKER_GROUP'],
		}),
	}),
});

export const {
	useGetUserDetailsQuery,
	useGetBrokerGroupDetailsQuery,
	useGetBrokerGroupsQuery,
	useUpdateBrokerMutation,
	useUpdateUserProfileMutation,
} = userApi;
