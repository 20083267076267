/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import {
	Stack,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { determineContext } from '@/hooks/determineContext';
import { DetailsTab } from '../../ClientView/ClientTabs/DetailsTab';
import { ClientType } from '@/components/cards/DealCardValues/ClientType';
import { GoalsOfCover } from '@/components/cards/DealCardValues/GoalsOfCover';
import { NeedsAnalysisAccordian } from '../../NeedsAnalysisView';
import { ExpandMore } from '@mui/icons-material';
import { folioBlue } from '@/utils/constants';
import { RiskClasses } from '@/components/cards/DealCardValues/RiskClasses';
import { NeedsAnalysisDropdownContainer } from '@/components/cards/DealCardValues/NeedsAnalysis';
import { AdditionalProducts } from '@/components/cards/DealCardValues/AdditionalProducts';
import { PoliciesTab } from './PoliciesTab';
import { EditPolicyTransactionButton } from '@/components/buttons/viewHeaderButtons/EditPolicyTransactionsButton';
import { DealOverview } from '@/components/DealOverviewField';

export const ScopeOfAdviceTab = () => {
	// DealContext
	const { deal } = determineContext();

	const [expandedPanels, setExpandedPanels] = useState({ client: true, deal: true });

	const handleChange = (panel) => (event, isExpanded) => {
		setExpandedPanels((prev) => ({ ...prev, [panel]: isExpanded }));
	};

	return (
		<Stack spacing={3} width='100%' direction='column' alignItems='flex-start'>
			<DealOverview/>
			{/* <Accordion
				elevation={0}
				disableGutters
				square
				expanded={expandedPanels.client}
				onChange={handleChange('client')}
				sx={{
					backgroundColor: 'transparent',
					width: '100%',
					'&:not(:last-child)': {
						borderBottom: 0,
					},
					'&::before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
					sx={{
						borderBottom: expandedPanels === 'client' ? '1px solid #d3d3d3' : 'none',
						padding: 0,
						marginBottom: '1em',
					}}
				>
					<Typography variant='form_label' fontSize={'18px'}>Policies</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px' }}>
					<Grid container spacing={4}>
						<Grid size={12}>
							<PoliciesTab />
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion> */}
			<Accordion
				elevation={0}
				disableGutters
				square
				expanded={expandedPanels.deal}
				onChange={handleChange('deal')}
				sx={{
					backgroundColor: 'transparent',
					width: '100%',
					'&:not(:last-child)': {
						borderBottom: 0,
					},
					'&::before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
					sx={{
						borderBottom: expandedPanels === 'deal' ? '1px solid #d3d3d3' : 'none',
						padding: 0,
						marginBottom: '1em',
					}}
				>
					<Typography variant='form_label' fontSize={'18px'}>Scope of advice</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px' }}>
					<Grid container spacing={4}>
						<Grid  xs={6}>
							<ClientType />
						</Grid>
						<Grid  xs={12}>
							<GoalsOfCover />
						</Grid>
						{/* <NeedsAnalysisAccordian /> */}
						<Grid size={12}>
							<Typography variant='form_label'>Risk classes considered</Typography>
							<RiskClasses/>
						</Grid>
						<Grid size={12}>
							<NeedsAnalysisDropdownContainer />
						</Grid>
						<Grid size={12}>
							<AdditionalProducts />
						</Grid>
					</Grid>
					
				</AccordionDetails>
			</Accordion>
		</Stack>
	);
};
