import { theme } from '@/app/theme';
import * as Yup from 'yup';

export const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;

export const inputProps = {
	...minimalInputTheme,
	'& .MuiInput-underline:before': {
		borderBottom: 'none',
	},
	'& .MuiInputBase-input': {
		color: 'inherit',
		fontWeight: 'inherit',
		padding: '0 0 5px',
	},
};
	
export const clientHeaders = {
	name: 'Client',
	website: 'Website Address',
	industry: 'Industry',
	numberofemployees: 'No. of employees',
	annualrevenue: 'Annual turnover',
	address: 'Address',
	description: 'Business description'
	
};

export const addressSubtitles = {
	address: 'Street address',
	city: 'City',
	Suburb: 'Suburb', 
	country: 'Country',
	Postcode: 'Postcode', 
};

export const contactHeaders = {
	fullname: 'Full name',
	email: 'Email',
	// firstname: 'First name',
	// lastname: 'Last name',
	phone: 'Mobile number',
	role: 'role',
	isMainContact: 'Main contact',
};

export const schemaFields = {
	name: Yup.string().required('Name is required.'),
	firstname: Yup.string().required('First name is required.'),
	lastname: Yup.string().required('Last name is required.'),
	phone: Yup.string().required('Phone number is required.')
		.min(7, 'Phone number should be more than 7 characters')
		.max(15, 'Phone number should be no more than 15 characters'),
	email: Yup.string()
		.email('Email address must be a valid email')
		.required('Email address is required.')
		.test('domain-length', 'Email address must be a valid email', value => {
			if (!value) return false;
			const parts = value.split('@');
			if (parts.length !== 2 || parts[1].length <= 2) {
				return false;
			}
			const domainParts = parts[1].split('.');
			if (domainParts.length < 2 || domainParts.length > 3) {
				return false;
			}
			return domainParts.every(part => part.length >= 2);
		}),
};

// Dynamically create Yup validation schema based on array of headers
export const createValidationSchema = (headers) => {
	const schema = headers.reduce((acc, header) => {
		if (Object.keys(schemaFields).includes(header)) {
			acc[header] = schemaFields[header];
		}
		return acc;
	}, {});
	return Yup.object().shape(schema);
};