import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import { TaskAccordion } from '@/components/TaskAccordion';
import { AddTaskDialog } from '@/components/dialogs/AddTaskDialog';
import { isAfter } from 'date-fns';
import { stabilisedTaskSort } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { useSelector } from 'react-redux';
import { LoadingActivityCard } from '@/components/cards/LoadingActivityCard';

export const TasksTab = () => {
	const [openedTask, setOpenedTask] = useState(null);
	const location = useLocation();

	const { tasks } = determineContext();

	const { loading } = useSelector((state) => state.engagementsSlice);

	const today = new Date();

	const completedTasks = useMemo(() => tasks?.filter((t) => t.completed) ?? [], [tasks]);
	const incompleteTasks = useMemo(() => tasks?.filter((t) => !t.completed) ?? [], [tasks]);

	const overdueTasks = useMemo(
		() => stabilisedTaskSort(incompleteTasks.filter((t) => isAfter(today, new Date(t.due)))),
		[incompleteTasks]
	);
	const upcomingTasks = useMemo(
		() => stabilisedTaskSort(incompleteTasks.filter((t) => isAfter(new Date(t.due), today))),
		[incompleteTasks]
	);

	// const [expanded, setExpanded] = useState(() => {
	// 	return [overdueTasks.length > 0 ? 'Overdue' : upcomingTasks.length > 0 ? 'Upcoming' : completedTasks.length > 0 ? 'Completed' : 'Overdue'];
	// });

	const [expanded, setExpanded] = useState(['Overdue', 'Upcoming', 'Completed']);

	useEffect(() => {
		if (location.state?.task) {
			const task = tasks.find((t) => t.id == location.state.task);
			if (task) {
				if (task?.completed) {
					setExpanded(['Completed']);
				} else {
					setExpanded([isAfter(today, new Date(task?.due)) ? 'Overdue' : 'Upcoming']);
				}
			}
		}
	}, [tasks, location?.state?.task]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded((prev) => {
			return prev.includes(panel) ? prev.filter((e) => e !== panel) : [...prev, panel];
		});
	};

	const handleTaskOpened = (index) => {
		setOpenedTask((prevIndex) => (prevIndex === index ? null : index));
	};

	const buildTask = (task, index, title) => {
		return (
			<TaskAccordion
				key={`task-accordion-task-tab-${title}-${index}`}
				task={task}
				index={index}
				onChange={() => handleTaskOpened(index)}
			/>
		);
	};

	const buildTaskSection = (taskArray, title) => {
		return (
			<Accordion
				// expanded={expanded === title}
				expanded={expanded.includes(title)}
				onChange={handleChange(title)}
				disableGutters
				sx={{
					backgroundColor: 'transparent',
					boxShadow: 'none',
					'&.MuiAccordion-root:before': {
						display: 'none',
					},
					'&:not(:last-child)': {
						borderBottom: 0,
					},
					'&:before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={`${title}bh-content`}
					id={`${title}bh-header`}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
				>
					<Typography
						variant='subtitle1'
						fontWeight={taskArray.length > 0 ? 500 : undefined}
					>{`${title} ${
							taskArray.length > 0 ? `(${taskArray.length})` : ''
						}`}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{loading ? (
						<LoadingActivityCard />
					) : taskArray.length > 0 ? (
						taskArray.map((task, index) => buildTask(task, index, title.toLowerCase()))
					) : (
						<Typography>{`No ${title.toLowerCase()} tasks`}</Typography>
					)}
				</AccordionDetails>
			</Accordion>
		);
	};

	return (
		<Box>
			{buildTaskSection(overdueTasks, 'Overdue')}
			{buildTaskSection(upcomingTasks, 'Upcoming')}
			{buildTaskSection(completedTasks, 'Completed')}
			<AddTaskDialog/>
		</Box>
	);
};
