import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { claimHeaders } from '@/components/ClaimDetailsEntry/claimDetailData';
import { ClaimDetailField } from '@/components/ClaimDetailsEntry/claimDetailEntry';
import { DealOverview } from '@/components/DealOverviewField';

export const ClaimDetailsTab = () => {

	return (
		<Box sx={{ p: 3 }}>
			<Grid container spacing={4}>
				<DealOverview/>
				{Object.entries(claimHeaders).map(([key, header]) => (
					<Grid
						size={{ xs: 12, sm: key === 'Description' ? 12 : 6 }}
						key={`detail-entry-${key}`}
					>
						<ClaimDetailField title={header} header={key} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
};
