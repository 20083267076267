/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Stack,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Box,
	Collapse,
	Table,
	TableHead,
	TableBody,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BlinkingCircle } from '../../icons/BlinkingCircle';
import { ChevronRightOutlined } from '@mui/icons-material';
import {
	folioBlue,
	formatDate,
	formatDateString,
	getPipelineIcon,
	getUrlFromPipeline,
	DEAL_STATUS_TYPES,
} from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ClaimsIcon } from '@/components/icons/ClaimsIcon';
import { paths } from '@/app/routes';
import ClientContext from '@/context/clientContext';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { useDeleteDealMutation, useGetDealRowQuery } from '@/features/deals/dealsApi';
import { showToast } from '@/features/toast/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isAfter } from 'date-fns';
import { EndorsementIcon } from '@/components/icons/Endorsement';
import { RenewalIcon } from '@/components/icons/Renewal';
import { InsightIcon } from '@/components/icons/InsightIcon';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { IS_DEV } from '@/utils/environment';
import {
	useGetDriveItemQuery,
	useRenameSharepointItemMutation,
} from '@/features/msGraph/msGraphApi';

const StatusCircle = ({ colour, status, loading }) => {
	return (
		<Stack direction='row' width='100%' alignItems='center' spacing={'0.5em'}>
			<BlinkingCircle fontSize='6px' color={colour} />
			<Typography sx={{ fontSize: 'inherit' }}>
				{loading ? <Skeleton variant='text' width='5em' /> : status}
			</Typography>
		</Stack>
	);
};

export const PolicyTransactionRow = ({ hit: deal }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
	const [policyMenuAnchorEl, setPolicyMenuAnchorEl] = useState(null);
	const { client } = useContext(ClientContext);
	const [subMenuType, setSubMenuType] = useState(null);

	const { expandPolicies } = useSelector((state) => state.userPrefs);

	const dealId = deal?.id;

	const { data: dealRow, isLoading: isLoadingDealRow } = useGetDealRowQuery(dealId, {
		skip: !dealId,
	});

	const { data: driveItem } = useGetDriveItemQuery(
		{
			driveId: dealRow?.clientFolderDriveId,
			itemId: dealRow?.driveId,
		},
		{ skip: !dealRow?.driveId ?? !dealRow?.clientFolderDriveId }
	);

	const [renameSharepointItem] = useRenameSharepointItemMutation();

	const objectType =
		deal?.pipeline === process.env.REACT_APP_PIPELINE_CLAIMS ||
		(IS_DEV && deal?.pipeline === process.env.REACT_APP_PIPELINE_ENDORSEMENTS)
			? 'ticket'
			: 'deal';

	const policies = useMemo(
		() => (deal?.policies ?? []).filter((p) => Object.keys(p ?? {}).length > 0),
		[deal?.policies]
	);

	const mainMenuOpen = Boolean(anchorEl);
	const subMenuOpen = Boolean(subMenuAnchorEl);
	const policyMenuOpen = Boolean(policyMenuAnchorEl);

	const [openRow, setOpenRow] = useState(false);
	const isFirstRender = useRef(true);

	useEffect(() => {
		const policiesLength = (dealRow?.policies ?? []).length;

		if (isFirstRender.current && policiesLength === 1) {
			setOpenRow(true);
			isFirstRender.current = false;
		} else {
			setOpenRow(policiesLength === 0 ? false : expandPolicies);
		}
	}, [expandPolicies, dealRow?.policies, isFirstRender]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const pipeline = deal?.pipeline ?? deal?.properties?.hs_pipeline;

	const [showDialog, setShowDialog] = useState(false);

	const [deleteDeal, { isLoadingDeal }] = useDeleteDealMutation({
		fixedCacheKey: `delete-deal-${dealId}-${objectType}`,
	});

	const isLoading = deal?.policiesLoading || isLoadingDeal;

	const handleClose = (menuType) => {
		if (menuType === 'main') {
			setAnchorEl(null);
		} else if (menuType === 'policy') {
			setPolicyMenuAnchorEl(null);
		} else {
			setSubMenuAnchorEl(null);
		}
	};
	

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePolicyMenuClick = (event) => {
		setPolicyMenuAnchorEl(event.currentTarget);
	};

	const handleOpenSubMenu = (event, type) => {
		event.stopPropagation();
		setSubMenuAnchorEl(event.currentTarget);
		setSubMenuType(type);
	};

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const stages = useMemo(
		() =>
			pipelineQuery?.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? []).flatMap((r) =>
				r?.stages.map((s) => ({ ...s, pipeline: r?.label }))
			) ??
			[],
		[pipelineQuery.data?.pipelines]
	);

	const { active, inactive } = useMemo(() => {
		return {
			active: stages.filter((stage) => stage.metadata?.isClosed != 'true').map((s) => s.id),
			inactive: stages
				.filter(
					(stage) =>
						stage.metadata?.probability?.toString() == '1.0' ||
						(IS_DEV &&
							stage.metadata?.isClosed == 'true' &&
							stage.metadata?.probability != '0.0')
				)
				.map((s) => s.id),
		};
	}, [stages]);

	const isRenewable = useMemo(
		() =>
			policies.length > 0 &&
			showNewRenewalsFeature &&
			(dealRow?.isRenewable == null || dealRow?.isRenewable === true) &&
			inactive.includes(dealRow?.dealStage),
		[policies, showNewRenewalsFeature, dealRow, inactive]
	);

	const handleRenewDeal = () => {
		console.log('RENEW DEAL', deal, client?.hs_object_id, policies);

		if (deal && client?.hs_object_id) {
			const { hs_object_id: clientId } = client;
			const dealIds = [dealId];
			navigate(paths.createRenewal, {
				state: {
					dealIds,
					clientId,
					policies,
				},
			});
		} else {
			console.error('Missing deal or client information');
		}
	};

	const handlePolicySelect = (policy, type) => {
		handleClose();
		const policyInsight = policy?.policy ?? {};
		const transaction = policy?.transaction ?? {};
		if (type === 'claim') {
			navigate(paths.createClaim, {
				state: {
					client: client,
					policy: policyInsight,
					transaction: transaction,
				},
			});
		} else if (type === 'endorse') {
			navigate(paths.createEndorsement, {
				state: {
					client: client,
					policy: policyInsight,
					transaction: transaction,
				},
			});
		}
	};

	const renderPolicyMenuItems = () => {
		return policies.map((policy, index) => (
			<MenuItem key={index} onClick={() => handlePolicySelect(policy, subMenuType)}>
				<Typography>
					{policy?.transaction?.PolicyNumber ?? policy?.policy?.PolicyNumber ?? '-'} -{' '}
					{policy?.transaction?.BriefDescription ?? policy?.policy?.Description ?? '-'}
				</Typography>
			</MenuItem>
		));
	};

	const dealLink = useMemo(() => {
		const path = getUrlFromPipeline(pipeline);
		return `${path}/${dealId}`;
	}, [deal, pipeline]);

	const subMenus = [
		<MenuItem onClick={(e) => handleOpenSubMenu(e, 'claim')} key={'menu-claims'}>
			<ListItemIcon>
				<ClaimsIcon bgColor={folioBlue} width='22px' height='22px' sx={{ ml: '4px' }} />
			</ListItemIcon>
			<ListItemText sx={{ color: folioBlue }}>Claim</ListItemText>
			<ChevronRightOutlined sx={{ color: folioBlue }} />
		</MenuItem>,

		<MenuItem onClick={(e) => handleOpenSubMenu(e, 'endorse')} key={'menu-endorse'}>
			<ListItemIcon>
				<EndorsementIcon
					bgColor={folioBlue}
					width='22px'
					height='22px'
					sx={{ ml: '4px' }}
				/>
			</ListItemIcon>
			<ListItemText sx={{ color: folioBlue }}>Endorse</ListItemText>
			<ChevronRightOutlined sx={{ color: folioBlue }} />
		</MenuItem>,

		<Menu
			anchorEl={subMenuAnchorEl}
			open={subMenuOpen}
			onClose={() => handleClose('submenu')}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			key={'menu-policy-submenu'}
		>
			{renderPolicyMenuItems()}
		</Menu>,
	];

	const getMenuLink = () => {
		return (
			<>
				<IconButton aria-label='edit' onClick={handleMenuClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu anchorEl={anchorEl} open={mainMenuOpen} onClose={() => handleClose('main')}>
					<MenuItem
						onClick={(e) => {
							handleClose('main');
							navigate(dealLink);
						}}
					>
						<ListItemIcon>
							<ArticleIcon sx={{ color: folioBlue }} />
						</ListItemIcon>
						<ListItemText sx={{ color: folioBlue }}>View deal</ListItemText>
					</MenuItem>
					{policies.length > 0 && subMenus.map((s) => s)}
					{!isLoading && isRenewable && (
						<MenuItem onClick={handleRenewDeal}>
							<ListItemIcon>
								<RenewalIcon
									bgColor={folioBlue}
									width='22px'
									height='22px'
									sx={{ ml: '4px' }}
								/>
							</ListItemIcon>
							<ListItemText sx={{ color: folioBlue }}>Renew</ListItemText>
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const navigateToInsight = (policy) => {
		const ledgerName = client?.broker?.ledger;
		const ledger = ledgerName ?? (IS_DEV ? 'tstfolio' : 'folio');
		const ledgerUrl = `https://${ledger}.insightbroking.co.nz`;
		const policyUrl = `policy/${policy?.policy?.Id}/policy-transaction`;
		const url = `${ledgerUrl}/#/${policyUrl}`;
		window.open(url, '_blank');
	};

	const getPolicyMenuLink = (policy) => {
		console.log('POLICY', policy);
		return (
			<>
				<IconButton
					size='small'
					aria-label='edit'
					onClick={handlePolicyMenuClick}
					disabled={isLoading}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					anchorEl={policyMenuAnchorEl}
					open={policyMenuOpen}
					onClose={() => handleClose('policy')}
				>
					<MenuItem onClick={() => handlePolicySelect(policy, 'claim')}>
						<ListItemIcon>
							<ClaimsIcon bgColor={folioBlue} width='22px' height='22px' />
						</ListItemIcon>
						<ListItemText sx={{ color: folioBlue }}>Claim</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => handlePolicySelect(policy, 'endorse')}>
						<ListItemIcon>
							<EndorsementIcon bgColor={folioBlue} width='22px' height='22px' />
						</ListItemIcon>
						<ListItemText sx={{ color: folioBlue }}>Endorse</ListItemText>
					</MenuItem>
					{policy?.policy?.Id && (
						<MenuItem onClick={() => navigateToInsight(policy)}>
							<ListItemIcon>
								<InsightIcon />
							</ListItemIcon>
							<ListItemText sx={{ color: folioBlue }}>Open in Insight</ListItemText>
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const handleDeleteConfirmation = async () => {
		setShowDialog(false);
		const path = getUrlFromPipeline(deal?.pipeline ?? deal?.hs_pipeline);

		await deleteDeal({ dealId, objectType })
			.unwrap()
			.then(async (res) => {
				try {
					// const item = await getDriveItem(dealRow?.clientFolderDriveId, dealRow?.driveId);
					// console.log('ITEM', item);
					await renameSharepointItem({
						driveId: driveItem?.parentReference?.driveId,
						itemId: driveItem?.id,
						name: `[DELETED] - ${driveItem?.name}`,
					}).unwrap();
				} catch (e) {
					console.log('Error updating item name', e);
				}
				dispatch(
					showToast({
						message: `Deal ${dealId} successfully deleted.`,
						success: true,
					})
				);
				navigate(path);
			})
			.catch((err) => {
				console.log(`Error deleting deal ${dealId}`, err);
				dispatch(
					showToast({
						message: 'Error deleting deal; please try again.',
						error: true,
					})
				);
			});
	};
	// const isInactive = useMemo(() => {
	// 	const today = new Date();
	// 	const isLapsed = transaction?.ToDate
	// 	  ? isAfter(today, new Date(transaction?.ToDate))
	// 	  : false;
	// 	return isLapsed || transaction?.IsClosed || !transaction?.IsLatestInPeriod;
	//   }, [transaction?.IsLatestInPeriod, transaction?.IsClosed, transaction?.ToDate]);

	// const isInactive = useMemo(() => {
	// 	const today = new Date();

	// 	// Check if any policy is inactive
	// 	const isAnyPolicyInactive = policies.some((policy) => {
	// 		const transaction = policy?.transaction;
	// 		if (!transaction) return false;

	// 		const isLapsed = transaction?.ToDate
	// 			? isAfter(today, new Date(transaction?.ToDate))
	// 			: false;
	// 		return (
	// 			isLapsed || transaction?.IsClosed || !transaction?.IsLatestInPeriod
	// 		);
	// 	});

	// 	return isAnyPolicyInactive;
	// }, [policies]);

	const checkPolicyInactive = (transaction) => {
		if (!transaction) return false;
		// console.log('transaction: ', transaction);
		const today = new Date();
		const isLapsed =
			transaction?.ToDate ?? transaction?.toDate
				? isAfter(today, new Date(transaction?.ToDate ?? transaction?.toDate))
				: false;
		//transaction?.IsClosed !transaction?.IsCurrent
		const isClosed = transaction?.IsClosed ?? transaction?.isClosed ?? transaction?.IsCurrent;
		return isLapsed || !transaction?.IsLatestInPeriod || isClosed;
	};

	const policyTableRows = () => (
		<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
			<Collapse in={openRow && !isLoadingDealRow} timeout='auto' unmountOnExit>
				<Box sx={{ mt: 1, ml: 6, mb: 2 }}>
					{/* <Typography variant='form_label' gutterBottom component='div'>
                Policy Transactions
				</Typography> */}
					<Table
						size='small'
						aria-label='policy-transactions'
						sx={{
							'& .MuiTableCell-root': {
								fontSize: '0.8rem !important',
							},
						}}
					>
						<TableHead
							sx={{
								'& .MuiTableCell-root': {
									backgroundColor: 'rgba(0, 0, 0, 0.05)',
									borderBottom: 'unset',
								},
							}}
						>
							<TableRow>
								<TableCell>Policy Number</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>From Date</TableCell>
								<TableCell>To Date</TableCell>
								{policies.some(
									(row) => row?.transaction?.TransactionTypeName === 'Endorsement'
								) && <TableCell>Effective Date</TableCell>}
								<TableCell>Status</TableCell>
								<TableCell padding={'checkbox'}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(dealRow?.policies ?? policies).map((row, index) => (
								<TableRow
									key={`policy-row-${index}-policy-${
										row?.policy?.Id ?? row?.policyId
									}-transaction-${row?.transaction?.Id ?? row?.id}`}
									sx={{
										...(index ===
											(dealRow?.policies ?? policies).length - 1 && {
											'& .MuiTableCell-root': {
												borderBottom: 'unset',
											},
										}),
									}}
								>
									<TableCell component='th' scope='row'>
										{row?.policyNumber ??
											row?.transaction?.PolicyNumber ??
											row?.policy?.PolicyNumber ??
											'-'}
									</TableCell>
									<TableCell>
										{row?.description ??
											row?.transaction?.BriefDescription ??
											row?.policy?.Description ??
											'-'}
									</TableCell>
									<TableCell>
										{(row?.fromDate ||
											row?.transaction?.FromDate ||
											row?.policy?.FromDate) && (
											<>
												{formatDateString(
													row?.fromDate ??
														row?.transaction?.FromDate ??
														row?.policy?.FromDate
												)}
											</>
										)}
									</TableCell>
									<TableCell>
										{(row?.toDate ||
											row?.transaction?.ToDate ||
											row?.policy?.ToDate) && (
											<>
												{formatDateString(
													row?.toDate ??
														row?.transaction?.ToDate ??
														row?.policy?.ToDate
												)}
											</>
										)}
									</TableCell>
									{policies.some(
										(r) => r?.transaction?.TransactionTypeName === 'Endorsement'
									) && (
										<TableCell>
											{row?.transaction?.TransactionTypeName ===
												'Endorsement' &&
												formatDateString(row?.transaction?.EffectiveDate)}
										</TableCell>
									)}
									<TableCell>
										<StatusCircle
											colour={
												isLoading
													? 'rgba(0,0,0,0.5)'
													: checkPolicyInactive(
														row?.transaction || row?.policy
													  )
														? '#FF0000'
														: '#00C650'
											}
											status={
												checkPolicyInactive(row?.transaction || row?.policy)
													? 'Inactive'
													: 'Active'
											}
											loading={isLoading}
										/>
									</TableCell>
									<TableCell padding={'checkbox'}>
										{getPolicyMenuLink(row)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</Collapse>
		</TableCell>
	);

	return (
		<React.Fragment>
			<React.Fragment>
				<TableRow
					selected={showDialog}
					sx={{
						'& .MuiTableCell-root': {
							borderBottom: 'unset',
						},
						'&.Mui-selected': {
							color: 'rgba(0, 0, 0, 0.9) !important',
							'& .MuiTypography-root': {
								color: 'rgba(0, 0, 0, 0.9) !important',
							},
						},
					}}
				>
					<TableCell padding={'checkbox'}>
						<IconButton
							aria-label='expand row'
							size='small'
							disabled={isLoadingDealRow || (dealRow?.policies ?? []).length === 0}
							// disabled={isLoading || policies.length === 0}
							onClick={() => setOpenRow(!openRow)}
						>
							{openRow && !isLoadingDealRow ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
							{/* {openRow && !isLoading ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
						</IconButton>
					</TableCell>
					<TableCell component='th' scope='row'>
						<Typography variant='subtitle2'>{deal?.dealName}</Typography>
					</TableCell>
					<TableCell>
						<Typography>{`${
							deal?.['policies.description']?.length ??
							dealRow?.policies?.length ??
							policies.length
						} policies linked`}</Typography>
						{/* {isLoading ? (
							<Skeleton variant='text' />
						) : (
							<Typography>{`${policies.length} policies linked`}</Typography>
						)} */}
					</TableCell>
					<TableCell align='right'>{formatDate(deal?.renewalDate)}</TableCell>
					<TableCell>
						{deal?.status == DEAL_STATUS_TYPES.archived ? (
							<StatusCircle colour={'rgba(0,0,0,0.5)'} status={deal?.status} />
						) : policies.length === 0 || active.includes(deal?.dealStage) ? (
							<StatusCircle
								colour={isLoading ? 'rgba(0,0,0,0.5)' : folioBlue}
								status={'In progress'}
								loading={isLoading}
							/>
						) : (
							<StatusCircle
								colour={
									isLoading
										? 'rgba(0,0,0,0.5)'
										: deal?.status == DEAL_STATUS_TYPES.renewalCreated
											? 'purple'
											: deal?.status == DEAL_STATUS_TYPES.endorsementCreated
												? 'cyan'
												: 'orange'
								}
								status={deal?.status}
								loading={isLoading}
							/>
						)}
					</TableCell>
					<TableCell>
						<Stack direction='row' alignItems={'center'} spacing={1} width='100%'>
							{getPipelineIcon(pipeline, '1.5em')}
							<Typography variant='subtitle2'>
								{pipelineQuery.data?.pipelines?.label}
							</Typography>
						</Stack>
					</TableCell>
					<TableCell align='right'>{getMenuLink()}</TableCell>
				</TableRow>
				<TableRow>
					{policyTableRows()}
					{/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
						<Collapse in={openRow && !isLoading} timeout='auto' unmountOnExit>
							<Box sx={{ mt: 1, ml: 6, mb: 2 }}>
								<Typography variant='form_label' gutterBottom component='div'>
									Policy Transactions
								</Typography>
								<Table size='small' aria-label='policy-transactions'>
									<TableHead>
										<TableRow>
											<TableCell>Policy Number</TableCell>
											<TableCell>Description</TableCell>
											<TableCell>From Date</TableCell>
											<TableCell>To Date</TableCell>
											{policies.some(
												(row) =>
													row?.transaction?.TransactionTypeName ===
													'Endorsement'
											) && <TableCell>Effective Date</TableCell>}
											<TableCell>Status</TableCell>
											<TableCell padding={'checkbox'}></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{policies.map((row, index) => (
											<TableRow
												key={`policy-row-${index}-policy-${row?.policy?.Id}-transaction-${row?.transaction?.Id}`}
												sx={{
													...(index === policies.length - 1 && {
														'& .MuiTableCell-root': {
															borderBottom: 'unset'
														}
													}),
												}}
											>
												<TableCell component='th' scope='row'>
													{row?.transaction?.PolicyNumber ??
														row?.policy?.PolicyNumber ??
														'-'}
												</TableCell>
												<TableCell>
													{row?.transaction?.BriefDescription ??
														row?.policy?.Description ??
														'-'}
												</TableCell>
												<TableCell>
													{(row?.transaction?.FromDate ||
														row?.policy?.FromDate) && (
														<>
															{formatDateString(
																row?.transaction?.FromDate ||
																	row?.policy?.FromDate
															)}
														</>
													)}
												</TableCell>
												<TableCell>
													{(row?.transaction?.ToDate ||
														row?.policy?.ToDate) && (
														<>
															{formatDateString(
																row?.transaction?.ToDate ||
																	row?.policy?.ToDate
															)}
														</>
													)}
												</TableCell>
												{policies.some(
													(r) =>
														r?.transaction?.TransactionTypeName ===
														'Endorsement'
												) && (
													<TableCell>
														{row?.transaction?.TransactionTypeName ===
															'Endorsement' &&
															formatDateString(
																row?.transaction?.EffectiveDate
															)}
													</TableCell>
												)}
												<TableCell>
													<StatusCircle
														colour={
															checkPolicyInactive(
																row?.transaction || row?.policy
															)
																? '#FF0000'
																: '#00C650'
														}
														status={
															checkPolicyInactive(
																row?.transaction || row?.policy
															)
																? 'Inactive'
																: 'Active'
														}
													/>
												</TableCell>
												<TableCell padding={'checkbox'}>
													{getPolicyMenuLink(row)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell> */}
				</TableRow>
			</React.Fragment>
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={handleDeleteConfirmation}
					actionName={'delete'}
				/>
			)}
		</React.Fragment>
	);
};
