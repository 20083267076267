import { TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

const DataGridNumericFormat = (props) => {
	const { id, field, value, colDef, hasFocus, isCurrency, isNumber, isYear, decimalScale } = props;
	const [valueState, setValueState] = useState(value);
	const [inputRef, setInputRef] = useState(null);
	const apiRef = useGridApiContext();

	useLayoutEffect(() => {
		if (hasFocus && inputRef) {
			inputRef.focus();
		}
	}, [hasFocus, inputRef]);

	const handleChange = useCallback(
		({ floatValue }) => {
			setValueState(floatValue);
			apiRef.current.setEditCellValue({ id, field, value: floatValue, debounceMs: 200 });
		},
		[apiRef, field, id]
	);

	return <NumericFormat
		value={valueState}
		customInput={TextField}
		{...(isCurrency || isNumber) && { thousandSeparator: true }}
		decimalScale={decimalScale ?? 0}
		fixedDecimalScale
		allowNegative={false}
		{...isCurrency && { prefix: '$' }}
		variant='standard'
		sx={{ '& .MuiInputBase-root': {
			backgroundColor: 'transparent',
			pl: '0.5em',
			width: '100%'
		}}}
		InputProps={{ disableUnderline: true, height: '100%', width: '100%' }}
		onValueChange={handleChange}
		inputRef={(ref) => setInputRef(ref)}
	/>;
};

export default DataGridNumericFormat;