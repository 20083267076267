import React, { useEffect, useMemo } from 'react';
import { Box, CircularProgress, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import DataGridYearPicker from '@/components/table/DataGridYearPicker';
import useCellModes from '@/hooks/useCellModes';
import { useRetrieveClaimInsurersQuery } from '@/features/claims/ticketsApi';
import { renderInsurerSelect } from '@/components/table/DataGridInsurerSelect';

export const InsurerRatingTable = ({ rows, updateRows, removeRow, disabled, searchList }) => {
	const { data: insurerList, isLoading, isUninitialized } = useRetrieveClaimInsurersQuery();

	// const apiRef = useGridApiContext();

	const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellModes();

	const insurers = useMemo(
		// () => [{ Id: -1, Name: 'NONE' }].concat(insurerList?.data ?? []),
		() => insurerList?.data ?? [],
		[insurerList?.data]
	);

	const disabledInsurers = useMemo(() => {
		const codes = rows.map((r) => r.code);
		return (insurerList?.data ?? []).filter((r) => codes.includes(r.Id)).map((r) => r?.Id);
	}, [insurerList?.data, rows]);

	const columns = [
		{
			field: 'code',
			headerName: 'Insurer',
			flex: 0.5,
			minWidth: 150,
			editable: !disabled && !isLoading,
			sortable: false,
			display: 'flex',
			singleClickCell: true,
			type: 'singleSelect',

			renderCell: (params) => {
				// console.log('PARAMS', params, params.value);
				return isLoading ? (
					<Skeleton height='3em' width='100%' />
				) : (
					<Tooltip title='Double-click to change'>
						<span
						// {...params}
							style={{ padding: `${params.value === -1 ? 22 : 8}px 0` }}
						>
							{params.value === -1 ? '' : params.row.insurer}
						</span>
					</Tooltip>
				);
			},
			getOptionValue: (value) => value.code,
			getOptionLabel: (value) => value.name,
			valueOptions: ({ row }) => {
				if (isLoading) return [];
				return insurers.map((val) => {
					return {
						code: val?.Id,
						name: val?.Name,
						disabled: disabledInsurers.includes(val?.Id) && val?.Id !== row?.code,
					};
				});
			},
			renderEditCell: renderInsurerSelect,
			// renderCell: (params) => {isLoading ? <Skeleton height='100%' width='100%' /> : <span {...params}></span>},
		},
		{
			field: 'rating',
			headerName: 'Rating',
			flex: 0.5,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
		},
		{
			// ...GRID_CHECKBOX_SELECTION_COL_DEF,
			field: 'delete',
			headerName: 'Delete',
			width: 48,
			sortable: false,
			resizable: false,
			display: 'flex',
			renderHeader: (params) => <></>,
			renderCell: (params) =>
				params.row.insurer === '' || rows.length <= 1 ? (
					<></>
				) : (
					<IconButton
						sx={{ fontSize: '10px', padding: 0 }}
						color='rgba(0,0,0,0)'
						onClick={() => removeRow(params.row.id)}
					>
						<Delete />
					</IconButton>
				),
			// cellClassName: 'pinned-column',
			// headerClassName: 'pinned-column',
		},
	];

	const handleRowUpdate = (newRow, oldRow, params) => {
		console.log('handle row update', { newRow, oldRow, params });
		const newInsurer = (insurerList?.data ?? []).find((insurer) => insurer?.Id === newRow.code);
		const newUpdatedRow = {
			...newRow,
			insurer: newInsurer?.Name ?? '',
			rating: newInsurer?.FinancialStrengthRating ?? '',
			ratingId: newInsurer?.FinancialStrengthRatingScaleId ?? -1,
			data: newInsurer ?? {},
		};
		// if (newRow)
		if (newRow.code != oldRow.code) {
			return updateRows(newUpdatedRow, oldRow);
		}
		return oldRow;
	};

	return (
		<DataGrid
			disableVirtualization
			width='100%'
			processRowUpdate={handleRowUpdate}
			onProcessRowUpdateError={(error) => {
				console.log('Error updating row', error);
				// apiRef.current.stopCellEditMode;
			}}
			rows={rows}
			columns={columns}
			getRowHeight={() => 'auto'}
			// getEstimatedRowHeight={() => 200}
			// editMode={'row'}
			// isCellEditable={(params) => !disabled}
			getRowId={(row) => {
				// console.log('ROW getRowId', row);
				return row?.code ?? row?.id ?? 0;
			}}
			// checkboxSelection
			// onRowSelectionModelChange={setRowSelectionModel}
			// rowSelectionModel={rowSelectionModel}
			cellModesModel={cellModesModel}
			onCellModesModelChange={handleCellModesModelChange}
			onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
