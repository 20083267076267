/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { Box, IconButton, TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { usePagination, useHitsPerPage, Hits } from 'react-instantsearch';

export function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange, showLastButton, showFirstButton, slotProps } =
		props;

	// useEffect(() => console.log('SLOT PROPS', slotProps), [slotProps]);

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		const totalPages = Math.ceil(count / rowsPerPage);
		const lastPage = totalPages - 1;
		onPageChange(event, lastPage);
	};

	return (
		<div style={{ display: 'flex' }}>
			{showFirstButton && (
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label='first page'
					size='large'
				>
					{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
				</IconButton>
			)}
			<IconButton
				onClick={handleBackButtonClick}
				disabled={slotProps?.previousButton?.disabled ?? page === 0}
				aria-label='previous page'
				size='large'
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={
					slotProps?.nextButton?.disabled ?? page >= Math.ceil(count / rowsPerPage) - 1
				}
				aria-label='next page'
				size='large'
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			{showLastButton && (
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label='last page'
					size='large'
				>
					{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
				</IconButton>
			)}
		</div>
	);
}

export const HitsTablePagination = ({ rowsPerPage, onRowsPerPageChange, ...props }) => {
	const { currentRefinement, nbHits, refine } = usePagination(props);

	const { items, refine: refineRows } = useHitsPerPage(props);

	const handleChangePage = (event, page) => {
		refine(page);
	};

	const handleRowsPerPageChange = (event) => {
		// const value = event.target.value;
		// const rows = value === 'All' ? nbHits : value;
		onRowsPerPageChange(event);
		refineRows(event.target.value);
	};

	return (
		<Box width='100%' justifyItems='end'>
			<TablePagination
				rowsPerPageOptions={[
					...items.map((i) => ({
						value: i.value,
						label: i.label,
						default: i.default || false,
					})),
				]}
				count={nbHits}
				rowsPerPage={rowsPerPage}
				page={currentRefinement}
				component={'div'}
				SelectProps={{
					inputProps: {
						'aria-label': 'Rows per page',
					},
					native: true,
				}}
				sx={{
					display: 'block',
					width: '100%',
				}}
				showLastButton
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleRowsPerPageChange}
				ActionsComponent={TablePaginationActions}
			/>
		</Box>
	);
};
