import React, { useEffect, useMemo, useState } from 'react';
import { TextField, IconButton, InputAdornment, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import { formatKey } from '@/utils/constants';
import { FIELDS } from '../validationSchema';
import { NEEDS_ANALYSIS_LABELS } from '../../CreateNewBusinessForm/validationSchema';
import PerformantTextField from '@/components/fields/PerformantTextField';

export const ChangeProductSelection = ({ product, onBlur, products, removeProduct, disabled }) => {
	const [name, setName] = useState(product.title ?? '');
	const [value, setValue] = useState(product.value ?? '');

	const isError = useMemo(() => {
		const keys = Object.keys(FIELDS.scopeOfAdvice).concat(
			Object.keys(FIELDS.scopeOfAdvice).map((k) => formatKey(k))
		);
		const otherProducts = products
			.filter((p) => p.title.trim() != product.title.trim())
			.map((p) => p.title);
		return keys.concat(otherProducts).includes(name.trim());
	}, [name]);

	useEffect(() => setName(product.title), [product.title]);
	useEffect(() => setValue(product.value), [product.value]);

	const onFieldBlur = () => {
		if (!isError) {
			onBlur(name.trim(), value.trim());
		}
	};

	return (
		<Stack direction='column'>
			<PerformantTextField
				value={name}
				fullWidth
				size='small'
				variant='standard'
				className='minimal-input'
				onChange={(e) => setName(e.target.value)}
				onBlur={onFieldBlur}
				error={isError}
				helperText={isError ? 'Product already exists' : ''}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<IconButton
								sx={{ fontSize: '10px', padding: 0 }}
								color='rgba(0,0,0,0)'
								onClick={removeProduct}
							>
								<DeleteIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				disabled={disabled}
			/>
			<Select
				disabled={disabled}
				fullWidth
				// {...formik.getFieldProps(formikKey)}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onBlur={onFieldBlur}
			>
				{Object.entries(NEEDS_ANALYSIS_LABELS).map(([value, displayLabel]) => (
					<MenuItem
						value={value}
						key={`${product.title}-needs-analysis-selection-${value}`}
					>
						{displayLabel}
					</MenuItem>
				))}
			</Select>
		</Stack>
	);
};
