import React, { useMemo } from 'react';
import { Typography, FormControlLabel, Radio, RadioGroup, FormGroup } from '@mui/material';
import {
	CLIENT_TYPE,
	DOMESTIC,
	COMMERCIAL,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';

export const ClientType = () => {
	const { dealRow } = determineContext();
	const clientType = useMemo(() => dealRow[CLIENT_TYPE] ?? '', [dealRow]);

	const [updateDeal] = useUpdateDealRowMutation();

	const onChange = async (event) => {
		const newValue = event.target.value;
		try {
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				properties: {
					[CLIENT_TYPE]: newValue,
				},
			}).unwrap();
		} catch (error) {
			console.error('Error updating client type:', error);
		}
	};

	return (
		<FormGroup row sx={{ width: '100%' }}>
			<Typography variant='form_label'>Risk type</Typography>
			<RadioGroup
				row
				name={CLIENT_TYPE}
				value={clientType}
				onChange={onChange}
				sx={{ width: '100%' }}
			>
				<FormControlLabel
					value={COMMERCIAL}
					control={<Radio />}
					label={<span style={{ fontSize: '12px' }}>{'Commercial'}</span>}
				/>
				<FormControlLabel
					value={DOMESTIC}
					control={<Radio />}
					label={<span style={{ fontSize: '12px' }}>{'Domestic'}</span>}
				/>
			</RadioGroup>
		</FormGroup>
	);
};
