/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { Breadcrumbs, Link, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { setItemId } from '@/features/table/attachmentsTableSlice';
import { folioBlue } from '@/utils/constants';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { useGetBreadcrumbsQuery } from '@/features/msGraph/msGraphApi';

export const FolderBreadcrumbs = () => {
	const dispatch = useDispatch();

	const { refreshIndicator, itemId, parentItemId } = useSelector(
		(state) => state.attachmentsTable
	);

	const { clientFolderDriveId } = useAttachmentsContext();

	const {
		data: breadcrumbs,
		isError,
		isLoading,
		isUninitialized,
		...breadcrumbsQuery
	} = useGetBreadcrumbsQuery(
		{
			endItemId: itemId,
			startItemId: parentItemId,
			driveId: clientFolderDriveId,
		},
		{ skip: !itemId || !parentItemId || !clientFolderDriveId }
	);

	// useEffect(
	// 	() =>
	// 		console.log('breadcrumbsQuery', {
	// 			itemId,
	// 			parentItemId,
	// 			clientFolderDriveId,
	// 			breadcrumbs,
	// 			isError,
	// 			isLoading,
	// 			isUninitialized,
	// 			...breadcrumbsQuery,
	// 		}),
	// 	[
	// 		itemId,
	// 		parentItemId,
	// 		clientFolderDriveId,
	// 		breadcrumbs,
	// 		isError,
	// 		isLoading,
	// 		isUninitialized,
	// 		breadcrumbsQuery,
	// 	]
	// );

	useEffect(() => {
		if (breadcrumbs) {
			breadcrumbsQuery.refetch();
		}
	}, [refreshIndicator]);

	return isLoading || isUninitialized ? (
		<Skeleton width='20%' />
	) : (
		<Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} sx={{ mb: '0.5em' }}>
			{breadcrumbs.map((b, i) => {
				return (
					<Link
						component='button'
						key={`breadcrumb-${b.id}`}
						underline='hover'
						variant='task_updated'
						color={i !== breadcrumbs.length - 1 ? 'inherit' : 'textPrimary'}
						sx={{
							'&:hover': {
								color: folioBlue,
							},
						}}
						onClick={() => {
							if (itemId !== b.id) {
								dispatch(setItemId(b.id));
							}
							// if (i !== breadcrumbs.length - 1) {
							// 	dispatch(setItemId(b.id));
							// 	// const newBreadcrumbs = breadcrumbs.slice(0, i + 1);
							// 	// dispatch(setBreadcrumbs(newBreadcrumbs));
							// }
						}}
					>
						{b.name}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};
