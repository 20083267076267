/* eslint-disable no-empty */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import FileUpload from '../FileUpload';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { createUniqueFileName } from '@/utils/folders';
import { FileExistsDialog } from '../dialogs/FileExistsDialog';
import { useLazyGetFoldersAndFilesQuery, useLazySearchFoldersAndFilesQuery } from '@/features/msGraph/msGraphApi';
import { tryDecodeFilePath } from '@/utils/constants';

export const FileUploadMSGraph = () => {
	// const [filesToUpload, setFilesToUpload] = useState([]);
	const [filesQueue, setFilesQueue] = useState([]);

	const [searchFile] = useLazySearchFoldersAndFilesQuery();

	const [currentFile, setCurrentFile] = useState();
	const [dialogOpen, setDialogOpen] = useState(false);

	const [uploadLoading, setUploadLoading] = useState(false);

	const { client, canCreate, handleUploadFile, clientFolderDriveId } = useAttachmentsContext();

	const { itemId, loading, searchValue } = useSelector((state) => state.attachmentsTable);

	const isLoading = uploadLoading || loading;
	const disableUpload = !itemId || !canCreate || searchValue.length > 0;

	// const checkFileExists = (file) => {
	// 	return files.some((f) => f.name === file.name && !f.isFolder);
	// };

	const checkFilesExists = async (filesToSearch) => {
		const existing = [];
		const nonExisting = [];
		for (const file of filesToSearch) {
			const { files: search } = await searchFile({
				driveId: clientFolderDriveId,
				itemId,
				searchText: file.name,
                exact: true,
			}).unwrap();
            console.log('SEARCH FILES', search);
			if (search.some((f) => tryDecodeFilePath(f?.name, file.name) && f?.parentReference?.id == itemId)) {
				//
				const fileName = createUniqueFileName(file.name);
				existing.push({
					file: new File([file], fileName, {
						type: file.type,
						lastModified: file.lastModified,
					}),
					name: file.name,
				});
			} else {
				nonExisting.push(file);
			}
		}
		return { existing, nonExisting };
	};

	// Handle the upload
	const handleUploadFiles = async (files) => {
		setUploadLoading(true);
		for (const file of files) {
			await handleUploadFile(file);
		}
		// processNextFile();
		setUploadLoading(false);
	};

	// const processNextFile = () => {
	// 	if (filesQueue.length === 0) {
	// 		setDialogOpen(false);
	// 		setCurrentFile(null);
	// 		return;
	// 	}

	// 	const nextFile = filesQueue[0];
	// 	setCurrentFile(nextFile);
	// 	setDialogOpen(true);
	// };

	// Handle dialog actions
	const handleCreateCopy = async () => {
		if (!currentFile?.file) return;
		console.log('CURRENT FILE', currentFile);
		// const fileName = createUniqueFileName(currentFile.name);
		// const renamedFile = new File([currentFile], fileName, {
		// 	type: currentFile.type,
		// 	lastModified: currentFile.lastModified,
		// });
		setUploadLoading(true);
		await handleUploadFile(currentFile?.file);
		setDialogOpen(false);
		setUploadLoading(false);
		// handleNextFile();
	};

	const handleNextFile = () => {
		setDialogOpen(false);
		setCurrentFile(null);
		// handleUploadFiles(filesToUpload.slice(1));
	};

	useEffect(() => {
		console.log('FILES QUEUE', filesQueue);
		if (filesQueue.length > 0 && !dialogOpen) {
			const newQueue = [...filesQueue].slice();
			setCurrentFile(newQueue.pop());
			setDialogOpen(true);
			console.log('New queue', newQueue);
			setFilesQueue(newQueue);
		}
	}, [filesQueue, dialogOpen]);

	return (
		<>
			<Box width='100%' pb={'1em'}>
				{Object.keys(client ?? {}).length > 0 && !disableUpload && (
					<FileUpload
						files={[]}
						setFiles={async (newFiles) => {
							if (!disableUpload) {
								setUploadLoading(true);
								const { existing, nonExisting } = await checkFilesExists(newFiles);
								console.log('Existing', existing, 'non existing', nonExisting);
								// const existing = await checkFilesExists(filesToSearch).then(r => {
								//     return r.map((file) => {
								// 	const fileName = createUniqueFileName(file.name);
								// 	return {
								// 		file: new File([file], fileName, {
								// 			type: file.type,
								// 			lastModified: file.lastModified,
								// 		}),
								// 		name: file.name,
								// 	};
								// })
								// })
								// const existing = newFiles.filter(checkFileExists).map((file) => {
								// 	const fileName = createUniqueFileName(file.name);
								// 	return {
								// 		file: new File([file], fileName, {
								// 			type: file.type,
								// 			lastModified: file.lastModified,
								// 		}),
								// 		name: file.name,
								// 	};
								// });
								// const nonExisting = newFiles.filter(
								// 	(file) => !checkFileExists(file)
								// );
								handleUploadFiles(nonExisting).then(() => {
									setFilesQueue(existing);
								});
								// setFilesToUpload(files);
								// handleUploadFiles(newFiles);
							}
						}}
						loading={isLoading}
					/>
				)}
			</Box>
			<FileExistsDialog
				open={dialogOpen}
				fileName={currentFile?.name}
				onClose={handleNextFile}
				onCreateCopy={handleCreateCopy}
			/>
		</>
	);
};
